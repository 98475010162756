import firebase from 'firebase/app';

export default function* reorderTracks({ conferenceHandle, tracks }) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/tracks`)
    .transaction(dbTracks => {
      for (let i = 0, n = tracks.length; i < n; i += 1) {
        const dbTrack = dbTracks[tracks[i]];
        if (dbTrack) {
          dbTrack.order = i;
        }
      }
      return dbTracks;
    });
}
