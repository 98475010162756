import { defineMessages } from 'react-intl';

export default defineMessages({
  logIn: {
    id: 'app.components.AuthNavbar.logIn',
    defaultMessage: 'Log In',
  },
  signUp: {
    id: 'app.components.AuthNavbar.signUp',
    defaultMessage: 'Sign Up',
  },
});
