/*
 * EditSponsorPage Messages
 *
 * This contains all the text for the UsersPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  noData: {
    id: 'app.components.EditSponsorPage.noData',
    defaultMessage: 'There are no data.',
  },
  loading: {
    id: 'app.components.EditSponsorPage.loading',
    defaultMessage: 'Loading…',
  },
  error: {
    id: 'app.components.EditSponsorPage.error',
    defaultMessage:
      'There was an error loading the analytics page. Please refresh and try again. Contact the site administrator if the problem persists.',
  },
  confirmDelete: {
    id: 'app.components.EditSponsorPage.confirmDelete',
    defaultMessage: 'Deleting a sponsor is permanent. Proceed?',
  },
});
