/*
 * EditSponsorGroupPage Messages
 *
 * This contains all the text for the UsersPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  noData: {
    id: 'app.components.EditSponsorGroupPage.noData',
    defaultMessage: 'There are no data.',
  },
  loading: {
    id: 'app.components.EditSponsorGroupPage.loading',
    defaultMessage: 'Loading…',
  },
  error: {
    id: 'app.components.EditSponsorGroupPage.error',
    defaultMessage:
      'There was an error loading the analytics page. Please refresh and try again. Contact the site administrator if the problem persists.',
  },
  confirmDelete: {
    id: 'app.components.EditSponsorGroupPage.confirmDelete',
    defaultMessage:
      'Deleting a sponsor group is permanent and it will delete all sponsors in this group. Proceed?',
  },
});
