/**
 *
 * SpeakerView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const SpeakerView = ({ name }) => <div>{name}</div>;

SpeakerView.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SpeakerView;
