/*
 *
 * Embed constants
 *
 */

export const ACCEPT_CONFERENCE = 'sava/embed/ACCEPT_CONFERENCE';
export const ADD_TAG_FILTER = 'sava/embed/ADD_TAG_FILTER';
export const ADD_SPEAKER_FILTER = 'sava/embed/ADD_SPEAKER_FILTER';
export const SUBMIT_QUESTION = 'sava/embed/SUBMIT_QUESTION';
export const SET_QUESTION_ARCHIVED = 'sava/embed/SET_QUESTION_ARCHIVED';
