/**
 *
 * TagsInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { injectGlobal } from 'styled-components';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { List } from 'immutable';
import { isFunction } from 'is-of-type';

import ThinLabel from '../ThinLabel';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  .ReactTags__tags {
    display: flex;
  }

  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;
  }

  .ReactTags__tagInput {
    display: flex;
  }

  .ReactTags__tagInputField {
    padding: 0;
    border-top: 1px solid transparent;
    height: 32px;
    margin: 6px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ReactTags__selected .ReactTags__tag {
    background: #f0f0f0;
    padding: 4px 12px;
    margin-right: 4px;
    align-self: center;
    border-radius: 3px;
    margin-left: 6px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .ReactTags__selected .ReactTags__remove {
    margin-left: 6px;
    cursor: pointer;
  }

  .ReactTags__suggestions {
  }

  .ReactTags__activeSuggestion {
  }
`;

const Root = styled.div``;

const TagsInput = ({
  input: { value, onChange },
  transformProvider,
  additionTransformProvider,
  placeholder,
  isHintVisible,
  hintMessage,
}) => {
  const handleDelete = i => {
    onChange(value.delete(i));
  };

  const handleAddition = tag => {
    const newValue = isFunction(additionTransformProvider)
      ? additionTransformProvider(value, tag)
      : (value || List()).push(tag);

    onChange(newValue);
  };

  const handleDrag = (tag, currPos, newPos) => {
    onChange(value.delete(currPos).insert(newPos, value.get(currPos)));
  };

  const inputValue = isFunction(transformProvider)
    ? transformProvider(value)
    : (value || List()).map(v => ({ id: v, text: v }));

  return (
    <Root>
      <ReactTags
        tags={inputValue}
        suggestions={undefined}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        autofocus={false}
        placeholder={placeholder}
      />
      {isHintVisible ? <ThinLabel>{hintMessage}</ThinLabel> : null}
    </Root>
  );
};

TagsInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default TagsInput;
