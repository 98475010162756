import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/buttons/Button';
import { Header } from './styled';
import ToggleSwitch from '../../../components/ToggleSwitch';
import { Link } from '../../ModeratorPage/components/styled';
import Icon from '../../../components/Icon';

const ToggleLabel = styled.label`
  display: flex;
  align-items: end;
  font-weight: 400;
  > span {
    margin-left: 12px;
  }
`;

const getLink = conferenceHandle =>
  `${window.location.origin}/@${conferenceHandle}/embed/cfp`;

export default class ActionHeader extends React.Component {
  toggle = () =>
    this.props.onToggleAcceptingSubmissions(!this.props.acceptingSubmissions);

  render() {
    return (
      <Header>
        <Header.Item>
          <div>
            <Button
              type="secondary"
              to={`${this.props.rootUrl}/form-builder`}
              label={
                <span>
                  <Icon>assignment</Icon> Edit CFP Form
                </span>
              }
            />
          </div>
          <Link
            href={getLink(this.props.conferenceHandle)}
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: '0', marginTop: '2em' }}
          >
            {getLink(this.props.conferenceHandle)}
          </Link>
        </Header.Item>
        <Header.Item style={{ alignSelf: 'self-start' }}>
          <ToggleLabel>
            <ToggleSwitch
              value={this.props.acceptingSubmissions}
              onChange={this.toggle}
            />
            <span>
              {this.props.acceptingSubmissions
                ? 'Call for Papers is active.'
                : 'Call for Papers is inactive.'}
            </span>
          </ToggleLabel>
        </Header.Item>
      </Header>
    );
  }
}
