import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import isNonEmptyFileList from '../../../utils/isNonEmptyFileList';
import uploadFile from '../file/uploadFile';
import randomColor from '../../../utils/randomColor';

export default function* createSpeaker(
  conferenceHandle,
  name,
  headline,
  description,
  tags,
  image,
  profiles,
  doNotRoute
) {
  const speaker = {
    name,
    headline,
    color: randomColor(),
  };

  if (description) {
    speaker.description = description;
  }

  if (tags) {
    speaker.tags = tags;
  }

  if (profiles) {
    speaker.profiles = profiles;
  }

  const result = yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/speakers`)
    .push(speaker);

  const id = result.key;

  if (isNonEmptyFileList(image)) {
    const { url } = yield uploadFile(
      image[0],
      `/@${conferenceHandle}/speakers/${id}`
    );

    if (url) {
      yield firebase
        .database()
        .ref(`conferences/${conferenceHandle}/speakers/${id}`)
        .update({ image: url });
    }
  }

  try {
    window.$crisp.push([
      'set',
      'session:event',
      [
        [
          [
            'speaker_create',
            {
              id: id,
              name: name,
              headline: headline,
            },
            'orange',
          ],
        ],
      ],
    ]);
  } catch (e) {
    // crisp not ready
    console.log('crisp error', e);
  }

  if (!doNotRoute) {
    yield put(goBack());
  }

  return id;
}
