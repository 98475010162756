/*
 *  SessionTypeInput Messages
 *
 *  This contains all the text for the SessionTypeInput component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  namePlaceholder: {
    id: 'app.components.SessionTypeInput.namePlaceholder',
    defaultMessage: 'e.g., Talk',
  },
  durationPlaceholder: {
    id: 'app.components.SessionTypeInput.durationPlaceholder',
    defaultMessage: 'Duration e.g. 30',
  },
});
