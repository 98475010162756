import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  renderNothing,
  lifecycle,
  withState,
} from 'recompose';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import { toListOfMapsWithIds } from 'sava-shared/lib/utils/mapOfMapsToListOfMaps';
import { slugify } from 'sava-shared/lib/utils/core';

import messagingClients from '../../constants/messagingClients';
import Speakers from '../../components/CompactSpeakers/index';
import SavaPropTypes from '../../utils/SavaPropTypes';
import withNow from '../../hocs/withNow';
import { getAverage } from '../../utils/math/ratings';
import makeHeadline from '../../utils/makeHeadline';
import makeName from '../../utils/makeName';
import validateTimeDifference from '../../utils/validateTimeDifference';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import getMessagingClientsData from '../../sagas/user/integrations/getMessagingClientsData';
import { generateChatLink } from '../OverlayPage';
import emptyStateIllustration from '../../assets/images/empty-state-speakers.svg';
import PoweredBy from '../../components/PoweredBy';
import SessionQuestionsView from "../../components/SessionQuestionsView";

const listOfMessagingClients = toListOfMapsWithIds(messagingClients);

const Overlay = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 5fr;
  grid-template-rows: 6fr 1fr 3fr;
  width: 100%;
  height: 100%;
`;

const Promo = styled.div`
  border-right: 1px dotted rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1.5vw;
  grid-column: 1;
  grid-row: 1;
`;

const SpeakerStream = styled.div`
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  border-right: 1px dotted rgba(0, 0, 0, 0.1);
  grid-column: 1;
  grid-row: 2 / span 3;
`;

const VideoStream = styled.div`
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  grid-column: 2 / span 3;
  grid-row: 1 / span 2;
`;

const TalkSection = styled.div`
  padding: 1.5vw;
`;

const CurrentTalkSection = styled(TalkSection)`
  grid-column: 2;
  grid-row: 3;
  padding: 1.5vw;
`;

const UpcomingTalkSection = styled(TalkSection)`
  display: grid;
  grid-column: ${props => props.shouldSpan ? '2 / span 6' : '3'};
  grid-template-columns: ${props => props.shouldSpan ? '1fr 1fr' : '1fr'};
  grid-row: 3;
`;

const UpcomingTalk = styled.div``;

const CompactSpeakers = ({ speakers }) => (
  <div>
    <p style={{ fontSize: '20px' }}>{makeName(speakers)}</p>
    <p style={{ fontSize: '16px' }}>
      {makeHeadline(speakers.map(speaker => speaker.get('headline')))}
    </p>
  </div>
);

CompactSpeakers.propTypes = {
  speakers: PropTypes.any,
};

const Channels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Channel = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const ChannelIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 0.75rem;
`;

const NoTalks = styled.div`
  align-items: center;
  justify-content: space-between;
  text-align: center;

  > img {
    height: 18vh;
  }
`;

const Text = styled.h2`
  font-size: 1.8vw;
  margin-top: 1.5rem;
  line-height: 1.3;
  height: calc(1.8vw * 3 * 1.3);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const PromoText = styled(Text)`
  font-size: 1.8vw;
  font-weight: 100;
  height: auto;
`;

const SectionTitle = styled.h3`
  font-size: 1.2vw;
  margin-top: 0.5rem;
`;

const QuestionsPromo = ({messagingClientsData}) => (
  <Promo>
    <PromoText>Ask questions via the chatbot</PromoText>
    {messagingClientsData.length ? (
      <Channels row jcenter acenter>
        {messagingClientsData.map((messagingClient, index) => (
          <Channel key={index}>
            <ChannelIcon
              src={messagingClient.icon}
              alt={messagingClient.username}
            />
            <SectionTitle>
              {messagingClient.baseUrl}
              <span>/</span>
              {messagingClient.username}
            </SectionTitle>
          </Channel>
        ))}
      </Channels>
    ) : null}
    <PoweredBy margin="0" />
  </Promo>
);

const VideoMixOverlayPage = ({
  next,
  upcoming,
  best,
  status,
  messagingClientsData,
}) => (
  <Fragment>
    <Overlay>
      {next && next.get('questions', List()).size > 0 ?
        <QuestionsPromo messagingClientsData={messagingClientsData}/> :
        <QuestionsPromo messagingClientsData={messagingClientsData}/>
        }
      <VideoStream />
      <SpeakerStream />
      {next ? (
        <CurrentTalkSection>
          <Fragment>
            <SectionTitle>Current Talk</SectionTitle>
            <Text>{next.get('title')}</Text>
            <Speakers
              em={5}
              speakers={next.get('speakers')}
              type="normal"
              size="1vw"
            />
          </Fragment>
        </CurrentTalkSection>
      ) : null}
      <UpcomingTalkSection shouldSpan={!next}>
        {next || upcoming.count() > 0 ? (
          <Fragment>
            {upcoming.count() > 0 ? (
              upcoming.slice(0, next ? 1 : 2).map(talk => (
                <UpcomingTalk key={talk.get('id')}>
                  <SectionTitle>
                    Coming Up @ {talk.get('time').format('HH:mm')}
                  </SectionTitle>
                  <Text>{talk.get('title')}</Text>
                  <Speakers
                    speakers={talk.get('speakers')}
                    type="normal"
                    size="1vw"
                  />
                </UpcomingTalk>
              ))
            ) : (
              <p>None.</p>
            )}
          </Fragment>
        ) : (
          <NoTalks>
            <SectionTitle style={{ opacity: '.45' }}>
              No upcoming talks
            </SectionTitle>
            <img src={emptyStateIllustration} alt="No upcoming talks" />
          </NoTalks>
        )}
      </UpcomingTalkSection>
    </Overlay>
  </Fragment>
);

VideoMixOverlayPage.propTypes = {
  next: ImmutablePropTypes.contains({
    time: MomentPropTypes.momentObj,
    title: PropTypes.string,
    speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
  }),
  upcoming: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string,
      time: MomentPropTypes.momentObj,
      title: PropTypes.string,
      speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
    })
  ).isRequired,
  best: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.any,
      score: PropTypes.number,
      title: PropTypes.string,
      speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
    })
  ).isRequired,
  status: PropTypes.string,
};

const using = (...args) => f => f(...args);

const mapStateToProps = (state, props) => {
  if (!state.getIn(['embed', 'sessions'])) {
    return {};
  }

  const tomorrow = props.now
    .clone()
    .add(1, 'day')
    .startOf('day');

  const sessions = state
    .getIn(['embed', 'sessions'], List())
    .valueSeq()
    .toList();

  const trackSessions = sessions
    .filter(
      session =>
        slugify(session.getIn(['track', 'id'])) ===
        slugify(props.match.params.track)
    )
    .filter(session => session.get('endTime').isAfter(props.now))
    .filter(session => session.get('startTime').isBefore(tomorrow))
    .sortBy(session => session.get('startTime'), (a, b) => a.unix() - b.unix());


  const next = using(trackSessions.find(session => session.get('startTime').isBefore(props.now) && session.get('endTime').isAfter(props.now)))(
    talk =>
      talk &&
      Map({
        id: talk.get('id'),
        time: talk.get('startTime'),
        title: talk.get('title'),
        speakers: talk.get('speakers'),
        questions: talk.get('questions')
      })
  );

  const upcoming = trackSessions.filter(session => session.get('id') !== (next || Map()).get('id')).slice(0, 3).map(talk =>
    Map({
      id: talk.get('id'),
      time: talk.get('startTime'),
      title: talk.get('title'),
      speakers: talk.get('speakers'),
    })
  );

  const best = sessions
    .map(talk =>
      using(talk.get('ratings', List()))(ratings =>
        talk
          .set('score', getAverage(ratings.toJS()))
          .set('scores', ratings.count())
      )
    )
    .filter(talk => talk.get('scores') > 2)
    .sortBy(talk => talk.get('scores'))
    .reverse()
    .slice(0, 4)
    .sortBy(talk => talk.get('score'))
    .reverse()
    .map(talk =>
      Map({
        id: talk.get('id'),
        score: talk.get('score'),
        title: talk.get('title'),
        speakers: talk.get('speakers'),
      })
    );

  const diff =
    next &&
    validateTimeDifference(
      null,
      moment().format('HH:mm'),
      next.get('time').format('HH:mm')
    );
  let status;

  if (diff) {
    for (let i = 0; i < diff.length; i += 1) {
      if (diff[0] === 0 && diff[1] <= 5) {
        status = 5 - (5 - diff[1]);
      }
    }
  }

  return { next, upcoming, best, status };
};

const limitScores = Component =>
  class LimitScores extends React.Component {
    static propTypes = {
      best: PropTypes.any.isRequired,
      location: PropTypes.any.isRequired,
    };

    render() {
      const { best, location, ...props } = this.props;

      const { scores } = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      return (
        <Component
          {...props}
          best={best.take(
            using(parseInt(scores, 10))(scores =>
              Number.isFinite(scores)
                ? Math.max(0, scores)
                : Number.POSITIVE_INFINITY
            )
          )}
        />
      );
    }
  };

const lifecycleMethods = {
  async componentDidMount() {
    const {
      conferenceHandle,
      createUserRequestExecutor,
      setMessagingClientsData,
    } = this.props;
    const defaultUserRequestExecutor = createUserRequestExecutor({
      shouldNotifyToastr: false,
    });

    const messagingClientsData = await defaultUserRequestExecutor(
      getMessagingClientsData,
      conferenceHandle
    );

    const result = listOfMessagingClients
      .map(generateChatLink(messagingClientsData))
      .filter(_ => !!_);

    setMessagingClientsData(result);
  },
};

export default compose(
  withUserRequestExecutor,
  withRouter,
  withNow,
  connect(mapStateToProps),
  withState('messagingClientsData', 'setMessagingClientsData', {}),
  branch(props => !props.upcoming || !props.best, renderNothing),
  limitScores,
  lifecycle(lifecycleMethods)
)(VideoMixOverlayPage);
