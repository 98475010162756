import React from 'react';

import ContentLoading from '../components/ContentLoading';
import ContentError from '../components/ContentError';

export const createWithAsyncContent = ({
  Loading = ContentLoading,
  Error = ContentError,
  propName = 'content',
} = {}) => (promiseProvider, refreshProvider) => Component =>
  class AsyncContent extends React.Component {
    constructor() {
      super();

      this.state = {
        loading: true,
        error: false,
      };
    }

    async componentDidMount() {
      try {
        const content = await promiseProvider(this.props);
        this.setState({ loading: false, error: false, content });
      } catch (error) {
        this.setState({ loading: false, error: true });
      }
    }

    async componentDidUpdate(prevProps) {
      if (refreshProvider && refreshProvider(this.props, prevProps)) {
        promiseProvider(this.props).then(content => this.setState({ content }));
      }
    }

    render() {
      const { loading, error, content } = this.state;

      const props = {
        ...this.props,
        [propName]: content,
      };

      if (loading && !error) {
        return <Loading />;
      } else if (!loading && error) {
        return <Error />;
      }

      return <Component {...props} />;
    }
  };

export default createWithAsyncContent();
