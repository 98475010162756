/*
* ChatBuilderPage Container
*/

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, setPropTypes, withState } from 'recompose';
import styled from 'styled-components';
import { Map } from 'immutable';
import Flexbox from 'flexbox-react';
import { injectIntl } from 'react-intl';

import updateChatBuilderSettings from '../../sagas/user/conferences/chatBuilderSettings';
import updateResponsesToUnhandledMessages from '../../sagas/user/integrations/facebook/updateResponsesToUnhandlesMessages';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

import IntentList from '../../components/IntentList';

import ModalRoute from '../../components/ModalRoute';
import CreateChatIntentPage from '../CreateChatIntentPage';
import EditChatIntentPage from '../EditChatIntentPage';
import MessengerCustomMessage from '../../components/MessengerCustomMessage';
import CustomResponsesForm from '../../components/CustomResponsesForm';
import Button from '../../components/buttons/Button';
import EmptyState from '../../components/EntityEmptyState';
import emptyStateIllustration from '../../assets/images/conversation.svg';

import data from './descriptions';
import withAsyncContent from '../../hocs/withAsyncContent';
import withBreadcrumbs from '../../hocs/withBreadcrumbs';

import messages from './messages';
import settingsMessages from '../ChatbotConfiguration/messages';
import { createSelector } from 'reselect';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
} from '../../selectors/user';
import createFetchResource from '../../sagas/user/createFetchResource';

const TopBar = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
  text-align: right;
`;

const BlockWrapper = styled.div`
  background: white;
  ${props => props.theme.altitude(1)};
  padding: 1em 1.5em;
  flex-direction: column;
  margin: 1.5em 0.75em;
  max-height: 800px;
  flex-grow: 3;
  border-radius: 0.25em;
  border: ${props => props.theme.borders.default};
  align-self: flex-start;
`;

const BlockWrapperStripped = styled.div`
  margin: 1.5em 0.75em;
  align-self: flex-start;
  width: 50%;
`;

const MessengerConfiguration = styled.div`
  width: 50%;
  background: white;
  flex-direction: column;
`;

const Root = styled(Flexbox)`
  padding-left: 0.75em;
  padding-right: 0.75em;
`;

const Title = styled.div`
  margin-left: 40px;
  margin-top: 40px;
  font-size: 24px;
  font-weight: bold;
`;

const ChatBuilderPage = ({
  intl,
  conferenceHandle,
  conference,
  intents,
  match,
  customMessages,
  content,
  responses,
  formState,
  setFormState,
  createUserRequestExecutor,
}) => {
  const defaultUserRequestExecutor = createUserRequestExecutor(
    configuration(setFormState)
  );

  const handleSaveGreetingMessage = async message => {
    const data = {
      id: conferenceHandle,
      name: 'greetingMessage',
      message: message,
    };
    await defaultUserRequestExecutor(updateChatBuilderSettings, data);
  };

  const handleSaveGetStartedMessage = async message => {
    const data = {
      id: conferenceHandle,
      name: 'getStartedMessage',
      message: message,
    };
    await defaultUserRequestExecutor(updateChatBuilderSettings, data);
  };

  const handleSaveResponsesToUnhandledMessages = async responses => {
    const data = {
      id: conferenceHandle,
      responses: responses.get('responses').toJS(),
    };
    await defaultUserRequestExecutor(updateResponsesToUnhandledMessages, data);
  };

  const root = `/@${conferenceHandle}/conversations`;

  const getStartedMessage =
    (customMessages && customMessages.getStartedMessage) ||
    (content && content.getStartedMessage) ||
    '';
  const greetingMessage =
    (customMessages && customMessages.greetingMessage) ||
    (content && content.greetingMessage) ||
    '';

  return (
    <div>
      <Title>Conversation Automation</Title>
      <Root>
        <BlockWrapperStripped>
          <Helmet
            title={`${conference.get('name')} ${intl.formatMessage(
              messages.helmet
            )}`}
          />
          <TopBar>
            {intents.length > 0 ? (
              <Button
                to={`${root}/chatbuilder/new`}
                type="primary"
                label="Add New Intent"
              />
            ) : null}
          </TopBar>
          {intents.length > 0 ? (
            <IntentList intents={intents} root={`${root}/chatbuilder`} />
          ) : (
            <EmptyState
              illustration={emptyStateIllustration}
              icon=""
              title="Create an automated response"
              hint="Automatically reply to your users’ questions or comments with predefined responses."
            >
              <Button
                to={`${root}/chatbuilder/new`}
                type="primary"
                label="Create First Intent"
              />
            </EmptyState>
          )}
        </BlockWrapperStripped>
        <MessengerConfiguration>
          <BlockWrapper>
            <MessengerCustomMessage
              title={data[1].title}
              description={data[1].description}
              initialValue={getStartedMessage}
              onSubmit={handleSaveGetStartedMessage}
              disabled={formState.busy}
              previewConfig={{
                type: 'conversation',
                userMessage: 'Get Started',
              }}
            />
          </BlockWrapper>
          <BlockWrapper>
            <MessengerCustomMessage
              title={data[2].title}
              description={data[2].description}
              initialValue={greetingMessage}
              onSubmit={handleSaveGreetingMessage}
              disabled={formState.busy}
              previewConfig={{
                type: 'page',
                pageName: conferenceHandle,
              }}
              messages={messages.personalized}
            />
          </BlockWrapper>
          <BlockWrapper>
            <CustomResponsesForm
              initialValues={responses}
              onSubmit={handleSaveResponsesToUnhandledMessages}
            />
          </BlockWrapper>
        </MessengerConfiguration>

        <ModalRoute
          exact
          path={`${match.path}/new`}
          component={CreateChatIntentPage}
        />
        <ModalRoute
          exact
          path={`${match.path}/:id`}
          except={[`${match.path}/new`]}
          component={EditChatIntentPage}
        />
      </Root>
    </div>
  );
};

const breadcrumbsProvider = ({ conference, intl }) => [
  {
    to: `/@${conference.get('id')}/conversations`,
    title: intl.formatMessage(settingsMessages.breadcrumb),
  },
  {
    to: `/@${conference.get('id')}/conversations/chatbuilder`,
    title: intl.formatMessage(messages.breadcrumb),
  },
];

const selectIntents = createSelector(selectCurrentConference, conference =>
  conference
    .getIn(['chatbotSettings', 'customIntents'], Map())
    .entrySeq()
    .map(([k, v]) => v.set('id', k))
    .toList()
    .toJS()
);

const selectCustomMessages = createSelector(
  selectCurrentConference,
  conference =>
    conference.getIn(['chatbotSettings', 'customMessages'], Map()).toJS()
);

const selectResponses = createSelector(selectCurrentConference, conference =>
  conference
    .getIn(['chatbotSettings', 'customResponsesToUnhandledMessages'], Map())
    .entrySeq()
    .map(([k, v]) => v.set('id', k))
    .toJS()
);

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectIntents,
  selectCustomMessages,
  selectResponses,
  (conferenceHandle, conference, intents, customMessages, responses) => ({
    conferenceHandle,
    conference,
    intents,
    customMessages,
    responses: { responses },
  })
);

const configuration = setFormState => ({
  onStart: () => setFormState({ busy: true }),
  onSuccess: () => setFormState({ busy: false }),
  onFailure: e => setFormState({ busy: false }),
});

const asyncContentProvider = createFetchResource(
  'configuration/defaultChatbotSettings',
  {
    fallback: {},
    throwError: false,
  }
);

const asyncContentRefreshProvider = (props, prevProps) =>
  props.messages !== prevProps.messages;

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
  withBreadcrumbs(breadcrumbsProvider),
  withAsyncContent(asyncContentProvider, asyncContentRefreshProvider),
  withState('formState', 'setFormState', { busy: false }),
  withUserRequestExecutor,
  setPropTypes({
    conference: PropTypes.object.isRequired,
    conferenceHandle: PropTypes.string.isRequired,
    intents: PropTypes.array.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  })
)(ChatBuilderPage);
