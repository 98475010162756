import React from 'react';
import styled from 'styled-components';
import { compose, lifecycle, withState } from 'recompose';
import { componentWillMount } from './shared';
import BroadcastMetrics from './BroadcastMetrics';
import BroadcastScheduledTime from './BroadcastScheduledTime';
import MessengerTemplateList from '../MessengerPreviewMocks/MessengerTemplateList';

const BroadcastListWrapper = styled.div``;

const BroadcastList = ({
  items,
  conference,
  sentAt,
  messengerBroadcastId,
  messengerBroadcastFailed,
  reached,
  scheduled,
  scheduleTime,
  timezone,
}) => (
  <BroadcastListWrapper>
    {items.length > 0 && !scheduled && (
      <BroadcastMetrics
        sentAt={sentAt}
        messengerBroadcastId={messengerBroadcastId}
        messengerBroadcastFailed={messengerBroadcastFailed}
        reached={reached}
      />
    )}
    {items.length > 0 && scheduled && (
      <BroadcastScheduledTime timestamp={scheduleTime} timezone={timezone} />
    )}
    <MessengerTemplateList
      items={items.map(item => ({
        ...item,
        buttons: [
          {
            type: 'web_url',
            action: item.action,
            label: item.buttonLabel,
          },
        ],
      }))}
    />
  </BroadcastListWrapper>
);

export default compose(
  withState('reached', 'setReached', 0),
  lifecycle({ componentWillMount })
)(BroadcastList);
