import { createSelector } from 'reselect';
import { Map, fromJS } from 'immutable';

import { selectMatchParam } from './navigation';
import { selectProp } from './common';

import theme from '../theme';

export const selectBlock = () => state =>
  state ? state.get('user', Map()) : Map();

export const selectUser = () =>
  createSelector(selectBlock(), userState => userState.get('user'));

export const selectError = () =>
  createSelector(selectBlock(), userState =>
    userState.get('error') ? userState.get('error').toJS() : {}
  );

export const selectSuccess = () =>
  createSelector(selectBlock(), userState =>
    userState.get('success') ? userState.get('success').toJS() : {}
  );

export const selectUserData = createSelector(selectBlock(), userState =>
  userState.get('userData', Map())
);

export const selectConferences = createSelector(selectUserData, userData =>
  userData.get('conferences', Map())
);

export const selectCurrentConference = createSelector(
  selectUserData,
  userData => userData.get('currentConference', Map())
);

export const selectCurrentConferenceHandle = createSelector(
  selectCurrentConference,
  conference => conference.get('id')
);

export const selectUserFacebookPages = createSelector(selectBlock(), user =>
  user.get('fbPages', Map()).toJS()
);

export const selectConferenceName = createSelector(
  selectCurrentConference,
  conference => conference.get('name', 'Unknown Conference')
);

export const selectId = (state, props) => (props.match.params || {}).id;

export const selectBroadcasts = state =>
  state.getIn(['user', 'userData', 'currentConference', 'broadcasts'], Map());

export const selectBroadcastBasedOnMatchParam = createSelector(
  selectMatchParam('id'),
  selectBroadcasts,
  (matchParam, broadcasts) =>
    broadcasts.get(matchParam, Map()).set('id', matchParam)
);

export const selectTimezone = createSelector(
  selectCurrentConference,
  conference => conference.get('timezone', 'America/New_York')
);

export const selectHasPermission = createSelector(
  selectCurrentConference,
  selectUserData,
  selectProp('isAdministrator'),
  (conference, userData, isAdministrator) => {
    const isEditor = conference.getIn(['users', userData.get('id', '')], false);
    const isOwner = conference.get('owner', 1) === userData.get('id', 2);
    return isAdministrator || isOwner || isEditor;
  }
);

export const selectTheme = createSelector(selectCurrentConference, conference =>
  fromJS(theme)
    .merge(conference.get('theme', Map()) || Map())
    .toJS()
);
