import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';

const randomColor = () =>
  `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
    Math.random() * 255
  )}, ${Math.floor(Math.random() * 255)})`;

export default function* createSponsorGroup(conferenceHandle, name) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsorGroups`)
    .push({
      name,
      color: randomColor(),
    });

  yield put(goBack());
}
