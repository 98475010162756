import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';

import messages from './messages';

import { ellipsisText } from '../../utils/ui/content';
import EditButton from '../EditButton';

const Label = styled.div`
  width: 80%;
  font-weight: bold;
  ${ellipsisText};
`;

const CustomEditButton = styled(EditButton)`
  width: 20%;
  text-align: right;
`;

const QuickReplyItem = ({ item: quickReply, intl, match }) => {
  return (
    <Fragment>
      <Label>
        {quickReply.label || intl.formatMessage(messages.defaultLabel)}
      </Label>
      <CustomEditButton to={`${match.url}/${quickReply.id}`}>
        {intl.formatMessage(messages.edit)}
      </CustomEditButton>
    </Fragment>
  );
};

export default compose(
  injectIntl,
  withRouter
)(QuickReplyItem);
