import firebase from 'firebase/app';

export default function* disconnect(conferenceHandle) {
  yield firebase
    .functions()
    .httpsCallable('disconnectFacebookPageFromFacebookApp')({
    conferenceHandle,
  });

  return { success: true };
}
