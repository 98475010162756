import styled from 'styled-components';

export default styled.p`
  font-size: 0.85em;
  font-weight: 400;
  margin: auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: inline-block;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
`;
