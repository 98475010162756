import React from 'react';
import NavLink from '../NavLink';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

const Link = NavLink.withComponent('a');

class EmbedAwareNavLink extends React.Component {
  constructor(props) {
    super(props);
    this.inProgress = false;
    this.timer = null;
  }

  componentDidMount() {
    if (window !== window.parent) {
      window.addEventListener('message', this.handleMessage);
    }
  }

  componentWillUnmount() {
    if (window !== window.parent) {
      window.removeEventListener('message', this.handleMessage);
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  navigate = () => {
    const { to, history } = this.props;
    this.inProgress = false;
    history.push(to);
  };

  handleMessage = ({ data: { continueNavigation } }) => {
    if (continueNavigation && this.inProgress) {
      this.navigate();
    } else {
      this.inProgress = false;
    }
  };

  handleTimeout = () => {
    // Parent iframe didnt respond to our request
    if (this.inProgress) {
      this.navigate();
    }
  };

  handleClick = e => {
    e.preventDefault();
    if (!this.inProgress) {
      this.inProgress = true;
      // do this message passing only if embedded in a page as iframe
      const { to, history } = this.props;
      if (window !== window.parent) {
        window.parent.postMessage(
          {
            backNavigation: true,
            pathname: to,
            prevPathname: window.location.pathname,
            id: window.savaId,
          },
          '*'
        );
        this.timer = setTimeout(this.handleTimeout, 100);
      } else {
        this.inProgress = false;
        history.push(to);
      }
    }
  };

  render() {
    const { to, children } = this.props;
    return (
      <Link href={to} onClick={this.handleClick}>
        {children}
      </Link>
    );
  }
}

export default compose(withRouter)(EmbedAwareNavLink);
