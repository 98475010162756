import { defineMessages } from 'react-intl';

export default defineMessages({
  messageTypeText: {
    id: 'app.components.BroadcastsForm.messageType.text',
    defaultMessage: 'Text',
  },
  messageTypeList: {
    id: 'app.components.BroadcastsForm.messageType.list',
    defaultMessage: 'Gallery',
  },
  messageTypeGeneric: {
    id: 'app.components.BroadcastsForm.messageType.generic',
    defaultMessage: 'Image',
  },
  messageTypeFollowUp: {
    id: 'app.components.BroadcastsForm.messageType.followUp',
    defaultMessage: 'Form',
  },
});
