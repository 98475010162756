/*
 * ConferencesPage Container
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import color from 'color';
import styled, { css } from 'styled-components';
import colorForIndex from './colors';
import { button } from '../../utils/ui/interactive';
import { createSelector } from 'reselect';

import { selectConferences } from '../../selectors/user';

import Breadcrumb from '../../components/Breadcrumb';
import ActionLink from '../../components/ActionLink';

import { selectConference } from '../../actions/user';

const buttonBackgroundColor = color('white');
const linkBackgroundColor = color('#248BD1');

const sharedStyles = css`
  padding: 24px;
  width: 315px;
  height: 190px;
  box-sizing: border-box;
  margin: 12px;
  text-align: left;
  font-weight: 600;
  border: ${props => props.theme.borders.default};
  border-radius: 4px;
`;

const ConferenceActionLink = styled(ActionLink)`
  ${sharedStyles} font-size: 24px;
  border: ${props => props.theme.borders.default};
  border-top: 6px solid ${props => props.borderColor};
  border-radius: 4px;
  ${button({ backgroundColor: buttonBackgroundColor })};
`;

const CreateConferenceLink = styled(Link)`
  ${sharedStyles} ${button({ backgroundColor: linkBackgroundColor })};
  color: ${props => props.theme.colors.gray};
  font-size: 18px;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    text-align: center;
  }
`;

const ConferencesPage = ({ conferences }) => (
  <Fragment>
    <Helmet title="Select or Create an Event • Sava Events" />
    <Breadcrumb to="/">Select or Create an Event</Breadcrumb>
    <Flexbox
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexWrap="wrap"
    >
      {conferences.map((conference, index) => (
        <ConferenceActionLink
          key={conference.get('id')}
          action={selectConference}
          param={conference.get('id')}
          borderColor={colorForIndex(index)}
        >
          {conference.get('name')}
        </ConferenceActionLink>
      ))}
      <CreateConferenceLink to="/new">
        <span>Create An Event</span>
      </CreateConferenceLink>
    </Flexbox>
  </Fragment>
);

const mapStateToProps = createSelector(selectConferences, conferences => ({
  conferences,
}));

export default connect(mapStateToProps)(ConferencesPage);
