import React from 'react';
import styled from 'styled-components';
import { compose, setPropTypes } from 'recompose';

import Spinner from '../Spinner';
import PropTypes from 'prop-types';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SpinnerWrapper = styled.div`
  margin-right: 10px;
`;

const DefaultLoadingComponent = ({ message }) => (
  <LoadingWrapper>
    <SpinnerWrapper>
      <Spinner size={2} barThickness={2} />
    </SpinnerWrapper>
    {message ? <div>{message}</div> : null}
  </LoadingWrapper>
);

const DefaultErrorComponent = ({ message }) => message;

export const CreateLoadingIndicator = (
  LoadingComponent = DefaultLoadingComponent,
  ErrorComponent = DefaultErrorComponent
) => ({ isLoading, loadingMessage, hasError, errorMessage, children }) => {
  if (isLoading && !hasError) {
    return <LoadingComponent message={loadingMessage} />;
  } else if (hasError && !isLoading) {
    return <ErrorComponent message={errorMessage} />;
  } else if (!hasError && !isLoading) {
    return children || null;
  }

  return null;
};

const DefaultLoadingIndicator = compose(
  setPropTypes({
    isLoading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.node.isRequired,
    hasError: PropTypes.bool.isRequired,
    errorMessage: PropTypes.node.isRequired,
  })
)(CreateLoadingIndicator());

export default DefaultLoadingIndicator;
