/*
 * SpeakerPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Map, List } from 'immutable';
import styled, { css, withTheme } from 'styled-components';
import { slugify } from 'sava-shared/lib/utils/core';
import qs from 'qs';
import { createSelector } from 'reselect';

import { selectId } from '../../../selectors/user';

import ProfilesView from '../../../components/ProfilesView';
import Session from '../../../components/Session';
import RichTextView from '../../../components/RichTextView';
import Spinner from '../../../components/Spinner';

import getTwitterImage from '../../../utils/ui/getTwitterImage';

import SpeakerImage from '../../../components/SpeakerImage';
import EmbedAwareNavLink from '../../../components/EmbedAwareNavLink';
import Tags from '../../../components/Tags';
import PoweredBy from '../../../components/PoweredBy';

const NavLinkWrapper = styled.div`
  max-width: 560px;
  margin: 0 auto;
  padding-top: 4em;
  padding-left: 1em;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const media = {
  narrowerThan: width => (...args) => css`
    @media (max-width: ${width}px) {
      ${css(...args)};
    }
  `,
  smallerThanTwoContainers: (...args) => props =>
    media.narrowerThan(props.theme.contentWidth * 2)(...args),
  smallerThanContainer: (...args) => props =>
    media.narrowerThan(props.theme.contentWidth)(...args),
};

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${media.smallerThanContainer`
    flex-direction: column;
    align-items: center;
  `};
`;

const SpeakerImageWrapper = styled.div`
  padding-top: 1.5em;
  margin-left: -10em;
`;

const Info = styled.div`
  max-width: 560px;
  margin-left: 2em;
  margin-bottom: 2em;
`;

const Name = styled.h1`
  font-size: 36px;
  line-height: 1;
  margin-bottom: ${props => props.theme.baseSpacing / 4}px;
`;
const Headline = styled.p`
  font-size: 24px;
  line-height: 1;
  margin: 0;
  margin-bottom: ${props => props.theme.baseSpacing / 2}px;
  opacity: 0.5;
`;

const StyledRichTextView = styled(RichTextView)`
  margin-bottom: ${props => props.theme.baseSpacing / 2}px;
`;

const StyledProfilesView = styled(ProfilesView)`
  margin: ${props => props.theme.baseSpacing / 2}px 0;
`;

const Talks = styled.section``;

const SessionWrapper = styled.div`
  margin-bottom: 8px;
`;

class SpeakerPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    speaker: PropTypes.any.isRequired,
    theme: PropTypes.object.isRequired,
  };

  static provideLink = (route, { conferenceHandle, sessionTitle } = {}) => {
    switch (route) {
      case 'session':
        return `/@${conferenceHandle}/embed/schedule/sessions/${slugify(
          sessionTitle
        )}`;
      default:
        return undefined;
    }
  };

  render() {
    const { conferenceHandle, speaker } = this.props;

    if (speaker) {
      const profiles = speaker.get('profiles', List());

      const sessions = speaker.get('sessions', List());

      return (
        <div>
          <Helmet title="Speaker" />
          <NavLinkWrapper>
            <EmbedAwareNavLink to={`/@${conferenceHandle}/embed/speakers`}>
              ← View All Speakers
            </EmbedAwareNavLink>
          </NavLinkWrapper>
          <Profile>
            <SpeakerImageWrapper>
              <SpeakerImage
                backgroundColor={speaker.get('color', '#f0f0f0')}
                image={speaker.get('image')}
                type="large"
              />
            </SpeakerImageWrapper>
            <Info>
              <Name>{speaker.get('name', <span>&nbsp;</span>)}</Name>
              <Headline>
                {speaker.get(
                  'headline',
                  <span style={{ visibility: 'hidden' }}>–</span>
                )}
              </Headline>

              {speaker.get('description') && (
                <StyledRichTextView
                  raw={
                    speaker.get('description') &&
                    JSON.parse(speaker.get('description'))
                  }
                />
              )}

              {speaker.get('tags', List()).count() > 0 && (
                <Tags tags={speaker.get('tags')} />
              )}

              {profiles.count() > 0 && (
                <StyledProfilesView profiles={profiles} />
              )}

              {sessions.count() > 0 && (
                <Talks>
                  {sessions.map(session => (
                    <SessionWrapper key={session.get('id')}>
                      <Session
                        conferenceHandle={conferenceHandle}
                        linkProvider={SpeakerPage.provideLink}
                        session={session}
                        showDay
                        showTrack
                        ignoreDuration
                      />
                    </SessionWrapper>
                  ))}
                </Talks>
              )}
              <PoweredBy conferenceHandle={conferenceHandle} />
            </Info>
          </Profile>
        </div>
      );
    }

    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
}

const selectLocation = (state, props) => props.location;
const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;
const selectSpeakers = state => state.getIn(['embed', 'speakers'], Map());

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectId,
  selectLocation,
  selectSpeakers,
  (conferenceHandle, speakerId, location, speakers) => ({
    conferenceHandle,
    preferredSize: qs.parse(location.search).preferredSize
      ? parseInt(qs.parse(location.search).preferredSize, 10)
      : 320,
    speaker: speakers.find(possibleSpeaker => {
      const match =
        slugify(possibleSpeaker.get('name')) === speakerId ||
        possibleSpeaker.get('id') === speakerId;

      if (match) {
        possibleSpeaker.update('image', val => {
          if (val) {
            return val;
          }
          const twitter = possibleSpeaker
            .get('profiles', List())
            .find(profile => profile.get('icon') === 'twitter');
          if (!possibleSpeaker.get('image') && twitter) {
            return getTwitterImage(twitter.get('link'));
          }
          return undefined;
        });
      }

      return match;
    }),
  })
);

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps)
)(SpeakerPage);
