/*
 * SpeakersPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { List } from 'immutable';
import { compose } from 'recompose';
import styled from 'styled-components';
import { slugify } from 'sava-shared/lib/utils/core';
import { createSelector } from 'reselect';

import withBodySizes from '../../../utils/ui/withBodySizes';
import interactive, { link } from '../../../utils/ui/interactive';
import getTwitterImage from '../../../utils/ui/getTwitterImage';

import Speaker from '../../../components/Speaker';
import PoweredBy from '../../../components/PoweredBy';

const findOptimalSize = (canvasWidth, preferredSize) => {
  // this is ideal situation where the elements fit from edge to edge
  const maxElementSize = canvasWidth / Math.round(canvasWidth / preferredSize);

  // we want to have some padding on the edges in order for layout to fit well
  return maxElementSize - maxElementSize / 16;
};

const SpeakerLink = styled(Link)`
  transition: transform 80ms ease-in-out;

  ${link({ decorate: false })}

  ${interactive.styles`
    color: ${props => props.theme.page.color};
    & > :first-child > :first-child {
      ${props => props.theme.altitude(2)}
    }
  `}

  ${interactive.hover`
    transform: scale(1.05);
    & > :first-child > :first-child {
      ${props => props.theme.altitude(3)}
    }
  `}

  ${interactive.focus`
    outline: none; transform: scale(1.1);
  `}

  ${interactive.active`
    transform: scale(1.16);
  `}

`;

const SpeakersPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: ${props => props.theme.page.color};
  padding-top: 5em;
`;

class SpeakersPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    preferredSize: PropTypes.number.isRequired,
    speakers: PropTypes.any.isRequired,
    body: PropTypes.object.isRequired,
  };

  render() {
    const { conferenceHandle, preferredSize, speakers, body } = this.props;

    const optimalSize = findOptimalSize(body.clientWidth, preferredSize);

    return (
      <div
        style={{
          paddingLeft: optimalSize / 32,
          paddingRight: optimalSize / 32,
        }}
      >
        <SpeakersPageWrapper>
          <Helmet title="Speakers" />
          {speakers
            .map((speaker, speakerId) => (
              <SpeakerLink
                key={speakerId}
                to={`/@${conferenceHandle}/embed/speakers/${slugify(
                  speaker.get('name')
                )}`}
              >
                <Speaker size={optimalSize} speaker={speaker} />
              </SpeakerLink>
            ))
            .valueSeq()}
        </SpeakersPageWrapper>
        <PoweredBy conferenceHandle={conferenceHandle} />
      </div>
    );
  }
}

const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;

const selectTagFilters = (state, props) =>
  state.getIn(['embed', 'tagFilters']).toJS();
const selectSpeakersFilter = (state, props) =>
  (state.getIn(['embed', 'speakersFilter']) || List()).toJS();

const selectPreferredSize = (state, props) =>
  props.body.clientWidth < 640 ? 180 : 320;

const selectSpeakers = (state, props) =>
  state.getIn(['embed', 'speakers'], List());

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectTagFilters,
  selectSpeakersFilter,
  selectPreferredSize,
  selectSpeakers,
  (conferenceHandle, tagFilters, speakersFilter, preferredSize, speakers) => ({
    conferenceHandle,
    preferredSize,
    speakers: speakers
      .map(speaker => {
        const twitter = speaker
          .get('profiles', List())
          .find(profile => profile.get('icon') === 'twitter');
        if (!speaker.get('image') && twitter) {
          return speaker.set('image', getTwitterImage(twitter.get('link')));
        }
        return speaker;
      })
      .filter(speaker => {
        const tags = speaker.get('tags');

        if (tagFilters.length > 0) {
          return tags ? tags.find(tag => tagFilters.includes(tag)) : false;
        }

        return true;
      })
      .filter(speaker => {
        const slug = slugify(speaker.get('name'));
        const id = speaker.get('id');

        if (speakersFilter.length > 0) {
          return speakersFilter.includes(slug) || speakersFilter.includes(id);
        }

        return true;
      }),
  })
);

export default compose(
  withBodySizes,
  withRouter,
  connect(mapStateToProps)
)(SpeakersPage);
