import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Field, FieldArray } from 'redux-form/immutable';
import styled from 'styled-components';
import { compose } from 'recompose';
import { fromJS } from 'immutable';

import Icon from '../../Icon';
import { Button } from './shared';
import FormLabel from '../../FormLabel';
import ActualImageInput from '../../ImageInput';
import TextInput from '../../TextInput';
import TextAreaInput from '../../TextAreaInput';
import ButtonsInput from './ButtonsInput';

const ListItemRoot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ChildBlock = styled.div`
  width: 100%;
`;

const BorderBox = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid whitesmoke;
`;

const NoClearIcon = styled.div`
  height: 30px;
`;

export const MessageListItemWrapper = ({
  children,
  onRemove,
  min,
  current,
  fieldsCount,
}) => (
  <ListItemRoot>
    <ChildBlock>{children}</ChildBlock>
    <BorderBox>
      {fieldsCount > min ? (
        <Button small onClick={onRemove} marginTop>
          <Icon>clear</Icon>
        </Button>
      ) : (
        <NoClearIcon />
      )}
    </BorderBox>
  </ListItemRoot>
);

MessageListItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const ImageInput = ({
  subtitleRequired,
  fileRequired,
  buttonLabelRequired,
  buttonActionRequired,
  titleLimit,
  subtitleLimit,
  buttonLimit,
  messages,
  intl,
  name,
  value,
}) => (
  <ListItemRoot>
    <fieldset>
      <FormLabel
        htmlFor={`${name ? `${name}.` : ''}title`}
        message={intl.formatMessage(messages.messageListTitleLabel)}
        messageInfo={intl.formatMessage(messages.titleAndSubtitleLabelInfo)}
      />
      <Field
        name={`${name ? `${name}.` : ''}title`}
        placeholder={intl.formatMessage(messages.messageListTitlePlaceholder)}
        component={TextInput}
        props={{
          limit: titleLimit,
        }}
        required
      />
    </fieldset>

    <fieldset>
      <FormLabel
        htmlFor={`${name ? `${name}.` : ''}subtitle`}
        message={intl.formatMessage(messages.messageListSubtitleLabel)}
        messageInfo={intl.formatMessage(messages.titleAndSubtitleLabelInfo)}
      />
      <Field
        name={`${name ? `${name}.` : ''}subtitle`}
        placeholder={intl.formatMessage(
          messages.messageListSubtitlePlaceholder
        )}
        component={TextAreaInput}
        props={{
          limit: subtitleLimit,
        }}
        required={subtitleRequired}
      />
    </fieldset>

    <fieldset>
      <FormLabel
        htmlFor={`${name ? `${name}.` : ''}imageUrl`}
        message={intl.formatMessage(messages.messageListImageLabel)}
      />
      <Field
        name={`${name ? `${name}.` : ''}imageUrl`}
        component={ActualImageInput}
        required={fileRequired}
      />
    </fieldset>

    <fieldset>
      <FormLabel
        htmlFor={`${name ? `${name}.` : ''}buttons`}
        message="Action Buttons"
      />
      <FieldArray
        name={`${name ? `${name}.` : ''}buttons`}
        component={ButtonsInput}
        props={{
          props: {
            subtitleRequired,
            fileRequired,
            buttonLabelRequired,
            buttonActionRequired,
            titleLimit,
            subtitleLimit,
            buttonLimit,
            messages,
            intl,
          },
        }}
        values={value.get('buttons', fromJS([]))}
      />
    </fieldset>
  </ListItemRoot>
);

ImageInput.propTypes = {
  messages: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(ImageInput);
