import firebase from 'firebase/app';

export default function* reorderSpeakers(conferenceHandle, speakers) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/speakers`)
    .transaction(dbSpeakers => {
      for (let i = 0, n = speakers.length; i < n; i += 1) {
        const dbSpeaker = dbSpeakers[speakers[i]];
        if (dbSpeaker) {
          dbSpeaker.order = i;
        }
      }
      return dbSpeakers;
    });
}
