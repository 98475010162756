import { call, put, all } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import { toListOfMapsWithIds } from 'sava-shared/lib/utils/mapOfMapsToListOfMaps';

import deleteSessionSpeaker from '../session/deleteSpeaker';

function* confirm(message) {
  return yield window.confirm(`${message} Proceed?`);
}

export default function* deleteSpeaker(conferenceHandle, id, sessions) {
  const confirmed = yield call(
    confirm,
    'Deleting a speaker is permanent and will remove them from events.'
  );

  const sessionSpeakers = sessions.reduce((result, session) => {
    const speakers = toListOfMapsWithIds(session.speakers)
      .filter(speaker => speaker.id === id)
      .map(speaker => ({
        speakerId: speaker.id,
        sessionId: session.id,
      }));

    return result.concat(speakers);
  }, []);

  if (confirmed) {
    yield all(
      sessionSpeakers.map(sessionSpeaker =>
        call(
          deleteSessionSpeaker,
          conferenceHandle,
          sessionSpeaker.sessionId,
          sessionSpeaker.speakerId
        )
      )
    );

    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/speakers/${id}`)
      .remove();

    yield put(goBack());
  }
}
