/*
 * CreateSponsorGroupPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConferenceHandle,
  selectConferenceName,
} from '../../selectors/user';

import SponsorGroupForm from '../../components/SponsorGroupForm';

import createSponsorGroup from '../../sagas/user/sponsorGroups/create';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

export class CreateSponsorGroupPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  handleSubmit = async (data, dispatch, { form }) => {
    const { name, color } = data.toJS();

    await this.defaultUserRequestExecutor(
      createSponsorGroup,
      this.props.conferenceHandle,
      name,
      color,
      form
    );
  };

  render() {
    const { conferenceName } = this.props;

    return (
      <div>
        <Helmet
          title={`${conferenceName} • Create SponsorGroup • Sava Events`}
        />
        <SponsorGroupForm onSubmit={this.handleSubmit} isCreateForm />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  (conferenceHandle, conferenceName) => ({
    conferenceHandle,
    conferenceName,
  })
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor
)(CreateSponsorGroupPage);
