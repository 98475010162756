/**
 *
 * TrackPicker
 *
 */

import { Map } from 'immutable';

import SelectOrCreate from '../SelectOrCreate';
import TrackInput from '../TrackInput';
import TrackView from '../TrackView';

export default SelectOrCreate(TrackInput, TrackView, (state, props) =>
  state
    .getIn(['user', 'userData', 'currentConference', 'tracks'], Map())
    .entrySeq()
    .map(([k, v]) => v.set('id', k))
    .toJS()
);
