/*
 * SponsorForm Messages
 *
 * This contains all the text for the SponsorForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.SponsorForm.name',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'app.components.SponsorForm.namePlaceholder',
    defaultMessage: 'e.g., SomeComp LLC',
  },
  description: {
    id: 'app.components.SponsorForm.description',
    defaultMessage: 'Description',
  },
  image: {
    id: 'app.components.SponsorForm.image',
    defaultMessage: 'Image',
  },
  group: {
    id: 'app.components.SponsorForm.group',
    defaultMessage: 'Sponsor Group',
  },
  profiles: {
    id: 'app.components.SponsorForm.profiles',
    defaultMessage: 'Web Profiles',
  },
  create: {
    id: 'app.components.SponsorForm.create',
    defaultMessage: 'Add New Sponsor',
  },
  update: {
    id: 'app.components.SponsorForm.update',
    defaultMessage: 'Update Sponsor',
  },
  delete: {
    id: 'app.components.SponsorForm.update',
    defaultMessage: 'Delete',
  },
});
