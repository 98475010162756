import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import Modal from '../Modal';

const ModalRoute = ({
  exact,
  path,
  except,
  params = {},
  component: Component,
  modalProps = {},
}) => (
  <Switch>
    {except &&
      except.map(exception => <Route key={exception} path={exception} />)}
    <Route
      exact={exact}
      path={path}
      render={props => (
        <Modal key={path} {...modalProps}>
          <Component {...props} {...params} />
        </Modal>
      )}
    />
    <Route render={() => <Modal key={path} />} />
  </Switch>
);

ModalRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  except: PropTypes.array,
};

export default ModalRoute;
