const toSeconds = timeStr => {
  // Extract hours, minutes and seconds
  const parts = timeStr.split(':');
  // compute  and return total seconds
  return parts[0] * 3600 + parts[1] * 60;
};

export default (times, current, end) => {
  let startTime;
  let endTime;

  if (times !== null) {
    startTime = times.startTime;
    endTime = times.endTime;
  } else {
    startTime = current;
    endTime = end;
  }

  const s = startTime.split(':')[0];
  const e = endTime.split(':')[0];

  let result;
  const difference = Math.abs(toSeconds(startTime) - toSeconds(endTime));

  if (s !== e) {
    result = [
      Math.floor(difference / 3600), // an hour has 3600 seconds
      Math.floor((difference % 3600) / 60),
    ];
  } else {
    result = [-1, Math.floor((difference % 3600) / 60)];
  }

  // format time differnece
  return result;
};
