import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled, { css } from 'styled-components';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { button, link } from '../../utils/ui/interactive';
import Form from '../../components/Form';
import messages from './messages';
import OpenEye from '../icons/OpenEye.svg';

const Button = styled.button`
  border-radius: 3px;
  padding: 12px;
  color: white;
  width: 100%;
  ${button({ darken: true, backgroundColor: '#FF6800' })};
  margin: 12px 0px;
`;

const ToS = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: normal;

  color: ${props => props.theme.colors.gray};

  span {
    text-align: left;
  }

  input {
    :checked + .cbx:before {
      background: ${props => props.theme.colors.brandPrimary};
      border-color: ${props => props.theme.colors.brandPrimary};
    }

    :checked + .cbx:after {
      transform: rotate(40deg) scale(1);
    }
  }

  .cbx {
    position: relative;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;

    :before {
      content: '';
      position: relative;
      float: left;
      margin-right: 8px;
      width: 25px;
      height: 25px;
      border: 1px solid
        ${props =>
          props.isTermsErrorMessageVisible
            ? props.theme.colors.attention
            : '#d0d0d0'};
      border-radius: 2px;
      transition: all 0.4s ease;
    }

    :after {
      content: '';
      position: absolute;
      top: 3px;
      left: 9px;
      width: 7px;
      height: 14px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(25deg) scale(0.2);
      transition: transform 0.2s ease;
    }

    :hover:before {
      border-color: ${props => props.theme.colors.brandPrimary};
    }
  }
`;

const ToSLink = styled.a`
  color: #ccc;
  ${props => css`
    ${link({
      color: `${
        props.isTermsErrorMessageVisible
          ? props.theme.colors.attention
          : props.theme.colors.gray
      }`,
    })};
  `};
`;

const ToSError = styled.span`
  color: ${props => props.theme.colors.attention};
  position: absolute;
  left: 0;
  top: 135%;
  font-weight: bold;
`;

const PasswordField = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
`;

const PasswordIcon = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 20px;
  height: 15px;
  background-image: url(${OpenEye});
`;

let pwShown = 0;

const show = () => {
  const p = document.getElementById('input');
  p.setAttribute('type', 'text');
};

const hide = () => {
  const p = document.getElementById('input');
  p.setAttribute('type', 'password');
};

const checkPass = () => {
  if (pwShown === 0) {
    pwShown = 1;
    show();
  } else {
    pwShown = 0;
    hide();
  }
};

const LegalLink = ({ path, isTermsErrorMessageVisible, message }) => (
  <ToSLink
    href={`${process.env.REACT_APP_WEBSITE_URL}/legal/${path}`}
    target="_blank"
    isTermsErrorMessageVisible={isTermsErrorMessageVisible}
  >
    <FormattedMessage {...message} />
  </ToSLink>
);

/**
 * We are not using required field for the checkbox because we will validate
 * data before signing up a new user.
 */
const SignupForm = props => {
  const { handleSubmit, submitting, intl, isTermsErrorMessageVisible } = props;

  return (
    <Form
      id="app-signup-form"
      name="sign-up-form"
      onSubmit={handleSubmit}
      style={{ width: '100%', marginBottom: 20 }}
    >
      <fieldset>
        <FormattedMessage {...messages.name} />
        <Field
          name="fullName"
          component="input"
          type="name"
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          required
        />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.email} />
        <Field
          name="email"
          component="input"
          type="email"
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          required
        />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.password} />
        <PasswordField>
          <Field
            id="input"
            name="password"
            component="input"
            type="password"
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            required
          />
          <PasswordIcon onClick={checkPass} role="button" id="icon" />
        </PasswordField>
      </fieldset>

      <div style={{ marginBottom: '38px', position: 'relative' }}>
        <ToS isTermsErrorMessageVisible={isTermsErrorMessageVisible}>
          <Field
            type="checkbox"
            name="terms"
            component="input"
            style={{ display: 'none' }}
          />
          <span className="cbx" />
          <FormattedMessage
            {...messages.termsLabel}
            values={{
              termsOfService: (
                <LegalLink
                  path="tos"
                  isTermsErrorMessageVisible={isTermsErrorMessageVisible}
                  message={messages.termsOfService}
                />
              ),
              privacyPolicy: (
                <LegalLink
                  path="privacy"
                  isTermsErrorMessageVisible={isTermsErrorMessageVisible}
                  message={messages.privacyPolicy}
                />
              ),
            }}
          />
        </ToS>
        <ToSError>
          {isTermsErrorMessageVisible
            ? intl.formatMessage(messages.mustAgreeWarning)
            : null}
        </ToSError>
      </div>
      <fieldset>
        <Button disabled={submitting}>
          <FormattedMessage {...messages.signUp} />
        </Button>
      </fieldset>
    </Form>
  );
};

SignupForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
  isTermsErrorMessageVisible: PropTypes.bool.isRequired,
};

const selector = formValueSelector('signup');

const mapStateToProps = state => {
  const terms = selector(state, 'terms');

  return {
    terms: !!terms,
  };
};

const formProps = {
  form: 'signup',
};

const lifecycleMethods = {
  /**
   * When the terms checkbox changes value this is where we execute onTermsChange method.
   */
  componentDidUpdate(prevProps) {
    const { terms, onTermsChange } = this.props;
    const { terms: prevTerms } = prevProps;

    if (prevTerms !== terms) {
      onTermsChange(terms);
    }
  },
};

export default compose(
  injectIntl,
  connect(mapStateToProps),
  lifecycle(lifecycleMethods),
  reduxForm(formProps)
)(SignupForm);
