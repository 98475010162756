import firebase from 'firebase/app';

export default function* connectFacebookPageToFacebookApp(
  conferenceHandle,
  pageId,
  pageToken,
  name,
  username
) {
  yield firebase.functions().httpsCallable('connectFacebookPageToFacebookApp')({
    conferenceHandle,
    pageId,
    pageToken,
    name,
    username,
  });
}
