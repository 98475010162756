/**
 *
 * SpeakersList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { link } from '../../utils/ui/interactive';
import { addFallbackProfilePhoto } from '../../utils/ui/getTwitterImage';
import SpeakerImage from '../../components/SpeakerImage';
import SpeakerInfo from '../../components/SpeakerInfo';

const using = (...values) => f => f(...values);

const SpeakerLink = styled(Link)`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  ${props =>
    link({
      color: props.options.usePageColor
        ? props.theme.page.color
        : props.theme.card.textColor,
    })};
`;

const Speakers = styled.div`
  display: flex;
  flex-direction: column;
`;

const Speaker = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: left;
  margin-bottom: ${props =>
    (props.theme.SpeakersList || {}).baseSize / 4 || 8}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SpeakersList = ({
  speakers,
  linkProvider,
  conferenceHandle,
  usePageColor,
  type,
  ...props
}) => (
  <Speakers {...props}>
    {addFallbackProfilePhoto(speakers).map(speaker =>
      using(
        linkProvider('speaker', {
          conferenceHandle,
          speakerId: speaker.get('id'),
          speakerName: speaker.get('name'),
        })
      )(speakerLink =>
        using(
          <Speaker key={speaker.get('id')}>
            <SpeakerImage
              usePageColor={usePageColor}
              backgroundColor={speaker.get('color', '#e0e0e0')}
              image={speaker.get('image')}
              type={type || 'small'}
            />
            <SpeakerInfo
              usePageColor={usePageColor}
              name={speaker.get('name')}
              headline={speaker.get('headline')}
              hideHeadline={speakers.count() > 1}
              size=".875em"
            />
          </Speaker>
        )(speakerView =>
          speakerLink
            ? (() => (
                <SpeakerLink
                  key={speaker.get('id')}
                  to={speakerLink}
                  options={{
                    usePageColor,
                  }}
                >
                  {speakerView}
                </SpeakerLink>
              ))()
            : speakerView
        )
      )
    )}
  </Speakers>
);

SpeakersList.propTypes = {
  speakers: PropTypes.any.isRequired,
  linkProvider: PropTypes.func.isRequired,
  conferenceHandle: PropTypes.string.isRequired,
};

export default SpeakersList;
