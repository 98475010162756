/**
 * Given an object and predicate function, returns a new object with key/value pairs
 * that the predicate function did not filter.
 *
 * The predicate function receives two arguments: value and key, and returns true if
 * the given key/value pair should be omitted.
 *
 * @param {Object} obj
 * @param {function (value: *, key: string): boolean} predicate
 * @return {Object}
 */
export default (obj = {}, predicate) =>
  Object.keys(obj).reduce((acc, key) => {
    if (!predicate(obj[key], key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
