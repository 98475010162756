import { fromJS } from 'immutable';
import moment from 'moment-timezone';

export const session = fromJS({
  day: 0,
  speakers: [
    {
      id: '',
      color: '',
      image:
        'https://img.etimg.com/thumb/msid-64633722,width-643,imgsize-187011,resizemode-4/einstein-commons-wikimedia.jpg',
      name: 'Albert Einstain',
      headline: 'Genius.',
      profiles: [],
    },
  ],
  questions: [],
  ratings: [],
  tags: ['this', 'that', 'here'],
  startTime: moment(),
  endTime: moment().add(90, 'minutes'),
  track: {
    name: 'Sava Events',
  },
  title: 'Understanding E=mc²',
  description:
    '{"blocks":[{"key":"639hh","text":"In publishing and graphic design, lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document without relying on meaningful content. Replacing the actual content with placeholder text allows designers to design the form of the content before the content itself has been produced.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  backgroundColor: '#122E40',
});
