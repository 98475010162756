/*
 * SponsorPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';
import { slugify } from 'sava-shared/lib/utils/core';
import { createSelector } from 'reselect';

import { selectId } from '../../../selectors/user';

import PropertyList, {
  Property,
  Term,
  Definition,
} from '../../../components/PropertyList';

import Image from '../../../components/Image';
import RichTextView from '../../../components/RichTextView';
import ProfilesView from '../../../components/ProfilesView';
import Spinner from '../../../components/Spinner';
import Session from '../../../components/Session';
import EmbedAwareNavLink from '../../../components/EmbedAwareNavLink';

const H2 = styled.h2`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 24px;
`;

const StyledProfilesView = styled(ProfilesView)`
  margin: ${props => props.theme.baseSpacing / 3}px 0;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

class SponsorPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    sponsor: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.string,
      })
    ).isRequired,
  };

  render() {
    const { conferenceHandle, sponsor } = this.props;

    if (sponsor) {
      const description = sponsor.get('description') && (
        <RichTextView
          raw={
            sponsor.get('description') && JSON.parse(sponsor.get('description'))
          }
        />
      );
      const sessions = sponsor.get('sessions', List());
      const profiles = sponsor.get('profiles', List());

      return (
        <PropertyList>
          <Helmet title="Sponsor" />
          <Definition>
            <EmbedAwareNavLink to={`/@${conferenceHandle}/embed/sponsors`}>
              ← View Sponsors
            </EmbedAwareNavLink>
          </Definition>
          <Property>
            <Term />
            <Definition alignItems="center">
              <Image
                width={200}
                height={200}
                margin={0}
                src={sponsor.get('image')}
                alt={sponsor.get('name')}
                style={{
                  transform: `scale(${sponsor.get(
                    'scale',
                    1
                  )}) translate(${sponsor.getIn(
                    ['offset', 'x'],
                    0
                  )}px, ${sponsor.getIn(['offset', 'y'], 0)}px)`,
                }}
                contain
              />
            </Definition>
          </Property>
          {profiles.count() > 0 && <StyledProfilesView profiles={profiles} />}
          {description && (
            <Property>
              <Term>
                <H2>About</H2>
              </Term>
              <Definition style={{ marginTop: -7.5 }}>{description}</Definition>
            </Property>
          )}
          {sessions.count() > 0 && (
            <Property>
              <Term>
                <H2>Talks</H2>
              </Term>
              <Definition>
                {sessions.map(session => (
                  <Session
                    key={session.get('id')}
                    conferenceHandle={conferenceHandle}
                    linkProvider={SponsorPage.provideLink}
                    session={session}
                    showDay
                    showTrack
                  />
                ))}
              </Definition>
            </Property>
          )}
        </PropertyList>
      );
    }

    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
}

const selectSponsor = (state, props) => {
  const sponsorId = selectId(state, props);
  return state
    .getIn(['embed', 'sponsorGroups'], List())
    .flatMap(sponsorGroup => sponsorGroup.get('sponsors', List()))
    .find(
      sponsor =>
        slugify(sponsor.get('name')) === sponsorId ||
        sponsor.get('id') === sponsorId
    );
};

const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectSponsor,
  (conferenceHandle, sponsor) => ({
    conferenceHandle,
    sponsor,
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps)
)(SponsorPage);
