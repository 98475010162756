/*
 * ConferenceSettingsThemeCustomization Messages
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.ThemeCustomization.title',
    defaultMessage: 'Theme Customization • Sava Events',
  },
  breadcrumbSettings: {
    id: 'app.containers.ThemeCustomization.breadcrumbSettings',
    defaultMessage: 'Settings',
  },
  themeCustomization: {
    id: 'app.containers.ThemeCustomization.themeCustomization',
    defaultMessage: 'Theme Customization',
  },
  dirtyFormLeavePrompt: {
    id: 'app.containers.ThemeCustomization.dirtyFormLeavePrompt',
    defaultMessage: 'You have unsaved changes. Are you sure you want to leave?',
  },
});
