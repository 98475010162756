import React from 'react';
import interactive, { link } from '../../utils/ui/interactive';
import styled, { css } from 'styled-components';

const Button = styled.button`
  ${props => css`
    padding: 0 12px;
    ${interactive.focus`
      outline: none;
    `} ${link({ color: `${props.theme.colors.attention}` })};
  `};
`;

const DeleteButton = props => <Button {...props} />;

export default DeleteButton;
