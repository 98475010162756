import { injectGlobal } from 'styled-components';
import styles from 'sava-shared/lib/utils/styleConstants';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Nunito Sans', sans-serif;
    color: ${styles.baseFontColor};
    background-color: transparent;
    -webkit-font-smoothing: antialiased;
  }

  body.fontLoaded {
    font-family: 'Nunito Sans', sans-serif;
  }

  #app {
    height: 100%;
  }

  p,
  label {
    font-family: 'Nunito Sans', sans-serif;
    line-height: 1.5em;
  }

  label {
    font-family: inherit;
    font-weight: bold;
  }

  h3 {
    font-size: 1.25rem;
  }

  button {
    padding: 0;
  }

  .material-icons {
    vertical-align: text-bottom;
  }

  // Necessary for React-tippy to work smoothly on touch devices
  .tippy-touch { cursor: pointer !important; }

  .tippy-tooltip { background-color: #249bd1; }

  .tippy-popper[x-placement^=top] [x-arrow] {
    border-top: 7px solid #249bd1;
  }
  
  // override 3rd party style for consistency with other UI elements
  .Select-control {
    height: 42px !important;
  }
  .Select-value-label > div:not([class]) {
    margin-top: 6px;
  }
`;
