import { defineMessages } from 'react-intl';

export default defineMessages({
  inVenueSectionTitle: {
    id: 'app.containers.ModeratorPage.inVenueTitle',
    defaultMessage: 'In-Venue',
  },
  inVenueSectionSubTitle: {
    id: 'app.containers.ModeratorPage.inVenueSubTitle',
    defaultMessage:
      'In-Venue digital signage are screens you can share with your\n' +
      '              attendees by placing them on venue monitors or large screens with\n' +
      '              Internet access.',
  },
  inVenueAllTracksTitle: {
    id: 'app.containers.ModeratorPage.inVenueAllTracksTitle',
    defaultMessage: 'All tracks overview screen',
  },
  inVenueAllTracksSubTitle: {
    id: 'app.containers.ModeratorPage.inVenueAllTracksSubTitle',
    defaultMessage:
      'Explore and present your full schedule in a beautiful way to your attendees.',
  },
  inVenueSingleTrackTitle: {
    id: 'app.containers.ModeratorPage.inVenueSingleTrackTitle',
    defaultMessage: 'Single track overview screen',
  },
  inVenueSingleTrackSubTitle: {
    id: 'app.containers.ModeratorPage.inVenueSingleTrackSubTitle',
    defaultMessage:
      'Follow single tracks inside the venue space for clear picture of that room.\n' +
      '\n',
  },
  inVenueScoreboardTitle: {
    id: 'app.containers.ModeratorPage.inVenueScoreboardTitle',
    defaultMessage: 'Scoreboard',
  },
  inVenueScoreboardSubTitle: {
    id: 'app.containers.ModeratorPage.inVenueScoreboardSubTitle',
    defaultMessage:
      'Engage speakers with attendees rank their talks, live, from any screen.',
  },

  qaSectionTitle: {
    id: 'app.containers.ModeratorPage.qaSectionTitle',
    defaultMessage: 'Questions & Answers',
  },
  qaSectionSubTitle: {
    id: 'app.containers.ModeratorPage.qaSectionSubTitle',
    defaultMessage:
      'Questions & Answers is meant for moderators to follow incoming questions from attendees in real time. Moderators can than share the questions to the speakers.',
  },
  qaModeratorTitle: {
    id: 'app.containers.ModeratorPage.qaModeratorTitle',
    defaultMessage: 'Question Moderator Screen',
  },
  qaModeratorSubTitle: {
    id: 'app.containers.ModeratorPage.qaModeratorSubTitle',
    defaultMessage:
      'A screen where the moderator can approve user questions to be answered.',
  },
  qaQuestionsTitle: {
    id: 'app.containers.ModeratorPage.qaQuestionsTitle',
    defaultMessage: 'Questions Screen',
  },
  qaQuestionsSubTitle: {
    id: 'app.containers.ModeratorPage.qaQuestionsSubTitle',
    defaultMessage:
      'Share this link so users can access a screen to ask questions for the speaker.',
  },
  qaPublicTitle: {
    id: 'app.containers.ModeratorPage.qaPublicTitle',
    defaultMessage: 'Public screen',
  },
  qaPublicSubTitle: {
    id: 'app.containers.ModeratorPage.qaPublicSubTitle',
    defaultMessage:
      'You can place this page on your big screens and canvases to show user questions.\n' +
      '\n',
  },

  videoMixingTitle: {
    id: 'app.containers.ModeratorPage.qaPublicTitle',
    defaultMessage: 'Video Mix screen',
  },
  videoMixingSubTitle: {
    id: 'app.containers.ModeratorPage.qaPublicSubTitle',
    defaultMessage:
      'You can use this overlay to combine live streaming elements and event information.\n' +
      '\n',
  },
});
