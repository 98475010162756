/*
 * EditSpeakerPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Map, List } from 'immutable';
import { createSelector } from 'reselect';

import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectId,
} from '../../selectors/user';

import SpeakerForm from '../../components/SpeakerForm';

import updateSpeaker from '../../sagas/user/speakers/update';
import deleteSpeaker from '../../sagas/user/speakers/delete';
import { formatLinkToHandle } from '../../utils/formatters';
import withUserRequestExecutor, {
  errorMessages,
} from '../../hocs/withUserRequestExecutor';

export class EditSpeakerPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
    speaker: PropTypes.any.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages,
  });

  handleSubmit = async (data, dispatch, { form }) => {
    const { id, name, headline, description, tags, image, profiles } = data
      .update('profiles', List(), _profiles =>
        _profiles.map(profile => {
          const icon = profile.get('icon');
          return [
            'facebook',
            'github',
            'google',
            'linkedIn',
            'twitter',
            'instagram',
          ].indexOf(icon) >= 0
            ? profile
            : profile.delete('icon');
        })
      )
      .toJS();

    await this.defaultUserRequestExecutor(
      updateSpeaker,
      this.props.conferenceHandle,
      id,
      name,
      headline,
      description,
      tags,
      image,
      profiles || [],
      form
    );
  };

  handleDelete = async () => {
    const {
      conferenceHandle,
      speaker: { id },
      sessions,
    } = this.props;

    await this.defaultUserRequestExecutor(
      deleteSpeaker,
      conferenceHandle,
      id,
      sessions.toList().toJS()
    );
  };

  render() {
    const { conferenceHandle, conferenceName, speaker } = this.props;
    return (
      <div>
        <Helmet title={`${conferenceName} • ${speaker.name}`} />
        <SpeakerForm
          conferenceHandle={conferenceHandle}
          initialValues={speaker}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectConferenceName,
  selectId,
  (conferenceHandle, conference, conferenceName, id) => ({
    conferenceHandle,
    speaker: conference
      .getIn(['speakers', id], Map())
      .set('id', id)
      .update('profiles', List(), profiles =>
        profiles.map(profile => {
          if (
            profile.has('icon') &&
            ['twitter', 'instagram', 'github'].includes(profile.get('icon'))
          ) {
            return profile.update('link', link => formatLinkToHandle(link));
          }

          return profile;
        })
      )
      .toJS(),
    conferenceName,
    sessions: conference
      .get('sessions', Map())
      .map((session, sessionId) => session.set('id', sessionId)),
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  withUserRequestExecutor
)(EditSpeakerPage);
