import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import styled from 'styled-components';
import { Container } from './components/styled';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
} from '../../selectors/user';
import Breadcrumb from '../../components/Breadcrumb';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import Button from '../../components/buttons/Button';
import FormBuilder from './components/FormBuilder';
import saveSubmissionForm from '../../sagas/user/cfp/saveSubmissionForm';

const BackLink = styled(Button)`
  margin: 0 0 0.5em 0;
  padding: 0;
  > span {
    margin: 0;
  }
`;

class CallForPapersFormBuilderPage extends Component {
  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  handSubmit = async data => {
    const { config, blocks } = data.toJS();
    this.defaultUserRequestExecutor(
      saveSubmissionForm,
      this.props.conferenceHandle,
      blocks,
      config
    );
  };

  render() {
    const root = `/@${this.props.conference.get('id')}/cfp`;
    const cfpData = this.props.conference.get('cfp')
      ? this.props.conference.get('cfp').toJS()
      : {};

    return (
      <div>
        <Helmet
          title={`${this.props.conference.get(
            'name'
          )} Call for Papers • Sava Events`}
        />
        <Breadcrumb to={`${root}/form-builder`}>CFP Form Builder</Breadcrumb>
        <Container style={{ flexDirection: 'column' }}>
          <BackLink type="link" to={root} label="Back to CFP Manager" />
          <FormBuilder
            initialValues={fromJS({
              config: cfpData.submissionFormConfig || {},
              blocks: cfpData.submissionFormBlocks || [],
            })}
            onSubmit={this.handSubmit}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  (conferenceHandle, conference) => ({
    conferenceHandle,
    conference,
  })
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor
)(CallForPapersFormBuilderPage);
