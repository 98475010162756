import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

import Form from '../../components/Form';

import messages from './messages';

const Button = styled.button`
  padding: 12px;

  ${button({ lighten: true })};
`;

const InfoParagraph = styled.p`
  margin-top: 0;
`;

const ForgotPasswordForm = props => {
  const { handleSubmit, pristine, submitting, intl } = props;

  return (
    <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <FormattedMessage {...messages.info}>
        {info => <InfoParagraph>{info}</InfoParagraph>}
      </FormattedMessage>

      <fieldset>
        <FormattedMessage {...messages.email} />
        <Field
          name="email"
          component="input"
          type="email"
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          required
        />
      </fieldset>

      <fieldset>
        <Button disabled={pristine || submitting}>
          <FormattedMessage {...messages.requestReset} />
        </Button>
      </fieldset>
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default reduxForm({
  form: 'forgotPassword',
})(injectIntl(ForgotPasswordForm));
