import firebase from 'firebase/app';

export default function* scaleSponsorGroup(
  conferenceHandle,
  sponsorGroupId,
  scale
) {
  yield firebase
    .database()
    .ref(
      `conferences/${conferenceHandle}/sponsorGroups/${sponsorGroupId}/scale`
    )
    .set(scale);
}
