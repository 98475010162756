import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';

import { clearUser, logOutError } from '../../../actions/user';

export default function* logOut() {
  try {
    firebase.auth().signOut();
    yield put(clearUser());

    yield put(push('/'));
  } catch (error) {
    yield put(logOutError(error));
  }
}
