import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.ResetPasswordPage.title',
    defaultMessage: 'Reset Password • Sava Events',
  },
  header: {
    id: 'app.containers.ResetPasswordPage.header',
    defaultMessage: 'Reset Password',
  },
  resetPasswordSuccess: {
    id: 'app.containers.ResetPasswordPage.resetPasswordSuccess',
    defaultMessage:
      'You have successfully changed your password. You can now sign in with your new password.',
  },
});
