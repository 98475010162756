import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { EditLink } from './EditLink';
import InfoBox from './InfoBox';
import { Token, ActionToken } from './tokens';
import messages from '../messages';

export const QueriesWrapper = styled.div`
  display: flex;
  padding: 0.75em 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
`;

const QueryList = styled.div`
  flex: 0 0 70%;
`;

export default injectIntl(({ rootUrl, queries, intentId }) => (
  <QueriesWrapper subtle={queries.length > 0} flex="2">
    <InfoBox
      title={messages.phrasesListTitle}
      subtitle={messages.phrasesListInfo}
    />
    <QueryList>
      {queries.length > 0
        ? queries.map((item, key) => <Token key={key}>{item.query}</Token>)
        : null}
      <ActionToken>
        <FormattedMessage
          {...messages[queries.length > 0 ? 'phrasesAdded' : 'phrasesEmpty']}
        >
          {message => (
            <EditLink to={`${rootUrl}/${intentId}`}>{message}</EditLink>
          )}
        </FormattedMessage>
      </ActionToken>
    </QueryList>
  </QueriesWrapper>
));
