import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import MomentPropTypes from 'react-moment-proptypes';

const speaker = ImmutablePropTypes.contains({
  id: PropTypes.string,
  name: PropTypes.string,
  headline: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
});

const session = ImmutablePropTypes.contains({
  id: PropTypes.string,
  title: PropTypes.string,
  day: PropTypes.number,
  startTime: MomentPropTypes.momentObj,
  endTime: MomentPropTypes.momentObj,
  speakers: ImmutablePropTypes.listOf(speaker),
  sessionType: ImmutablePropTypes.contains({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
  }),
});

export default {
  session,
  speaker,
};
