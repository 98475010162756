import React from 'react';
import styled from 'styled-components';
import { createSelector } from 'reselect';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Box } from './common';
import {
  flatten,
  findUserProfile,
  mergeTicketsWithProfiles,
  transduceMessengerUsers,
  transduceTelegramUsers,
  getOpenTickets,
  getClosedTickets,
} from './domain/helpers';
import RTUpdater from '../../utils/RTUpdater';
import TicketBox from './subcomponents/TicketBox';
import ContentArea from './subcomponents/ContentArea';
import {
  selectCurrentConferenceHandle,
  selectUserData,
} from '../../selectors/user';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import createFetchResource from '../../sagas/user/createFetchResource';
import { createWithAsyncContent } from '../../hocs/withAsyncContent';
import closeTicket from '../../sagas/user/supportinbox/closeTicket';
import NoTickets from './subcomponents/NoTickets';

const TicketUpdater = new RTUpdater();
const OpenTickets = TicketBox('open');
const ClosedTickets = TicketBox('closed');
const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1.5em;
  max-height: 980px;
`;

class SupportInbox extends React.Component {
  state = { tickets: [] };
  defaultUserRequestExecutor = this.props.createUserRequestExecutor();
  chatInputRequestExecutor = this.props.createUserRequestExecutor({
    shouldNotifyToastr: false,
  });

  componentDidMount = () => {
    TicketUpdater.subscribe(this.onTicketChange);
    TicketUpdater.start(`conferences/${this.props.conferenceHandle}/tickets`, {
      onAdded: true,
      onRemoved: true,
      onChanged: true,
    });
  };

  componentWillUnmount = () => {
    TicketUpdater.stop();
    TicketUpdater.unsubscribe();
  };

  onTicketChange = (type, value) => {
    let tickets = this.state.tickets;
    const index = tickets.findIndex(_ => _.id === value.id);
    if (type === 'add') {
      tickets.push(value);
    } else if (type === 'change' && index > -1) {
      tickets[index] = value;
    } else if (type === 'remove' && index > -1) {
      tickets.splice(index, 1);
    }
    this.setState({ tickets });
  };

  closeTicket = async ticketId => {
    await this.defaultUserRequestExecutor(
      closeTicket,
      this.props.conferenceHandle,
      ticketId,
      this.props.currentUserId
    );
  };

  render() {
    const users = [
      ...flatten(transduceMessengerUsers(this.props.messengerUsers)),
      ...flatten(transduceTelegramUsers(this.props.telegramUsers)),
    ];
    const tickets = mergeTicketsWithProfiles(
      flatten(this.state.tickets),
      users
    );
    const openTickets = getOpenTickets(tickets);
    const closedTickets = getClosedTickets(tickets);
    const { ticketId } = this.props.match.params;
    const profile = findUserProfile(users, tickets, ticketId);
    const currentTicket = tickets.find(_ => _.id === ticketId);

    return (
      <Layout>
        <Box>
          <OpenTickets
            tickets={openTickets}
            conferenceHandle={this.props.match.params.conferenceHandle}
          />
          <ClosedTickets
            tickets={closedTickets}
            conferenceHandle={this.props.match.params.conferenceHandle}
          />
        </Box>
        {profile ? (
          <ContentArea
            ticket={currentTicket}
            profile={profile}
            onCloseTicket={this.closeTicket}
            conferenceHandle={this.props.match.params.conferenceHandle}
            createUserRequestExecutor={this.chatInputRequestExecutor}
            currentUserId={this.props.currentUserId}
          />
        ) : (
          <Box>
            <NoTickets type="notStarted" />
          </Box>
        )}
      </Layout>
    );
  }
}

const asyncUsersProvider = client => ({ conferenceHandle }) =>
  createFetchResource(`conferences/${conferenceHandle}/${client}Users`, {
    fallback: {},
    throwError: false,
  })();

const asyncContentRefreshProvider = () => {
  return false;
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectUserData,
  (conferenceHandle, user) => ({
    conferenceHandle,
    currentUserId: user.get('id'),
  })
);

export default compose(
  connect(mapStateToProps),
  createWithAsyncContent({ propName: 'messengerUsers' })(
    asyncUsersProvider('messenger'),
    asyncContentRefreshProvider
  ),
  createWithAsyncContent({ propName: 'telegramUsers' })(
    asyncUsersProvider('telegram'),
    asyncContentRefreshProvider
  ),
  withUserRequestExecutor
)(SupportInbox);
