import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

const Info = styled.div`
  flex: 0 0 30%;
  padding-right: 0.75em;
  opacity: 0.5;
`;

const InfoTitle = styled.h4`
  margin: 0;
`;

const InfoSubtitle = styled.p`
  margin: 0;
  font-size: 0.85em;
  line-height: 1.4;
`;

export default injectIntl(({ title, subtitle }) => (
  <Info>
    <InfoTitle>
      <FormattedMessage {...title} />
    </InfoTitle>
    <InfoSubtitle>
      <FormattedMessage {...subtitle} />
    </InfoSubtitle>
  </Info>
));
