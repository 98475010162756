/**
 *
 * PropertyList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';

export const PropertyList = ({ children, ...rest }) => (
  <Flexbox
    flexDirection="column"
    alignContent="center"
    alignItems="center"
    {...rest}
  >
    {children}
  </Flexbox>
);

PropertyList.propTypes = {
  children: PropTypes.any,
};

const StyledPropertyFlexbox = styled(Flexbox)`
  margin-left: -200px;
  margin-bottom: 30px;
`;

export const Property = ({ children, ...rest }) => (
  <StyledPropertyFlexbox {...rest}>{children}</StyledPropertyFlexbox>
);

Property.propTypes = {
  children: PropTypes.any,
};

const StyledTermFlexbox = styled(Flexbox)`
  width: 185px;
  margin-right: 15px;
`;

export const Term = ({ children, ...rest }) => (
  <StyledTermFlexbox flexDirection="column" {...rest}>
    {children}
  </StyledTermFlexbox>
);

Term.propTypes = {
  children: PropTypes.any,
};

const StyledDefinitionFlexbox = styled(Flexbox)`
  width: 600px;
`;
export const Definition = ({ children, ...rest }) => (
  <StyledDefinitionFlexbox flexDirection="column" {...rest}>
    {children}
  </StyledDefinitionFlexbox>
);

Definition.propTypes = {
  children: PropTypes.any,
};

export default PropertyList;
