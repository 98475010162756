import React from 'react';

import messages from './messages';
import ButtonListInput from '../ButtonListInput';

// icons
import textIcon from '../../assets/images/text.svg';
import genericIcon from '../../assets/images/card.svg';
import listIcon from '../../assets/images/gallery.svg';
import followUpIcon from '../../assets/images/follow-up.svg';

const items = [
  {
    value: 'text',
    label: { ...messages.messageTypeText },
    icon: textIcon,
  },
  {
    value: 'generic',
    label: { ...messages.messageTypeGeneric },
    icon: genericIcon,
  },
  {
    value: 'list',
    label: { ...messages.messageTypeList },
    icon: listIcon,
  },
  {
    value: 'followUp',
    label: { ...messages.messageTypeFollowUp },
    icon: followUpIcon,
  },
];

const MessageTypeInput = props => <ButtonListInput items={items} {...props} />;

export default MessageTypeInput;
