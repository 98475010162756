/**
 *
 * SponsorForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

import CollectionInput from '../CollectionInput';
import ProfileInput from '../ProfileInput';
import ImageInput from '../ImageInput';
import Form from '../Form';
import RichTextInput from '../RichTextInput';
import SponsorGroupPicker from '../SponsorGroupPicker';
import ActionBar from '../ActionBar';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';
import DeleteButton from '../DeleteButton';

const Button = styled.button`
  padding: 12px;

  ${button()};
`;

const ProfilesInput = CollectionInput(ProfileInput);

const SponsorForm = props => {
  const {
    conferenceHandle,
    isCreateForm,
    handleSubmit,
    pristine,
    submitting,
    intl,
    onDelete,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      {!isCreateForm && (
        <Field name="id" component="input" type="hidden" required />
      )}

      <fieldset>
        <FormattedMessage {...messages.name} />
        <Field
          name="name"
          component="input"
          type="text"
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          style={{ fontSize: 22 }}
          required
        />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.description} />
        <Field name="description" component={RichTextInput} required />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.image} />
        <Field name="image" component={ImageInput} required />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.group} />
        <Field
          name="group"
          component={SponsorGroupPicker}
          props={{ conferenceHandle }}
        />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.profiles} />
        <FieldArray name="profiles" component={ProfilesInput} />
      </fieldset>

      <ActionBar justifyContent="space-between" style={{ padding: '0' }}>
        <Button disabled={pristine || submitting}>
          <FormattedMessage
            {...(isCreateForm ? messages.create : messages.update)}
          />
        </Button>

        {!isCreateForm && (
          <DeleteButton type="reset" onClick={onDelete}>
            <FormattedMessage {...messages.delete} />
          </DeleteButton>
        )}
      </ActionBar>
    </Form>
  );
};

SponsorForm.propTypes = {
  conferenceHandle: PropTypes.string.isRequired,
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default reduxForm({
  form: 'sponsor',
  initialValues: fromJS({
    image: null,
    group: { id: null },
    profiles: [],
  }),
  validate: values => {
    const errors = {};

    const { group = {} } = values.toJS();

    if (group.id === null) {
      errors.group = 'Please select the sponsor group';
    } else if (group.id === undefined) {
      if (!group.name) {
        errors.group = 'Please enter the name of the sponsor group';
      }
    }
    return errors;
  },
})(injectIntl(SponsorForm));
