import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import queryString from 'qs';

export const AccountAction = () => null;

export default compose(
  withRouter,
  lifecycle({
    componentDidMount() {
      const { location, history } = this.props;
      const { mode, oobCode } = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      switch (mode) {
        case 'resetPassword':
          history.push(`/reset-password/${oobCode}`);
          break;
        default:
          history.push('/404');
      }
    },
  })
)(AccountAction);
