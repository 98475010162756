import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import styled from 'styled-components';

import messages from './messages';

const WarningWrapper = styled.div`
  margin-bottom: 20px;
`;

const Info = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const Warning = styled.div`
  font-size: 13px;
  font-style: italic;
  margin-left: 10px;
`;

const FacebookWarning = ({ intl }) => (
  <Warning>{intl.formatMessage(messages.facebook)}</Warning>
);

const warnings = {
  11: FacebookWarning,
};

const QuickRepliesWarnings = ({ numberOfQuickReplies, intl }) => {
  const listOfWarnings = Object.entries(warnings);

  return (
    <WarningWrapper>
      {listOfWarnings.find(([match]) => match < numberOfQuickReplies) && (
        <FormattedMessage {...messages.orderProperly}>
          {message => <Info>{message}</Info>}
        </FormattedMessage>
      )}
      {listOfWarnings.map(
        ([match, Component]) =>
          Number(match) < numberOfQuickReplies && (
            <Component key={match} intl={intl} />
          )
      )}
    </WarningWrapper>
  );
};

export default compose(injectIntl)(QuickRepliesWarnings);
