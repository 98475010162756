import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatTimestamp from '../../utils/formatTimestamp';
import ThinLabel from '../ThinLabel';

const TimeWrapper = styled.div``;

const BroadcastScheduledTime = ({ timestamp, timezone }) => (
  <TimeWrapper>
    <ThinLabel style={{ fontWeight: 600, marginRight: '1em' }}>
      {formatTimestamp(timestamp, timezone)}
    </ThinLabel>
    <ThinLabel>
      {moment(timestamp)
        .tz(timezone)
        .format('LLL')}
    </ThinLabel>
  </TimeWrapper>
);

export default BroadcastScheduledTime;
