import React from 'react';
import styled from 'styled-components';
import MessengerTemplate from '../../MessengerPreviewMocks/MessengerTemplate';

const ListTemplatePreviewInfoText = styled.p`
  opacity: 0.5;
`;

export default ({ items = [] }) => (
  <div>
    <MessengerTemplate
      title={items[0].title}
      subtitle={items[0].subtitle}
      imageUrl={items[0].imageUrl}
      buttons={[
        {
          label: items[0].buttonLabel,
          action: items[0].action,
          type: items[0].buttonType,
        },
      ]}
    />
    {items.length > 1 && (
      <ListTemplatePreviewInfoText>
        And {items.length - 1} more {items.length - 1 === 1 ? 'item' : 'items'}.
      </ListTemplatePreviewInfoText>
    )}
  </div>
);
