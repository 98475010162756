import moment from 'moment-timezone';

import convertTime from './convertTime';

export default (date, time) => {
  if (!date || !time) {
    return;
  }

  const convertedTime = convertTime(time);
  const [hour, minute] = convertedTime.split(':');
  const newDate = moment(date);

  return newDate.set({ hour, minute });
};
