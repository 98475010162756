import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import queryString from 'qs';

export default Component =>
  class WithNow extends React.Component {
    static propTypes = {
      location: PropTypes.object,
    };

    constructor(props) {
      super(props);

      const { location = {} } = props;
      const { now: queryNow, pace: queryPace } = queryString.parse(
        location.search,
        {
          ignoreQueryPrefix: true,
        }
      );
      const now = moment(queryNow && queryNow.replace(/\s/g, '+'));
      const pace = parseFloat(queryPace) || 1;

      this.now = now.isValid() ? now : moment();
      this.inception = new Date().getTime();
      this.pace = pace;

      this.state = {
        now: this.now,
      };
    }

    componentWillMount() {
      if (this.pace > 0) {
        this.interval = setInterval(
          () =>
            this.setState({
              now: this.now
                .clone()
                .add(
                  ((new Date().getTime() - this.inception) * this.pace) / 1000,
                  'seconds'
                ),
            }),
          (10 * 1000) / this.pace || 10
        );
      }
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };
