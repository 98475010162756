import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { createSelector } from 'reselect';
import { ThemeProvider } from 'styled-components';

import Saga from '../components/Saga';
import embedSagas from '../sagas/embed';

import Spinner from '../components/Spinner';

import {
  selectConferenceHandle,
  selectConference,
  selectTheme,
} from '../selectors/embed';

// We need to wait for the whole conf.
// One way to check is to see if prop 'days'
// is array and has at least one element
const storeIsReady = conference =>
  !conference.equals(Map()) && conference.get('days', List()).count() > 0;

const OverlayWrapper = isLoggedIn => Component => ({
  conference,
  conferenceHandle,
  theme,
}) => (
  <Fragment>
    {embedSagas(conferenceHandle, isLoggedIn).map((saga, i) => (
      <Saga key={i} saga={saga} />
    ))}
    {storeIsReady(conference) ? (
      <ThemeProvider key="embed" theme={theme}>
        <Component conferenceHandle={conferenceHandle} />
      </ThemeProvider>
    ) : (
      <Spinner />
    )}
  </Fragment>
);

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectConference,
  selectTheme,
  (conferenceHandle, conference, theme) => ({
    conferenceHandle,
    conference,
    theme,
  })
);

export default isLoggedIn => Component =>
  connect(mapStateToProps)(OverlayWrapper(isLoggedIn)(Component));
