/**
 *
 * SponsorGroupPicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Field } from 'redux-form/immutable';

import SponsorGroupInput from '../SponsorGroupInput';
import SponsorGroupView from '../SponsorGroupView';
import SelectOrCreate from '../SelectOrCreate';

const using = value => f => f(value);

const SponsorGroupPicker = SelectOrCreate(
  SponsorGroupInput,
  SponsorGroupView,
  (state, props) =>
    using(['user', 'userData', 'currentConference', 'sponsorGroups'])(
      selector =>
        state
          .getIn(selector, Map())
          .entrySeq()
          .map(([k, v]) => v.set('id', k))
          .toJS()
    )
);

export const CollectionItem = ({ conferenceHandle, name }) => (
  <Field
    name={name}
    component={SponsorGroupPicker}
    props={{ conferenceHandle }}
    required
  />
);

CollectionItem.propTypes = {
  conferenceHandle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

CollectionItem.defaultValue = Map({ id: null });

export default SponsorGroupPicker;
