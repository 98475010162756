import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import { Container } from './components/styled';
import KanbanBoard from './components/KanbanBoard';
import Submission from './components/SubmissionView';
import toArray from './utils/toArray';
import ActionHeader from './components/ActionHeader';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectUser,
} from '../../selectors/user';
import Breadcrumb from '../../components/Breadcrumb';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import toggleAcceptingSubmissions from '../../sagas/user/cfp/toggleAcceptingSubmissions';
import updateSubmissionStatus from '../../sagas/user/cfp/updateSubmissionStatus';
import addComment from '../../sagas/user/cfp/addComment';
import addReaction from '../../sagas/user/cfp/addReaction';

class CallForPapersPage extends Component {
  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  toggleAcceptingSubmissions = value => {
    this.defaultUserRequestExecutor(
      toggleAcceptingSubmissions,
      this.props.conferenceHandle,
      value
    );
  };

  updateSubmissionStatus = (submission, status) => {
    this.props.createUserRequestExecutor({ shouldNotifyToastr: false })(
      updateSubmissionStatus,
      this.props.conferenceHandle,
      submission,
      status
    );
  };

  handleSubmitComment = (submissionId, content) => {
    this.props.createUserRequestExecutor({ shouldNotifyToastr: false })(
      addComment,
      this.props.conferenceHandle,
      submissionId,
      this.props.currentUser.get('uid'),
      this.props.currentUser.get('displayName'),
      content
    );
  };

  handleSubmitReaction = (submissionId, type) => {
    this.props.createUserRequestExecutor({ shouldNotifyToastr: false })(
      addReaction,
      this.props.conferenceHandle,
      submissionId,
      this.props.currentUser.get('uid'),
      this.props.currentUser.get('displayName'),
      type
    );
  };

  render() {
    const root = `/@${this.props.conference.get('id')}/cfp`;
    const cfpData = this.props.conference.get('cfp')
      ? this.props.conference.get('cfp').toJS()
      : {};
    const submissions = toArray(cfpData.submissions || {});
    return (
      <div>
        <Helmet
          title={`${this.props.conference.get(
            'name'
          )} Call for Papers • Sava Events`}
        />
        <Breadcrumb to={root}>Call for Papers</Breadcrumb>
        <Submission
          onSubmitComment={this.handleSubmitComment}
          onSubmitReaction={this.handleSubmitReaction}
          submissions={submissions}
          currentUserId={this.props.currentUser.get('uid')}
        />
        <Container>
          <ActionHeader
            onToggleAcceptingSubmissions={this.toggleAcceptingSubmissions}
            acceptingSubmissions={cfpData.acceptingSubmissions}
            rootUrl={root}
            conferenceHandle={this.props.conferenceHandle}
          />
        </Container>
        <Container>
          <KanbanBoard
            rootUrl={root}
            submissions={submissions}
            updateSubmissionStatus={this.updateSubmissionStatus}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectUser(),
  (conferenceHandle, conference, currentUser) => ({
    conferenceHandle,
    conference,
    currentUser,
  })
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor
)(CallForPapersPage);
