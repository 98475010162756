import React from 'react';
import styled from 'styled-components';
import { MessengerTemplatePropTypes } from './propTypes';

const Template = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Image = styled.div`
  background-image: url(${props => props.imageUrl});
  background-position: 50% 50%;
  background-size: cover;
  min-width: 300px;
  min-height: ${props => (props.imageUrl ? '300px' : '0px')};
  width: 100%;
  border-radius: 4px 4px 0 0;
  border: 1px solid lightgray;
`;

const InfoBox = styled.div`
  padding: 12px;
  border: 1px solid lightgray;
  border-top: none;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

InfoBox.Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

InfoBox.Subtitle = styled.p`
  opacity: 0.6;
  margin: 0;
  font-size: 14px;
`;

const ButtonList = styled.div`
  ${props =>
    props.hasButtons
      ? `
    border: 1px solid lightgray;
    border-top: none;
    padding: 12px;
    :last-child {
      border-radius: 0px 0px 4px 4px;
    }        
  `
      : ''};
`;

const Button = styled.div`
  text-align: center;
`;

Button.Link = styled.a`
  color: #0084ff;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
`;

Button.Action = styled.span`
  color: #0084ff;
  font-weight: 500;
  cursor: not-allowed;
`;

const MessengerTemplate = ({ title, subtitle, imageUrl, buttons }) => (
  <Template>
    <Image imageUrl={imageUrl} />
    <InfoBox>
      <InfoBox.Title>{title}</InfoBox.Title>
      <InfoBox.Subtitle>{subtitle}</InfoBox.Subtitle>
    </InfoBox>
    <ButtonList hasButtons={buttons.length > 0}>
      {buttons.map((button, index) => (
        <Button key={index}>
          {button.type === 'web_url' ? (
            <Button.Link href={button.action} target="_blank">
              {button.label}
            </Button.Link>
          ) : (
            <Button.Action>{button.label}</Button.Action>
          )}
        </Button>
      ))}
    </ButtonList>
  </Template>
);

MessengerTemplate.propTypes = MessengerTemplatePropTypes;

MessengerTemplate.defaultProps = {
  buttons: [],
};

export default MessengerTemplate;
