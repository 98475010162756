import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { Field } from 'redux-form/immutable';
import styled from 'styled-components';

import ThemeColorInput from '../../ColorInput';
import { session } from '../../../mocks/session';
import FormLabel from '../../FormLabel';
import Session from '../../Session';
import provideLink from '../../../mocks/provideLink';
import { FieldWrapper, InputWrapper, PreviewWrapper } from '../common';

const StyledSessionWrapper = styled(PreviewWrapper)`
  > div {
    width: 20em;
  }
`;

const CardThemeInput = ({ messages, intl }) => (
  <InputWrapper>
    <FieldWrapper>
      <FormLabel htmlFor={'card'} message={intl.formatMessage(messages.card)} />

      <Field
        id="card.textColor"
        name="card.textColor"
        label={intl.formatMessage(messages.cardTextColor)}
        component={ThemeColorInput}
      />

      <Field
        id="card.backgroundColor"
        name="card.backgroundColor"
        label={intl.formatMessage(messages.cardBackgroundColor)}
        component={ThemeColorInput}
      />
    </FieldWrapper>
    <StyledSessionWrapper>
      <Session
        session={session}
        conferenceHandle="sava"
        linkProvider={provideLink(undefined)}
      />
    </StyledSessionWrapper>
  </InputWrapper>
);

CardThemeInput.propTypes = {
  messages: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(CardThemeInput);
