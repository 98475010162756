import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  border: 1px solid rgba(5, 26, 31, 0.05);
  padding: 12px;
  margin-top: 12px;
  width: 100%;
`;

export default class ChatInput extends React.Component {
  state = {};

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.props.onSubmit();
    }
  };

  render() {
    return (
      <div>
        <Input
          name="chatinput"
          placeholder="Type a message…"
          type="text"
          value={this.props.value || ''}
          onKeyPress={this.handleKeyPress}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}
