import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import Flexbox from 'flexbox-react';
import { Map, fromJS } from 'immutable';
import Slider from 'rc-slider';
import styled from 'styled-components';

import 'rc-slider/dist/rc-slider.css';

import Image from '../../components/Image';

import CardLink from './CardLink';

import shiftRange from './shiftRange';
import Icon from '../../components/Icon/index';

const sliderToScale = shiftRange(0, 100, 0.5, 2);
const sliderToOffset = shiftRange(0, 100, -60, 60);

const scaleToSlider = sliderToScale.inverse();
const offsetToSlider = sliderToOffset.inverse();

const ReferencePoint = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
  background-color: black;
  border-radius: 50%;
  z-index: 300;
`;

const Card = styled(CardLink)`
  :hover {
    > i {
      cursor: move;
      opacity: 1;
    }
  }
`;

const ImageReferencePoint = styled(ReferencePoint)`
  border: 1px solid black;
  background-color: white;
  margin-top: -3px;
  margin-left: -3px;
  z-index: 250;
  box-sizing: content-box;
`;

const snap = (to, offset) => value =>
  value > to - offset && value < to + offset ? to : value;

const DragIcon = styled(Icon)`
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #e7e7e7;
`;

class Sponsor extends Component {
  static propTypes = {
    optimalSize: PropTypes.any.isRequired,
    conferenceHandle: PropTypes.any.isRequired,
    sponsor: PropTypes.any.isRequired,
    onScaleChange: PropTypes.func.isRequired,
    onOffsetChange: PropTypes.func.isRequired,

    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    isDragged: PropTypes.bool,

    groupScale: PropTypes.number,
  };

  static handleSliderStart() {
    this.setState({
      sliding: true,
    });
  }

  static handleSliderChange(value) {
    const slider = snap(50, 2)(value);

    const { sliding, alt, shift, scale, offset } = this.state;

    const scaling = sliding && !alt;
    const offsettingX = sliding && alt && !shift;
    const offsettingY = sliding && alt && shift;

    this.setState({
      scale: scaling ? sliderToScale(slider) : scale,
      offset: {
        x: offsettingX ? sliderToOffset(slider) : offset.x,
        y: offsettingY ? sliderToOffset(slider) : offset.y,
      },
    });
  }

  static handleSliderStop() {
    this.setState(
      {
        sliding: false,
        justSlid: true,
      },
      () => setTimeout(() => this.setState({ justSlid: false }))
    );

    if (this.props.sponsor.get('scale') !== this.state.scale) {
      this.props.onScaleChange(this.state.scale, this.props.sponsor);
    }

    if (
      !this.props.sponsor.get('offset', Map()).equals(fromJS(this.state.offset))
    ) {
      this.props.onOffsetChange(this.state.offset, this.props.sponsor);
    }
  }

  static handleLinkClick(e) {
    if (this.state.justSlid) {
      e.preventDefault();
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      sliding: false,
      justSlid: false,
      alt: false,
      shift: false,
      scale: props.sponsor.get('scale', 1), // [0.5,2]
      offset: props.sponsor
        .get('offset', Map())
        .update('x', x => x || 0)
        .update('y', x => x || 0)
        .toJS(),
    };

    this.handleSliderStart = Sponsor.handleSliderStart.bind(this);
    this.handleSliderChange = Sponsor.handleSliderChange.bind(this);
    this.handleSliderStop = Sponsor.handleSliderStop.bind(this);
    this.handleLinkClick = Sponsor.handleLinkClick.bind(this);

    this.handleMousemove = e => {
      if (e.altKey !== this.state.alt || e.shiftKey !== this.state.shift) {
        this.setState({
          alt: e.altKey,
          shift: e.shiftKey,
        });
      }
    };

    this.handleKeydown = e => {
      if (e.keyCode === 18) {
        this.setState({ alt: true });
      } else if (e.keyCode === 16) {
        this.setState({ shift: true });
      }
    };

    this.handleKeyup = e => {
      if (e.keyCode === 18) {
        this.setState({ alt: false });
      } else if (e.keyCode === 16) {
        this.setState({ shift: false });
      }
    };
  }

  componentWillMount() {
    window.addEventListener('mousemove', this.handleMousemove);
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);
  }

  componentWillReceiveProps(nextProps) {
    const scale = nextProps.sponsor.get('scale', 1);
    const offset = nextProps.sponsor
      .get('offset', Map())
      .update('x', x => x || 0)
      .update('y', x => x || 0)
      .toJS();

    if (!this.state.sliding && scale !== this.state.scale) {
      this.setState({
        scale,
        offset,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMousemove);
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
  }

  render() {
    const {
      optimalSize,
      conferenceHandle,
      sponsor,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isDragged,
      groupScale,
    } = this.props;

    const { sliding, alt, shift, scale, offset } = this.state;

    const offsettingX = alt && !shift;
    const offsettingY = alt && shift;

    let sliderValue;

    if (offsettingX) {
      sliderValue = offsetToSlider(offset.x);
    } else if (offsettingY) {
      sliderValue = offsetToSlider(offset.y);
    } else {
      sliderValue = scaleToSlider(scale);
    }

    const styles = {
      width: optimalSize * (groupScale || 1),
      margin: 0,
      visibility: isDragging ? 'hidden' : undefined,
      background: isDragged ? 'white' : undefined,
      boxShadow: isDragged ? '0 2px 8px rgba(0, 0, 0, 0.1)' : undefined,
      cursor: isDragged ? 'move' : undefined,
    };

    return connectDragSource(
      connectDropTarget(
        <div
          style={{
            margin: 10,
            background: isDragging && !isDragged ? '#e7e7e7' : undefined,
          }}
        >
          <Card
            to={`/@${conferenceHandle}/sponsors/${sponsor.get('id')}`}
            onClick={this.handleLinkClick}
            style={styles}
          >
            <DragIcon style={styles.icon}>open_with</DragIcon>
            <Flexbox
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                width={120 * (groupScale || 1)}
                height={120 * (groupScale || 1)}
                src={sponsor.get('image')}
                alt={sponsor.get('name')}
                style={{
                  transform: `scale(${scale}) translate(${offset.x}px, ${
                    offset.y
                  }px)`,
                }}
                contain
              >
                {alt && (
                  <ReferencePoint
                    className="logo-referencePoint"
                    style={{
                      display: sliding ? 'block' : null,
                    }}
                  />
                )}
                {alt && (
                  <ImageReferencePoint
                    className="logo-referencePoint"
                    style={{
                      display: sliding ? 'block' : null,
                      transform: `translate(${offset.x * scale}px, ${offset.y *
                        scale}px)`,
                    }}
                  />
                )}
              </Image>
              {sponsor.get('image') ? (
                <Flexbox
                  alignSelf="stretch"
                  style={{
                    fontSize: optimalSize / 12,
                    margin: `${optimalSize / 32}px 0 0 0`,
                  }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Slider
                    className={sliding ? 'rc-slider--sliding' : null}
                    tipTransitionName="rc-slider-tooltip-zoom-down"
                    value={sliderValue}
                    onBeforeChange={this.handleSliderStart}
                    onChange={this.handleSliderChange}
                    onAfterChange={this.handleSliderStop}
                  />
                </Flexbox>
              ) : null}
            </Flexbox>
          </Card>
        </div>
      )
    );
  }
}

const ItemTypes = {
  SPONSOR: 'SPONSOR',
};

const sponsorSource = {
  beginDrag(props) {
    props.onDrag();

    return {
      index: props.index,
      optimalSize: props.optimalSize,
      conferenceHandle: props.conferenceHandle,
      sponsor: props.sponsor,
    };
  },
};

const sponsorTarget = {
  drop(props) {
    props.onDrop();
  },
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    /* eslint-disable no-param-reassign */
    monitor.getItem().index = hoverIndex;
    /* eslint-enable no-param-reassign */
  },
};

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function collectDropTarget(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default DragSource(ItemTypes.SPONSOR, sponsorSource, collectDragSource)(
  DropTarget(ItemTypes.SPONSOR, sponsorTarget, collectDropTarget)(Sponsor)
);
