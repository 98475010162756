import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';

import { makeGetLocaleFromState } from '../selectors/language';

// Locale
import en from 'react-intl/locale-data/en';

// Messages
const messages = {
  en: require('../translations/en.json'),
};

addLocaleData([...en]);

const LanguageProvider = ({ locale, children }) => {
  return (
    <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = () => {
  const getLocaleFromState = makeGetLocaleFromState();
  return (state, props) => getLocaleFromState(state, props);
};

export default connect(mapStateToProps)(LanguageProvider);
