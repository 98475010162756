import React from 'react';
import styled, { keyframes } from 'styled-components';
import Flexbox from 'flexbox-react';

const pulse = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const SessionDetailsBase = styled.div`
  display: flex;
  font-size: 0.875rem;
`;

const Track = styled.div`
  margin-left: 12px;
  opacity: 0.67;
  max-height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const OnAir = styled(Flexbox)`
  margin-left: 12px;
  font-weight: 800;
  color: ${props => props.theme.colors.attention};
  animation: ${pulse} 1s ease-in-out alternate infinite;
`;

const SessionTime = styled.div`
  line-height: 1;
  padding: ${props => (props.theme.SpeakersList || {}).baseSize / 24 || 0}px 0;
`;

const SessionDay = styled.div``;

const SessionType = styled(Flexbox)`
  margin-left: auto;
  font-size: 0.85em;
  font-weight: 800;
  color: ${props => props.color};
`;

const SessionDetails = ({
  hideStartTime,
  showDay,
  showTrack,
  hideSessionType,
  ignoreDuration,
  startTime,
  endTime,
  session,
  sessionColor,
}) => (
  <SessionDetailsBase>
    {!hideStartTime && (
      <SessionTime>
        {session.get('startTime').format('HH:mm')} -
        {session.get('endTime').format('HH:mm')}
      </SessionTime>
    )}
    {!hideStartTime && showDay && <div style={{ marginLeft: 12 }} />}
    {showDay && (
      <SessionDay>{session.get('startTime').format('ddd, MMM Do')}</SessionDay>
    )}
    {showTrack && <Track>{session.getIn(['track', 'name'])}</Track>}
    {session.get('isNow', false) && <OnAir>On Air</OnAir>}
    {!hideSessionType && (
      <SessionType color={sessionColor}>
        {session.getIn(['sessionType', 'name'])}
      </SessionType>
    )}
  </SessionDetailsBase>
);

export default SessionDetails;
