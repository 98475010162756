import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'app.components.LoginForm.email',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'app.components.LoginForm.emailPlaceholder',
    defaultMessage: 'e.g., john@doe.com',
  },
  password: {
    id: 'app.components.LoginForm.password',
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: 'app.components.LoginForm.passwordPlaceholder',
    defaultMessage: 'e.g., ••••••••••••',
  },
  logIn: {
    id: 'app.components.LoginForm.logIn',
    defaultMessage: 'Take me to my dashboard',
  },
  forgotPassword: {
    id: 'app.components.LoginForm.forgotPassword',
    defaultMessage: 'Forgot Password?',
  },
});
