/*
 * BroadcastPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Map } from 'immutable';
import { compose, withState, setPropTypes } from 'recompose';
import { TabList, Tab, TabPanel } from 'react-tabs';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { toListOfMapsWithIds } from 'sava-shared/lib/utils/mapOfMapsToListOfMaps';
import { withRouter } from 'react-router-dom';

import messages from './messages';
import {
  isBroadcastScheduledAndGreaterThanNow,
  isBroadcastRegularOrScheduledAndLowerThanNow,
  fromEarliestToLatest,
  fromNewestToOldest,
  bySize,
  flattenScheduledAndRegularBroadcasts,
} from './helpers';
import EditBroadcastPage from '../EditBroadcastPage';
import ShowMoreButton from './components/ShowMoreButton';

import Tabs from '../../components/Tabs';
import Breadcrumb from '../../components/Breadcrumb';
import BroadcastForm from '../../components/BroadcastForm';
import Spinner from '../../components/Spinner';
import {
  createBroadcast,
  removeBroadcast,
} from '../../sagas/user/conferences/broadcasts';
import { withSagaMiddleware } from '../../providers/SagaMiddlewareProvider';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectTimezone,
} from '../../selectors/user';
import Broadcasts from '../../components/Broadcasts/Broadcasts';
import ModalRoute from '../../components/ModalRoute';
import BroadcastSubscribers from '../../components/BroadcastSubscribers';
import EntityEmptyState from '../../components/EntityEmptyState';
import emptyStateScheduledIllustration from '../../assets/images/empty-state-scheduled-broadcasts.svg';
import emptyStatePastIllustration from '../../assets/images/empty-state-past-broadcasts.svg';

const Root = styled.div`
  display: flex;
  padding: 1em 0.75em;
`;

const Title = styled.h3`
  margin-top: 0;
`;

const BroadcastSubscribersAndFormWrapper = styled.div`
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;
`;

const BroadcastFormWrapper = styled.div`
  background: white;
  ${props => props.theme.altitude(1)};
  padding: 1em 1.5em;
  margin: 1.5em 0.75em;
  border-radius: 0.25em;
  border: ${props => props.theme.borders.default};
`;

const TabWrapper = styled(Tabs)``;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.attention};
  text-align: center;
  padding: 20px;
`;

const CreateBroadcastForm = BroadcastForm('createBroadcastForm');

const deltaNumberOfBroadcast = 5;

const BroadcastPage = ({
  intl,
  conference,
  conferenceHandle,
  sagaMiddleware,
  createUserRequestExecutor,
  broadcastTypeFormValue,
  timezone,
  isBusy,
  error,
  setBusy,
  setErrorMessage,
  match,
  showScheduledBroadcasts,
  setShowScheduledBroadcasts,
  showPastBroadcasts,
  setShowPastBroadcasts,
}) => {
  const announcements = conference.get('announcements', Map());
  const broadcasts = conference.get('broadcasts', Map());
  const messengerUsers = conference.get('messengerUsers', Map());
  const numberOfMessengerUsers = messengerUsers.count();

  const broadcastsWithAnnouncements = toListOfMapsWithIds(
    announcements.merge(broadcasts).toJS()
  );

  const scheduledBroadcasts = broadcastsWithAnnouncements
    .filter(isBroadcastScheduledAndGreaterThanNow)
    .sort(fromEarliestToLatest);

  const pastBroadcasts = broadcastsWithAnnouncements
    .filter(isBroadcastRegularOrScheduledAndLowerThanNow)
    .map(flattenScheduledAndRegularBroadcasts)
    .sort(fromNewestToOldest);

  const root = `/@${conference.get('id')}/broadcasts`;

  const removeBroadcastUserRequestExecutor = createUserRequestExecutor({
    errorMessages: () =>
      intl.formatMessage(messages.removeScheduledBroadcastError),
  });

  const addBroadcastUserRequestExecutor = createUserRequestExecutor({
    onStart: () => {
      setBusy(true);
    },
    onEnd: () => setBusy(false),
  });

  const handleAddBroadcast = async data => {
    await addBroadcastUserRequestExecutor(
      createBroadcast,
      conference.get('id'),
      data.toJS()
    );
  };

  const handleRemovalOfScheduledBroadcast = broadcastId => async () => {
    await removeBroadcastUserRequestExecutor(
      removeBroadcast,
      conferenceHandle,
      broadcastId,
      intl.formatMessage(messages.removeBroadcastMessage)
    );
  };

  return (
    <Root>
      <Helmet
        title={`${conference.get('name', '')} ${intl.formatMessage(
          messages.title
        )}`}
      />
      <Breadcrumb to={root}>Broadcasts</Breadcrumb>

      <BroadcastSubscribersAndFormWrapper>
        <div>
          <BroadcastSubscribers
            value={numberOfMessengerUsers}
            hasError={numberOfMessengerUsers === 0}
            isLoading={false}
          />
        </div>

        <BroadcastFormWrapper>
          <Title>{intl.formatMessage(messages.newBroadcastTitle)}</Title>
          {isBusy ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <CreateBroadcastForm
              onSubmit={handleAddBroadcast}
              timezone={timezone}
              initialValues={Map({ type: 'text' })}
              isCreateForm
            />
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </BroadcastFormWrapper>
      </BroadcastSubscribersAndFormWrapper>
      <TabWrapper>
        <TabList>
          <Tab>{intl.formatMessage(messages.scheduledBroadcasts)}</Tab>
          <Tab>{intl.formatMessage(messages.pastBroadcasts)}</Tab>
        </TabList>
        <TabPanel>
          {scheduledBroadcasts.length < 1 ? (
            <EntityEmptyState
              illustration={emptyStateScheduledIllustration}
              title={intl.formatMessage(
                messages.scheduledBroadcastsEmptyStateTitle
              )}
              hint={intl.formatMessage(
                messages.scheduledBroadcastsEmptyStateHint
              )}
            />
          ) : (
            <Broadcasts
              broadcasts={scheduledBroadcasts.filter(
                bySize(showScheduledBroadcasts * deltaNumberOfBroadcast)
              )}
              timezone={timezone}
              conferenceHandle={conferenceHandle}
              sagaMiddleware={sagaMiddleware}
              onScheduledBroadcastRemove={handleRemovalOfScheduledBroadcast}
              scheduled
            />
          )}
          {scheduledBroadcasts.length > 0 &&
            !(
              showScheduledBroadcasts * deltaNumberOfBroadcast >=
              scheduledBroadcasts.length
            ) && (
              <ShowMoreButton
                disabled={
                  showScheduledBroadcasts * deltaNumberOfBroadcast >=
                  scheduledBroadcasts.length
                }
                onClick={() =>
                  setShowScheduledBroadcasts(showScheduledBroadcasts + 1)
                }
                label={intl.formatMessage(messages.showMore)}
              />
            )}
        </TabPanel>
        <TabPanel>
          {pastBroadcasts.length < 1 ? (
            <EntityEmptyState
              illustration={emptyStatePastIllustration}
              title={intl.formatMessage(messages.pastBroadcastsEmptyStateTitle)}
              hint={intl.formatMessage(messages.pastBroadcastsEmptyStateHint)}
            />
          ) : (
            <Broadcasts
              broadcasts={pastBroadcasts.filter(
                bySize(showPastBroadcasts * deltaNumberOfBroadcast)
              )}
              timezone={timezone}
              conferenceHandle={conferenceHandle}
              sagaMiddleware={sagaMiddleware}
            />
          )}
          {pastBroadcasts.length > 0 &&
            !(
              showPastBroadcasts * deltaNumberOfBroadcast >=
              pastBroadcasts.length
            ) && (
              <ShowMoreButton
                disabled={
                  showPastBroadcasts * deltaNumberOfBroadcast >=
                  pastBroadcasts.length
                }
                onClick={() => setShowPastBroadcasts(showPastBroadcasts + 1)}
                label={intl.formatMessage(messages.showMore)}
              />
            )}
        </TabPanel>
      </TabWrapper>

      <ModalRoute
        exact
        path={`${match.path}/:id`}
        component={EditBroadcastPage}
      />
    </Root>
  );
};

const selectBroadcastTypeFormValue = state =>
  state.getIn(['form', 'broadcastForm', 'values', 'type'], false);

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectBroadcastTypeFormValue,
  selectTimezone,
  (conferenceHandle, conference, broadcastTypeFormValue, timezone) => ({
    conferenceHandle,
    conference,
    broadcastTypeFormValue,
    timezone,
  })
);

export default compose(
  withUserRequestExecutor,
  withSagaMiddleware,
  injectIntl,
  withRouter,
  connect(mapStateToProps),
  withState('isBusy', 'setBusy', false),
  withState('error', 'setErrorMessage', null),
  withState('totalSubscribers', 'setTotalSubscribers', false),
  withState('totalSubscribersError', 'setTotalSubscribersError', false),
  withState('showScheduledBroadcasts', 'setShowScheduledBroadcasts', 1),
  withState('showPastBroadcasts', 'setShowPastBroadcasts', 1),
  setPropTypes({
    conference: PropTypes.object.isRequired,
    conferenceHandle: PropTypes.string,
  })
)(BroadcastPage);
