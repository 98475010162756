import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';

import { button, link } from '../../utils/ui/interactive';

import Form from '../Form';

import messages from './messages';
import OpenEye from '../icons/OpenEye.svg';

const Button = styled.button`
  border-radius: 3px;
  padding: 12px;
  color: white;
  width: 100%;
  margin: 12px 0px;
  ${button({ lighten: true, backgroundColor: '#FF6800' })};
`;

const ForgotPassword = styled.div`
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 13px;

  ${link({ color: '#777', darken: true })};
`;

const PasswordField = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
`;

const PasswordIcon = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 20px;
  height: 15px;
  background-image: url(${OpenEye});
`;

let pwShown = 0;

const show = () => {
  const p = document.getElementById('input');
  p.setAttribute('type', 'text');
};

const hide = () => {
  const p = document.getElementById('input');
  p.setAttribute('type', 'password');
};

const checkPass = () => {
  if (pwShown === 0) {
    pwShown = 1;
    show();
  } else {
    pwShown = 0;
    hide();
  }
};

const LoginForm = props => {
  const { handleSubmit, pristine, submitting, intl } = props;

  return (
    <Form onSubmit={handleSubmit} style={{ width: '100%', marginBottom: 20 }}>
      <fieldset>
        <FormattedMessage {...messages.email} />
        <Field
          name="email"
          component="input"
          type="email"
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          required
        />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.password} />
        <PasswordField>
          <Field
            name="password"
            id="input"
            component="input"
            type="password"
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            required
          />
          <PasswordIcon onClick={checkPass} role="button" id="icon" />
        </PasswordField>
      </fieldset>

      <ForgotPassword>
        <FormattedMessage {...messages.forgotPassword}>
          {message => (
            <ForgotPasswordLink to="/forgot">{message}</ForgotPasswordLink>
          )}
        </FormattedMessage>
      </ForgotPassword>

      <fieldset>
        <Button disabled={pristine || submitting}>
          <FormattedMessage {...messages.logIn} />
        </Button>
      </fieldset>
    </Form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default reduxForm({
  form: 'login',
})(injectIntl(LoginForm));
