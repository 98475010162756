import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';

import responsive from '../../utils/ui/responsive';
import Session from '../Session';
import Break from '../Break';

const Schedule = styled.div`
  display: grid;
  width: 750px;

  ${responsive.md.andSmaller`
    width: 100%;
  `};
`;

const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
`;

const FlatGridSchedule = props => {
  const {
    conferenceHandle,
    linkProvider,
    tracks,
    allSessionsAndBreaks,
  } = props;

  if (allSessionsAndBreaks.size) {
    return (
      <Schedule>
        {tracks.size === 1 ? (
          <TrackInfo>
            <h2>{tracks.get(0).get('name')}</h2>
            <i>{tracks.get(0).get('location')}</i>
          </TrackInfo>
        ) : null}
        {allSessionsAndBreaks.map(item =>
          item.get('type') === 'session' ? (
            <Session
              key={item.get('id')}
              conferenceHandle={conferenceHandle}
              linkProvider={linkProvider}
              session={item}
              ignoreDuration
            />
          ) : (
            <Break key={item.get('id')} breakObj={item} />
          )
        )}
      </Schedule>
    );
  }

  return null;
};

FlatGridSchedule.propTypes = {
  conferenceHandle: PropTypes.string,
  linkProvider: PropTypes.func,
  tracks: PropTypes.object,
  breaks: PropTypes.object,
  isFlatLayout: PropTypes.bool,
};

export default compose(
  withProps(props => {
    const { tracks, breaks } = props;

    const allSessionsAndBreaks = tracks
      .flatMap(track =>
        track.get('sessions').map(session => session.set('type', 'session'))
      )
      .concat(
        breaks ? breaks.map(breakObj => breakObj.set('type', 'break')) : []
      )
      .sort((a, b) => a.get('startTime').unix() - b.get('startTime').unix())
      .filter(item =>
        item.get('type') === 'break' ? item.get('isBlock') : true
      );

    return {
      allSessionsAndBreaks,
    };
  })
)(FlatGridSchedule);
