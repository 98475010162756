/**
 *
 * Auth.js
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import responsive from '../../utils/ui/responsive';
import Navbar from '../../components/AuthNavbar/index';
import Footer from '../../components/AuthFooter/index';
import bottomLeft from './images/app.svg';
import topLeft from './images/womenbuilding.svg';
import topRight from './images/stairs.svg';
import bottomRight from './images/workers.svg';
import { setPropTypes } from 'recompose';

const FlexWrapper = styled(Flexbox)`
  background-image: url(${bottomLeft}), url(${topLeft}), url(${topRight}),
    url(${bottomRight});
  background-position: 5% 95%, 25% 75%, 80% 75%, 95% 95%;
  background-size: 14%, 12%, 14%, 14%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;

  ${responsive.lg.andSmaller`
    background-image: none;
  `};
`;

const Auth = ({ children, location }) => (
  <FlexWrapper
    flexDirection="column"
    style={{ minHeight: '100%' }}
    justifyContent="space-between"
    alignItems="center"
  >
    <Navbar
      width={'60%'}
      style={{ marginTop: 33, marginBottom: 45 }}
      alignItems="center"
      justifyContent="center"
      location={location}
    />
    <Flexbox flexDirection="column">
      <div
        style={{
          minWidth: '30vw',
          marginBottom: 80,
        }}
      >
        {children}
      </div>
    </Flexbox>
    <Footer width={'100%'} />
  </FlexWrapper>
);

export default setPropTypes({
  location: PropTypes.object,
  children: PropTypes.node,
})(Auth);
