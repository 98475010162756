/*
 * SponsorsPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import Flexbox from 'flexbox-react';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';
import { slugify } from 'sava-shared/lib/utils/core';
import { createSelector } from 'reselect';
import qs from 'qs';

import Image from '../../../components/Image';
import withBodySizes from '../../../utils/ui/withBodySizes';

const findOptimalSize = (canvasWidth, preferredSize) =>
  canvasWidth / Math.round(canvasWidth / preferredSize);

const GroupTitle = styled(Flexbox)`
  font-size: 38px;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const SponsorLink = styled(Link)`
  outline: none;
`;

class SponsorsPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    preferredSize: PropTypes.number.isRequired,
    sponsorGroups: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        id: PropTypes.string,
        name: PropTypes.string,
        sponsors: ImmutablePropTypes.listOf(
          ImmutablePropTypes.contains({
            id: PropTypes.string,
            name: PropTypes.string,
            image: PropTypes.string,
          })
        ),
      })
    ).isRequired,
    body: PropTypes.object.isRequired,
  };

  render() {
    const { conferenceHandle, preferredSize, sponsorGroups, body } = this.props;

    const optimalSize = findOptimalSize(body.clientWidth, preferredSize);

    return (
      <Flexbox
        flexDirection="column"
        alignContent="center"
        alignItems="center"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: optimalSize / 8,
          paddingRight: optimalSize / 8,
        }}
      >
        <Helmet title="Sponsors" />
        {sponsorGroups.map(sponsorGroup => (
          <Flexbox
            key={sponsorGroup.get('id')}
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <GroupTitle>{sponsorGroup.get('name')}</GroupTitle>
            <Flexbox
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              {sponsorGroup.get('sponsors').map(sponsor => (
                <SponsorLink
                  to={`/@${conferenceHandle}/embed/sponsors/${slugify(
                    sponsor.get('name')
                  )}`}
                >
                  <Flexbox
                    key={sponsor.get('id')}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      width: optimalSize * sponsorGroup.get('scale', 1),
                      height: (optimalSize * sponsorGroup.get('scale', 1)) / 2,
                    }}
                  >
                    <Image
                      width={(optimalSize * sponsorGroup.get('scale', 1)) / 2}
                      height={(optimalSize * sponsorGroup.get('scale', 1)) / 2}
                      margin={0}
                      src={sponsor.get('image')}
                      alt={sponsor.get('name')}
                      style={{
                        transform: `scale(${sponsor.get(
                          'scale',
                          1
                        )}) translate(${sponsor.getIn(
                          ['offset', 'x'],
                          0
                        )}px, ${sponsor.getIn(['offset', 'y'], 0)}px)`,
                      }}
                      contain
                    />
                  </Flexbox>
                </SponsorLink>
              ))}
            </Flexbox>
          </Flexbox>
        ))}
      </Flexbox>
    );
  }
}

const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;

const selectPreferredSize = (state, props) => {
  const query = qs.parse(props.location.search);
  return query.preferredSize ? parseInt(query.preferredSize, 10) : 320;
};

const selectSponsorGroups = (state, props) =>
  state.getIn(['embed', 'sponsorGroups'], List());

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectPreferredSize,
  selectSponsorGroups,
  (conferenceHandle, preferredSize, sponsorGroups) => ({
    conferenceHandle,
    preferredSize,
    sponsorGroups,
  })
);

export default compose(
  withBodySizes,
  withRouter,
  connect(mapStateToProps)
)(SponsorsPage);
