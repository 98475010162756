import { call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';

function* confirm(message) {
  return yield window.confirm(`${message}`);
}

export default function* removeEditor(conferenceHandle, id, authId, message) {
  const confirmed = yield call(confirm, message);

  if (confirmed) {
    const response = yield firebase
      .functions()
      .httpsCallable('removeEditorFromConference')({
      conferenceHandle,
      userId: id,
    });

    if (id === authId) {
      yield firebase
        .database()
        .ref(`users/${id}/currentConference`)
        .remove();

      yield put(push('/'));
    }

    return response;
  }

  return false;
}
