import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { EditLink, LightEditLink } from './EditLink';
import InfoBox from './InfoBox';
import { QueriesWrapper } from './Queries';
import { ResponseToken } from './tokens';
import messages from '../messages';
import { ActionToken } from './tokens';
import ListTemplatePreview from '../components/ListTemplatePreview';
import TooltipHover from '../../TooltipHover';

const ResponseList = styled.div`
  flex: 0 0 70%;
  max-width: 70%;
`;

const Responses = styled(QueriesWrapper)`
  border: none;
`;

export default injectIntl(({ response, rootUrl, intentId }) => (
  <Responses>
    <InfoBox
      title={messages.responseListTitle}
      subtitle={messages.responseListInfo}
    />
    <ResponseList>
      {response.items || response.message || response.item ? (
        <ResponseToken>
          <LightEditLink to={`${rootUrl}/${intentId}`}>
            {response.type === 'text' ? response.message : null}
            {response.type === 'list' &&
            response.items &&
            response.items.length > 0 ? (
              <TooltipHover
                html={<ListTemplatePreview items={response.items} />}
              >
                <FormattedMessage {...messages.previewResponse} />
              </TooltipHover>
            ) : null}
            {response.type === 'generic' || response.type === 'followUp' ? (
              <TooltipHover
                html={<ListTemplatePreview items={[response.item]} />}
              >
                <FormattedMessage {...messages.previewResponse} />
              </TooltipHover>
            ) : null}
          </LightEditLink>
        </ResponseToken>
      ) : (
        <ActionToken>
          <FormattedMessage {...messages['responsesEmpty']}>
            {message => (
              <EditLink to={`${rootUrl}/${intentId}`}>{message}</EditLink>
            )}
          </FormattedMessage>
        </ActionToken>
      )}
    </ResponseList>
  </Responses>
));
