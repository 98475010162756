import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { compose } from 'recompose';

import Form from '../../components/Form';
import { button } from '../../utils/ui/interactive';
import messages from './messages';
import DefaultThemeInput from '../ThemeInputs/DefaultThemeInput';
import TagsThemeInput from '../ThemeInputs/TagsThemeInput';
import CardThemeInput from '../ThemeInputs/CardThemeInput';
import NavigationThemeInput from '../ThemeInputs/NavigationThemeInput';
import { FieldSeparator } from '../ThemeInputs/common';

const FormWrapper = styled(Form)`
  > fieldset {
    margin: 0;
    padding-bottom: 1.5em;
    border-bottom: 1px solid rgba(241, 240, 240, 0.6);
  }
`;

const Button = styled.button`
  padding: 12px;
  margin: 2em 0px;

  ${button()};
`;

const ThemeForm = props => {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <FormWrapper
      onSubmit={handleSubmit}
      autoComplete="off"
      style={{ width: '100%' }}
    >
      <fieldset>
        <Field
          id="page"
          name="page"
          messages={messages}
          component={DefaultThemeInput}
        />
      </fieldset>

      <FieldSeparator />

      <fieldset>
        <Field
          id="tags"
          name="tags"
          messages={messages}
          component={TagsThemeInput}
        />
      </fieldset>

      <FieldSeparator />

      <fieldset>
        <Field
          id="card"
          name="card"
          messages={messages}
          component={CardThemeInput}
        />
      </fieldset>

      <FieldSeparator />

      <fieldset>
        <Field
          id="navigation"
          name="navigation"
          messages={messages}
          component={NavigationThemeInput}
        />
      </fieldset>

      <Button disabled={pristine || submitting}>
        <FormattedMessage {...messages.save} />
      </Button>
    </FormWrapper>
  );
};

ThemeForm.propTypes = {
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default compose(
  reduxForm({
    form: 'themeForm',
    enableReinitialize: true,
  })
)(ThemeForm);
