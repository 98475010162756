import PropTypes from 'prop-types';
import styled from 'styled-components';

const sizes = {
  tiny: '1.65em',
  small: '2em',
  normal: '3em',
  medium: '7.5em',
  large: '10em',
};

const SpeakerImage = styled.div`
  width: ${props => sizes[props.type]};
  height: ${props => sizes[props.type]};
  background: ${props => props.backgroundColor} url(${props => props.image})
    center center / cover no-repeat;
  border-radius: 50%;
  flex-shrink: 0;
`;

SpeakerImage.propTypes = {
  image: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']).isRequired,
};

export default SpeakerImage;
