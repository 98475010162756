import React from 'react';
import styled from 'styled-components';
import { compose, lifecycle, withState } from 'recompose';
import { componentWillMount } from './shared';
import BroadcastMetrics from './BroadcastMetrics';
import BroadcastScheduledTime from './BroadcastScheduledTime';
import MessengerTemplate from '../MessengerPreviewMocks/MessengerTemplate';

const BroadcastGenericWrapper = styled.div``;

export const Wrapper = styled.div`
  max-width: 400px;
`;

const BroadcastGeneric = ({
  buttons,
  action,
  buttonLabel,
  title,
  subtitle,
  imageUrl,
  sentAt,
  delivered,
  reached,
  messengerBroadcastId,
  messengerBroadcastFailed,
  scheduled,
  scheduleTime,
  timezone,
}) => (
  <BroadcastGenericWrapper>
    {!scheduled && (
      <BroadcastMetrics
        sentAt={sentAt}
        messengerBroadcastId={messengerBroadcastId}
        messengerBroadcastFailed={messengerBroadcastFailed}
        reached={reached}
      />
    )}
    {scheduled && (
      <BroadcastScheduledTime timestamp={scheduleTime} timezone={timezone} />
    )}
    <Wrapper>
      <MessengerTemplate
        title={title}
        subtitle={subtitle}
        imageUrl={imageUrl}
        buttons={
          buttons
            ? buttons.map(({ buttonType, buttonLabel }) => ({
                type: buttonType === 'link' ? 'web_url' : 'postback',
                label: buttonLabel,
              }))
            : [
                {
                  type: 'web_url',
                  action,
                  label: buttonLabel,
                },
              ]
        }
      />
    </Wrapper>
  </BroadcastGenericWrapper>
);

export default compose(
  withState('reached', 'setReached', 0),
  lifecycle({ componentWillMount })
)(BroadcastGeneric);
