import { fork } from 'redux-saga/effects';

import consume from '../consume';
import loop from '../loop';

import loopUserData from './account/loopUserData';
import syncUser from './account/syncUser';

import deselectConference from './conferences/deselect';
import selectConference from './conferences/select';

import authenticateUsingFacebook from './session/authenticateUsingFacebook';
import authenticateUsingGoogle from './session/authenticateUsingGoogle';
import authenticateUsingLinkedIn from './session/authenticateUsingLinkedIn';
import logOut from './session/logOut';

import userChannel from '../../channels/user';

import {
  ACCEPT_USER,
  DESELECT_CONFERENCE,
  SELECT_CONFERENCE,
  INITIATE_FACEBOOK_AUTH,
  INITIATE_GOOGLE_AUTH,
  INITIATE_LINKEDIN_AUTH,
  LOG_OUT,
} from '../../constants/user';

export default function* userSaga() {
  yield fork(loop(ACCEPT_USER, loopUserData));
  yield fork(consume(userChannel(), syncUser));

  yield fork(loop(DESELECT_CONFERENCE, deselectConference));
  yield fork(loop(SELECT_CONFERENCE, selectConference));

  yield fork(loop(INITIATE_FACEBOOK_AUTH, authenticateUsingFacebook));
  yield fork(loop(INITIATE_GOOGLE_AUTH, authenticateUsingGoogle));
  yield fork(loop(INITIATE_LINKEDIN_AUTH, authenticateUsingLinkedIn));
  yield fork(loop(LOG_OUT, logOut));
}
