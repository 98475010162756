/**
 *
 * RichTextView
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import redraft, { Atomic } from 'redraft';
import styled from 'styled-components';

const styles = {
  code: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  codeBlock: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 20,
  },
};

// just a helper to add a <br /> after a block
const addBreaklines = children =>
  children.map((child, i) => (
    <Fragment key={i}>
      {child} <br />
    </Fragment>
  ));

const P = styled.p`
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledLink = styled.a`
  color: ${props => props.theme.page.color};
`;

const renderers = {
  /**
   * Those callbacks will be called recursively to render a nested structure
   */
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
    CODE: (children, { key }) => (
      <span key={key} style={styles.code}>
        {children}
      </span>
    ),
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */
  blocks: {
    unstyled: children => children.map((child, i) => <P key={i}>{child}</P>),
    blockquote: children => <blockquote>{addBreaklines(children)}</blockquote>,
    'header-one': children =>
      children.map((child, i) => <h1 key={i}>{child}</h1>),
    'header-two': children =>
      children.map((child, i) => <h2 key={i}>{child}</h2>),
    // You can also access the original keys of the blocks
    'code-block': (children, { keys }) => (
      <pre style={styles.codeBlock} key={keys[0]}>
        {addBreaklines(children)}
      </pre>
    ),
    // or depth for nested lists
    'unordered-list-item': (children, { depth, keys }) => (
      <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ul>
    ),
    'ordered-list-item': (children, { depth, keys }) => (
      <ol key={keys.join('|')} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),
    // If your blocks use meta data it can also be accessed like keys
    atomic: (children, { keys, data }) =>
      children.map((child, i) => <Atomic key={keys[i]} {...data[i]} />),
  },
  /**
   * Entities receive children and the entity data
   */
  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => (
      <StyledLink target="_blank" key={key} href={data.url}>
        {children}
      </StyledLink>
    ),
  },
  /**
   * Array of decorators,
   * Entities receive children and the entity data,
   * inspired by https://facebook.github.io/draft-js/docs/advanced-topics-decorators.html
   */
  decorators: [],
};

const RichTextView = ({ raw, ...props }) => {
  const draft = (raw && redraft(raw, renderers)) || null;

  return draft && <div {...props}>{draft}</div>;
};

RichTextView.propTypes = {
  raw: PropTypes.object,
};

export default RichTextView;
