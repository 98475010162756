import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import MessengerTemplate from './MessengerTemplate';
import { MessengerTemplateListPropTypes } from './propTypes';

const Button = styled.button`
  cursor: pointer;
  background: #fff;
  border: 1px solid lightgray;
  padding: 4px;
  width: 32px;
  height: 32px;
  color: black;
  outline: none;
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  border-radius: ${props =>
    props.type === 'next' ? `4px 0px 0px 4px` : `0px 4px 4px 0px`};
`;

const NavButton = type => ({
  nextSlide,
  previousSlide,
  currentSlide,
  slideCount,
}) => (
  <Button
    type={type}
    onClick={type === 'next' ? nextSlide : previousSlide}
    disabled={
      type === 'next' ? currentSlide + 1 === slideCount : currentSlide === 0
    }
  >
    {type === 'next' ? '>' : '<'}
  </Button>
);

const PreviousButton = NavButton('previous');
const NextButton = NavButton('next');

const MessengerTemplateList = ({ items }) => (
  <div style={{ maxWidth: 400 }}>
    <Carousel
      renderCenterLeftControls={PreviousButton}
      renderCenterRightControls={NextButton}
      renderBottomCenterControls={() => null}
    >
      {items.map((item, i) => (
        <MessengerTemplate
          key={i}
          title={item.title}
          subtitle={item.subtitle}
          imageUrl={item.imageUrl}
          buttons={item.buttons}
        />
      ))}
    </Carousel>
  </div>
);

MessengerTemplate.propTypes = MessengerTemplateListPropTypes;
export default MessengerTemplateList;
