import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { Field } from 'redux-form/immutable';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import messages from './messages';

const Tracks = styled(Flexbox)`
  margin-top: 5px;

  span {
    color: gray;
  }

  input {
    padding-left: 15px;
  }
`;

class TrackInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    intl: intlShape.isRequired,
  };

  render() {
    const { name, intl } = this.props;

    return (
      <Tracks flexDirection="row" flexGrow={2}>
        <Flexbox flexGrow={2} style={{ marginRight: 6 }}>
          <Flexbox flexDirection="column">
            <span>Title</span>
            <Field
              name={`${name ? `${name}.` : ''}name`}
              component="input"
              type="text"
              placeholder={intl.formatMessage(messages.namePlaceholder)}
              required
            />
          </Flexbox>
        </Flexbox>
        <Flexbox flexGrow={2}>
          <Flexbox flexDirection="column">
            <span>Location</span>
            <Field
              name={`${name ? `${name}.` : ''}location`}
              component="input"
              type="text"
              placeholder={intl.formatMessage(messages.locationPlaceholder)}
              required
            />
          </Flexbox>
        </Flexbox>
      </Tracks>
    );
  }
}

export default injectIntl(TrackInput);
