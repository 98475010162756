/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.NotFoundPage.header',
    defaultMessage: 'Page Not Found',
  },
  action: {
    id: 'app.components.NotFoundPage.action',
    defaultMessage: 'Go Back to Homepage.',
  },
});
