export const ACCEPT_CONFERENCE = 'sava/user/ACCEPT_CONFERENCE';
export const ACCEPT_USER_DATA = 'sava/user/ACCEPT_USER_DATA';
export const ACCEPT_USER = 'sava/user/ACCEPT_USER';
export const CLEAR_USER = 'sava/user/CLEAR_USER';

export const LOG_IN_SUCCESS = 'sava/user/LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'sava/user/LOG_IN_ERROR';

export const LOG_OUT = 'sava/user/LOG_OUT';
export const LOG_OUT_SUCCESS = 'sava/user/LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'sava/user/LOG_OUT_ERROR';

export const SIGN_UP_SUCCESS = 'sava/user/SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'sava/user/SIGN_UP_ERROR';

export const RESET_PASSWORD_SUCCESS = 'sava/user/RESET_PASSWORD_SUCCESS';

export const SELECT_CONFERENCE = 'sava/user/SELECT_CONFERENCE';
export const DESELECT_CONFERENCE = 'sava/user/DESELECT_CONFERENCE';

export const INITIATE_GOOGLE_AUTH = 'sava/user/INITIATE_GOOGLE_AUTH';
export const INITIATE_FACEBOOK_AUTH = 'sava/user/INITIATE_FACEBOOK_AUTH';
export const INITIATE_LINKEDIN_AUTH = 'sava/user/INITIATE_LINKEDIN_AUTH';

export const SEND_BROADCAST = 'sava/user/SEND_BROADCAST';
export const SEND_VIDEOS = 'sava/user/SEND_VIDEOS';

export const ACCEPT_FB_PAGES = 'sava/user/ACCEPT_FB_PAGES';
