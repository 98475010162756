import { defineMessages } from 'react-intl';

export default defineMessages({
  labelName: {
    id: 'app.components.ChatIntentForm.quickReply.labelName',
    defaultMessage: 'Quick Reply Label',
  },
  labelNamePlaceholder: {
    id: 'app.components.ChatIntentForm.quickReply.labelNamePlaceholder',
    defaultMessage: 'e.g., Show me upcoming sessions',
  },
  createQuickReply: {
    id: 'app.components.ChatIntentForm.quickReply.create',
    defaultMessage: 'Create Quick Reply',
  },
  updateQuickReply: {
    id: 'app.components.ChatIntentForm.quickReply.update',
    defaultMessage: 'Update Quick Reply',
  },
  deleteQuickReply: {
    id: 'app.components.ChatIntentForm.quickReply.delete',
    defaultMessage: 'Delete',
  },
  responseType: {
    id: 'app.components.ChatIntentForm.quickReply.responseType',
    defaultMessage: 'Response type',
  },
  responseTypeSystemMessage: {
    id: 'app.components.ChatIntentForm.quickReply.responseTypeSystemMessage',
    defaultMessage: 'System Message',
  },
  responseTypeCustomIntent: {
    id: 'app.components.ChatIntentForm.quickReply.responseTypeCustomIntent',
    defaultMessage: 'Custom Intent',
  },
  systemMessage: {
    id: 'app.components.ChatIntentForm.quickReply.systemMessage',
    defaultMessage: 'System Message',
  },
  customIntent: {
    id: 'app.components.ChatIntentForm.quickReply.customIntent',
    defaultMessage: 'Custom Intent',
  },
  labelRequired: {
    id: 'app.components.ChatIntentForm.quickReply.labelRequired',
    defaultMessage: 'Label field is required',
  },
  responseTypeRequired: {
    id: 'app.components.ChatIntentForm.quickReply.responseTypeRequired',
    defaultMessage: 'Response type field is required',
  },
  systemMessageRequired: {
    id: 'app.components.ChatIntentForm.quickReply.systemMessageRequired',
    defaultMessage: 'System message field is required',
  },
  customIntentRequired: {
    id: 'app.components.ChatIntentForm.quickReply.customIntentRequired',
    defaultMessage: 'Custom intent field is required',
  },
  createTitle: {
    id: 'app.containers.CreateQuickReplyPage.title',
    defaultMessage: 'New Quick Reply',
  },
  createError: {
    id: 'app.components.ChatIntentForm.quickReply.createError',
    defaultMessage:
      'Failed to create new quick reply. If error persists, contact our 🌶️ support.',
  },
  updateError: {
    id: 'app.components.ChatIntentForm.quickReply.updateError',
    defaultMessage:
      'Failed to update quick reply. If error persists, contact our 🌶️ support.',
  },
  removeError: {
    id: 'app.components.ChatIntentForm.quickReply.removeError',
    defaultMessage:
      'Failed to remove quick reply. If error persists, contact our 🌶️ support.',
  },
  confirmRemoveQuickReply: {
    id: 'app.components.ChatIntentForm.quickReply.confirmRemoveQuickReply',
    defaultMessage: 'Deleting a quick reply is permanent. Proceed?',
  },
  confirmSetQuickRepliesToEmptyState: {
    id:
      'app.components.ChatIntentForm.quickReply.confirmSetQuickRepliesToEmptyState',
    defaultMessage: 'Deleting all quick replies is permanent. Proceed?',
  },
});
