import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/index';
import createSagaMiddleware from 'redux-saga';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';

// Build the middleware for intercepting and dispatching navigation actions
export const history = createHistory();
const reactRouterMiddleware = routerMiddleware(history);

export const sagaMiddleware = createSagaMiddleware();

export default createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(sagaMiddleware, reactRouterMiddleware))
);
