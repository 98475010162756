import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Box } from '../common';
import Summary from './Summary';
import RTChat from './RTChat';
import messagingClients from '../../../constants/messagingClients';
import { button } from '../../../utils/ui/interactive';

const statusLabels = {
  open: 'Request Open',
  closed: 'Request Closed',
};

export const Button = styled.button`
  padding: ${props => (props.small ? 2 : 12)}px;
  ${button()}
  margin-top: ${props => (props.marginTop ? 26 : 0)}px
`;

const StatusLabel = styled.p`
  color: ${props =>
    props.status === 'open'
      ? props.theme.colors.accent
      : props.theme.colors.gray};
`;

const PlatformIcon = styled.img`
  height: 32px;
`;

const PlatformBox = styled.div`
  flex: 1;
  margin-bottom: 24px;
`;

const ThreadContent = styled.div`
  flex: 1;
  flex-direction: row;
  text-align: center;
  padding: 24px 0px 0px 0px;
`;

const SubtleLabel = styled.p`
  color: ${props => props.theme.colors.lightOpacity};
  font-size: 0.75em;
  margin: 2px 0px;
`;

export default ({
  profile,
  ticket,
  onCloseTicket,
  conferenceHandle,
  currentUserId,
  createUserRequestExecutor,
}) => (
  <Box>
    <Summary profile={profile} />
    <ThreadContent>
      <PlatformBox>
        <PlatformIcon src={messagingClients[ticket.platform].icon} />
        <StatusLabel status={ticket.status}>
          {statusLabels[ticket.status]}
        </StatusLabel>
        <SubtleLabel>
          Opened at: {moment(ticket.openedAt).format('MMMM Do YYYY, h:mm:ss a')}
        </SubtleLabel>
        {ticket.status !== 'closed' && (
          <Button onClick={() => onCloseTicket(ticket.id)}>
            Resolve Request
          </Button>
        )}
        {ticket.closedAt && ticket.status === 'closed' && (
          <SubtleLabel>
            Closed at:{' '}
            {moment(ticket.closedAt).format('MMMM Do YYYY, h:mm:ss a')}
          </SubtleLabel>
        )}
      </PlatformBox>
      <RTChat
        createUserRequestExecutor={createUserRequestExecutor}
        conferenceHandle={conferenceHandle}
        ticketId={ticket.id}
        currentUserId={currentUserId}
        archived={ticket.status === 'closed'}
      />
    </ThreadContent>
  </Box>
);
