import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from './messages';
import Form from '../Form';
import SubmitButton from '../SubmitButton';
import DeleteButton from '../DeleteButton';
import CustomIntentPicker from '../CustomIntentPicker';
import SystemMessagePicker from '../SystemMessagePicker';
import TextInput from '../TextInput';
import SelectInputControl from '../SelectInputControl';

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const actionFields = {
  systemMessage: SystemMessagePicker,
  customIntent: CustomIntentPicker,
};

const ActionField = ({ type, intl }) => {
  const ActionFieldComponent = actionFields[type];
  const label = intl.formatMessage(messages[type]);
  return <ActionFieldComponent label={label} name={'action'} />;
};

const ActionTypeField = SelectInputControl((state, { intl }) => [
  {
    id: 'systemMessage',
    name: intl.formatMessage(messages.responseTypeSystemMessage),
  },
  {
    id: 'customIntent',
    name: intl.formatMessage(messages.responseTypeCustomIntent),
  },
]);

const ChatQuickReplyForm = ({
  onDelete,
  isCreateForm,
  handleSubmit,
  onSubmit,
  pristine,
  submitting,
  actionType,
  intl,
}) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <label htmlFor="label">
          <FormattedMessage {...messages.labelName} />
        </label>
        <Field
          name="label"
          component={TextInput}
          placeholder={intl.formatMessage(messages.labelNamePlaceholder)}
          required
          autoFocus
        />
      </fieldset>

      <fieldset>
        <label htmlFor="action.type">
          <FormattedMessage {...messages.responseType} />
        </label>
        <Field
          name="action.type"
          component={ActionTypeField}
          props={{ intl }}
        />
      </fieldset>

      {actionType && <ActionField type={actionType} intl={intl} />}

      <ActionBar>
        <SubmitButton
          submitting={submitting}
          label={intl.formatMessage(
            messages[isCreateForm ? 'createQuickReply' : 'updateQuickReply']
          )}
        />

        {!isCreateForm && (
          <DeleteButton type="reset" onClick={onDelete}>
            <FormattedMessage {...messages.deleteQuickReply} />
          </DeleteButton>
        )}
      </ActionBar>
    </Form>
  );
};

ChatQuickReplyForm.propTypes = {
  onDelete: PropTypes.func,
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  actionType: PropTypes.string,
};

const selector = formValueSelector('quickReply');

const mapStateToProps = state => {
  const actionType = selector(state, 'action.type');
  return { actionType };
};

const formValidation = (values, { intl }) => {
  const errors = {};

  if (!values.get('label')) {
    errors.label = intl.formatMessage(messages.labelRequired);
  }

  if (!values.get('action') || !values.getIn(['action', 'type'])) {
    errors.action = {
      type: intl.formatMessage(messages.responseTypeRequired),
    };
  }

  if (
    values.getIn(['action', 'type']) === 'systemMessage' &&
    !values.getIn(['action', 'systemMessageId'])
  ) {
    errors.action = {
      systemMessageId: intl.formatMessage(messages.systemMessageRequired),
    };
  } else if (
    values.getIn(['action', 'type']) === 'customIntent' &&
    !values.getIn(['action', 'customIntentId'])
  ) {
    errors.action = {
      customIntentId: intl.formatMessage(messages.customIntentRequired),
    };
  }

  return errors;
};

const formProps = {
  form: 'quickReply',
  validate: formValidation,
};

export default compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm(formProps)
)(ChatQuickReplyForm);
