const messengerIcon = `${process.env.PUBLIC_URL}/icons/facebook-messenger.svg`;
const telegramIcon = `${process.env.PUBLIC_URL}/icons/telegram.png`;

const messenger = {
  icon: messengerIcon,
  baseUrl: 'm.me',
  accessor: 'username',
  fallbackAccessor: 'id',
};

export default {
  facebook: messenger,
  messenger: messenger,
  telegram: {
    icon: telegramIcon,
    baseUrl: 't.me',
    accessor: 'username',
  },
};
