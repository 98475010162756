import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import Icon from '../Icon';

const EmptyStateContainer = styled(Flexbox)`
  color: rgba(18, 46, 64, 0.35);
  max-width: 480px;
  text-align: center;
  margin: 0px auto 0px auto;
`;

EmptyStateContainer.Illustration = styled.div`
  width: 18em;
  height: 12em;
  background: url(${props =>
    props.illustration}) center center / contain no-repeat};
`;

EmptyStateContainer.Icon = styled(Icon)`
  font-size: 32px;
`;

EmptyStateContainer.Title = styled.h2`
  margin-bottom: 0.25em;
  color: ${props => props.theme.page.color};
`;

EmptyStateContainer.Hint = styled.p`
  margin-top: 0;
  margin-bottom: 1.5em;
  color: ${props => props.theme.page.color};
`;

EmptyStateContainer.PaddingWrapper = styled.div`
  padding-top: 2.5em;
`;

const EntityEmptyState = ({
  illustration,
  icon,
  title,
  hint = '',
  children,
}) => (
  <EmptyStateContainer flexDirection="column" alignItems="center">
    <EmptyStateContainer.Illustration illustration={illustration} />
    {icon && <EmptyStateContainer.Icon>{icon}</EmptyStateContainer.Icon>}
    <EmptyStateContainer.Title>{title}</EmptyStateContainer.Title>
    <EmptyStateContainer.Hint>{hint}</EmptyStateContainer.Hint>
    {children}
  </EmptyStateContainer>
);

EntityEmptyState.propTypes = {
  illustration: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  hint: PropTypes.string,
};

const EmptyStateWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const EmptyStateMarginWrapper = styled(EmptyStateWrapper)`
  margin: 2em 2em 0em 2em;
`;

export const EmptyStatePaddingWrapper = styled(EmptyStateWrapper)`
  padding: 2em 2em 0em 2em;
`;

export default EntityEmptyState;
