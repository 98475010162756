/*
 * TimeInputControl Messages
 *
 * This contains all the text for the TimeInputControl component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'app.components.TimeInputControl.placeholder',
    defaultMessage: 'e.g., 11:15 AM',
  },
});
