import { call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';
import { fromJS } from 'immutable';

import { acceptUser } from '../../../actions/user';

export default function* authenticateUsingFacebook({ errorAction }) {
  try {
    const result = yield firebase
      .auth()
      .signInWithPopup(
        new firebase.auth.FacebookAuthProvider()
          .addScope('manage_pages')
          .addScope('pages_messaging')
      );

    const user = result.user;

    yield call(() =>
      firebase
        .database()
        .ref(`users/${user.uid}`)
        .update({ fullName: user.displayName })
    );

    yield put(acceptUser(fromJS(user.toJSON())));
    yield put(push('/'));
  } catch (error) {
    if (error.code === 'auth/account-exists-with-different-credential') {
      const new_error = {
        code: 'auth/account-exists-with-different-credential',
        message:
          'An account already exists with the same email address but ' +
          'different sign-in credentials. You can link those two accounts ' +
          'once you log in with already registered one.',
      };
      yield put(errorAction(new_error));
    } else {
      yield put(errorAction(error));
    }
  }
}
