/*
 *  EditBreakPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Map } from 'immutable';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectId,
} from '../../selectors/user';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

import BreakForm from '../../components/BreakForm';

import updateBreak from '../../sagas/user/breaks/update';
import deleteBreak from '../../sagas/user/breaks/delete';

import convertTime from '../../utils/convertTime';

export class EditBreakPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    breakObj: PropTypes.any.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages: error => {
      switch ((error || {}).code) {
        case 'break/duration':
          return 'Break can not have 0 or negative duration. Please correct start/end time of this break.';
        case 'break/overlapping':
          return 'Break overlaps with another break. Please correct start/end time of this break.';
        default:
          return 'Please try again. If error persists, contact our support.';
      }
    },
  });

  handleSubmit = async data => {
    const { conferenceHandle } = this.props;
    const { id, title, description, day, time, isBlock } = data
      .updateIn(['time', 'startTime'], convertTime)
      .updateIn(['time', 'endTime'], convertTime)
      .toJS();

    await this.defaultUserRequestExecutor(updateBreak, {
      conferenceHandle,
      id,
      title,
      description,
      day,
      time,
      isBlock,
    });
  };

  handleDelete = async () => {
    const { conferenceHandle, id } = this.props;

    await this.defaultUserRequestExecutor(deleteBreak, {
      conferenceHandle,
      id,
    });
  };

  render() {
    const { conferenceHandle, breakObj } = this.props;
    return (
      <div>
        <Helmet title={`${breakObj.get('title')} • Sava Events`} />
        <BreakForm
          conferenceHandle={conferenceHandle}
          initialValues={breakObj}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectId,
  (conferenceHandle, conference, id) => ({
    conferenceHandle,
    id,
    breakObj: conference.getIn(['breaks', id], Map()).set('id', id),
  })
);

export default compose(
  withRouter,
  withUserRequestExecutor,
  connect(mapStateToProps)
)(EditBreakPage);
