export default {
  1: {
    title: 'Welcome Message',
    description:
      'Information you present to your users when they initiate a conversation with you over messenger.',
  },
  2: {
    title: 'About',
    description:
      'Information you present to your users before they initiate a conversation with you over messenger.',
  },
};
