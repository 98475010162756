import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, withState } from 'recompose';
import { Map } from 'immutable';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import SingleDateInputControl from '../SingleDateInputControl';
import TimeInputControl from '../TimeInputControl';
import FormError from '../FormError';

const DateTimeFields = styled(Flexbox)``;

const SingleDateInputWrapper = styled.div`
  width: 50%;
`;

const TimeInputWrapper = styled.div`
  width: 50%;
`;

const DateTimeInput = ({ setFocus, focused, input, meta, timezone }) => {
  const { error } = meta;

  const handleDateChange = value => {
    input.onChange(
      (input.value || Map()).set(
        'date',
        value ? (timezone ? moment.tz(value, timezone) : value) : value
      )
    );
  };

  const handleTimeChange = value => {
    input.onChange((input.value || Map()).set('time', value));
  };

  const handleFocusChange = ({ focused }) => setFocus(focused);

  const value = input.value || Map();

  return (
    <Fragment>
      {error && <FormError>{error}</FormError>}

      <DateTimeFields flexDirection="row">
        <SingleDateInputWrapper>
          <SingleDateInputControl
            date={value.get('date')}
            onDateChange={handleDateChange}
            focused={focused}
            onFocusChange={handleFocusChange}
            showClearDate
          />
        </SingleDateInputWrapper>
        <TimeInputWrapper>
          <TimeInputControl
            value={value.get('time')}
            onChange={handleTimeChange}
          />
        </TimeInputWrapper>
      </DateTimeFields>
    </Fragment>
  );
};

const propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default compose(
  withState('focused', 'setFocus', false),
  setPropTypes(propTypes)
)(DateTimeInput);
