import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import styled from 'styled-components';

import ColorPicker from '../ColorPicker';
import FormError from '../FormError';

import { injectIntl, intlShape } from 'react-intl';
import messages from './messages';

const minValue = min => value =>
  value && value < min ? `Must be at least ${min}min` : undefined;

const Input = styled.div`
  position: relative;

  > span {
    position: absolute;
    border-radius: 3px;
    font-size: 14px;
    top: 0;
    left: 121px;
    width: 100%;
    background-color: #ff6464;
    color: white;
    box-shadow: 0px 8px 14px -1px rgba(0, 0, 0, 0.12);
    padding: 0px 15px;
  }
`;

const renderField = ({
  input: inputProps,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <Input>
    <input
      className="input"
      {...inputProps}
      placeholder={placeholder}
      type={type}
    />
    {touched && (error && <FormError>{error}</FormError>)}
  </Input>
);

renderField.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.any,
  type: PropTypes.any,
  meta: PropTypes.any,
};

const SessionTypeInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  }
`;

class SessionTypeInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    intl: intlShape.isRequired,
  };

  render() {
    const { name, intl } = this.props;
    return (
      <SessionTypeInputWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ paddingLeft: 12, paddingRight: 12 }}>
            <Field
              name={`${name ? `${name}.` : ''}color`}
              component={ColorPicker}
              normalize={value => value.hex}
              required
            />
          </div>
          <Field
            name={`${name ? `${name}.` : ''}name`}
            component="input"
            type="text"
            placeholder={intl.formatMessage(messages.namePlaceholder)}
            required
          />
        </div>
        <Field
          name={`${name ? `${name}.` : ''}duration`}
          component={renderField}
          type="number"
          placeholder={intl.formatMessage(messages.durationPlaceholder)}
          validate={minValue(5)}
          required
        />
      </SessionTypeInputWrapper>
    );
  }
}

export default injectIntl(SessionTypeInput);
