import React from 'react';
import { Tooltip } from 'react-tippy';

const TooltipHover = props => {
  return (
    <Tooltip
      title={props.text}
      html={props.html}
      theme="light"
      position={props.position ? props.position : 'top'}
      trigger="mouseenter"
      arrow="true"
    >
      {props.children}
    </Tooltip>
  );
};

export default TooltipHover;
