import firebase from 'firebase/app';

export default function* addComment(
  conferenceHandle,
  submissionId,
  author,
  authorDisplayName,
  content
) {
  yield firebase
    .database()
    .ref(
      `conferences/${conferenceHandle}/cfp/submissions/${submissionId}/comments`
    )
    .push({
      submittedAt: Date.now(),
      author,
      authorDisplayName,
      content,
    });
}
