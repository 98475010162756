import React from 'react';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  ${props => (!props.separator ? 'padding: 20px;' : '')}
  background: white;
  ${props => (!props.separator ? props.theme.altitude(1) : '')}
  ${props =>
    !props.separator ? `border: ${props.theme.borders.default};` : ''}
  ${props => (!props.separator ? 'border-radius: 0.25em;' : '')}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-basis: 73.5%;
  justify-content: center;
  background-color: ${props => props.theme.page.backgroundColor};
`;

export const Separator = styled.div`
  height: 1.5em;
`;

export const FieldSeparator = () => (
  <InputWrapper>
    <FieldWrapper separator>
      <Separator />
    </FieldWrapper>
    <PreviewWrapper />
  </InputWrapper>
);
