/*
 * TrackInput Messages
 *
 * This contains all the text for the TrackInput component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  namePlaceholder: {
    id: 'app.components.TrackInput.namePlaceholder',
    defaultMessage: 'e.g., Hyperstrategic Stage',
  },
  locationPlaceholder: {
    id: 'app.components.TrackInput.locationPlaceholder',
    defaultMessage: 'e.g., 2nd Floor, next to stairway',
  },
});
