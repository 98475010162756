import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form/immutable';

export default formId => Component => {
  class WithIsFormDirty extends React.Component {
    render() {
      return <Component {...this.props} isFormDirty={this.props.ifFormDirty} />;
    }
  }

  const mapIsFormDirty = state => ({
    ifFormDirty: isDirty(formId)(state),
  });

  return compose(connect(mapIsFormDirty))(WithIsFormDirty);
};
