import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';

const ReorderableTrack = ({
  children,
  connectDragSource,
  connectDropTarget,
  isDragging,
  isDragged,
}) =>
  connectDragSource(
    connectDropTarget(
      <div
        style={{
          margin: 2,
          background: isDragging && !isDragged ? '#e7e7e7' : undefined,
        }}
      >
        <div style={{ visibility: isDragging ? 'hidden' : undefined }}>
          {children}
        </div>
      </div>
    )
  );

ReorderableTrack.propTypes = {
  children: PropTypes.node.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isDragged: PropTypes.bool,
};

const ItemTypes = {
  TRACK: 'TRACK',
};

const trackSource = {
  beginDrag(props) {
    props.onDrag();

    return {
      index: props.index,
      optimalSize: props.optimalSize,
      conferenceHandle: props.conferenceHandle,
      speaker: props.speaker,
    };
  },
};

const trackTarget = {
  drop(props) {
    props.onDrop();
  },
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    /* eslint-disable no-param-reassign */
    monitor.getItem().index = hoverIndex;
    /* eslint-enable no-param-reassign */
  },
};

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function collectDropTarget(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default DragSource(ItemTypes.TRACK, trackSource, collectDragSource)(
  DropTarget(ItemTypes.TRACK, trackTarget, collectDropTarget)(ReorderableTrack)
);
