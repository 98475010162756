import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';

export default function* updateConference({
  id,
  name,
  dates,
  tracks,
  sessionTypes,
  timezone,
  embedLayout,
  mobileEmbedLayout,
}) {
  yield firebase
    .database()
    .ref(`conferences/${id}`)
    .update({
      name,
      dates,
      tracks,
      sessionTypes,
      timezone,
      embedLayout,
      mobileEmbedLayout,
    });

  yield put(push(`${'/'}@${id}`));

  try {
    window.$crisp.push([
      'set',
      'session:event',
      [
        [
          [
            'conference_update',
            {
              id: id,
              name: name,
              dates: dates,
              tracks: JSON.stringify(tracks),
              sessionTypes: JSON.stringify(sessionTypes),
              timezone: timezone,
            },
            'green',
          ],
        ],
      ],
    ]);
  } catch (e) {
    // crisp not ready
    console.log('crisp error', e);
  }
}
