import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { statusColors } from './styled';
import getDisplayTitle from '../utils/getDisplayTitle';
import toArray from '../utils/toArray';
import Icon from '../../../components/Icon';

const Stats = styled.div`
  opacity: 0.7;
  font-size: 0.8em;
  > span {
    margin-right: 6px;
  }
  > span > i {
    font-size: 16px;
    vertical-align: text-top;
  }
`;

const WrappingLink = styled(Link)`
  z-index: 9999;
  text-decoration: none;
  color: ${p => p.theme.colors.dark};
`;

const List = styled.div`
  background-color: ${p => p.theme.colors.lightGray};
  padding: 0.25em;
  height: 520px;
  width: 300px;
  margin-right: 8px;
  border-radius: 3px;
  border-top: 4px solid ${p => statusColors[p.status]};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 2px;
  }
`;

const Card = styled.div`
  padding: 1em;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(9, 45, 66, 0.25);
  margin: 4px auto;
  transition: 160ms all;
  :hover {
    opacity: 0.8;
    box-shadow: 0 1px 0 rgba(9, 45, 66, 1);
  }
`;

const CardTitle = styled.span`
  font-weight: 700;
`;

const Board = styled.div`
  display: flex;
  flex-direction: row;
`;

class CardItem extends React.Component {
  handleOnDragStart = e => {
    e.dataTransfer.setData('text', e.target.id);
  };

  render() {
    const { responses, submittedAt, id, rootUrl } = this.props;
    const comments = toArray(this.props.comments);
    const reactions = toArray(this.props.reactions);
    const approvedReactionsCount = reactions.filter(_ => _.type === 'approved')
      .length;
    const declinedReactionsCount = reactions.filter(_ => _.type === 'declined')
      .length;

    return (
      <Card id={id} onDragStart={this.handleOnDragStart} draggable="true">
        <WrappingLink to={`${rootUrl}/${id}`} draggable="false">
          <CardTitle draggable="false">
            {getDisplayTitle(responses, submittedAt)}
          </CardTitle>
          <Stats draggable="false">
            <span>
              <Icon>chat_bubble_outline</Icon> {comments.length}
            </span>
            <span>
              <Icon>sentiment_very_satisfied</Icon> {approvedReactionsCount}
            </span>
            <span>
              <Icon>sentiment_dissatisfied</Icon> {declinedReactionsCount}
            </span>
          </Stats>
        </WrappingLink>
      </Card>
    );
  }
}

class CardList extends React.Component {
  allowDrop = e => e.preventDefault();

  handleDrop = e => {
    e.preventDefault();
    const submissionId = e.dataTransfer.getData('text');
    this.props.updateSubmissionStatus(submissionId, this.props.status);
  };

  render() {
    const { submissions = [], status, rootUrl } = this.props;
    return (
      <List
        onDragOver={this.allowDrop}
        onDrop={this.handleDrop}
        status={status}
      >
        {submissions
          .filter(_ => _.status === status)
          .map(submission => (
            <CardItem key={submission.id} {...submission} rootUrl={rootUrl} />
          ))}
      </List>
    );
  }
}

const lists = [
  { status: 'new', label: 'New' },
  { status: 'review', label: 'In Review' },
  { status: 'accepted', label: 'Accepted' },
  { status: 'confirmed', label: 'Confirmed' },
  { status: 'rejected', label: 'Rejected' },
];

class KanbanBoard extends Component {
  render() {
    return (
      <Board>
        {lists.map(list => (
          <div key={list.status}>
            <h4>{list.label}</h4>
            <CardList
              updateSubmissionStatus={this.props.updateSubmissionStatus}
              submissions={this.props.submissions}
              status={list.status}
              rootUrl={this.props.rootUrl}
            />
          </div>
        ))}
      </Board>
    );
  }
}

export default KanbanBoard;
