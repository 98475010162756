import qs from 'qs';

import isNonEmptyFile from '../../../utils/isNonEmptyFile';

const url = `${process.env.REACT_APP_FUNCTIONS_URL}/uploadFile`;

export default async (file, path, options = {}) => {
  if (!isNonEmptyFile(file)) {
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  const query = qs.stringify({ path, ...options });
  const response = await fetch(`${url}?${query}`, {
    method: 'POST',
    body: formData,
  });
  const json = await response.json();

  if (response.status !== 200) {
    return Promise.reject(json);
  }

  return json;
};
