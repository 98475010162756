import React from 'react';
import styled from 'styled-components';

import messages from './messages';

import Icon from '../Icon';

import { ellipsisText } from '../../utils/ui/content';
import { Tag } from '../../components/Tags';
import SpeakerImage from '../SpeakerImage';
import colorForIndex from '../../containers/ConferencesPage/colors';

const IdentityTag = Tag.withComponent('span').extend`
  background-color: ${props => props.theme.colors.lightGray};
  color: ${props => props.theme.colors.gray};
  border-radius: 4px;
  margin-left: 12px;
  padding: 0.4rem;
  opacity: 1;
  vertical-align: super;
`;

const FullName = styled.div`
  width: 90%;
  ${ellipsisText};
  margin-top: 1em;
`;

const Avatar = SpeakerImage.extend`
  display: inline-block;
  margin-right: 12px;
`;

const DeleteIcon = styled.div`
  width: 10%;
  text-align: center;
  ${props => `color: ${props.theme.colors.gray};`} > i {
    cursor: pointer;
  }
  margin-top: 1em;
`;

const TeamListItem = ({
  currentUser,
  conferenceOwner,
  onDelete,
  isDeleteIconVisible,
  intl,
}) => ({ item: user, index }) => {
  return (
    <React.Fragment>
      <FullName>
        <Avatar
          type="tiny"
          backgroundColor={colorForIndex(index)}
          image={user.providerPhotoUrl}
        />
        <span style={{ verticalAlign: 'super' }}>
          {user.fullName || intl.formatMessage(messages.defaultFullName)}
        </span>
        {user.id === currentUser && <IdentityTag>⭐ You!</IdentityTag>}
        {user.id === conferenceOwner && <IdentityTag>✨ Owner</IdentityTag>}
      </FullName>
      {isDeleteIconVisible(user.id) && (
        <DeleteIcon onClick={onDelete(user.id)}>
          <Icon>close</Icon>
        </DeleteIcon>
      )}
    </React.Fragment>
  );
};

export default TeamListItem;
