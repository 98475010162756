import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import responsive from '../../../utils/ui/responsive';

export const Container = styled(Flexbox)`
  padding: 1.5em;

  ${responsive.md.andSmaller`
    flex-wrap: wrap;
    background-image: none;
  `};
  align-items: baseline;
`;

export const statusColors = {
  new: '#0AFEBA',
  review: '#00E1A2',
  accepted: '#00BE89',
  confirmed: '#01936A',
  rejected: '#FF423D',
};

export const Header = styled(Flexbox)`
  flex-direction: row;
  width: 100%;
`;

Header.Item = styled(Flexbox)`
  flex: 1;
  align-self: center;
  flex-direction: column;
  align-items: start;
`;
