import React from 'react';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';

import Spinner from '../Spinner';

const Root = styled(Flexbox)`
  flex: 1;
  margin: 20px;
`;

const ContentLoading = () => (
  <Root
    justifyContent={'center'}
    alignItems={'center'}
    flexDirection={'column'}
  >
    <Spinner size={4} barThickness={2} />
  </Root>
);

export default ContentLoading;
