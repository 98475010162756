import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import Form from '../../../components/Form';
import { button } from '../../../utils/ui/interactive';

const inputType = {
  smallText: 'input',
  largeText: 'textarea',
};

const Button = styled.button`
  padding: 12px;
  font-weight: 700;
  font-size: 1.1em;
  ${button()};
`;

const StyledForm = styled.div`
  margin: 0 auto;
  max-width: 640px;
`;

const ErrorBox = styled.div`
  background: crimson;
  padding: 0.25em 0.5em;
  color: #fff;
  margin: 12px 0;
`;

class CFPForm extends Component {
  render() {
    const { config } = this.props;
    return (
      <StyledForm>
        <h1>{config.title}</h1>
        <p>{config.description}</p>
        <Form onSubmit={this.props.handleSubmit}>
          {this.props.blocks.map((block, index) => (
            <fieldset key={index}>
              <label>
                {block.label} {block.required && ' *'}
                <Field
                  name={`data.${index}`}
                  component={inputType[block.type] || 'input'}
                  type="text"
                  placeholder={block.label || ''}
                  required={block.required}
                />
              </label>
            </fieldset>
          ))}
          {this.props.hasError && (
            <ErrorBox>
              <p>
                There was an error submitting the form. Please, check your
                answers and try submitting.
              </p>
            </ErrorBox>
          )}
          <Button>Submit Proposal</Button>
        </Form>
      </StyledForm>
    );
  }
}

export default reduxForm({
  form: 'cfp',
  initialValues: fromJS({ data: {} }),
})(CFPForm);
