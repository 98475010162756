export default (responses = [], timestamp) => {
  const potentialName =
    responses.find(_ => _.label.toLowerCase().includes('name')) || {};
  const potentialTitle =
    responses.find(_ => _.label.toLowerCase().includes('title')) || {};
  const potentialEmail =
    responses.find(_ => _.label.toLowerCase().includes('email')) || {};
  const fallback = `Submitted on ${new Date(
    timestamp
  ).toLocaleDateString()} at ${new Date(timestamp).toLocaleTimeString()}`;
  return (
    potentialName.value ||
    potentialTitle.value ||
    potentialEmail.value ||
    fallback
  );
};
