import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';

const Container = styled.div`
  flex: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  > h2 {
    font-size: 3em;
    flex: 0;
  }
  padding: 1rem;
  background: rgb(255, 244, 226);
`;

const Announcement = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: start !important;
  justify-content: start !important;
  font-size: 1.17em;
  color: black;
  max-height: 15em;
  padding: 2rem;
`;

Announcement.Time = styled.p`
  font-weight: 600;
  font-size: 1.17em;
  margin-right: 2rem !important;
`;

Announcement.Msg = styled.p`
  align-self: flex-start;
`;

const formatTime = (timestamp, timezone) =>
  moment.tz(timestamp, timezone || 'America/New_York').format('HH:mm');

const Announcements = ({ announcements, timezone }) => (
  <Container className="announcements">
    <h2>Announcement{announcements.count() === 1 ? '' : 's'}</h2>
    {announcements.map(annon => (
      <Announcement key={annon.get('timestamp')}>
        <Announcement.Time>
          {formatTime(annon.get('timestamp'), timezone)}
        </Announcement.Time>
        <Announcement.Msg>{annon.get('message', '')}</Announcement.Msg>
      </Announcement>
    ))}
  </Container>
);

Announcements.propTypes = {
  announcements: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      timestamp: PropTypes.number,
      message: PropTypes.string,
    })
  ),
  timezone: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  if (!state.getIn(['embed', 'announcements'])) {
    return { announcements: List(), ...props };
  }

  const announcements = state
    .getIn(['embed', 'announcements'])
    .take(props.limit || 3);
  const timezone = state.getIn(['embed', 'timezone']);

  return { announcements, timezone, ...props };
};

export default compose(
  connect(mapStateToProps),
  branch(props => props.announcements.size === 0, renderNothing)
)(Announcements);
