/**
 *
 *  NewTeamMemberForm
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import Form from '../../components/Form';
import SubmitButton from '../../components/SubmitButton';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';

const Title = styled.h3`
  margin-top: 0;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

class NewTeamMemberForm extends Component {
  render() {
    const { handleSubmit, submitting, intl } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Title>Add new team member</Title>
        <fieldset>
          <FormattedMessage {...messages.email} />
          <Field
            name="email"
            component="input"
            type="email"
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            required
            autoFocus
          />
        </fieldset>

        <ActionBar>
          <SubmitButton
            submitting={submitting}
            label={intl.formatMessage(messages.invite)}
          />
        </ActionBar>
      </Form>
    );
  }
}

NewTeamMemberForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'teamMember',
    validate: (values, { intl }) => {
      const errors = {};

      const { email } = values.toJS();

      if (!email) {
        errors.title = intl.formatMessage(messages.emailRequired);
      }

      return errors;
    },
  })(NewTeamMemberForm)
);
