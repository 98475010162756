export default (width, height) => aspectRatio => {
  const frameAspectRatio = width / height;

  const frameIsWider = frameAspectRatio > aspectRatio;

  return {
    width: frameIsWider ? height * aspectRatio : width,
    height: frameIsWider ? height : width / aspectRatio,
  };
};
