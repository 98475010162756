import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import styled from 'styled-components';

const View = styled.div`
  cursor: default;
  margin-bottom: 1em;
  /* override styles generated by react-rating */
  > span > span {
    cursor: default !important;
  }
`;

const Star = styled.div`
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;
  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #eee;
  border-left: 0.3em solid transparent;
  cursor: default;

  /* Controlls the size of the stars. */
  font-size: 10px;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;

    position: absolute;
    top: 0.6em;
    left: -1em;

    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #eee;
    border-left: 1em solid transparent;

    transform: rotate(-35deg);
  }

  &:after {
    transform: rotate(35deg);
  }
`;

const FullStar = styled(Star)`
  border-bottom: 0.7em solid #fc0;
  &:before,
  &:after {
    border-bottom: 0.7em solid #fc0;
  }
`;

const Info = styled.p`
  opacity: 0.8;
  margin: 0;
`;

const RatingView = ({ averageRating, totalRatings }) => (
  <View>
    <Rating
      readonly
      initialRating={averageRating}
      emptySymbol={<Star />}
      fullSymbol={<FullStar />}
    />
    <Info>
      Rated {averageRating.toFixed(2)} by {totalRatings} attendees.
    </Info>
  </View>
);

RatingView.propTypes = {
  averageRating: PropTypes.number.isRequired,
  totalRatings: PropTypes.number.isRequired,
};

export default RatingView;
