import firebase from 'firebase/app';

export default function* addMessage(
  message,
  conferenceHandle,
  ticketId,
  userId
) {
  yield firebase
    .database()
    .ref(
      `conferences/${conferenceHandle}/ticketMessageLogs/${ticketId}/${Date.now()}`
    )
    .set({
      author: userId,
      dateTime: new Date().toJSON(),
      type: 'text',
      content: message,
      sentBySupportAgent: true,
    });
}
