import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { Field } from 'redux-form/immutable';
import ThemeColorInput from '../../ColorInput';
import { SessionPage } from '../../../containers/SessionPage';
import { session } from '../../../mocks/session';
import provideLink from '../../../mocks/provideLink';
import { FieldWrapper, InputWrapper, PreviewWrapper } from '../common';
import FormLabel from '../../FormLabel';

const DefaultThemeInput = ({ messages, intl }) => (
  <InputWrapper>
    <FieldWrapper style={{ maxHeight: '225px' }}>
      <FormLabel
        htmlFor={'page'}
        message={intl.formatMessage(messages.general)}
      />

      <Field
        id={`page.color`}
        name={`page.color`}
        label={intl.formatMessage(messages.pageTextColor)}
        component={ThemeColorInput}
      />

      <Field
        id={`page.backgroundColor`}
        name={`page.backgroundColor`}
        label={intl.formatMessage(messages.pageBackgroundColor)}
        component={ThemeColorInput}
      />

      <Field
        id={`colors.accent`}
        name={`colors.accent`}
        label={intl.formatMessage(messages.linkColor)}
        component={ThemeColorInput}
      />
    </FieldWrapper>
    <PreviewWrapper>
      <SessionPage
        showDescription={true}
        showQuestionForm={false}
        showQuestions={false}
        linkProvider={provideLink('#')}
        conferenceHandle="sava"
        session={session}
        speakerLinkProvider={provideLink('#')}
      />
    </PreviewWrapper>
  </InputWrapper>
);

DefaultThemeInput.propTypes = {
  messages: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(DefaultThemeInput);
