import { call, put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';

function* confirm(message) {
  return yield window.confirm(`${message} Proceed?`);
}

export default function* deleteSession({ conferenceHandle, id }) {
  const confirmed = yield call(confirm, 'Deleting a session is permanent.');

  if (confirmed) {
    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/sessions/${id}`)
      .remove();

    yield put(goBack());
  }
}
