import React from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';

class CustomResponseInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
  };

  render() {
    const { name } = this.props;

    return (
      <Field
        name={`${name ? `${name}.` : ''}response`}
        component="input"
        type="text"
        placeholder={'Add Message Here'}
        required
      />
    );
  }
}

export default CustomResponseInput;
