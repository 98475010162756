const phonetics = [
  'Alfa',
  'Bravo',
  'Charlie',
  'Delta',
  'Echo',
  'Foxtrot',
  'Golf',
  'Hotel',
  'India',
  'Juliett',
  'Kilo',
  'Lima',
  'Mike',
  'November',
  'Oscar',
  'Papa',
  'Quebec',
  'Romeo',
  'Sierra',
  'Tango',
  'Uniform',
  'Victor',
  'Whiskey',
  'X-ray',
  'Yankee',
  'Zulu',
];

const colors = [
  'Aqua',
  'Aquamarine',
  'Azure',
  'Beige',
  'Black',
  'Blue',
  'Cornsilk',
  'Crimson',
  'Fuchsia',
  'Gold',
  'Gray',
  'Green',
  'Indigo',
  'Khaki',
  'Lavender',
  'Line',
  'Magenta',
  'Maroon',
  'Moccasin',
  'Navy',
  'Pink',
  'Plum',
  'Silver',
  'Violet',
  'Yellow',
];

const randomFrom = list => list[Math.floor(Math.random() * list.length)];

const hashes = {};

export default id => {
  if (id && hashes[id]) {
    return hashes[id];
  }
  const name = `${randomFrom(phonetics)} ${randomFrom(colors)}`;
  if (id) {
    hashes[id] = name;
  }
  return name;
};
