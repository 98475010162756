import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import range from 'sava-shared/lib/utils/range';

import { selectDates as selectConferenceDates } from './embed';

export const selectProps = (_, props) => props;
export const selectProp = name => (_, props) => props[name];
export const selectToday = (_, props) => {
  const now = props.now ? props.now.toDate() : undefined;
  const today = props.today || now;
  return today || new Date();
};

export const selectFirstDate = createSelector(selectConferenceDates, dates => {
  if (!dates) {
    return;
  }

  const [date] = dates.split(',');
  const [year, month, day] = date.split('/');

  return new Date(year, month - 1, day);
});

export const selectNumberOfDays = createSelector(
  selectConferenceDates,
  dates => {
    if (!dates) {
      return;
    }

    const [, days] = dates.split(',');

    return parseInt(days, 10);
  }
);

export const selectDates = createSelector(
  selectFirstDate,
  selectNumberOfDays,
  (firstDate, numberOfDays) => {
    if (!firstDate || !numberOfDays || numberOfDays < 1) {
      return;
    }

    return range(numberOfDays).map(day => moment(firstDate).add(day, 'days'));
  }
);

export const selectCurrentDay = createSelector(
  selectToday,
  selectDates,
  (today, dates) => {
    if (!today || !dates || dates.length < 1) {
      return;
    }

    const day = dates.findIndex(
      date => moment(today).diff(moment(date), 'days') === 0
    );

    if (day < 0) {
      return moment(today).isBefore(moment(dates[0])) ? 0 : dates.length - 1;
    }

    return day;
  }
);
