import firebase from 'firebase/app';

export default function* toggleAcceptingSubmissions(
  conferenceHandle,
  acceptingSubmissions
) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/cfp/acceptingSubmissions`)
    .set(acceptingSubmissions);
}
