import { call, select } from 'redux-saga/effects';
import firebase from 'firebase/app';

import { selectUser } from '../../../selectors/user';

export default function* deselectConference() {
  const user = yield select(selectUser());

  yield call(() =>
    firebase
      .database()
      .ref(`users/${user.get('uid')}/currentConference`)
      .remove()
  );
}
