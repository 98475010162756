/*
 * App Messages
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  schedule: {
    id: 'app.containers.App.schedule',
    defaultMessage: 'Schedule',
  },
  breaks: {
    id: 'app.containers.App.breaks',
    defaultMessage: 'Breaks',
  },
  speakers: {
    id: 'app.containers.App.speakers',
    defaultMessage: 'Speakers',
  },
  cfp: {
    id: 'app.containers.App.cfp',
    defaultMessage: 'Call for Papers',
  },
  sponsors: {
    id: 'app.containers.App.sponsors',
    defaultMessage: 'Sponsors',
  },
  broadcasts: {
    id: 'app.containers.App.broadcasts',
    defaultMessage: 'Broadcasts',
  },
  chatbot: {
    id: 'app.containers.App.chatbot',
    defaultMessage: 'Chatbot',
  },
  supportInbox: {
    id: 'app.containers.App.supportInbox',
    defaultMessage: 'Support Inbox',
  },
  moderator: {
    id: 'app.containers.App.moderator',
    defaultMessage: 'Screen Widgets',
  },
  settings: {
    id: 'app.containers.App.settings',
    defaultMessage: 'Settings',
  },
  support: {
    id: 'app.containers.App.support',
    defaultMessage: 'Support Inbox',
  },
});
