/*
 *  BreaksPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { Map, Range } from 'immutable';
import styled from 'styled-components';
import color from 'color';
import moment from 'moment-timezone';
import { compose, setPropTypes } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
} from '../../selectors/user';

import messages from './messages';
import interactive, { button } from '../../utils/ui/interactive';

import Breadcrumb from '../../components/Breadcrumb';
import Break from '../../components/Break';
import TooltipButton from '../../components/TooltipButton';
import EntityEmptyState from '../../components/EntityEmptyState';
import emptyStateIllustration from '../../assets/images/coffee_breaks.svg';

import ModalRoute from '../../components/ModalRoute';
import Button from '../../components/buttons/Button';
import CreateBreakPage from '../../containers/CreateBreakPage';
import EditBreakPage from '../../containers/EditBreakPage';

import firstImage from '../../components/TooltipCards/assets/addbreak.svg';
import secondImage from '../../components/TooltipCards/assets/Break.svg';

const DayPicker = styled(Flexbox)``;

const ButtonLink = styled(Link)`
  margin: 0;
  padding: 12px;

  margin-left: -1px;

  border: 1px solid rgba(0, 0, 0, 0.1);

  z-index: ${props => (props.data.active ? 30 : 10)};

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    z-index: 20;
  }

  ${interactive.styles`
    color: #333;
  `} ${props =>
    button({
      backgroundColor: color(props.data.active ? '#248bd1' : 'white'),
    })};
`;

const Topbar = styled(Flexbox)`
  padding: 1em;
`;

const BreakLink = styled(Link)`
  text-decoration: none;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0.5em 1em;
  }
`;

const BreaksPage = ({
  conferenceName,
  conferenceHandle,
  conferenceDays,
  day,
  breaks,
  match,
  intl,
}) => (
  <div>
    <TooltipButton
      title="Breakes page"
      body="Quickly add new breaks and edit old ones."
      firstImage={firstImage}
      body2="Set when your breaks will show and how people can enjoy them."
      secondImage={secondImage}
    />
    <Helmet title={conferenceName} />
    <Breadcrumb key={day} to={`/@${conferenceHandle}/breaks/days/${day}`}>
      Day {day + 1}
    </Breadcrumb>
    <Flexbox flexDirection="column">
      <Topbar justifyContent="space-between">
        <DayPicker>
          {conferenceDays.map((d, i) => (
            <ButtonLink
              key={i}
              to={`/@${conferenceHandle}/breaks/days/${i}`}
              data={{ active: day === i }}
            >
              Day {i + 1}
            </ButtonLink>
          ))}
        </DayPicker>

        {breaks.count() > 0 && (
          <Button
            to={`/@${conferenceHandle}/breaks/days/${day}/break/new`}
            label="Add a Break"
            icon="add"
            type="primary"
          />
        )}
      </Topbar>
      {breaks.count() === 0 && (
        <EntityEmptyState
          illustration={emptyStateIllustration}
          title={intl.formatMessage(messages.breaksEmptyState)}
          hint={intl.formatMessage(messages.breaksEmptyStateHint)}
        >
          <Button
            to={`/@${conferenceHandle}/breaks/days/${day}/break/new`}
            label="Add a Break"
            icon="add"
            type="primary"
          />
        </EntityEmptyState>
      )}
      <Layout>
        {breaks.map(breakObj => (
          <BreakLink
            style={{ width: 300, height: 100 }}
            key={breakObj.get('id')}
            to={`/@${conferenceHandle}/breaks/days/${day}/break/${breakObj.get(
              'id'
            )}`}
          >
            <Break breakObj={breakObj} />
          </BreakLink>
        ))}
      </Layout>
    </Flexbox>

    <ModalRoute
      exact
      path={`${match.path}/break/new`}
      component={CreateBreakPage}
    />
    <ModalRoute
      exact
      path={`${match.path}/break/:id`}
      except={[`${match.path}/break/new`]}
      component={EditBreakPage}
    />
  </div>
);

const toMoment = day => time => {
  const [hours, minutes] = time.split(':', 2).map(n => parseInt(n, 10));

  return day
    .clone()
    .hours(hours)
    .minutes(minutes)
    .milliseconds(0);
};

const selectCurrentDay = (state, props) =>
  parseInt((props.match.params || {}).day, 10);

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectCurrentDay,
  (conference, conferenceHandle, conferenceName, currentDay) => {
    const [firstDateStr, durationStr] = conference
      .get('dates', '')
      .split(',', 2);

    const [year, month, day] = firstDateStr
      .split('/', 3)
      .map(n => parseInt(n, 10));

    const dates = Range(0, parseInt(durationStr, 10)).map(i =>
      moment([year, month - 1, day]).add(i, 'days')
    );

    const toThisDaysMoment = toMoment(dates.get(currentDay));

    const breaks = conference
      .get('breaks', Map())
      .entrySeq()
      .map(([k, v]) => v.set('id', k))
      .toList()
      .filter(breakObj => breakObj.get('day') === currentDay)
      .map(breakObj =>
        breakObj
          .merge(breakObj.get('time'))
          .set('isBlock', true)
          .delete('time')
          .update('startTime', toThisDaysMoment)
          .update('endTime', toThisDaysMoment)
      )
      .sortBy(breakObj => breakObj.get('startTime').valueOf());

    return {
      conferenceHandle,
      conferenceName,
      conferenceDays: dates,
      day: currentDay,
      breaks,
    };
  }
);

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
  setPropTypes({
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string,
    conferenceDays: PropTypes.any,
    day: PropTypes.number.isRequired,
    breaks: PropTypes.any,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  })
)(BreaksPage);
