import { call, all } from 'redux-saga/effects';

import saveQuickReply from './saveQuickReply';

export default function* sortQuickReplies(conferenceHandle, quickReplies) {
  yield all(
    quickReplies.map(quickReply =>
      call(saveQuickReply, conferenceHandle, quickReply)
    )
  );
}
