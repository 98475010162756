import React from 'react';
import { withSagaMiddleware } from '../providers/SagaMiddlewareProvider';
import { actions as toastrActions } from 'react-redux-toastr';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const ToastrOptionsSuccess = (message = '') => ({
  type: 'light',
  title: 'Success',
  message: message,
  options: {
    showCloseButton: true,
    icon: 'success',
    status: 'info',
  },
});

const ToastrOptionsError = (
  message = 'Something went wrong. Please try again or contact our support.'
) => ({
  type: 'light',
  title: 'Error',
  message: message,
  options: {
    showCloseButton: true,
    icon: 'error',
    status: 'error',
  },
});

export const errorMessages = error => {
  if (error && error.code && (error.hint || error.message)) {
    return error.hint || error.message;
  }

  // Show error to console otherwise it will be ignored
  console.error(error);

  return undefined;
};

const withUserRequestExecutor = WrappedComponent => {
  const WithUserRequestExecutor = ({ sagaMiddleware, dispatch, ...props }) => {
    const createUserRequestExecutor = (configuration = {}) => async (
      saga,
      ...sagaArguments
    ) => {
      const {
        onStart = () => {},
        onEnd = () => {},
        onSuccess = () => {},
        onFailure = () => {},
        errorMessages = () => {},
        shouldNotifyToastr = true,
      } = configuration;

      dispatch(showLoading());
      onStart();
      try {
        const sagaHandle = sagaMiddleware.run(saga, ...sagaArguments);
        const response = await sagaHandle.done;
        onSuccess();
        shouldNotifyToastr &&
          dispatch(toastrActions.add(ToastrOptionsSuccess()));
        onEnd();
        dispatch(hideLoading());
        return response;
      } catch (error) {
        onFailure(error);
        const message = errorMessages(error);
        shouldNotifyToastr &&
          dispatch(toastrActions.add(ToastrOptionsError(message)));
        onEnd();
        dispatch(hideLoading());
      }
    };

    return (
      <WrappedComponent
        {...props}
        createUserRequestExecutor={createUserRequestExecutor}
      />
    );
  };

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }

  return compose(
    connect(mapDispatchToProps),
    withSagaMiddleware
  )(WithUserRequestExecutor);
};

export default withUserRequestExecutor;
