import React from 'react';
import TextFieldCounter from '../TextFieldCounter';

export default ({ input, limit, ...props }) => (
  <div>
    {limit ? (
      <TextFieldCounter limit={limit} length={input.value.length} />
    ) : null}
    <input id={input.name} type={'text'} {...input} {...props} />
  </div>
);
