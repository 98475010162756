/**
 *
 *  BreakForm
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { List, fromJS } from 'immutable';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { selectCurrentConference } from '../../selectors/user';

import { button } from '../../utils/ui/interactive';

import DayInput from '../../components/DayInput';
import Form from '../../components/Form';
import DeleteButton from '../../components/DeleteButton';
import TimeRangeInput from '../../components/TimeRangeInput';
import ToggleSwitch from '../../components/ToggleSwitch';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';
import addDurationToTime from '../../utils/addDurationToTime';
import { selectProps } from '../../selectors/common';

/* eslint-disable */
const renderToggleSwitch = props => (
  <ToggleSwitch value={!!props.input.value} onChange={props.input.onChange} />
);
/* eslint-enable */

const Button = styled.button`
  padding: 12px;

  ${button()};
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

class BreakForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      descriptionWanted: false,
    };
  }

  render() {
    const {
      conferenceHandle,
      onDelete,
      isCreateForm,
      handleSubmit,
      pristine,
      submitting,
      intl,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        {!isCreateForm && (
          <Field name="id" component="input" type="hidden" required />
        )}

        <Flexbox flexDirection="row">
          <fieldset>
            <FormattedMessage {...messages.day} />
            <Field
              name="day"
              component={DayInput}
              props={{ conferenceHandle }}
              required
            />
          </fieldset>

          <Flexbox style={{ width: 20 }} />

          <fieldset>
            <FormattedMessage {...messages.time} />
            <Field name="time" component={TimeRangeInput} required />
          </fieldset>
        </Flexbox>

        <fieldset>
          <FormattedMessage {...messages.title} />
          <Field
            name="title"
            component="input"
            type="text"
            placeholder={intl.formatMessage(messages.titlePlaceholder)}
            style={{ fontSize: 22 }}
            required
            autoFocus
          />
        </fieldset>

        <fieldset>
          <Flexbox alignItems="center" style={{ paddingBottom: '1em' }}>
            <span style={{ paddingRight: '1em' }}>
              <FormattedMessage {...messages.sideEvent} />
            </span>

            <Field name="isBlock" component={renderToggleSwitch} />

            <span style={{ paddingLeft: '1em' }}>
              <FormattedMessage {...messages.blockEvent} />
            </span>
          </Flexbox>
        </fieldset>

        <ActionBar>
          <Button disabled={pristine && submitting}>
            <FormattedMessage
              {...(isCreateForm ? messages.create : messages.update)}
            />
          </Button>

          {!isCreateForm && (
            <DeleteButton type="reset" onClick={onDelete}>
              Delete
            </DeleteButton>
          )}
        </ActionBar>
      </Form>
    );
  }
}

BreakForm.propTypes = {
  conferenceHandle: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

const isValidTime = time =>
  List([
    /^00:[0-5]\d$/,
    /^0?\d:[0-5]\d$/,
    /^1\d:[0-5]\d$/,
    /^2[0-3]:[0-5]\d$/,
    /^\d:[0-5]\d\sAM$/,
    /^1[0-2]:[0-5]\d\sAM$/,
    /^\d:[0-5]\d\sPM$/,
    /^1[0-2]:[0-5]\d\sPM$/,
  ]).some(regex => time.match(regex));

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectProps,
  (conference, props) => {
    if (props.isCreateForm) {
      const day = Number(props.initialValues.get('day'));

      // TODO: sort times & add time to endTime

      const times = conference.has('breaks')
        ? conference
            .get('breaks')
            .filter(breakObj => breakObj.get('day') === day)
            .map(breakObj => breakObj.getIn(['time', 'startTime']))
            .toArray()
        : [];

      const startTime = times[times.length - 1] || '10:00';
      const endTime = addDurationToTime(startTime, 15);

      const initialValues = fromJS({
        time: {
          startTime,
          endTime,
        },
        day,
        isBlock: true,
      });

      return {
        initialValues,
      };
    }

    return {};
  }
);

export default connect(mapStateToProps)(
  reduxForm({
    form: 'break',
    validate: values => {
      const errors = {};

      const { title, day, time } = values.toJS();

      if (!title) {
        errors.title = 'Please enter the title of the break';
      }

      if (day === null || day === undefined) {
        errors.day = 'Please enter the day of the break';
      }

      if (!time.startTime) {
        errors.time = 'Please enter the start time of the break';
      } else if (!isValidTime(time.startTime)) {
        errors.time = 'Please enter the full start time of the break';
      } else if (!time.endTime) {
        errors.time = 'Please enter the end time of the break';
      } else if (!isValidTime(time.endTime)) {
        errors.time = 'Please enter the full end time of the break';
      }

      return errors;
    },
  })(injectIntl(BreakForm))
);
