import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { Field } from 'redux-form/immutable';
import ThemeColorInput from '../../ColorInput';
import FormLabel from '../../FormLabel';
import Navigation from '../../Navigation';
import navigation from '../../../mocks/navigation';
import { FieldWrapper, InputWrapper, PreviewWrapper } from '../common';

const NavigationThemeInput = ({ messages, intl }) => (
  <InputWrapper>
    <FieldWrapper>
      <FormLabel
        htmlFor={'navigation'}
        message={intl.formatMessage(messages.navigation)}
      />

      <Field
        id="navigation.backgroundColor"
        name="navigation.backgroundColor"
        label={intl.formatMessage(messages.navigationBackgroundColor)}
        component={ThemeColorInput}
      />

      <Field
        id="navigation.color"
        name="navigation.color"
        label={intl.formatMessage(messages.navigationColor)}
        component={ThemeColorInput}
      />

      <Field
        id="navigation.activeBackgroundColor"
        name="navigation.activeBackgroundColor"
        label={intl.formatMessage(messages.navigationActiveBackgroundColor)}
        component={ThemeColorInput}
      />

      <Field
        id="navigation.activeColor"
        name="navigation.activeColor"
        label={intl.formatMessage(messages.navigationActiveColor)}
        component={ThemeColorInput}
      />
    </FieldWrapper>
    <PreviewWrapper>
      <Navigation centered {...navigation} />
    </PreviewWrapper>
  </InputWrapper>
);

NavigationThemeInput.propTypes = {
  messages: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(NavigationThemeInput);
