import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PreserveScrollPosition extends Component {
  positions = {};
  prevPosition = undefined;

  shouldComponentUpdate(nextProps) {
    return nextProps.location !== this.props.location;
  }

  componentWillUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.positions[this.props.location.pathname] = window.scrollY;
      this.prevPosition = window.scrollY;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, this.positions[this.props.location.pathname] || 0);
    }
  }

  render() {
    return this.props.children || null;
  }
}

export default withRouter(PreserveScrollPosition);
