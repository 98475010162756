import firebase from 'firebase/app';

const uploadImage = async (path, file) => {
  if (file instanceof File) {
    const snapshot = await firebase
      .storage()
      .ref(path)
      .put(file);

    return await snapshot.ref.getDownloadURL();
  }
};

export default uploadImage;
