import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 0 0 20%;
`;

const UserImage = styled.div`
  background-color: ${props => props.color};
  border-radius: 50%;
  height: 4em;
  width: 4em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ initials, color }) => {
  return (
    <Wrapper>
      <UserImage color={color}>
        <div>{initials}</div>
      </UserImage>
    </Wrapper>
  );
};
