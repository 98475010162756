import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { addFallbackProfilePhoto } from '../../utils/ui/getTwitterImage';
import makeName from '../../utils/makeName';
import SpeakerImage from '../../components/SpeakerImage';
import SpeakerInfo from '../../components/SpeakerInfo';

const Container = styled(Flexbox)`
  flex-wrap: nowrap;
  align-items: center;
`;

const Speakers = styled(Flexbox)`
  margin: 0 ${props => (props.em ? '1em' : '12px')};
  margin-right: 0;
`;

const Speaker = styled(Flexbox)`
  margin-left: ${props => (props.em ? '-1em' : '-12px')};
`;

const SpeakerImageCustom = styled(SpeakerImage)`
  border: 2px solid ${props => props.theme.card.backgroundColor};
`;

const CompactSpeakers = ({ em, speakers, type, size }) => (
  <Container flexDirection="row" flexWrap="wrap">
    <Speakers em={em} flexDirection="row">
      {addFallbackProfilePhoto(speakers).map(speaker => (
        <Speaker
          em={em}
          key={speaker.get('id')}
          flexDirection="row"
          alignItems="stretch"
        >
          <SpeakerImageCustom
            backgroundColor={speaker.get('color', '#e0e0e0')}
            image={speaker.get('image')}
            type={type}
          />
        </Speaker>
      ))}
    </Speakers>
    <SpeakerInfo
      name={makeName(speakers)}
      headline={speakers.map(speaker => speaker.get('headline'))}
      hideHeadline={speakers.count() > 1}
      size={size}
    />
  </Container>
);

CompactSpeakers.propTypes = {
  em: PropTypes.bool,
  speakers: PropTypes.any.isRequired,
};

export default CompactSpeakers;
