/*
 * SpeakerForm Messages
 *
 * This contains all the text for the SpeakerForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.SpeakerForm.name',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'app.components.SpeakerForm.namePlaceholder',
    defaultMessage: 'e.g., John Doe',
  },
  headline: {
    id: 'app.components.SpeakerForm.headline',
    defaultMessage: 'Headline',
  },
  headlinePlaceholder: {
    id: 'app.components.SpeakerForm.headlinePlaceholder',
    defaultMessage: 'e.g., Awesomization Guru at Some Tech Company',
  },
  description: {
    id: 'app.components.SpeakerForm.description',
    defaultMessage: 'Description',
  },
  tags: {
    id: 'app.components.SpeakerForm.tags',
    defaultMessage: 'Tags',
  },
  image: {
    id: 'app.components.SpeakerForm.image',
    defaultMessage: 'Image',
  },
  profiles: {
    id: 'app.components.SpeakerForm.profiles',
    defaultMessage: 'Web Profiles',
  },
  create: {
    id: 'app.components.SpeakerForm.create',
    defaultMessage: 'Add New Speaker',
  },
  update: {
    id: 'app.components.SpeakerForm.update',
    defaultMessage: 'Update Speaker',
  },
});
