import { call, put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';

export default function* updateSponsorGroup(conferenceHandle, id, name, form) {
  const sponsorGroup = { name };

  yield call(() =>
    firebase
      .database()
      .ref(`conferences/${conferenceHandle}/sponsorGroups/${id}`)
      .update(sponsorGroup)
  );

  yield put(goBack());
}
