import React from 'react';
import Icon from '../../../components/Icon';
import ModuleNavigation from '../../../components/ModuleNavigation';
import mobileConfiguration from '../../../assets/images/mobile-configuration.png';

const MobileAppSectionTitle = ({ hasMobileAppSubscription }) => (
  <span>
    {!hasMobileAppSubscription && (
      <Icon style={{ width: 24, verticalAlign: 'text-top' }}>locked</Icon>
    )}{' '}
    Mobile App Configuration
  </span>
);

const MobileAppCard = ({ hasMobileAppSubscription, root }) => (
  <ModuleNavigation
    to={hasMobileAppSubscription ? `${root}/mobile-app` : `${root}`}
    title={
      <MobileAppSectionTitle
        hasMobileAppSubscription={hasMobileAppSubscription}
      />
    }
    description={
      hasMobileAppSubscription
        ? 'Information and configuration for the Sava Mobile app'
        : 'To enable the mobile app functionality, contact sales@sava.events'
    }
    illustration={mobileConfiguration}
    style={
      hasMobileAppSubscription
        ? {}
        : {
            opacity: 0.85,
            cursor: 'not-allowed',
          }
    }
  />
);

export default MobileAppCard;
