import { link } from '../../utils/ui/interactive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavLink = styled(Link)`
  ${props => link({ color: props.theme.colors.accent })};
  font-weight: 600;
`;

export default NavLink;
