/**
 *
 * SponsorView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

const SponsorView = ({ name }) => <div>{name}</div>;

SponsorView.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SponsorView;
