import { cancel, fork, take, put, race, select } from 'redux-saga/effects';
import firebase from 'firebase/app';
import { Map, fromJS } from 'immutable';

import consume from '../../consume';

import { acceptUserData } from '../../../actions/user';
import { ACCEPT_CONFERENCE, CLEAR_USER } from '../../../constants/user';

import conferenceChannelProvider from '../../../channels/conference';
import userDataChannelProvider from '../../../channels/userData';

import syncConference from '../conferences/sync';
import { replace } from 'react-router-redux';

export default function* loopUserData({ user }) {
  const uid = user.get('uid');
  const userDataChannel = userDataChannelProvider(uid);

  let conferenceId;
  let conferenceMonitorTask;

  try {
    while (true) {
      const { userDataRead, stop } = yield race({
        userDataRead: take(userDataChannel),
        stop: take(CLEAR_USER),
      });

      if (stop) {
        break;
      }

      const userData = userDataRead || fromJS({});

      if (!userDataRead) {
        yield firebase
          .database()
          .ref(`users/${uid}`)
          .update({ fullName: user.get('displayName') });
      }

      const conferences = yield Promise.all(
        Object.keys(userData.get('conferences', Map()).toJS()).map(
          async conferenceId => {
            const conferenceNameSnapshot = await firebase
              .database()
              .ref(`conferences/${conferenceId}/name`)
              .once('value');
            return {
              id: conferenceId,
              name: conferenceNameSnapshot.val(),
            };
          }
        )
      );

      yield put(
        acceptUserData(userData.set('conferences', fromJS(conferences)))
      );

      const currentConference = userData.get('currentConference');

      if (currentConference !== conferenceId) {
        if (conferenceMonitorTask !== undefined) {
          yield cancel(conferenceMonitorTask);
          conferenceMonitorTask = undefined;
        }
        conferenceId = currentConference;

        if (conferenceId) {
          const conferenceChannel = conferenceChannelProvider(conferenceId);

          conferenceMonitorTask = yield fork(
            consume(conferenceChannel, syncConference)
          );

          yield take(ACCEPT_CONFERENCE);

          const pathname = yield select(
            state => state.get('router').location.pathname
          );

          const conferencePathname = `/@${conferenceId}`;

          if (!pathname.startsWith(conferencePathname)) {
            yield put(replace(conferencePathname));
          }
        } else {
          yield put(replace('/'));
        }
      }
    }
  } finally {
    userDataChannel.close();
  }
}
