/*
 *
 * SelectOrCreate
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import Flexbox from 'flexbox-react';
import { Map } from 'immutable';
import styled from 'styled-components';
import 'react-select/dist/react-select.css';

import { link } from '../../utils/ui/interactive';
import FormError from '../../components/FormError';

const Hint = styled(Flexbox)`
  font-size: 13px;
  margin-bottom: 3px;
`;

const Alternative = styled.span`
  opacity: 0.67;
`;

const Switch = styled.span`
  ${link({ color: '#248bd1' })};
`;

export default (CreateInput, ItemView, itemsProvider, clearable = false) => {
  class SelectOrCreate extends React.Component {
    static propTypes = {
      props: PropTypes.object,
      items: PropTypes.any.isRequired,
      input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
      }).isRequired,
      meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any,
      }),
    };

    static handleChange(item) {
      this.props.input.onChange(Map({ id: item.id }));
      if (this.props.postChange) {
        this.props.postChange(item.id);
      }
    }

    static handleCreate(e) {
      e.preventDefault();

      this.props.input.onChange(Map());
    }

    static handleSelectExisting(e) {
      e.preventDefault();

      this.props.input.onChange(Map({ id: null }));
    }

    constructor(props) {
      super(props);

      this.handleChange = SelectOrCreate.handleChange.bind(this);
      this.handleCreate = SelectOrCreate.handleCreate.bind(this);
      this.handleSelectExisting = SelectOrCreate.handleSelectExisting.bind(
        this
      );
    }

    render() {
      const {
        props,
        items,
        input,
        meta: { touched, error },
      } = this.props;
      const { name, value } = input;

      if ((value || Map()).get('id') !== undefined) {
        return (
          <Flexbox flexDirection="column" flexGrow={8} flexShrink={4}>
            {touched && error ? <FormError>{error}</FormError> : null}

            <Hint>
              Select existing
              <Alternative>
                &nbsp;or{' '}
                <Switch onClick={this.handleCreate}>Create new one</Switch>
              </Alternative>
            </Hint>

            <Select
              clearable={clearable}
              options={items}
              optionRenderer={ItemView}
              matchProp="name"
              labelKey="name"
              valueKey="id"
              value={items.find(item => item.id === value.get('id'))}
              valueRenderer={ItemView}
              onChange={this.handleChange}
            />
          </Flexbox>
        );
      }

      return (
        <Flexbox flexDirection="column" flexGrow={8} flexShrink={4}>
          {touched && error && <FormError>{error}</FormError>}

          <Hint>
            Create new one
            <Alternative>
              &nbsp;or{' '}
              <Switch onClick={this.handleSelectExisting}>
                Select existing
              </Switch>
            </Alternative>
          </Hint>

          <CreateInput name={name} {...props} />
        </Flexbox>
      );
    }
  }

  function mapStateToProps(state, props) {
    return {
      items: itemsProvider(state, props),
    };
  }

  return connect(mapStateToProps)(SelectOrCreate);
};
