import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import { textToKey } from 'sava-shared/lib/utils/core';
import isNonEmptyFileList from '../../../utils/isNonEmptyFileList';
import uploadFile from '../file/uploadFile';

export default function* createSponsor(
  conferenceHandle,
  name,
  description,
  image,
  group,
  profiles,
  form
) {
  const sponsor = {
    name,
    profiles,
    group,
  };

  if (description) {
    sponsor.description = description;
  }

  if (group.id) {
    sponsor.group = group.id;
  } else {
    const key = textToKey(group.name);

    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/sponsorGroups`)
      .update({ [key]: group });

    sponsor.group = key;
  }

  const result = yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsors`)
    .push(sponsor);

  const id = result.key;

  if (isNonEmptyFileList(image)) {
    const { url, thumbUrl } = yield uploadFile(
      image[0],
      `/@${conferenceHandle}/sponsors/${id}`,
      { thumb: true }
    );

    if (url && thumbUrl) {
      yield firebase
        .database()
        .ref(`conferences/${conferenceHandle}/sponsors/${id}`)
        .update({ image: url, thumb: thumbUrl });
    }
  }

  yield put(goBack());
}
