import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';

import FormError from '../FormError';

export default (
  itemsProvider,
  {
    transformProvider,
    itemView: ItemView,
    matchProp = 'name',
    labelKey = 'name',
    valueKey = 'id',
    clearable = false,
    searchable = false,
  } = {}
) => {
  class SelectInputControl extends Component {
    handleChange = item => {
      const value = transformProvider
        ? transformProvider(item)
        : item[valueKey];
      this.props.input.onChange(value);
    };

    render() {
      const {
        items,
        input,
        meta: { touched, error },
      } = this.props;

      const { value } = input;

      const selectProps = {
        clearable,
        searchable,
        options: items,
        matchProp,
        labelKey,
        valueKey,
        optionRenderer: ItemView,
        valueRenderer: ItemView,
        value,
        onChange: this.handleChange,
      };

      return (
        <Fragment>
          {touched && error && <FormError>{error}</FormError>}
          <Select {...selectProps} />
        </Fragment>
      );
    }
  }

  SelectInputControl.propTypes = {
    props: PropTypes.object,
    items: PropTypes.any.isRequired,
    input: PropTypes.shape({
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.any,
    }),
  };

  const mapStateToProps = (state, props) => {
    return {
      items: itemsProvider(state, props),
    };
  };

  return connect(mapStateToProps)(SelectInputControl);
};
