/**
 *
 * FileInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';
import DeleteButton from '../DeleteButton';

import FormError from '../FormError';

const Input = styled.input`
  &[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      color: ${props => props.theme.buttons.secondary.color};
      font-weight: inherit;
      text-decoration: none;
      border-radius: 0.25em;
      cursor: pointer;

      i {
        margin-right: 6px;
      }
    }
  }
`;

const FileInputWrap = styled.div`
  width: 100%;
  text-align: center;
`;

const LabelInput = styled.label`
  display: inline-block
  position: relative;
  width: 100%;
  padding: 0.5em;
  
  &,input {
    cursor: pointer;
  }
  
  &:focus {
    outline: 1px dotted #000;
	  outline: -webkit-focus-ring-color auto 5px;
  }
  
  &:focus,&:hover {
    background-color: ${props => props.theme.buttons.secondary.backgroundColor};
  }
`;

const FileInput = ({
  multiple,
  accept,
  input: { value, onChange, onFocus, onBlur, name },
  className,
  style,
  meta: { touched, error },
  required,
  emptyLabel = 'Browse for an image',
  hasValueLabel = 'Change the image',
  removeLabel = 'Remove',
}) => {
  const handleChange = event => {
    event.preventDefault();

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      return onChange(event.target.files);
    }
  };

  const handleRemove = event => {
    event.preventDefault();
    return onChange(null);
  };

  const handleBlur = e => {
    //FIXME: We disabled onBlur event because internal Redux-Form validation overwrites
    //FIXME: passed default string url with empty FileList on `Chose File` click.
    //FIXME: First for all it is bonkers to use one type for default value and another one for fields fresh value,
    //FIXME: value MUST be the of the same kind always.
  };

  return (
    <FileInputWrap>
      <Input
        id={name}
        name={name}
        type="file"
        accept={accept}
        multiple={!!multiple}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={handleBlur}
        className={className}
        style={style}
        required={required}
      />
      <LabelInput htmlFor={name}>
        {/* TODO: Change icon to `cloud_upload` once drag&drop is supported. */}
        <Icon>add_to_photos</Icon>
        {value ? hasValueLabel : emptyLabel}
      </LabelInput>
      {value ? (
        <DeleteButton type={'button'} onClick={handleRemove}>
          {removeLabel}
        </DeleteButton>
      ) : null}
      {touched && error ? <FormError>{error}</FormError> : null}
    </FileInputWrap>
  );
};

FileInput.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.any,
  style: PropTypes.any,
};

export default FileInput;
