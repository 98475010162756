import React, { Component } from 'react';
import firebase from 'firebase/app';

const { Provider, Consumer } = React.createContext();

export const withIsAdministrator = Component => props => (
  <Consumer>
    {isAdministrator => (
      <Component {...props} isAdministrator={isAdministrator} />
    )}
  </Consumer>
);

/**
 * TODO: Rename to Global Administrator
 */
export default class IsAdministratorProvider extends Component {
  state = {
    isAdministrator: undefined,
  };

  async componentDidMount() {
    const { userId } = this.props;

    try {
      const administratorSnapshot = await firebase
        .database()
        .ref(`configuration/administrators/${userId}`)
        .once('value');

      this.setState({ isAdministrator: !!administratorSnapshot.val() });
    } catch (error) {
      this.setState({ isAdministrator: false });
    }
  }

  render() {
    const { isAdministrator } = this.state;

    if (isAdministrator === undefined) {
      return null;
    }

    return <Provider value={isAdministrator}>{this.props.children}</Provider>;
  }
}
