import { put } from 'redux-saga/effects';

import {
  addBreadcrumb as addBreadcrumbAction,
  removeBreadcrumb as removeBreadcrumbAction,
} from '../actions/navigation';

const dispatchAction = action =>
  function* dispatchActionSaga(...args) {
    yield put(action(...args));
  };

export const addBreadcrumb = dispatchAction(addBreadcrumbAction);

export const removeBreadcrumb = dispatchAction(removeBreadcrumbAction);
