import { css } from 'styled-components';

const colors = {
  brandPrimary: '#248bd1',
  light: 'white',
  dark: '#333',
  gray: '#606060',
  accent: '#FF8C00',
  lightOpacity: '#c1c1c1',
  lightGray: '#eff4f7',
  attention: '#E53935',
  green: '#2ECC71',
};

const page = {
  backgroundColor: 'transparent',
  color: '#333',
};

const tag = {
  backgroundColor: '#ebeff1',
  color: '#606060',
};

const navigation = {
  backgroundColor: '#ffffff',
  color: '#606060',
  activeColor: '#ffffff',
  activeBackgroundColor: '#248bd1',
};

export default {
  sizes: {
    base: 16,
  },
  colors,
  tag,
  navigation,
  buttons: {
    primary: {
      backgroundColor: colors.brandPrimary,
      color: colors.light,
    },
    secondary: {
      backgroundColor: colors.lightGray,
      color: page.color,
    },
    link: {
      backgroundColor: 'transparent',
      color: colors.brandPrimary,
    },
  },
  borders: {
    formElement: '1px solid #d0d4d8;',
    default: '1px solid rgba(5, 26, 31, .12)',
  },
  shadows: {
    formElement: 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.06)',
  },
  altitude: level => {
    if (level === 0) {
      return css`
        box-shadow: none;
      `;
    }

    const exp = Math.abs(level);
    const inset = level < 0;

    const y = 2 ** exp;
    const r = 2 ** (exp + 2);

    return css`
      box-shadow: 0 ${y}px ${r}px rgba(0, 0, 0, 0.1) ${inset ? ' inset' : ''};
    `;
  },
  page,
  card: {
    backgroundColor: 'white',
    textColor: '#333',
  },
  break: {
    barColor: '#FDBE2C',
  },
  track: {
    backgroundColor: '#f7f7f7',
  },
  contentWidth: 960,
  baseSpacing: 20,
};
