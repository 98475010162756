/*
 *  EmbedCustomizeForm Messages
 *
 *  This contains all the text for the SessionTypeInput component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  page: {
    id: 'app.components.EmbedCustomizeForm.page',
    defaultMessage: 'Page',
  },
  general: {
    id: 'app.components.EmbedCustomizeForm.general',
    defaultMessage: 'General',
  },
  pageBackgroundColor: {
    id: 'app.components.EmbedCustomizeForm.pageBackgroundColor',
    defaultMessage: 'Background Color',
  },
  pageTextColor: {
    id: 'app.components.EmbedCustomizeForm.pageColor',
    defaultMessage: 'Text Color',
  },
  tags: {
    id: 'app.components.EmbedCustomizeForm.tags',
    defaultMessage: 'Tags',
  },
  tagBackgroundColor: {
    id: 'app.components.EmbedCustomizeForm.tagBackgroundColor',
    defaultMessage: 'Background Color',
  },
  tagColor: {
    id: 'app.components.EmbedCustomizeForm.tagColor',
    defaultMessage: 'Text Color',
  },
  card: {
    id: 'app.components.EmbedCustomizeForm.card',
    defaultMessage: 'Card',
  },
  cardTextColor: {
    id: 'app.components.EmbedCustomizeForm.cardTextColor',
    defaultMessage: 'Text Color',
  },
  cardBackgroundColor: {
    id: 'app.components.EmbedCustomizeForm.cardBackgroundColor',
    defaultMessage: 'Background Color',
  },
  linkColor: {
    id: 'app.components.EmbedCustomizeForm.linkColor',
    defaultMessage: 'Link Color',
  },
  textColor: {
    id: 'app.components.EmbedCustomizeForm.textColor',
    defaultMessage: 'Text Color',
  },
  navigation: {
    id: 'app.components.EmbedCustomizeForm.navigation',
    defaultMessage: 'Navigation',
  },
  navigationBackgroundColor: {
    id: 'app.components.EmbedCustomizeForm.navigationBackgroundColor',
    defaultMessage: 'Background Color',
  },
  navigationColor: {
    id: 'app.components.EmbedCustomizeForm.navigationColor',
    defaultMessage: 'Text Color',
  },
  navigationActiveBackgroundColor: {
    id: 'app.components.EmbedCustomizeForm.navigationActiveBackgroundColor',
    defaultMessage: 'Active Background Color',
  },
  navigationActiveColor: {
    id: 'app.components.EmbedCustomizeForm.navigationActiveColor',
    defaultMessage: 'Active Text Color',
  },
  otherColors: {
    id: 'app.components.EmbedCustomizeForm.otherColors',
    defaultMessage: 'Other Colors',
  },
  save: {
    id: 'app.components.EmbedCustomizeForm.Save',
    defaultMessage: 'Save',
  },
  default: {
    id: 'app.components.EmbedCustomizeForm.durationPlaceholder',
    defaultMessage: 'Duration e.g. 30',
  },
});
