/*
 * ConferenceSettingsThemeCustomization Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Flexbox from 'flexbox-react';
import Helmet from 'react-helmet';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import { Map, fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb';
import ThemeForm from '../../components/ThemeForm';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import { selectCurrentConference } from '../../selectors/user';
import updateTheme from '../../sagas/user/conferences/updateTheme';
import messages from './messages';
import withIsFormDirty from '../../hocs/withIsFormDirty';
import { selectProps } from '../../selectors/common';

const Root = styled(Flexbox)`
  > div {
    margin: 20px;
  }
`;

const Content = styled(Flexbox)`
  width: 100%;
`;

class ConferenceSettingsThemeCustomization extends Component {
  constructor(props) {
    super(props);

    this.state = { form: {} };

    this.handleSubmit = ConferenceSettingsThemeCustomization.handleSubmit.bind(
      this
    );
  }

  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  static async handleSubmit(data) {
    const theme = data.toJS();

    await this.defaultUserRequestExecutor(updateTheme, {
      id: this.props.conferenceHandle,
      colors: theme.colors,
      card: theme.card,
      page: theme.page,
      tag: theme.tag,
      navigation: theme.navigation,
    });
  }

  render() {
    const {
      conferenceHandle,
      theme,
      intl,
      formValues,
      isFormDirty,
    } = this.props;
    const root = `/@${conferenceHandle}/settings`;

    return (
      <Root>
        <Helmet title={intl.formatMessage(messages.title)} />
        <Breadcrumb to={root}>
          {intl.formatMessage(messages.breadcrumbSettings)}
        </Breadcrumb>
        <Breadcrumb to={`${root}/theme-customization`}>
          {intl.formatMessage(messages.themeCustomization)}
        </Breadcrumb>
        <Content>
          <ThemeProvider theme={formValues}>
            <ThemeForm initialValues={theme} onSubmit={this.handleSubmit} />
          </ThemeProvider>
        </Content>
        <Prompt
          when={isFormDirty}
          message={intl.formatMessage(messages.dirtyFormLeavePrompt)}
        />
      </Root>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectProps,
  (conference, props) => {
    return {
      conferenceHandle: conference.get('id'),
      conferenceName: conference.get('name'),
      theme: fromJS(props.theme || {}).merge(conference.get('theme', Map())),
    };
  }
);

const mapFormData = createSelector(
  state => state.get('form'),
  form => ({ formValues: form.getIn(['themeForm', 'values'], Map()).toJS() })
);

export default compose(
  injectIntl,
  withTheme,
  connect(mapStateToProps),
  connect(mapFormData),
  withIsFormDirty('themeForm'),
  withUserRequestExecutor
)(ConferenceSettingsThemeCustomization);
