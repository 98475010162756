import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import { Link } from 'react-router-dom';
import { List } from 'immutable';
import Flexbox from 'flexbox-react';
import Slider from 'rc-slider';
import styled from 'styled-components';

import 'rc-slider/dist/rc-slider.css';

import interactive, { link } from '../../utils/ui/interactive';

import Sponsor from './Sponsor';

import shiftRange from './shiftRange';

const sliderToScale = shiftRange(0, 100, 0.5, 2);

const scaleToSlider = sliderToScale.inverse();

const snap = (to, offset) => value =>
  value > to - offset && value < to + offset ? to : value;

const Card = styled(Flexbox)`
  ${interactive.styles`
    & > .rc-slider,
    & > .rc-slider > .rc-slider-track {
      visibility: hidden !important;
    }
  `} ${interactive.hover`
    & > .rc-slider,
    & > .rc-slider > .rc-slider-track {
      visibility: visible !important;
    }
  `};
`;

const Title = styled(Flexbox)`
  font-size: 24px;
  margin-bottom: 10px;
`;

const GroupLink = styled(Link)`
  ${link({ color: '#333' })};
`;

const NoSponsors = styled(Flexbox)`
  margin: 10px;
  color: #ccc;
`;
NoSponsors.Label = styled(Flexbox)`
  margin: 3px;
  font-size: 24px;
`;

class Group extends React.Component {
  static propTypes = {
    optimalSize: PropTypes.number.isRequired,
    conferenceHandle: PropTypes.any.isRequired,
    group: PropTypes.any.isRequired,

    onScaleChange: PropTypes.func.isRequired,

    onSponsorDrag: PropTypes.func.isRequired,
    onSponsorMove: PropTypes.func.isRequired,
    onSponsorDrop: PropTypes.func.isRequired,
    onSponsorScaleChange: PropTypes.func.isRequired,
    onSponsorOffsetChange: PropTypes.func.isRequired,

    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    isDragged: PropTypes.bool,
  };

  static handleSliderStart() {
    this.setState({
      sliding: true,
    });
  }

  static handleSliderChange(value) {
    const slider = snap(50, 2)(value);

    this.setState({
      scale: sliderToScale(slider),
    });
  }

  static handleSliderStop() {
    this.setState(
      {
        sliding: false,
        justSlid: true,
      },
      () => setTimeout(() => this.setState({ justSlid: false }))
    );

    if (this.props.group.get('scale') !== this.state.scale) {
      this.props.onScaleChange(this.state.scale, this.props.group);
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      sliding: false,
      justSlid: false,
      scale: props.group.get('scale', 1), // [0.5,2]
    };

    this.handleSliderStart = Group.handleSliderStart.bind(this);
    this.handleSliderChange = Group.handleSliderChange.bind(this);
    this.handleSliderStop = Group.handleSliderStop.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const scale = nextProps.group.get('scale', 1);

    if (!this.state.sliding && scale !== this.state.scale) {
      this.setState({
        scale,
      });
    }
  }

  render() {
    const {
      optimalSize,
      conferenceHandle,
      group,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isDragged,
    } = this.props;

    const { sliding, scale } = this.state;

    const styles = {
      margin: 20,
      padding: 20,
      visibility: isDragging ? 'hidden' : undefined,
      background: isDragged ? 'white' : undefined,
      boxShadow: isDragged ? '0 2px 8px rgba(0, 0, 0, 0.1)' : undefined,
      cursor: isDragged ? 'move' : undefined,
    };

    return connectDragSource(
      connectDropTarget(
        <div
          style={{
            margin: 10,
            background: isDragging && !isDragged ? '#e7e7e7' : undefined,
          }}
        >
          <Card
            flexDirection="column"
            alignContent="stretch"
            alignItems="center"
            style={styles}
          >
            <Title alignSelf="center">
              <GroupLink
                to={`/@${conferenceHandle}/sponsors/groups/${group.get('id')}`}
              >
                {group.get('name')}
              </GroupLink>
            </Title>
            <Slider
              className={sliding ? 'rc-slider--sliding' : null}
              tipTransitionName="rc-slider-tooltip-zoom-down"
              value={scaleToSlider(scale)}
              onBeforeChange={this.handleSliderStart}
              onChange={this.handleSliderChange}
              onAfterChange={this.handleSliderStop}
              style={{ width: 220 }}
            />
            <Flexbox flexDirection="column">
              <Flexbox
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
              >
                {group.get('sponsors', List()).count() === 0 && (
                  <NoSponsors flexDirection="column" alignItems="center">
                    <NoSponsors.Label>
                      No Sponsors in This Group
                    </NoSponsors.Label>
                  </NoSponsors>
                )}

                {group
                  .get('sponsors', List())
                  .map((sponsor, i) => (
                    <Sponsor
                      key={sponsor.get('id')}
                      index={i}
                      onDrag={this.props.onSponsorDrag}
                      onMove={this.props.onSponsorMove}
                      onDrop={this.props.onSponsorDrop}
                      onScaleChange={this.props.onSponsorScaleChange}
                      onOffsetChange={this.props.onSponsorOffsetChange}
                      optimalSize={optimalSize}
                      conferenceHandle={conferenceHandle}
                      sponsor={sponsor}
                      groupScale={scale}
                    />
                  ))}
              </Flexbox>
            </Flexbox>
          </Card>
        </div>
      )
    );
  }
}

const ItemTypes = {
  SPONSOR_GROUP: 'SPONSOR_GROUP',
};

const groupSource = {
  beginDrag(props) {
    props.onDrag();

    return {
      index: props.index,
      optimalSize: props.optimalSize,
      conferenceHandle: props.conferenceHandle,
      group: props.group,
    };
  },
};

const groupTarget = {
  drop(props) {
    props.onDrop();
  },
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    /* eslint-disable no-param-reassign */
    monitor.getItem().index = hoverIndex;
    /* eslint-enable no-param-reassign */
  },
};

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function collectDropTarget(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default DragSource(
  ItemTypes.SPONSOR_GROUP,
  groupSource,
  collectDragSource
)(DropTarget(ItemTypes.SPONSOR_GROUP, groupTarget, collectDropTarget)(Group));
