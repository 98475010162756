/*
 * ConferenceSettingsPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Flexbox from 'flexbox-react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Breadcrumb from '../../components/Breadcrumb';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
} from '../../selectors/user';
import { createSelector } from 'reselect';
import MobileAppCard from './components/MobileAppCard';
import ModuleNavigation from '../../components/ModuleNavigation';
import illoEventDetails from '../../assets/images/illo-event-details.svg';
import illoConfigureIntegrations from '../../assets/images/illo-configure-integrations.svg';
import illoTeam from '../../assets/images/illo-team.svg';
import illoEmbedCustomization from '../../assets/images/illo-embed-customization.svg';

const Container = styled(Flexbox)`
  padding: 1.5em;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const ConferenceSettingsPageNew = props => {
  const root = `/@${props.conference.get('id')}/settings`;
  const hasMobileAppSubscription = props.conference.get(
    'hasMobileAppSubscription'
  );

  return (
    <div>
      <Helmet
        title={`${props.conference.get('name')} Bot Settings • Sava Events`}
      />
      <Breadcrumb to={root}>Settings</Breadcrumb>
      <Container>
        <ModuleNavigation
          to={`${root}/event`}
          title="Event Details"
          description="Set up everything related to your event"
          illustration={illoEventDetails}
        />
        <ModuleNavigation
          to={`${root}/team`}
          title="Team"
          description="Set up everything related to your team"
          illustration={illoTeam}
        />
        <ModuleNavigation
          to={`${root}/integrations`}
          title="Configure Integrations"
          description="Manage integrations with different messaging platforms"
          illustration={illoConfigureIntegrations}
        />
        <ModuleNavigation
          to={`${root}/theme-customization`}
          title="Theme Customization"
          description="Customize the theme of the embed pages"
          illustration={illoEmbedCustomization}
        />
        <MobileAppCard
          root={root}
          hasMobileAppSubscription={hasMobileAppSubscription}
        />
      </Container>
    </div>
  );
};

ConferenceSettingsPageNew.propTypes = {
  conference: PropTypes.object.isRequired,
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  (conferenceHandle, conference) => ({
    conferenceHandle,
    conference,
  })
);

export default connect(mapStateToProps)(ConferenceSettingsPageNew);
