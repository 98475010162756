/*
 *  BreakForm Messages
 *
 *  This contains all the text for the BreakForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.BreakForm.title',
    defaultMessage: 'Title',
  },
  titlePlaceholder: {
    id: 'app.components.BreakForm.titlePlaceholder',
    defaultMessage: 'e.g., Afternoon Coffee Break',
  },
  description: {
    id: 'app.components.BreakForm.description',
    defaultMessage: 'Description',
  },
  day: {
    id: 'app.components.BreakFOrm.day',
    defaultMessage: 'Day',
  },
  time: {
    id: 'app.components.BreakForm.time',
    defaultMessage: 'Time',
  },
  create: {
    id: 'app.components.BreakForm.create',
    defaultMessage: 'Add New Break',
  },
  update: {
    id: 'app.components.BreakForm.update',
    defaultMessage: 'Update Break',
  },
  sideEvent: {
    id: 'app.components.BreakForm.sideEvent',
    defaultMessage: 'Side',
  },
  blockEvent: {
    id: 'app.components.BreakForm.blockEvent',
    defaultMessage: 'Block',
  },
  descriptionWanted: {
    id: 'app.components.BreakForm.descriptionWanted',
    defaultMessage: 'Want a description?',
  },
});
