import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.BroadcastPage.title',
    defaultMessage: 'Broadcasts • Sava Events',
  },
  newBroadcastTitle: {
    id: 'app.containers.BroadcastPage.newBroadcastTitle',
    defaultMessage: 'New broadcast',
  },
  saveBroadcastError: {
    id: 'app.containers.BroadcastPage.saveBroadcastError',
    defaultMessage: 'Unable to send broadcast. Please try again.',
  },
  scheduledBroadcasts: {
    id: 'app.containers.BroadcastPage.scheduledBroadcasts',
    defaultMessage: 'Scheduled',
  },
  pastBroadcasts: {
    id: 'app.containers.BroadcastPage.pastBroadcasts',
    defaultMessage: 'Past',
  },
  removeBroadcastMessage: {
    id: 'app.containers.BroadcastPage.removeBroadcastMessage',
    defaultMessage: 'Removing a scheduled broadcast is permanent. Proceed?',
  },
  removeScheduledBroadcastError: {
    id: 'app.containers.BroadcastPage.removeScheduledBroadcastError',
    defaultMessage:
      'Removing a scheduled broadcast failed. If error persists, contact our 🌶️ support.',
  },
  totalSubscribers: {
    id: 'app.containers.BroadcastPage.totalSubscribers',
    defaultMessage: 'Subscribers: ',
  },
  totalSubscribersError: {
    id: 'app.containers.BroadcastPage.totalSubscribersError',
    defaultMessage: 'Failed to calculate',
  },
  totalSubscribersLoading: {
    id: 'app.containers.BroadcastPage.totalSubscribersLoading',
    defaultMessage: '⚙️ Calculating...',
  },
  scheduledBroadcastsEmptyStateTitle: {
    id: 'app.containers.BroadcastPage.scheduledBroadcastsEmptyStateTitle',
    defaultMessage: 'No Scheduled Broadcasts Yet',
  },
  scheduledBroadcastsEmptyStateHint: {
    id: 'app.containers.BroadcastPage.scheduledBroadcastsEmptyStateHint',
    defaultMessage: 'Go ahead and create one. It will appear in this list.',
  },
  pastBroadcastsEmptyStateTitle: {
    id: 'app.containers.BroadcastPage.pastBroadcastsEmptyStateTitle',
    defaultMessage: 'No Broadcasts Yet',
  },
  pastBroadcastsEmptyStateHint: {
    id: 'app.containers.BroadcastPage.pastBroadcastsEmptyStateHint',
    defaultMessage: 'All sent broadcasts will be shown here.',
  },
  showMore: {
    id: 'app.containers.BroadcastPage.showMore',
    defaultMessage: 'Show More…',
  },
});
