/*
 * SponsorGroupInput Messages
 *
 * This contains all the text for the SponsorGroupInput component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.SponsorGroupInput.name',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'app.components.SponsorGroupInput.namePlaceholder',
    defaultMessage: 'e.g., John Doe',
  },
  headline: {
    id: 'app.components.SponsorGroupInput.headline',
    defaultMessage: 'Headline',
  },
  headlinePlaceholder: {
    id: 'app.components.SponsorGroupInput.headlinePlaceholder',
    defaultMessage: 'e.g., Awesomization Guru at Some Tech Company',
  },
  image: {
    id: 'app.components.SponsorGroupInput.image',
    defaultMessage: 'Image',
  },
});
