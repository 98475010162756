import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setPropTypes } from 'recompose';

const Form = styled.form`
  fieldset {
    padding: 0;
    border: none;
    width: 100%;
    margin: 0;
    margin-bottom: 12px;
    text-align: left;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  input[type='text'],
  input[type='date'],
  input[type='time'],
  input[type='number'],
  input[type='name'],
  input[type='email'],
  input[type='password'],
  input[type='url'],
  input[type='checkbox'] {
    font-family: inherit;
    display: block;
    background: white;
    border-radius: 3px;
    box-shadow: ${props => props.theme.shadows.formElement};
    height: 42px;
    outline: none;
    border: ${props => props.theme.borders.formElement};
    width: 100%;
    padding: 0px 15px;

    ::-webkit-input-placeholder,
    -moz-placeholder {
      font-size: 14px;
    }
  }

  textarea {
    font-family: inherit;
    font-size: inherit;
    display: block;
    background: white;
    border-radius: 3px;
    box-shadow: ${props => props.theme.shadows.formElement};
    height: 12em;
    border: ${props => props.theme.borders.formElement};
    outline: none;
    width: 100%;
    padding: 10px 15px;
    resize: none;

    ::-webkit-input-placeholder,
    -moz-placeholder {
      font-size: 14px;
    }
  }
`;

export default setPropTypes({
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  style: PropTypes.any,
})(Form);
