/**
 *
 * Image
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { Map } from 'immutable';
import styled from 'styled-components';

import Spinner from '../../components/Spinner';
import ThinLabel from '../ThinLabel';
import noImage from '../../assets/images/no-image.svg';

import aspectFill from './aspectFill';
import aspectFit from './aspectFit';

const widthFit = width => aspectRatio => ({
  width,
  height: width / aspectRatio,
});

const NoImage = styled.img`
  width: 4em;
`;

function loadImageAndSetAspectRatio(src) {
  const img = new window.Image();

  img.onload = () => {
    const { width, height } = img;
    this.setState({ aspectRatio: width / height });
  };

  img.src = src;
}

export default class Image extends React.Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    src: PropTypes.string,
    alt: PropTypes.string.isRequired,
    cover: PropTypes.bool,
    contain: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.any,
  };

  componentWillMount() {
    loadImageAndSetAspectRatio.call(this, this.props.src);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ aspectRatio: null });
      loadImageAndSetAspectRatio.call(this, nextProps.src);
    }
  }

  render() {
    const {
      width,
      height,
      src,
      alt,
      cover,
      contain,
      style,
      children,
    } = this.props;
    const { aspectRatio } = this.state || {};

    const size = ((cover && aspectFill) || (contain && aspectFit) || widthFit)(
      width,
      height
    )(aspectRatio);

    const image = () => (
      <img
        src={src}
        alt={alt}
        style={Map(style)
          .set('width', size.width)
          .set('height', size.height)
          .toJS()}
      />
    );

    if (!src) {
      return (
        <Fragment>
          <NoImage src={noImage} alt="No Image" />
          <ThinLabel textAlign={'center'}>
            Image source is not valid. Please upload your image again.
          </ThinLabel>
        </Fragment>
      );
    }

    if (!aspectRatio) {
      return <Spinner size={4} barThickness={2} />;
    }

    return (
      <Flexbox
        alignItems="center"
        style={{
          position: 'relative',
          justifyContent: 'center',
          width,
          height,
        }}
      >
        {image()}
        {children}
      </Flexbox>
    );
  }
}
