import styled from 'styled-components';

const SessionTypeBar = styled.div`
  background-color: ${props => props.backgroundColor};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 40;
`;

export default SessionTypeBar;
