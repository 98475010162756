import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Flexbox from 'flexbox-react';
import Helmet from 'react-helmet';
import { List, Map } from 'immutable';
import styled from 'styled-components';

import { textToKey } from 'sava-shared/lib/utils/core';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import createConference from '../../sagas/user/conferences/create';

import Breadcrumb from '../../components/Breadcrumb';
import ConferenceForm from '../../components/ConferenceForm';

const Root = styled(Flexbox)`
  > div {
    margin: 20px;
    padding: 20px;

    background: white;
    ${props => props.theme.altitude(1)};
    min-width: 35em;
  }
`;

function nameKey(item) {
  return textToKey(item.get('name'));
}

function listToMap(key, itemToKey) {
  return l => {
    const list = l || List();
    return list.reduce(
      (acc, item) =>
        acc.set(item.get(key) || itemToKey(item), item.delete(key)),
      Map()
    );
  };
}

class CreateConferencePage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  // We are not notifying through userRequestExecutor,
  // but we are doing that inside createConference method
  // because we want to check if there is a conference with
  // that name already...
  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    shouldNotifyToastr: false,
  });

  handleSubmit = async data => {
    const conference = data.toJS();
    const { startDate, endDate } = conference.dates;
    const dates = `${startDate.format('YYYY/M/D')},${endDate.diff(
      startDate,
      'days'
    ) + 1}`;
    const tracks = listToMap('id', nameKey)(data.get('tracks')).toJS();
    const sessionTypes = listToMap('id', nameKey)(
      data.get('sessionTypes')
    ).toJS();

    await this.defaultUserRequestExecutor(createConference, {
      id: textToKey(conference.id),
      name: conference.name,
      dates,
      tracks,
      sessionTypes,
      timezone: conference.timezone,
      embedLayout: conference.embedLayout,
      mobileEmbedLayout: conference.mobileEmbedLayout,
    });
  };

  goBack = () => this.props.history.goBack();

  render() {
    return (
      <Root
        flexDirection="column"
        justifyContent="flex-start"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Helmet title="Create an Event • Sava Events" />
        <Breadcrumb to="/new">Create an Event</Breadcrumb>
        <div>
          <ConferenceForm
            onSubmit={this.handleSubmit}
            isCreateForm
            initialValues={Map({
              embedLayout: 'grid',
              mobileEmbedLayout: 'flat',
            })}
          />
        </div>
      </Root>
    );
  }
}

export default compose(
  withRouter,
  connect(),
  withUserRequestExecutor
)(CreateConferencePage);
