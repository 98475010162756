/**
 *
 *  ProfileInput
 *
 */

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import styled from 'styled-components';
import messages from './messages';
import IconPicker from '../IconPicker';

const Root = styled.div`
  position: relative;

  > div {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  > input[type='text'] {
    padding-left: 42px;
  }

  > p {
    padding: 0px 14px;
    font-size: 0.8em;
    line-height: 0.8em;
    margin: 8px 0px;
  }
`;

const getPlaceholderText = icon => {
  switch (icon) {
    case 'twitter':
      return 'e.g. @SavaBot';
    case 'github':
      return 'e.g. savabot';
    case 'instagram':
      return 'e.g. @savabot';
    default:
      return 'e.g., https://example.com';
  }
};

class ProfileInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    profiles: ImmutablePropTypes.list,
    image: PropTypes.any,
  };

  render() {
    const { name, profiles, image } = this.props;
    const iconFieldName = `${name ? `${name}.` : ''}icon`;
    const fieledIndex = name
      ? Number(name.replace('profiles[', '').replace(']', ''))
      : undefined;
    const iconFieldValue = profiles
      ? profiles.getIn([fieledIndex, 'icon'])
      : '';

    return (
      <Root>
        <div>
          <Field name={iconFieldName} component={IconPicker} required />
        </div>
        <Field
          name={`${name ? `${name}.` : ''}link`}
          component="input"
          type="text"
          placeholder={getPlaceholderText(iconFieldValue)}
          required
        />
        {!image && iconFieldValue === 'twitter' ? (
          <FormattedMessage {...messages.fallbackInfo}>
            {messages => <p>{messages} </p>}
          </FormattedMessage>
        ) : null}
      </Root>
    );
  }
}

const selector = formValueSelector('speaker');
export default connect(state => {
  const profiles = selector(state, 'profiles');
  const image = selector(state, 'image');
  return {
    profiles,
    image,
  };
})(injectIntl(ProfileInput));
