import firebase from 'firebase/app';
import saveFacebookGreetingMessage from '../integrations/facebook/saveFacebookGreetingMessage';
import hasResource from '../hasResource';

export default function* updateChatBuilderSettings({ id, name, message } = {}) {
  yield firebase
    .database()
    .ref(`conferences/${id}/chatbotSettings/customMessages`)
    .update({ [name]: message ? message : '' });

  const hasFacebookIntegration = yield hasResource(
    `conferences/${id}/facebook`
  );

  if (hasFacebookIntegration && name === 'greetingMessage') {
    yield saveFacebookGreetingMessage(id, message);
  }
}
