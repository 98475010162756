import firebase from 'firebase/app';

import getDateAndTimeInMilliseconds from '../../../utils/getDateAndTimeInMilliseconds';
import confirm from '../confirm';
import generateMessage from '../../../utils/message/generate';
import replaceResourceWithUrl from '../../../utils/file/replaceResourceWithUrl';

const createMessage = async (conferenceHandle, broadcast) => {
  const messageWithResourceUrls = await replaceResourceWithUrl(
    broadcast,
    `/@${conferenceHandle}/messages`
  );
  return generateMessage(messageWithResourceUrls);
};

const getScheduleTime = (broadcast = {}) => {
  const { schedule = {} } = broadcast;
  const { date, time } = schedule;
  return date && time ? getDateAndTimeInMilliseconds(date, time) : false;
};

const createBroadcastData = async (conferenceHandle, broadcast, now) => {
  const timestamp = now ? now : new Date().getTime();
  const formattedBroadcast = {
    ...broadcast,
    schedule: getScheduleTime(broadcast),
  };
  const message = await createMessage(conferenceHandle, formattedBroadcast);

  return {
    messages: [message],
    timestamp,
    scheduleTime: formattedBroadcast.schedule,
  };
};

export function* createBroadcast(conferenceHandle, broadcast) {
  const now = new Date().getTime();
  const data = yield createBroadcastData(conferenceHandle, broadcast, now);

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/broadcasts`)
    .child(`${now}`)
    .set(data);
}

export function* updateBroadcast(conferenceHandle, broadcastId, broadcast) {
  const data = yield createBroadcastData(conferenceHandle, broadcast);

  yield firebase.functions().httpsCallable('updateBroadcast')({
    conferenceHandle,
    broadcastId,
    broadcast: data,
  });
}

export function* removeBroadcast(
  conferenceHandle,
  broadcastId,
  confirmMessage
) {
  if (yield confirm(confirmMessage)) {
    yield firebase.functions().httpsCallable('removeBroadcast')({
      conferenceHandle,
      broadcastId,
    });
  }
}
