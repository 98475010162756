import React from 'react';
import Helmet from 'react-helmet';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';

import Auth from '../../../containers/Auth';

const FlexWrapper = styled(Flexbox)`
  background-color: #f5f5ff;
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
  background-size: 18%;
  box-shadow: 0 9px 17px 0 rgba(21, 11, 57, 0.15);
  padding: 2.125em 3.75em;
  text-align: center;
`;

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
`;

export default ({ location }) => (
  <Auth location={location}>
    <Helmet title="Forgot Password Success • Sava Events" />
    <FlexWrapper flexDirection="column">
      <Title>Forgot Password</Title>
      <div>
        <p>
          You have successfully requested a password reset link. Check your
          inbox.
        </p>
      </div>
    </FlexWrapper>
  </Auth>
);
