import firebase from 'firebase/app';
import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';

import generateMessage from '../../../utils/message/generate';
import replaceResourceWithUrl from '../../../utils/file/replaceResourceWithUrl';

const createIntent = async (conferenceHandle, intent) => {
  const { response } = intent;
  const messageWithResourceUrls = await replaceResourceWithUrl(
    response,
    `/@${conferenceHandle}/messages`
  );
  const message = generateMessage(messageWithResourceUrls);
  return { ...intent, response: message };
};

export function* createChatbotIntent({ conferenceHandle, intent }) {
  const data = yield createIntent(conferenceHandle, intent);

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/chatbotSettings/customIntents`)
    .push(data);
  yield put(goBack());
}

export function* updateChatbotIntent({ conferenceHandle, id, intent }) {
  const data = yield createIntent(conferenceHandle, intent);

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/chatbotSettings/customIntents/${id}`)
    .update(data);
  yield put(goBack());
}

export function* deleteChatbotIntent({ conferenceHandle, id }) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/chatbotSettings/customIntents/${id}`)
    .remove();
  yield put(goBack());
}
