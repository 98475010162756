const leadZeroFix = (original, parsed) =>
  `${original[0] === '0' ? '0' : ''}${parsed}`;

export default time => {
  const am = time.match(/(^\d+):(\d+) AM$/);

  if (am) {
    return `${leadZeroFix(am[1], parseInt(am[1], 10))}:${leadZeroFix(
      am[2],
      parseInt(am[2], 10)
    )}`;
  }

  const pm = time.match(/^(\d+):(\d+) PM$/);

  if (pm) {
    const hours = (parseInt(pm[1], 10) + 12) % 24;

    return `${hours === 0 ? '00' : hours}:${leadZeroFix(
      pm[2],
      parseInt(pm[2], 10)
    )}`;
  }

  const twentyfour = time.trim().match(/^(\d+):(\d+)$/);

  if (twentyfour) {
    return `${leadZeroFix(
      twentyfour[1],
      parseInt(twentyfour[1], 10)
    )}:${leadZeroFix(twentyfour[2], parseInt(twentyfour[2], 10))}`;
  }

  throw new Error(`Unexpected time passed… '${time}'`);
};
