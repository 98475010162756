import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NoTickets from './NoTickets';
import { NavLink } from 'react-router-dom';
import { link } from '../../../utils/ui/interactive';
import { ActionToken } from '../../../components/IntentList/components/tokens';
import { ScrollableBox } from '../common';

const TicketLink = styled.span`
  ${link({ color: '#122E40', darken: true })} font-size: 1em;

  transition: 160ms all;
  opacity: 0.8;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
`;

const Section = styled.div``;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 25em;
  -webkit-backface-visibility: hidden;
`;

const SectionHeadline = styled.h2`
  width: 100%;
  text-align: left;
  border-bottom: 1px solid ${props => props.theme.colors.lightOpacity};
  line-height: 0.3em;
  margin: 10px 0 20px;
  color: ${props => props.theme.colors.lightOpacity};

  span {
    background: #fff;
    padding: 0 20px 0px 0px;
    font-size: 12px;
    font-weight: 100;
  }
`;

const TicketItemBox = styled(NavLink)`
  display: block;
  flex: 1;
  padding: 1em;
  border-bottom: 1px solid rgba(241, 240, 240, 0.6);
  text-decoration: none;

  :hover {
    background-color: #e6f7ff;

    span {
      color: ${props => props.theme.colors.brandPrimary};
      opacity: 1;
    }
  }

  &.active {
    background-color: #e6f7ff;

    span {
      color: ${props => props.theme.colors.brandPrimary} !important;
    }
  }
`;

const TicketItemStatusLabel = styled(ActionToken)`
  font-size: 0.8em;
  margin-left: 6px;
  margin-right 6px;
  color: ${props => props.theme.colors.accent} !important;
  font-weight: 700;
`;

const TicketItem = ({ conferenceHandle, ticket }) => (
  <TicketItemBox to={`/@${conferenceHandle}/supportinbox/${ticket.id}`}>
    <TicketLink>
      {ticket.profile.getFullName()}
      {ticket.status === 'new' && (
        <TicketItemStatusLabel>New!</TicketItemStatusLabel>
      )}
    </TicketLink>
  </TicketItemBox>
);

TicketItem.propTypes = {
  conference: PropTypes.string,
  ticket: PropTypes.object,
};

const typeToSectionLabel = {
  open: 'Requests',
  closed: 'Done',
};

const TicketBox = ({ type, tickets = [], conferenceHandle }) => (
  <Box>
    {tickets && (
      <div>
        <Section>
          <SectionHeadline>
            <span>{typeToSectionLabel[type]}</span>
          </SectionHeadline>
          <SectionContent>
            <ScrollableBox>
              {tickets.length === 0 ? (
                <NoTickets type={type} />
              ) : (
                tickets.map(ticket => (
                  <TicketItem
                    conferenceHandle={conferenceHandle}
                    ticket={ticket}
                    key={ticket.id}
                  />
                ))
              )}
            </ScrollableBox>
          </SectionContent>
        </Section>
      </div>
    )}
  </Box>
);

TicketBox.propTypes = {
  type: PropTypes.oneOf(['open', 'closed']),
  tickets: PropTypes.array,
  conference: PropTypes.string,
};

const TicketBoxHOC = type => props => <TicketBox type={type} {...props} />;

TicketBoxHOC.propTypes = {
  type: PropTypes.oneOf(['open', 'closed']),
};

export default TicketBoxHOC;
