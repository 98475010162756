import styled from 'styled-components';
import { compose } from 'recompose';
import { withAlignCenter, withMargin } from '../../utils/ui/decorators';

const FormError = styled.div`
  color: ${props => props.theme.colors.attention};
`;

export const FormErrorWithMargins = styled(FormError)`
  margin-top: 2em;
  margin-bottom: 0.5em;
`;

export const CenteredFormErrorWithSmallBottomMargin = compose(
  withAlignCenter,
  withMargin('0px 0px 1em 0')
)(FormError);

export default FormError;
