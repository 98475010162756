import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes } from 'recompose';

const ActionLink = ({
  validate,
  dispatch,
  action,
  param,
  className,
  style,
  children,
}) => {
  const handleClick = event => {
    event.preventDefault();

    if (validate && !validate()) {
      return;
    }

    dispatch(action(param));
  };

  return (
    <button onClick={handleClick} className={className} style={style}>
      {children}
    </button>
  );
};

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  param: PropTypes.any,
  validate: PropTypes.func,
  className: PropTypes.any,
  style: PropTypes.any,
  children: PropTypes.node,
};

export default compose(
  connect(),
  setPropTypes(propTypes)
)(ActionLink);
