import PropTypes from 'prop-types';

export const MessengerTemplatePropTypes = {
  imageUrl: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['web_url']),
      label: PropTypes.string,
      action: PropTypes.string,
    })
  ),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export const MessengerTemplateListPropTypes = {
  items: PropTypes.arrayOf(MessengerTemplatePropTypes),
};

export const ChatBubblePropTypes = {
  isUser: PropTypes.bool,
};

export const MessengerConversationPropTypes = {
  width: PropTypes.number,
  conversation: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['user', 'platform']).isRequired,
      messages: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['text', 'video', 'image']).isRequired,
          content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
          dateTime: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
};

export const MessengerPagePreviewPropTypes = {
  width: PropTypes.number,
  message: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
};
