import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import EmbedProvider from './providers/EmbedProvider';
import ScoreboardPage from './containers/ScoreboardPage';
import OverlayPage from './containers/OverlayPage';
import TrackOverlayPage from './containers/TrackOverlayPage';
import VideoMixOverlayPage from './containers/VideoMixOverlayPage';
import EmbedWrapperPage from './containers/embed/EmbedWrapperPage';
import AppWrapper from './containers/AppWrapper';
import { QuestionsPage } from './containers/QuestionsPage';
import TrackSessionQuestions from './containers/TrackSessionQuestions';
import ErrorPage from './containers/ErrorPage';

const EmbedWithoutAuthProvider = EmbedProvider(false);

const ConcreteScoreboardPage = EmbedWithoutAuthProvider(ScoreboardPage);
const ConcreteTrackOverlayPage = EmbedWithoutAuthProvider(TrackOverlayPage);
const ConcreteOverlayPage = EmbedWithoutAuthProvider(OverlayPage);
const ConcreteTrackSessionQuestions = EmbedWithoutAuthProvider(
  TrackSessionQuestions
);
const ConcreteEmbedWrapperPage = EmbedWithoutAuthProvider(EmbedWrapperPage);
const ConcreteQuestionsPage = EmbedWithoutAuthProvider(QuestionsPage);
const ConcreteVideoMixPage = EmbedWithoutAuthProvider(VideoMixOverlayPage);

const Website = () => (
  <Switch>
    <Route
      path="/@:conferenceHandle/embed/scoreboard"
      component={ConcreteScoreboardPage}
      exact
    />
    <Route
      path="/@:conferenceHandle/embed/overlay/:track"
      component={ConcreteTrackOverlayPage}
      exact
    />
    <Route
      path="/@:conferenceHandle/embed/overlay"
      component={ConcreteOverlayPage}
      exact
    />
    <Route
      path="/@:conferenceHandle/embed/overlay/:track/questions"
      component={ConcreteTrackSessionQuestions}
    />
    <Route
      path="/@:conferenceHandle/embed/videomix/:track"
      component={ConcreteVideoMixPage}
      exact
    />
    <Route
      path="/@:conferenceHandle/embed"
      component={ConcreteEmbedWrapperPage}
    />
    <Route path="/@:conferenceHandle/q" component={ConcreteQuestionsPage} />
    <Route path={'/error'} component={ErrorPage} exact />
    <Route component={AppWrapper} />
  </Switch>
);

export default compose(withRouter)(Website);
