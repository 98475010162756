import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import queryString from 'qs';

import { findEarliestStart, fillWithEmptySlots } from '../../utils/schedule';

import interactive, { button } from '../../utils/ui/interactive';
import SavaPropTypes from '../../utils/SavaPropTypes';

import Session from '../Session';
import Break from '../Break';
import TooltipButton from '../TooltipButton/index';

import sessionImage from '../TooltipCards/assets/new.svg';
import event from '../TooltipCards/assets/Event.svg';
import code from '../TooltipCards/assets/code.svg';
import Icon from '../Icon';

export const HOUR_HEIGHT = 300;
export const MINUTE_HEIGHT = HOUR_HEIGHT / 60;

const using = value => f => f(value);

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-right: 20px;
  margin-left: 20px;
`;

const SessionBlock = styled.div`
  background-color: ${props => props.theme.track.backgroundColor};
  margin: 0;
  padding: 0;
  line-height: 1;
  overflow: hidden;
  text-decoration: none;
  color: white;
`;

const BreakTrack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 41px;
  margin-right: 2px;
  width: 100px;
  direction: rtl;

  & > * {
    direction: ltr;
  }
`;

const BreakFiller = styled.div`
  width: 100px;
  height: ${props => `${props.height}px`};

  margin: 0;
  padding: 0;
  line-height: 1;
  overflow: hidden;
  text-decoration: none;
  color: white;
`;

const TrackTitle = styled(Flexbox)`
  margin-bottom: 6px;
  padding-top: 6px;
`;
TrackTitle.Name = styled(Flexbox)``;

TrackTitle.Location = styled(Flexbox)`
  font-size: 0.875em;
  opacity: 0.65;
`;

const AddSessionLink = styled(Link)`
  display: inline-block;

  margin-top: 20px;
  padding: 12px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  ${interactive.styles`
    color: #333;
  `} ${props => button({ backgroundColor: props.theme.colors.lightGray })};
`;

const Top = styled.div`
  background-color: white;
`;

const TrackColumn = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 1em;
`;

export default (Track = ({ children }) => children) =>
  class Schedule extends React.Component {
    static propTypes = {
      conferenceHandle: PropTypes.string.isRequired,
      linkProvider: PropTypes.func.isRequired,
      day: PropTypes.number.isRequired,
      tracks: ImmutablePropTypes.listOf(SavaPropTypes.session).isRequired,
      breaks: PropTypes.any,
      onDrag: PropTypes.func,
      onMove: PropTypes.func,
      onDrop: PropTypes.func,
    };

    render() {
      const {
        conferenceHandle,
        linkProvider,
        day,
        tracks,
        breaks,
      } = this.props;

      const allSessionsAndBreaks = tracks
        .flatMap(track => track.get('sessions'))
        .concat(breaks ? breaks : []); // eslint-disable-line

      const earliestStart = findEarliestStart(allSessionsAndBreaks);

      const transformedTracks = tracks.map(track =>
        track.update('sessions', sessions =>
          fillWithEmptySlots(
            sessions.update('speakers', Map(), speakers =>
              speakers.toOrderedMap().sortBy(speaker => speaker.get('order'))
            ),
            earliestStart
          )
        )
      );

      return (
        <Container>
          <TooltipButton
            title="Schedule page"
            body="Quickly add new sessions or edit old ones."
            firstImage={sessionImage}
            body2="Interact and edit speakers with ease."
            secondImage={event}
            body3="Easily share and embed your schedule to any website, anywhere."
            thirdImage={code}
          />
          {breaks && (
            <BreakTrack>
              {using(fillWithEmptySlots(breaks, earliestStart))(
                filledBreaks => {
                  if (filledBreaks) {
                    return filledBreaks.map((breakObj, i) => {
                      const isFiller = breakObj.get('isFiller');

                      const height = MINUTE_HEIGHT * breakObj.get('blockSize');

                      if (isFiller) {
                        return <BreakFiller key={i} height={height} />;
                      }

                      return (
                        <Break
                          key={i}
                          breakObj={breakObj}
                          numOfTracks={tracks.size}
                          height={height}
                        />
                      );
                    });
                  }

                  return <div />;
                }
              )}
            </BreakTrack>
          )}

          {transformedTracks.map((track, i) => (
            <Track
              key={track.get('id')}
              index={i}
              onDrag={this.props.onDrag}
              onMove={this.props.onMove}
              onDrop={this.props.onDrop}
            >
              <Flexbox flexDirection="column" style={{ width: 300 }}>
                <Sticky top={64} innerZ={101}>
                  <Top id="top">
                    <TrackTitle flexDirection="column">
                      <TrackTitle.Name>{track.get('name')}</TrackTitle.Name>
                      <TrackTitle.Location>
                        {track.get('location')}
                      </TrackTitle.Location>
                    </TrackTitle>
                    {using(
                      linkProvider('newSession', {
                        conferenceHandle,
                        day,
                      })
                    )(pathname => {
                      const query = {
                        track: track.get('id'),
                        day,
                      };
                      const stringifiedQuery = queryString.stringify(query);

                      return (
                        pathname && (
                          <AddSessionLink
                            to={`${pathname}?${stringifiedQuery}`}
                          >
                            <Icon>add</Icon> Add new session
                          </AddSessionLink>
                        )
                      );
                    })}
                  </Top>
                </Sticky>
                <TrackColumn>
                  {track.get('sessions').map((session, j) => {
                    // eslint-disable-line
                    const isFiller = session.get('isFiller');
                    const sessionBaseHeight =
                      MINUTE_HEIGHT * session.get('blockSize');

                    const height = isFiller
                      ? sessionBaseHeight
                      : Math.max(sessionBaseHeight, MINUTE_HEIGHT * 10);

                    return isFiller
                      ? (() => <SessionBlock key={j} style={{ height }} />)()
                      : (() => (
                          <Session
                            key={session.get('id')}
                            conferenceHandle={conferenceHandle}
                            linkProvider={linkProvider}
                            session={session}
                            height={height}
                          />
                        ))();
                  })}
                </TrackColumn>
              </Flexbox>
            </Track>
          ))}
        </Container>
      );
    }
  };
