import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default styled(Link)`
  position: relative;
  display: block;

  color: #333;

  padding: 20px;
  margin: 10px;

  background-color: #eeeff1;
  border-radius: 8px;

  .logo-referencePoint {
    display: none;
  }

  &:hover {
    .logo-referencePoint {
      display: block;
    }
  }

  &:active .rc-slider,
  &:active .rc-slider > .rc-slider-track,
  .rc-slider,
  .rc-slider > .rc-slider-track {
    visibility: hidden !important;
  }

  &:hover .rc-slider,
  &:hover .rc-slider > .rc-slider-track,
  .rc-slider.rc-slider--sliding,
  .rc-slider.rc-slider--sliding > .rc-slider-track {
    visibility: visible !important;
  }
`;
