import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { Field } from 'redux-form/immutable';

import TextAreaInput from '../../TextAreaInput';
import FormLabel from '../../FormLabel';

const TextInput = ({ limit, messages, intl, input }) => (
  <fieldset>
    <FormLabel
      htmlFor={input.name}
      message={intl.formatMessage(messages.messageTextLabel)}
      messageInfo={intl.formatMessage(messages.messageTextLabelInfo)}
    />
    <Field
      id={input.name}
      name={input.name}
      component={TextAreaInput}
      props={{
        limit,
        placeholder: intl.formatMessage(messages.messageTextPlaceholder),
      }}
      required
    />
  </fieldset>
);

TextInput.propTypes = {
  limit: PropTypes.number,
  messages: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(TextInput);
