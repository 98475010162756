/**
 *
 * TimeRangeInput
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { Map } from 'immutable';
import styled from 'styled-components';

import TimeInputControl from '../TimeInputControl';
import validateTimeDifference from '../../utils/validateTimeDifference';

const Label = styled.div`
  font-size: 11px;
  line-height: 3;
  margin-top: 3px;
  margin-bottom: -3px;
`;

const CustomTimeInputControl = styled(TimeInputControl)`
  font-size: 15px;
  line-height: 1;
  margin: 0;

  &[type='text'] {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

const Error = styled.p`
  color: ${props => props.theme.colors.attention};
  margin: 0;
  max-height: 0;
  transition: max-height 160ms ease-out;
  ${props => (props.hasError ? `max-height: 100px` : '')};
`;

export default class TimeRangeInput extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
  };

  static handleStartTimeChange(value) {
    const { input } = this.props;

    input.onChange((input.value || Map()).set('startTime', value));
  }

  static handleEndTimeChange(value) {
    const { input } = this.props;
    const time = { startTime: input.value.get('startTime'), endTime: value };
    const diff = validateTimeDifference(time);

    for (let i = 0; i < diff.length; i += 1) {
      if (diff[0] === -1 && diff[1] < 5) {
        this.setState({ hasError: 'Must be at least 5 minutes long.' });
      } else {
        this.setState({ hasError: null });
      }
    }

    input.onChange((input.value || Map()).set('endTime', value));
  }

  constructor(props) {
    super(props);

    this.handleStartTimeChange = TimeRangeInput.handleStartTimeChange.bind(
      this
    );
    this.handleEndTimeChange = TimeRangeInput.handleEndTimeChange.bind(this);
  }

  state = {
    hasError: null,
  };

  getErrorMessage = () => {
    const {
      meta: { error },
    } = this.props;
    if (error) {
      return error;
    } else if (this.state.hasError) {
      return this.state.hasError;
    }
    return '';
  };

  render() {
    const { input } = this.props;
    const value = input.value || Map();
    const errorMessage = this.getErrorMessage();
    return (
      <Flexbox flexDirection="column">
        <Flexbox flexDirection="row">
          <Flexbox flexDirection="column">
            <CustomTimeInputControl
              value={value.get('startTime')}
              onChange={this.handleStartTimeChange}
            />
            <Label>Starts</Label>
          </Flexbox>
          <Flexbox flexDirection="column">
            <CustomTimeInputControl
              value={value.get('endTime')}
              onChange={this.handleEndTimeChange}
            />
            <Label>Ends</Label>
          </Flexbox>
        </Flexbox>
        <Error hasError={!!errorMessage}>{errorMessage}</Error>
      </Flexbox>
    );
  }
}
