import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';

import { resetPasswordSuccess } from '../../../actions/user';

export default function* resetPassword(token, password, successMessage) {
  yield firebase.auth().confirmPasswordReset(token, password);
  yield put(resetPasswordSuccess({ message: successMessage }));
  yield put(push('/login'));
}
