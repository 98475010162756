import React from 'react';
import { compose } from 'recompose';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import CurrentSessionsPage from '../CurrentSessionsPage';
import SessionPage from '../SessionPage';
import NotFound from '../NotFoundPage';
import { withIsAdministrator } from '../../providers/IsAdministratorProvider';
import connect from 'react-redux/es/connect/connect';
import { createSelector } from 'reselect';
import { selectHasPermission } from '../../selectors/user';
import { selectCurrentDay } from '../../selectors/common';
import EntityEmptyState from '../../components/EntityEmptyState';
import emptyStatePastIllustration from '../../assets/images/access-denied-state.svg';
import Button from '../../components/buttons/Button';
import { EmbedBackground } from '../embed/common';

export const provideLink = isAuthenticated => (
  route,
  { conferenceHandle, sessionId, dayIndex } = {}
) => {
  const questionRoutePart = isAuthenticated ? 'm/q' : 'q';
  switch (route) {
    case 'session':
      return `/@${conferenceHandle}/${questionRoutePart}/sessions/${sessionId}`;
    case 'back':
      return `/@${conferenceHandle}/${questionRoutePart}`;
    case 'days':
      return `/@${conferenceHandle}/${questionRoutePart}/days/${dayIndex}`;
    default:
      return undefined;
  }
};

const createQuestionsPage = isAuthenticated => ({
  hasPermission,
  currentDay,
}) => {
  const questionRoutePart = isAuthenticated ? 'm/q' : 'q';

  if (isAuthenticated && !hasPermission) {
    return (
      <EmbedBackground>
        <EntityEmptyState
          illustration={emptyStatePastIllustration}
          title={'Access Denied'}
          hint={`You don't have permissions to access requested page.`}
        >
          <Button to={`/`} label="Go back" type="primary" />
        </EntityEmptyState>
      </EmbedBackground>
    );
  }

  return (
    <EmbedBackground>
      <Switch>
        <Redirect
          exact
          from={`/@:conferenceHandle/${questionRoutePart}`}
          to={`/@:conferenceHandle/${questionRoutePart}/days/${currentDay}`}
        />
        <Route
          exact
          path={`/@:conferenceHandle/${questionRoutePart}/days/:day`}
          render={() => (
            <CurrentSessionsPage
              linkProvider={provideLink(isAuthenticated)}
              showAllPassedSessions={isAuthenticated}
            />
          )}
        />
        <Route
          exact
          path={`/@:conferenceHandle/${questionRoutePart}/sessions/:id`}
          render={() => (
            <SessionPage
              linkProvider={provideLink(isAuthenticated)}
              moderator={isAuthenticated}
            />
          )}
        />

        <Route component={NotFound} />
      </Switch>
    </EmbedBackground>
  );
};

const QuestionPageMapStateToProps = createSelector(
  selectCurrentDay,
  currentDay => ({ currentDay })
);

export const QuestionsPage = compose(
  withRouter,
  connect(QuestionPageMapStateToProps)
)(createQuestionsPage(false));

const AuthenticatedQuestionsPageMapStateToProps = createSelector(
  selectHasPermission,
  selectCurrentDay,
  (hasPermission, currentDay) => ({
    hasPermission,
    currentDay,
  })
);

export const AuthenticatedQuestionsPage = compose(
  withRouter,
  withIsAdministrator,
  connect(AuthenticatedQuestionsPageMapStateToProps)
)(createQuestionsPage(true));

export default createQuestionsPage;
