import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { CenteredFormErrorWithSmallBottomMargin as FormError } from '../FormError';
import ButtonListInputControl from '../ButtonListInputControl';

const ButtonListInput = ({ input, meta, items, ...props }) => {
  const { touched, error } = meta;
  const { value, name } = input;

  return (
    <Fragment>
      {touched && error && <FormError>{error}</FormError>}
      <ButtonListInputControl
        {...props}
        value={value}
        name={name}
        items={items}
        keyExtractor={(item, index) => index}
        onChange={input.onChange}
      />
    </Fragment>
  );
};

const propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default compose(setPropTypes(propTypes))(ButtonListInput);
