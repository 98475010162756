import { defineMessages } from 'react-intl';

export default defineMessages({
  edit: {
    id: 'app.components.IntentList.edit',
    defaultMessage: 'Edit',
  },
  responseConfigured: {
    id: 'app.components.IntentList.response.configured',
    defaultMessage: 'Response configured.',
  },
  responseNotConfigured: {
    id: 'app.components.IntentList.response.notConfigured',
    defaultMessage: 'Response not configured!',
  },
  phrasesAdded: {
    id: 'app.components.IntentList.response.phrases.added',
    defaultMessage: 'Add phrases',
  },
  phrasesEmpty: {
    id: 'app.components.IntentList.response.phrases.empty',
    defaultMessage: 'No phrases! Add one',
  },
  phrasesListTitle: {
    id: 'app.components.IntentList.response.phrasesListTitle',
    defaultMessage: 'Phrases',
  },
  phrasesListInfo: {
    id: 'app.components.IntentList.response.phrasesListInfo',
    defaultMessage: 'User’s questions or comments',
  },
  responseListTitle: {
    id: 'app.components.IntentList.response.responseListTitle',
    defaultMessage: 'Response',
  },
  responseListInfo: {
    id: 'app.components.IntentList.response.responseListInfo',
    defaultMessage: 'Answer to user’s phrase',
  },
  responsesEmpty: {
    id: 'app.components.IntentList.response.responsesEmpty',
    defaultMessage: 'Add response',
  },
  previewResponse: {
    id: 'app.components.IntentList.response.previewResponse',
    defaultMessage: 'Preview Response',
  },
});
