import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { compose, setPropTypes, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import messages from './messages';

import Auth from '../Auth';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

import resetPassword from '../../sagas/user/account/resetPassword';
import { selectError } from '../../selectors/user';

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
`;

const ResetPassword = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5ff;
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
  background-size: 18%;
  box-shadow: 0 9px 17px 0 rgba(21, 11, 57, 0.15);
  padding: 2.125em 3.75em;
  text-align: center;
`;

const Error = styled.p`
  color: ${props => props.theme.colors.attention};
  margin: 0;
`;

const ResetPasswordPage = ({
  match,
  intl,
  createUserRequestExecutor,
  error,
  setError,
}) => {
  const defaultUserRequestExecutor = createUserRequestExecutor({
    onFailure: error => setError(error),
    shouldNotifyToastr: false,
  });

  const handleSubmit = async data => {
    const { params = {} } = match;
    const { token } = params;
    const { password } = data.toJS();
    await defaultUserRequestExecutor(
      resetPassword,
      token,
      password,
      intl.formatMessage(messages.resetPasswordSuccess)
    );
  };

  return (
    <Auth>
      <Helmet title={intl.formatMessage(messages.title)} />
      <ResetPassword>
        <Title>{intl.formatMessage(messages.header)}</Title>
        {error && <Error>{error && error.message}</Error>}
        <ResetPasswordForm onSubmit={handleSubmit} />
      </ResetPassword>
    </Auth>
  );
};

const propTypes = {
  error: PropTypes.object,
};

const mapStateToProps = createSelector(selectError(), error => ({
  error: error.resetPassword,
}));

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
  withUserRequestExecutor,
  withState('error', 'setError'),
  setPropTypes(propTypes)
)(ResetPasswordPage);
