import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import MessengerChatBubble from './MessengerChatBubble';
import { MessengerConversationPropTypes } from './propTypes';
import TooltipHover from '../TooltipHover';

const MessageList = styled.ul`
  list-style: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: ${props => (props.isUser ? 'flex-end' : 'flex-start')};
`;

const MessengerPreviewWrapper = styled.div`
  ${props => (props.width ? `width: ${props.width}%;` : '')};
`;

const DateTimeHover = ({ dateTime, children }) =>
  dateTime ? (
    <TooltipHover text={moment(dateTime).fromNow()}>{children}</TooltipHover>
  ) : (
    children
  );

const MessengerConversation = ({ width, conversation }) => (
  <MessengerPreviewWrapper width={width}>
    {conversation.map((conversation, i) => (
      <MessageList key={i} isUser={conversation.type === 'user'}>
        {conversation.messages.map((item, j) => {
          switch (item.type) {
            case 'image':
              return (
                <DateTimeHover dateTime={item.dateTime} key={j}>
                  <img
                    style={{ maxWidth: width * 0.7 + '%', margin: '0 0 5px 0' }}
                    src={item.content}
                    alt=""
                  />
                </DateTimeHover>
              );
            case 'text':
            default:
              return (
                <MessengerChatBubble
                  key={j}
                  isUser={conversation.type === 'user'}
                >
                  <DateTimeHover dateTime={item.dateTime}>
                    {item.content}
                  </DateTimeHover>
                </MessengerChatBubble>
              );
          }
        })}
      </MessageList>
    ))}
  </MessengerPreviewWrapper>
);

MessengerConversation.propTypes = MessengerConversationPropTypes;
export default MessengerConversation;
