import { fromJS } from 'immutable';
import { call, all } from 'redux-saga/effects';
import firebase from 'firebase/app';

export default function* fetchUsers(conferenceHandle, users) {
  const response = yield all(users.map(id => call(fetchUser, id)));
  return fromJS(response);
}

export function* fetchUser(id) {
  const fullName = yield firebase
    .database()
    .ref(`users/${id}/fullName`)
    .once('value');
  const providerPhotoUrl = yield firebase
    .database()
    .ref(`users/${id}/providerPhotoUrl`)
    .once('value');
  return {
    id,
    fullName: fullName.toJSON(),
    providerPhotoUrl: providerPhotoUrl.toJSON(),
  };
}
