/*
 *  EditSessionPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Map } from 'immutable';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectId,
} from '../../selectors/user';

import SessionForm from '../../components/SessionForm';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import deleteSession from '../../sagas/user/sessions/delete';
import updateSession from '../../sagas/user/sessions/update';

import convertTime from '../../utils/convertTime';

export class EditSessionPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    session: PropTypes.any.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages: error => {
      switch ((error || {}).code) {
        case 'session/duration':
          return 'Session can not have 0 or negative duration. Please correct start/end time of this session.';
        case 'session/overlapping':
          return 'Session overlaps with another session. Please correct start/end time of this session.';
        default:
          return 'Please try again. If error persists, contact our support.';
      }
    },
  });

  handleSubmit = async (data, dispatch, { form }) => {
    const { conferenceHandle } = this.props;
    const {
      id,
      sessionType,
      track,
      title,
      description,
      tags,
      speakers,
      day,
      time,
      video,
    } = data
      .updateIn(['time', 'startTime'], convertTime)
      .updateIn(['time', 'endTime'], convertTime)
      .toJS();

    await this.defaultUserRequestExecutor(updateSession, {
      conferenceHandle,
      id,
      sessionType,
      track,
      title,
      description,
      tags,
      speakers,
      day,
      time,
      video,
    });
  };

  handleDelete = async () => {
    const { conferenceHandle, id } = this.props;

    await this.defaultUserRequestExecutor(deleteSession, {
      conferenceHandle,
      id,
    });
  };

  render() {
    const { conferenceHandle, session } = this.props;
    return (
      <div>
        <Helmet title={`${session.get('title')} • Sava Events`} />
        <SessionForm
          conferenceHandle={conferenceHandle}
          initialValues={session}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const valueToId = value => Map({ id: value });

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectId,
  (conferenceHandle, conference, id) => ({
    conferenceHandle,
    id,
    session: conference
      .getIn(['sessions', id], Map())
      .set('id', id)
      .update('speakers', Map(), speakers =>
        speakers
          .keySeq()
          .toList()
          .map(id => Map({ id }))
      )
      .update('sessionType', valueToId)
      .update('track', valueToId),
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  withUserRequestExecutor
)(EditSessionPage);
