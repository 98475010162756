import firebase from 'firebase/app';

export default function* updateSettings({ id, settings }) {
  try {
    yield firebase
      .database()
      .ref(`conferences/${id}`)
      .update(settings);
  } catch (e) {
    console.log(e);
  }
}
