export default [
  {
    feature: 'schedule',
    pathToMatch: '/@:conferenceHandle/days/:day',
    getLink: conferenceHandle => `/@${conferenceHandle}/days/0`,
    icon: 'date_range',
    label: 'schedule',
  },
  {
    feature: 'schedule',
    pathToMatch: '/@:conferenceHandle/breaks/days/:day',
    getLink: conferenceHandle => `/@${conferenceHandle}/breaks/days/0`,
    icon: 'free_breakfast',
    label: 'breaks',
  },
  {
    feature: 'speakers',
    pathToMatch: '/@:conferenceHandle/speakers',
    getLink: conferenceHandle => `/@${conferenceHandle}/speakers`,
    icon: 'person',
    label: 'speakers',
  },
  {
    feature: 'cfp',
    pathToMatch: '/@:conferenceHandle/cfp',
    getLink: conferenceHandle => `/@${conferenceHandle}/cfp`,
    icon: 'how_to_reg',
    label: 'cfp',
  },
  {
    feature: 'sponsors',
    pathToMatch: '/@:conferenceHandle/sponsors',
    getLink: conferenceHandle => `/@${conferenceHandle}/sponsors`,
    icon: 'euro_symbol',
    label: 'sponsors',
  },
  {
    feature: 'broadcasts',
    pathToMatch: '/@:conferenceHandle/broadcasts',
    getLink: conferenceHandle => `/@${conferenceHandle}/broadcasts`,
    icon: 'announcement',
    label: 'broadcasts',
  },
  {
    feature: true,
    pathToMatch: '/@:conferenceHandle/conversations',
    getLink: conferenceHandle => `/@${conferenceHandle}/conversations`,
    icon: 'forum',
    label: 'chatbot',
  },
  {
    feature: 'moderator',
    pathToMatch: '/@:conferenceHandle/moderator',
    getLink: conferenceHandle => `/@${conferenceHandle}/moderator`,
    icon: 'record_voice_over',
    label: 'moderator',
  },
  {
    feature: true,
    pathToMatch: '/@:conferenceHandle/supportinbox',
    getLink: conferenceHandle => `/@${conferenceHandle}/supportinbox`,
    icon: 'inbox',
    label: 'support',
    dynamicValue: 'openTicketsCount',
  },
  {
    feature: true,
    pathToMatch: '/@:conferenceHandle/settings',
    getLink: conferenceHandle => `/@${conferenceHandle}/settings`,
    icon: 'build',
    label: 'settings',
  },
];
