import firebase from 'firebase/app';

export default function* reorderSponsorGroups(conferenceHandle, groups) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsorGroups`)
    .transaction(dbSponsorGroups => {
      for (let i = 0, n = groups.length; i < n; i += 1) {
        const dbSponsorGroup = dbSponsorGroups[groups[i]];
        if (dbSponsorGroup) {
          dbSponsorGroup.order = i;
        }
      }
      return dbSponsorGroups;
    });
}
