export const inRange = (n, a, b) => {
  return n > Math.min(a, b) && n < Math.max(a, b);
};

export const inMinutes = time => {
  const [h, m] = time.split(':');
  return parseInt(h, 10) * 60 + parseInt(m, 10);
};

export const isOverlapping = (l, r) => {
  const l0 = inMinutes(l.startTime);
  const l1 = inMinutes(l.endTime);
  const r0 = inMinutes(r.startTime);
  const r1 = inMinutes(r.endTime);

  return (
    inRange(l0, r0, r1) ||
    inRange(l1, r0, r1) ||
    inRange(r0, l0, l1) ||
    inRange(r1, l0, l1)
  );
};
