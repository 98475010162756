import { put } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { isArray } from 'is-of-type';

import { BASE_URL } from './common';
import getFacebookCredentials from './getCredentials';
import { acceptFbPages } from '../../../../actions/user';

const constructUrl = (baseUrl, userId, accessToken, attributes) =>
  `${baseUrl}/${userId}?access_token=${accessToken}&fields=${attributes.join(
    ','
  )}`;

const getPagesFromFb = async () => {
  const attributes = ['name', 'accounts{name,id,access_token,username}'];
  const {
    result: facebookCredentialsResult,
    error,
  } = await getFacebookCredentials();

  if (error) {
    return { error };
  }

  const { userId, accessToken } = facebookCredentialsResult;

  const response = await fetch(
    constructUrl(BASE_URL, userId, accessToken, attributes)
  );

  if (response.status === 200) {
    const { accounts } = await response.json();

    if (!accounts || !isArray(accounts.data)) {
      return { result: [] };
    }

    return {
      result: accounts.data.map(account => ({
        name: account.name,
        pageId: account.id,
        pageToken: account.access_token,
        username: account.username,
      })),
    };
  } else {
    const { error } = await response.json();
    return { error };
  }
};

export default function* getPages() {
  const { error, result } = yield getPagesFromFb();

  if (error) {
    return Promise.reject({
      code: error.code,
      message: error['error_user_msg'] || error.message,
    });
  } else {
    yield put(acceptFbPages(fromJS(result)));
  }
}
