import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';
import CollectionInput from '../../components/CollectionInput';
import Form from '../../components/Form';
import CustomResponseInput from '../CustomResponseInput';
import { button } from '../../utils/ui/interactive';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 0.25em;
`;

const Description = styled.div`
  margin-bottom: 1.5em;
  opacity: 0.75;
  line-height: 1.4;
`;

const Button = styled.button`
  padding: 12px;
  margin: 0px;

  ${button()};
`;

const CustomResponsesInput = CollectionInput(CustomResponseInput);

const CustomResponsesForm = ({ handleSubmit, pristine, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <Title>
      <FormattedMessage {...messages.title} />
    </Title>
    <Description>
      <FormattedMessage {...messages.info} />
    </Description>
    <fieldset>
      <FieldArray name="responses" component={CustomResponsesInput} />
    </fieldset>

    <fieldset>
      <Button disabled={pristine || submitting}>
        <FormattedMessage {...messages.save} />
      </Button>
    </fieldset>
  </Form>
);

CustomResponsesForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: 'responses',
})(injectIntl(CustomResponsesForm));
