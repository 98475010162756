/**
 *
 * CollectionInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

import Icon from '../Icon';

const ErrorCard = styled(Flexbox)``;

const ButtonCard = styled(Flexbox)`
  padding-left: 6px;
  padding-right: 8px;
  padding-top: 7px;
  padding-bottom: 5px;

  font-size: 13px;

  & > i {
    font-size: 17px;
  }

  ${button()};
`;

export const ListWrapper = ({ children, onAdd, touched, error }) => (
  <Flexbox flexDirection="column" alignItems="stretch">
    {touched && error && <ErrorCard>{error}</ErrorCard>}

    {children}

    <ButtonCard onClick={onAdd} alignSelf="flex-start">
      <Icon>add</Icon> Add
    </ButtonCard>
  </Flexbox>
);

ListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onAdd: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.any,
};

const Card = styled(Flexbox)`
  padding: 6px;
  margin-bottom: 6px;
  background: #f5f5f5;
`;

const RemoveButton = styled(Flexbox)`
  margin-bottom: -15px;
  z-index: 9999;
  & > i {
    font-size: 14px;
  }

  ${button({ backgroundColor: '#f5f5f5' })};
`;

export const ItemWrapper = ({ children, onRemove }) => (
  <Card flexDirection="column" alignItems="stretch">
    <RemoveButton onClick={onRemove} alignSelf="flex-end">
      <Icon>clear</Icon>
    </RemoveButton>

    <Flexbox flexDirection="column">{children}</Flexbox>
  </Card>
);

ItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired,
};
