import { defineMessages } from 'react-intl';

export default defineMessages({
  address: {
    id: 'app.components.HandleInput.address',
    defaultMessage: 'Your event would be hosted at:',
  },
  addressCurrent: {
    id: 'app.components.HandleInput.addressCurrent',
    defaultMessage: 'Your event is hosted at:',
  },
  eventCodeImmutable: {
    id: 'app.components.HandleInput.eventCodeImmutable',
    defaultMessage:
      'The event code cannot be changed later, so keep it short and memorable – like a hashtag!',
  },
});
