/*
 *  SessionForm Messages
 *
 *  This contains all the text for the SessionForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  track: {
    id: 'app.components.SessionForm.track',
    defaultMessage: 'Track',
  },
  sessionType: {
    id: 'app.components.SessionForm.sessionType',
    defaultMessage: 'Session Type',
  },
  title: {
    id: 'app.components.SessionForm.title',
    defaultMessage: 'Title',
  },
  titlePlaceholder: {
    id: 'app.components.SessionForm.titlePlaceholder',
    defaultMessage: 'e.g., My Cool Session',
  },
  video: {
    id: 'app.components.SessionForm.video',
    defaultMessage: 'Video Stream/Recording Link',
  },
  videoPlaceholder: {
    id: 'app.components.SessionForm.videoPlaceholder',
    defaultMessage: 'e.g., https://www.youtube.com/watch?v=pEkSCJFxkFU',
  },
  description: {
    id: 'app.components.SessionForm.description',
    defaultMessage: 'Description',
  },
  tags: {
    id: 'app.components.SessionForm.tags',
    defaultMessage: 'Tags',
  },
  speakers: {
    id: 'app.components.SessionForm.speakers',
    defaultMessage: 'Speakers',
  },
  day: {
    id: 'app.components.SessionForm.day',
    defaultMessage: 'Day',
  },
  time: {
    id: 'app.components.SessionForm.time',
    defaultMessage: 'Time',
  },
  create: {
    id: 'app.components.SessionForm.create',
    defaultMessage: 'Add New Session',
  },
  update: {
    id: 'app.components.SessionForm.update',
    defaultMessage: 'Update Session',
  },
  delete: {
    id: 'app.components.SessionForm.delete',
    defaultMessage: 'Delete',
  },
});
