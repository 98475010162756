/**
 *
 * SponsorGroupForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

import Form from '../Form';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';
import DeleteButton from '../DeleteButton';
import ActionBar from '../ActionBar';

const Button = styled.button`
  padding: 12px;

  ${button()};
`;

const SponsorGroupForm = props => {
  const {
    isCreateForm,
    handleSubmit,
    pristine,
    submitting,
    intl,
    onDelete,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      {!isCreateForm && (
        <Field name="id" component="input" type="hidden" required />
      )}

      <fieldset>
        <FormattedMessage {...messages.name} />
        <Field
          name="name"
          component="input"
          type="text"
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          style={{ fontSize: 22 }}
          required
        />
      </fieldset>

      <ActionBar justifyContent="space-between" style={{ padding: '0' }}>
        <Button disabled={pristine || submitting}>
          <FormattedMessage
            {...(isCreateForm ? messages.create : messages.update)}
          />
        </Button>

        {!isCreateForm && (
          <DeleteButton type="reset" onClick={onDelete}>
            <FormattedMessage {...messages.delete} />
          </DeleteButton>
        )}
      </ActionBar>
    </Form>
  );
};

SponsorGroupForm.propTypes = {
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default reduxForm({
  form: 'sponsorGroup',
})(injectIntl(SponsorGroupForm));
