import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Color from 'color';

import Icon from '../Icon';

const A = styled.a`
  i {
    color: ${props => props.theme.page.color || '#333'};
  }
  &:hover i {
    filter: opacity(75%);
  }
`;

const IconWrapper = styled.div`
  padding: 10px;
  padding-bottom: 2px;
`;

export const formatLink = (link, icon) => {
  if (link.match(/^https?:\/\//)) {
    return link;
  }
  switch (icon) {
    case 'twitter':
      if (!link.match(/twitter.com/)) {
        return `https://twitter.com/${link.replace('@', '')}`;
      }
      break;
    case 'instagram':
      if (!link.match(/instagram.com/)) {
        return `https://instagram.com/${link.replace('@', '')}`;
      }
      break;
    case 'github':
      if (!link.match(/github.com/)) {
        return `https://github.com/${link}`;
      }
      break;
    default:
      break;
  }
  return `http://${link}`;
};

class SocialLink extends React.Component {
  static propTypes = {
    theme: PropTypes.object,
    size: PropTypes.number.isRequired,
    optimalSize: PropTypes.number,
    SocialIcon: PropTypes.any,
    link: PropTypes.string.isRequired,
    iconName: PropTypes.string,
    style: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const {
      size,
      optimalSize,
      SocialIcon,
      link,
      iconName,
      style,
      theme,
    } = this.props;
    const { hover } = this.state;
    const color = theme.page.color;
    const hoverColor = Color(color).fade(0.25);

    let icon;

    if (SocialIcon) {
      icon = (
        <SocialIcon
          width={size || optimalSize / 8}
          height={size || optimalSize / 8}
          padding={size / 4 || optimalSize / 32}
          color={hover ? hoverColor || '#248bd1' : color || '#333'}
        />
      );
    } else {
      icon = (
        <IconWrapper>
          <Icon>launch</Icon>
        </IconWrapper>
      );
    }

    return (
      <A
        href={formatLink(link, iconName)}
        target="_blank"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={style}
      >
        {icon}
      </A>
    );
  }
}

export default withTheme(SocialLink);
