import styled from 'styled-components';

export const Token = styled.span`
  background-color: #ffea81;
  color: #7d6a09;
  border-radius: 0.75em;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  padding: 0.25em 0.75em;
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  line-height: 1.4;
`;

export const ActionToken = styled(Token)`
  background-color: ${props => props.theme.colors.lightGray};
`;

export const ResponseToken = styled(Token)`
  background-color: ${props => props.theme.colors.brandPrimary};
`;
