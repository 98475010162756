import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TimeRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lightOpacity};
  border-top: 1px solid ${props => props.theme.colors.lightOpacity};
  width: 100%;
  display: grid;
  grid-template-columns: 20% 80% 100% 100%;
  grid-gap: 5px;
  padding: ${props => (props.em ? '0.4em' : '8px')};
`;

const TimeLeft = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  font-size: ${props => (props.em ? '1.3em' : '22px')};
  color: ${props => props.theme.colors.accent};
`;

const TimeRightTitleNoTag = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10% 100% 100%;
  grid-gap: 5px;
  color: ${props => props.theme.page.color};
  padding-left: ${props => (props.em ? '0.4em' : '8px')};
`;

const Duration = styled.div`
  font-size: ${props => (props.em ? '0.7em' : '14px')};
  color: ${props => props.theme.colors.dark};
`;

const TimeRowNoTag = ({ startAt, title, duration }) => (
  <TimeRow flexDirection="row">
    <TimeLeft>{startAt}</TimeLeft>
    <TimeRightTitleNoTag>
      {title}
      <Duration>{duration} mins</Duration>
    </TimeRightTitleNoTag>
  </TimeRow>
);

TimeRowNoTag.propTypes = {
  startAt: PropTypes.string,
  title: PropTypes.string,
  duration: PropTypes.number,
};

export default TimeRowNoTag;
