import React from 'react';
import styled from 'styled-components';
import { button } from '../../../utils/ui/interactive';

const Button = styled.button`
  margin: 0.75em;
  border-radius: 3px;
  padding: 0.75em 1em;
  font-weight: 600;
  ${props => button({ backgroundColor: props.theme.colors.accent })};
  color: ${props => props.theme.colors.light};
`;

export default ({ label, disabled, onClick }) => (
  <Button disabled={disabled} onClick={onClick}>
    {label}
  </Button>
);
