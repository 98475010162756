/**
 *
 * SponsorGroupInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { Field } from 'redux-form/immutable';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';

class SponsorGroupInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    intl: intlShape.isRequired,
  };

  render() {
    const { name, intl } = this.props;

    return (
      <Flexbox flexDirection="column" flexGrow={2}>
        <fieldset>
          <FormattedMessage {...messages.name} />
          <Field
            name={`${name ? `${name}.` : ''}name`}
            component="input"
            type="text"
            placeholder={intl.formatMessage(messages.namePlaceholder)}
            required
          />
        </fieldset>
      </Flexbox>
    );
  }
}

export default injectIntl(SponsorGroupInput);
