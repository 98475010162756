import React, { Component, Fragment } from 'react';

import Breadcrumb from '../components/Breadcrumb';

const withBreadcrumbs = breadcrumbsProvider => WrappedComponent => {
  class WithBreadcrumbs extends Component {
    render() {
      const breadcrumbs = breadcrumbsProvider(this.props);

      return (
        <Fragment>
          {breadcrumbs.map(({ to, title }) => (
            <Breadcrumb key={to} to={to}>
              {title}
            </Breadcrumb>
          ))}
          <WrappedComponent {...this.props} />
        </Fragment>
      );
    }
  }

  return WithBreadcrumbs;
};

export default withBreadcrumbs;
