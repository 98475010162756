import React from 'react';

import isMobile from '../utils/isMobile';

export default Component =>
  class WithIsMobile extends React.Component {
    constructor(props) {
      super(props);

      this.isMobile = isMobile();
    }

    render() {
      return <Component {...this.props} isMobile={this.isMobile} />;
    }
  };
