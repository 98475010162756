import React, { Component } from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';

const IconButton = styled.button`
  background: ${props => (props.selected ? 'whitesmoke' : '#fff')};
  cursor: pointer;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid ${props => (props.selected ? props.primary : '#e8e8e8')};
  display: inline-flex;
  justify-content: center;
  padding: 5px 10px;
  color: ${props => props.primary};
  :hover {
    background-color: whitesmoke;
  }
`;

const Counter = styled.span`
  color: gray;
  line-height: 1.5em;
  margin-left: 6px;
`;

const approvedButtonConfig = {
  primary: '#66BE00',
  icon: 'sentiment_very_satisfied',
  type: 'approved',
};
const declinedButtonConfig = {
  primary: '#F95353',
  icon: 'sentiment_dissatisfied',
  type: 'declined',
};

const BaseIconButton = ({ primary, icon, count, selected, type, onSubmit }) => (
  <IconButton
    primary={primary}
    selected={selected}
    onClick={() => onSubmit(selected ? 'undecided' : type)}
  >
    <span>
      <Icon>{icon}</Icon>
    </span>
    <Counter>{count}</Counter>
  </IconButton>
);

const ApprovedIconButton = ({ count, onSubmit, currentUserReaction }) => (
  <BaseIconButton
    {...approvedButtonConfig}
    selected={currentUserReaction && currentUserReaction.type === 'approved'}
    count={count}
    onSubmit={onSubmit}
  />
);

const DeclinedIconButton = ({ count, onSubmit, currentUserReaction }) => (
  <BaseIconButton
    {...declinedButtonConfig}
    selected={currentUserReaction && currentUserReaction.type === 'declined'}
    count={count}
    onSubmit={onSubmit}
  />
);

class Reactions extends Component {
  handleSubmit = type => {
    this.props.onSubmit(this.props.submissionId, type);
  };

  render() {
    const approvedCount = this.props.reactions.filter(
      _ => _.type === 'approved'
    ).length;
    const declinedCount = this.props.reactions.filter(
      _ => _.type === 'declined'
    ).length;
    const currentUserReaction = this.props.reactions.find(
      _ => _.author === this.props.currentUserId
    );

    return (
      <div>
        <ApprovedIconButton
          count={approvedCount}
          currentUserReaction={currentUserReaction}
          onSubmit={this.handleSubmit}
        />
        <DeclinedIconButton
          count={declinedCount}
          currentUserReaction={currentUserReaction}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default Reactions;
