import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.ForgotPassword.title',
    defaultMessage: 'Forgot Password • Sava Events',
  },
  header: {
    id: 'app.containers.ForgotPassword.header',
    defaultMessage: 'Forgot Password',
  },
  limit: {
    id: 'app.containers.ForgotPassword.header',
    defaultMessage: 'Reset password exceed limit.',
  },
});
