import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import { inMinutes, isOverlapping } from '../../../utils/time';

export default function* updateBreak({
  conferenceHandle,
  id,
  title,
  description,
  day,
  time,
  isBlock,
}) {
  // TODO Bla…
  const breakObj = {
    title,
    day,
    time,
    isBlock,
  };

  if (description) {
    breakObj.description = description;
  }

  if (inMinutes(time.endTime) <= inMinutes(time.startTime)) {
    throw {
      code: 'break/duration',
      message: 'Break duration can not be 0 or negative.',
    };
  }

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/breaks`)
    .transaction(breaks => {
      const hasConflicts = Object.entries(breaks || {}).some(([_, b]) => {
        return _ === id
          ? false
          : b.day === breakObj.day &&
              b.track === breakObj.track &&
              isOverlapping(b.time, breakObj.time);
      });

      if (hasConflicts) {
        throw {
          code: 'break/overlapping',
          message: 'Break overlaps with another break.',
        };
      }

      return Object.assign(breaks, {
        [id]: Object.assign(breaks[id], breakObj),
      });
    });

  yield put(goBack());
}
