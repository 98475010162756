/**
 *
 *  Session Card
 *
 */

import React from 'react';
import styled from 'styled-components';
import SessionTypeBar from '../SessionTypeBar';
import SessionDetails from './SessionDetails';

const SessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  height: 100%;
  color: ${props => props.theme.card.textColor};
  overflow: auto;
  width: 100%;
  opacity: ${props => (props.isPast ? 0.5 : 1)};
`;

const SessionCard = ({
  isPast,
  session,
  hideStartTime,
  showDay,
  showTrack,
  hideSessionType,
  sessionColor,
  children,
}) => (
  <SessionContainer isPast={isPast}>
    <SessionTypeBar backgroundColor={sessionColor} />
    <SessionDetails
      day={session.get('startTime')}
      startTime={session.get('startTime')}
      endTime={session.get('endTime')}
      sessionColor={sessionColor}
      session={session}
      hideStartTime={hideStartTime}
      showDay={showDay}
      showTrack={showTrack}
      hideSessionType={hideSessionType}
      flexDirection="row"
      flexShrink={0}
      flexGrow={2}
    />
    {children}
  </SessionContainer>
);

export default SessionCard;
