import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.AddResponseForm.title',
    defaultMessage: 'Answers to Unhandled Messages',
  },
  info: {
    id: 'app.components.AddResponseForm.info',
    defaultMessage:
      'When the chatbot is unsure how to respond, it will default to a predefined system message. Here you can configure a set of “failure state” messages. The chatbot will randomly choose one of them over the system message.',
  },
  save: {
    id: 'app.components.AddResponseForm.save',
    defaultMessage: 'Save',
  },
});
