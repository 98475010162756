/*
 * ConnectToFbPage Container
 */

import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { compose } from 'recompose';
import Flexbox from 'flexbox-react';
import interactive, { button } from '../../utils/ui/interactive';
import withUserRequestExecutor, {
  errorMessages,
} from '../../hocs/withUserRequestExecutor';
import getPages from '../../sagas/user/integrations/facebook/getPages';
import connectToFacebook from '../../sagas/user/integrations/facebook/connect';
import disconnectFromFacebook from '../../sagas/user/integrations/facebook/disconnect';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectUserFacebookPages,
} from '../../selectors/user';
import { createSelector } from 'reselect';

const CreateNewPage = styled.a`
  width: 100%;
  text-align: left;
  padding: 12px;
  margin-bottom: 12px;
  ${interactive.styles`
  color: #333;
  `}${button({ backgroundColor: '#248bd1' })};
`;

const ChooseFromFb = styled.button`
  width: 100%;
  text-align: left;
  padding: 12px;
  margin-bottom: 12px;
  ${interactive.styles`
  color: #333;
  `}${button({ backgroundColor: '#248bd1' })};
`;

const Separate = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ConnectToPage = styled.button`
  padding: 12px;
  ${interactive.styles`
    color: #333;
  `}${button({ backgroundColor: '#248bd1' })};
`;

const PageWrap = styled(Flexbox)`
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const PageTitle = styled.div`
  text-align: left;
  padding: 12px;
`;

const Root = styled(Flexbox)`
  margin-top: 35px;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
`;

export class ConnectToFbPage extends React.Component {
  static propTypes = {
    pages: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: props.conference.get('facebook', Map()).toJS(),
      busy: false,
    };
  }

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({});

  disconnectUserRequestExecutor = this.props.createUserRequestExecutor({
    onStart: () => this.setState({ busy: true }),
    onEnd: () => this.setState({ page: {}, busy: false }),
    errorMessages,
  });

  connectUserRequestExecutor = page =>
    this.props.createUserRequestExecutor({
      onStart: () => this.setState({ busy: true }),
      onEnd: () => this.setState({ page: page, busy: false }),
      errorMessages,
    });

  handleConnect = page => {
    return async () => {
      await this.connectUserRequestExecutor(page)(
        connectToFacebook,
        this.props.conferenceHandle,
        page.pageId,
        page.pageToken,
        page.name,
        page.username
      );
    };
  };

  handleDisconnect = () => {
    return async () => {
      await this.disconnectUserRequestExecutor(
        disconnectFromFacebook,
        this.props.conferenceHandle
      );
    };
  };

  handleGetFbPages = () => {
    this.defaultUserRequestExecutor(getPages);
  };

  render() {
    const { pages } = this.props;

    return (
      <Root>
        <div>
          <CreateNewPage
            href="https://www.facebook.com/pages/creation/"
            target="_newtab"
          >
            Create new Facebook Page
          </CreateNewPage>
        </div>
        {(!pages || pages.length === 0) && (
          <div>
            <Separate>Or</Separate>
            <ChooseFromFb onClick={this.handleGetFbPages}>
              Choose from your Facebook Pages
            </ChooseFromFb>
          </div>
        )}
        {pages &&
          pages.map((p, i) => (
            <PageWrap key={i}>
              <PageTitle>{p.name}</PageTitle>
              <ConnectToPage
                onClick={
                  this.state.page.pageId === p.pageId
                    ? this.handleDisconnect(p)
                    : this.handleConnect(p)
                }
                disabled={this.state.busy}
              >
                <FormattedMessage
                  id={'connect'}
                  defaultMessage={`${
                    this.state.page.pageId === p.pageId
                      ? `Disconnect`
                      : `Connect`
                  }`}
                />
              </ConnectToPage>
            </PageWrap>
          ))}
      </Root>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectUserFacebookPages,
  (conferenceHandle, conference, pages) => {
    return {
      conferenceHandle,
      conference,
      pages,
    };
  }
);

export default compose(
  withUserRequestExecutor,
  connect(mapStateToProps)
)(ConnectToFbPage);
