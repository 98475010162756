import firebase from 'firebase/app';

export default class RTUpdater {
  _update = (type, res) => {
    if (this._subscriber) {
      this._subscriber(type, { id: res.key, ...res.val() });
    }
  };

  _add = res => {
    this._update('add', res);
  };

  _change = res => {
    this._update('change', res);
  };

  _remove = res => {
    this._update('remove', res);
  };

  /**
   * @param {function} cb
   */
  subscribe = cb => {
    this._subscriber = cb;
  };

  unsubscribe = () => {
    this._subscriber = undefined;
  };

  /**
   * @param {string} path
   * @param {{ onAdded: boolean, onChanged: boolean, onRemoved: boolean }}
   */
  start = (path, { onAdded, onChanged, onRemoved }) => {
    this._ref = firebase.database().ref(path);
    this._ref && onAdded && this._ref.on('child_added', this._add);
    this._ref && onChanged && this._ref.on('child_changed', this._change);
    this._ref && onRemoved && this._ref.on('child_removed', this._remove);
  };

  stop = () => {
    this._ref && this._ref.off();
  };
}
