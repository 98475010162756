import { put, all, call } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import { isObject, isDefinedAndNotNull } from 'is-of-type';

import deleteSponsor from '../sponsors/delete';

export default function* deleteSponsorGroup(conferenceHandle, id) {
  const sponsorsSnapshot = yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsors`)
    .once('value');

  const sponsors = sponsorsSnapshot.val();

  if (isDefinedAndNotNull(sponsors) && isObject(sponsors)) {
    yield all(
      Object.entries(sponsors).map(([sponsorId, sponsor]) =>
        sponsor.group === id
          ? call(deleteSponsor, conferenceHandle, sponsorId, false)
          : null
      )
    );
  }

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsorGroups/${id}`)
    .remove();

  yield put(goBack());
}
