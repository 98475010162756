import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CounterSpan = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 40px;
  text-align: center;
  line-height: 25px;
  color: ${props => (props.showWarning ? 'white' : '#051a25ba')}
  background-color: ${props =>
    props.showWarning ? props.theme.colors.attention : 'transparent'};
  font-size: 12px;
  border-radius: 3px;
  transition: 160ms all;
`;

const TextFieldCounter = ({ length, limit }) => (
  <CounterSpan showWarning={length > limit}>
    {limit > 0 ? limit - length : ''}
  </CounterSpan>
);

TextFieldCounter.propTypes = {
  length: PropTypes.number,
  limit: PropTypes.number,
};

TextFieldCounter.defaultProps = {
  length: 0,
  limit: -1,
};

export default TextFieldCounter;
