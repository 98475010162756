import {
  EMBED_CONFERENCE_HANDLE,
  EMBED_DAY_INDEX,
} from '../constants/localStorage';

export const getLastDayViewed = conferenceHandle => {
  const cachedEmbedConferenceHandle = localStorage.getItem(
    EMBED_CONFERENCE_HANDLE
  );
  const cachedEmbedDayIndex = localStorage.getItem(EMBED_DAY_INDEX) || 0;

  return conferenceHandle === cachedEmbedConferenceHandle
    ? cachedEmbedDayIndex
    : 0;
};

export const setLastDayViewed = (conferenceHandle, day) => {
  localStorage.setItem(EMBED_CONFERENCE_HANDLE, conferenceHandle);
  localStorage.setItem(EMBED_DAY_INDEX, day);
};
