import React from 'react';

export default message => {
  if (message.indexOf('GET_RATE_OPTIONS_FOR_EVENT_ID__') === 0) {
    return (
      <span>
        <em>User requested rate options.</em>
      </span>
    );
  }

  if (message.indexOf('GIVE_SCORE____') === 0) {
    return (
      <span>
        <em>User gave a score.</em>
      </span>
    );
  }

  if (message === 'HUMAN_SUPPORT_CONFIRMATION_CANCEL') {
    return (
      <span>
        <em>User canceled human support.</em>
      </span>
    );
  }

  return message;
};
