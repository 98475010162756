import React from 'react';

export default seconds => Component =>
  class WithNow extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        key: new Date().getTime(),
      };
    }

    getNextRefreshTimeout = () =>
      (60 - Number(new Date().getSeconds())) * 1000 - 100;

    refresh = () => this.setState({ key: new Date().getTime() });

    initRefresh = () => {
      if (seconds > 0) {
        this.refresh();
        this.interval = setInterval(this.refresh, seconds * 1000);
      }
    };

    componentWillMount() {
      setTimeout(this.initRefresh, this.getNextRefreshTimeout());
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    render() {
      return <Component key={this.state.key} {...this.props} />;
    }
  };
