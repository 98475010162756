/**
 *
 * ApiSettings Form
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';

import Form from '../Form';
import ToggleSwitch from '../ToggleSwitch';

import { button } from '../../utils/ui/interactive';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

const Button = styled.button`
  padding: 12px;
  margin: 0px;

  ${button()};
`;

/* eslint-disable */
const renderToggleSwitch = props => (
  <ToggleSwitch
    value={props.input.value ? true : false}
    onChange={props.input.onChange}
  />
);
/* eslint-enable */

const ChatbotSettingsForm = props => {
  const { handleSubmit, pristine, submitting } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset>
        <Flexbox alignItems="center">
          <span style={{ paddingRight: '1em' }}>
            <FormattedMessage {...messages.apiWanted} />
          </span>

          <Field name="apiWanted" component={renderToggleSwitch} />
        </Flexbox>
      </fieldset>

      <fieldset>
        <Button disabled={pristine || submitting}>
          <FormattedMessage {...messages.save} />
        </Button>
      </fieldset>
    </Form>
  );
};

ChatbotSettingsForm.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'apiSettings',
})(injectIntl(ChatbotSettingsForm));
