import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { compose, setPropTypes } from 'recompose';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import messages from './messages';
import emptyStateIllustration from '../../assets/images/error_state.svg';
import EntityEmptyState from '../../components/EntityEmptyState';
import Button from '../../components/buttons/Button';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const ErrorPage = ({ location, intl }) => {
  const { state = {} } = location || {};
  const { errorCode } = state;

  return (
    <Container>
      <Helmet title={intl.formatMessage(messages.title)} />
      <EntityEmptyState
        illustration={emptyStateIllustration}
        title={intl.formatMessage(messages[errorCode || 'UNKNOWN'])}
        hint={intl.formatMessage(messages.defaultDescription)}
      >
        <Button
          to={`/`}
          label={intl.formatMessage(messages.goBack)}
          type="primary"
        />
      </EntityEmptyState>
    </Container>
  );
};

const propTypes = {
  location: PropTypes.object,
};

export default compose(
  withRouter,
  injectIntl,
  setPropTypes(propTypes)
)(ErrorPage);
