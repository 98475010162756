/**
 *
 *  SessionTypeView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const SessionTypeView = ({ name }) => <div>{name}</div>;

SessionTypeView.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SessionTypeView;
