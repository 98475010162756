import { List } from 'immutable';

const getTwitterImage = link => {
  if (link.match(/^https/)) {
    if (link[link.length - 1] === '/') {
      return `${link}profile_image?size=original`;
    }
    return `${link}/profile_image?size=original`;
  }
  return `https://twitter.com/${link.replace(
    '@',
    ''
  )}/profile_image?size=original`;
};

export const addFallbackProfilePhoto = speakers =>
  speakers.map(speaker => {
    const twitter = speaker
      .get('profiles', List())
      .find(profile => profile.get('icon') === 'twitter');
    if (!speaker.get('image') && twitter) {
      return speaker.set('image', getTwitterImage(twitter.get('link')));
    }
    return speaker;
  });

export default getTwitterImage;
