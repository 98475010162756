import React from 'react';
import styled from 'styled-components';

const Info = styled.div`
  margin-left: ${props =>
    props.size ? '.5em' : (props.theme.SpeakersList || {}).baseSize / 4 || 6};
  line-height: 1;
  padding: ${props => (props.theme.SpeakersList || {}).baseSize / 24 || 0}px 0;
  overflow: hidden;
  white-space: nowrap;
`;

Info.Name = styled.div`
  font-size: ${props =>
    props.size || (props.theme.SpeakersList || {}).baseSize / 3 || 14};
  font-weight: 600;
  color: ${props =>
    props.usePageColor ? props.theme.page.color : props.theme.card.textColor};
`;

Info.Headline = styled.div`
  font-size: ${props =>
    props.size || (props.theme.SpeakersList || {}).baseSize / 4 || 12};
  font-weight: 600;
  line-height: 1.33;
  margin-top: 1px;
  height: calc(
    ${props =>
        props.size || (props.theme.SpeakersList || {}).baseSize / 4 || 12} * 1.3
  );
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.67;
  color: ${props =>
    props.usePageColor ? props.theme.page.color : props.theme.card.textColor};
`;

const SpeakerInfo = ({
  name,
  headline,
  hideHeadline,
  speaker,
  usePageColor,
  size,
}) => (
  <Info size={size}>
    <Info.Name size={size} usePageColor={usePageColor}>
      {name}
    </Info.Name>
    <Info.Headline size={size} usePageColor={usePageColor}>
      {headline}
    </Info.Headline>
  </Info>
);

export default SpeakerInfo;
