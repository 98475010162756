import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';
import { fromJS } from 'immutable';

import { acceptUser } from '../../../actions/user';

export default function* authenticateUsingGoogle({ errorAction }) {
  try {
    const user = yield firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(result => result.user);

    yield firebase
      .database()
      .ref(`users/${user.uid}`)
      .update({ fullName: user.displayName });

    yield put(acceptUser(fromJS(user.toJSON())));
    yield put(push('/'));
  } catch (error) {
    yield put(errorAction(error));
  }
}
