export const conferenceBorderColors = [
  '#B8BDBF',
  '#7080D4',
  '#7AC4E5',
  '#00BE89',
  '#FF423D',
  '#FB8049',
  '#F3B11C',
  '#F36F8F',
  '#945ECF',
  '#8F9FA5',
  '#8F9FA5',
  '#55A3D9',
];

const colorForIndex = index => {
  const _ = Math.floor(index / conferenceBorderColors.length);
  return conferenceBorderColors[index - _ * conferenceBorderColors.length];
};

export default colorForIndex;
