import { call, all } from 'redux-saga/effects';
import excludeFromMap from 'sava-shared/lib/utils/excludeFromMap';

import saveQuickReply from './saveQuickReply';
import removeQuickReplies from './removeQuickReplies';
import confirm from '../confirm';

export default function* saveQuickReplies(
  conferenceHandle,
  quickReplies,
  resetToDefaults,
  resetToDefaultsMessage
) {
  const saveArgs = quickReplies.map(quickReply =>
    call(saveQuickReply, conferenceHandle, excludeFromMap(quickReply, ['id']))
  );
  if (resetToDefaults) {
    if (yield confirm(resetToDefaultsMessage)) {
      yield all([call(removeQuickReplies, conferenceHandle), ...saveArgs]);
    }
  } else {
    yield all(saveArgs);
  }
}
