import { BASE_URL } from './common';

export default async (appId, appSecret, accessToken) => {
  const url = `${BASE_URL}/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}& fb_exchange_token=${accessToken}`;
  const response = await fetch(url);
  if (response.status === 200) {
    return { result: await response.json() };
  } else {
    const { error } = await response.json();
    return { error };
  }
};
