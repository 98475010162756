/**
 *
 * ResetPasswordForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

import Form from '../Form';

import messages from './messages';

const Button = styled.button`
  padding: 12px;

  ${button({ lighten: true })};
`;

const InfoParagraph = styled.p`
  margin-top; 0;
`;

const ResetPasswordForm = props => {
  const { handleSubmit, pristine, submitting, intl } = props;

  return (
    <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <InfoParagraph>{intl.formatMessage(messages.description)}</InfoParagraph>

      <fieldset>
        <FormattedMessage {...messages.password} />
        <Field
          name="password"
          component="input"
          type="password"
          placeholder={intl.formatMessage(messages.passwordPlaceholder)}
          required
        />
      </fieldset>

      <fieldset>
        <Button disabled={pristine || submitting}>
          <FormattedMessage {...messages.resetPassword} />
        </Button>
      </fieldset>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default reduxForm({
  form: 'resetPassword',
})(injectIntl(ResetPasswordForm));
