import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import messages from '../messages';
import { EditLink } from './EditLink';

const TitleWrapper = styled.div`
  display: flex;
`;

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  flex: 1 0 80%;
`;

const Actions = styled.div`
  flex: 1 0 20%;
  text-align: right;
`;

export default injectIntl(
  ({ title, intentId, rootUrl, isResponseSet, hasQueries }) => (
    <TitleWrapper flex="2">
      <Title>{title}</Title>
      <Actions flex="1" subtle={isResponseSet && hasQueries}>
        <FormattedMessage {...messages.edit}>
          {message => (
            <EditLink to={`${rootUrl}/${intentId}`}>{message}</EditLink>
          )}
        </FormattedMessage>
      </Actions>
    </TitleWrapper>
  )
);
