/*
 *
 * Embed reducer
 *
 */

import { Map, List, Range } from 'immutable';
import moment from 'moment-timezone';

import {
  ACCEPT_CONFERENCE,
  ADD_TAG_FILTER,
  ADD_SPEAKER_FILTER,
} from '../constants/embed';

const initialState = Map({
  tagFilters: List(),
  speakerFilters: List(),
  announcements: List(),
});

const newerThan = (timestamp, minutes, timezone) => {
  const cutoff = moment
    .tz(timezone || 'America/New_York')
    .subtract(minutes, 'minutes');
  return moment.tz(timestamp, timezone).isAfter(cutoff);
};

const using = (...values) => f => f(...values);

const timeToMoment = m => time =>
  using(...time.split(':', 2).map(n => parseInt(n, 10)))((hours, minutes) =>
    m
      .clone()
      .hours(hours)
      .minutes(minutes)
  );

function embedReducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_CONFERENCE:
      return using(
        action.conference.get('dates'),
        action.conference.get('embedLayout'),
        action.conference.get('mobileEmbedLayout'),
        action.conference.get('sessionTypes'),
        action.conference.get('sessions'),
        action.conference.get('speakers'),
        action.conference.get('sponsorGroups'),
        action.conference.get('sponsors'),
        action.conference.get('theme'),
        action.conference.get('tracks'),
        action.conference.get('breaks'),
        action.conference.get('timezone'),
        action.conference.get('announcements'),
        action.conference.get('users'),
        action.conference.get('configuration'),
        action.conference.get('subscriptionPlan')
      )(
        (
          dates,
          embedLayout,
          mobileEmbedLayout,
          sessionTypes,
          sessions,
          speakers,
          sponsorGroups,
          sponsors,
          theme,
          tracks,
          breaks,
          timezone,
          announcements,
          users,
          configuration,
          subscriptionPlan
        ) =>
          using(
            ...using(...dates.split(',', 2))((dateStr, daysStr) =>
              using(...dateStr.split('/', 3).map(n => parseInt(n, 10)))(
                (year, month, day) => [
                  moment([year, month - 1, day]),
                  parseInt(daysStr, 10),
                ]
              )
            )
          )((dayOneMoment, days) =>
            state
              .set('dates', dates)
              .set(
                'days',
                Range(0, days)
                  .toList()
                  .map(day =>
                    using(timeToMoment(dayOneMoment.clone().add(day, 'days')))(
                      toMoment =>
                        Map({
                          tracks: tracks
                            ? tracks
                                .toOrderedMap()
                                .sortBy(track => track.get('order'))
                                .map((track, trackId) =>
                                  track
                                    .set('id', trackId)
                                    .delete('order')
                                    .set(
                                      'sessions',
                                      sessions
                                        ? sessions
                                            .filter(
                                              session =>
                                                session.get('track') ===
                                                  trackId &&
                                                session.get('day') === day
                                            )
                                            .map((session, sessionId) =>
                                              using(
                                                toMoment(
                                                  session.getIn([
                                                    'time',
                                                    'startTime',
                                                  ])
                                                ),
                                                toMoment(
                                                  session.getIn([
                                                    'time',
                                                    'endTime',
                                                  ])
                                                )
                                              )((startTime, endTime) =>
                                                session
                                                  .set('id', sessionId)
                                                  .delete('time')
                                                  .delete('track')
                                                  .set('startTime', startTime)
                                                  .set('endTime', endTime)
                                                  .set(
                                                    'tags',
                                                    session.get('tags')
                                                  )
                                                  .set(
                                                    'trackName',
                                                    track.get('name')
                                                  )
                                                  .set(
                                                    'duration',
                                                    Math.abs(
                                                      startTime.diff(
                                                        endTime,
                                                        'minutes'
                                                      )
                                                    )
                                                  )
                                                  .update(
                                                    'speakers',
                                                    Map(),
                                                    ss =>
                                                      ss
                                                        .keySeq()
                                                        .map(id =>
                                                          (speakers || Map())
                                                            .get(id, Map())
                                                            .set('id', id)
                                                        )
                                                        .sortBy(speaker =>
                                                          speaker.get('order')
                                                        )
                                                        .map(speaker =>
                                                          speaker
                                                            .update(
                                                              'color',
                                                              color =>
                                                                color ||
                                                                '#f0f0f0'
                                                            )
                                                            .delete('order')
                                                        )
                                                        .toList()
                                                  )
                                                  .update('sessionType', id =>
                                                    sessionTypes
                                                      .get(id, Map())
                                                      .set('id', id)
                                                  )
                                              )
                                            )
                                            .sortBy(session =>
                                              session.get('startTime').valueOf()
                                            )
                                            .toList()
                                        : List()
                                    )
                                )
                                .toList()
                            : Map(),
                          breaks: breaks
                            ? breaks
                                .filter(breakObj => breakObj.get('day') === day)
                                .map((breakObj, breakId) =>
                                  using(
                                    toMoment(
                                      breakObj.getIn(['time', 'startTime'])
                                    ),
                                    toMoment(
                                      breakObj.getIn(['time', 'endTime'])
                                    )
                                  )((startTime, endTime) =>
                                    breakObj
                                      .set('id', breakId)
                                      .delete('time')
                                      .set('startTime', startTime)
                                      .set('endTime', endTime)
                                      .set(
                                        'duration',
                                        Math.abs(
                                          startTime.diff(endTime, 'minutes')
                                        )
                                      )
                                      .update('speakers', Map(), ss =>
                                        ss
                                          .keySeq()
                                          .map(id =>
                                            (speakers || Map())
                                              .get(id, Map())
                                              .set('id', id)
                                          )
                                          .sortBy(speaker =>
                                            speaker.get('order')
                                          )
                                          .map(speaker =>
                                            speaker
                                              .update(
                                                'color',
                                                color => color || '#f0f0f0'
                                              )
                                              .delete('order')
                                          )
                                          .toList()
                                      )
                                  )
                                )
                                .sortBy(breakObj =>
                                  breakObj.get('startTime').valueOf()
                                )
                                .toList()
                            : List(),
                        })
                    )
                  )
              )
              .set(
                'sessions',
                sessions
                  ? sessions.map((session, sessionId) =>
                      using(
                        timeToMoment(
                          dayOneMoment.clone().add(session.get('day'), 'days')
                        )
                      )(toMoment =>
                        using(
                          toMoment(session.getIn(['time', 'startTime'])),
                          toMoment(session.getIn(['time', 'endTime']))
                        )((startTime, endTime) =>
                          session
                            .set('id', sessionId)
                            .delete('time')
                            .set('startTime', startTime)
                            .set('endTime', endTime)
                            .set(
                              'duration',
                              Math.abs(startTime.diff(endTime, 'minutes'))
                            )
                            .update('speakers', Map(), ss =>
                              ss
                                .keySeq()
                                .map(id =>
                                  (speakers || Map())
                                    .get(id, Map())
                                    .set('id', id)
                                )
                                .sortBy(speaker => speaker.get('order'))
                                .map(speaker =>
                                  speaker
                                    .update(
                                      'color',
                                      color => color || '#f0f0f0'
                                    )
                                    .delete('order')
                                )
                                .toList()
                            )
                            .update('questions', Map(), ss =>
                              ss
                                .keySeq()
                                .map(seq =>
                                  session
                                    .getIn(['questions', seq])
                                    .set('id', seq)
                                )
                                .sortBy(question => question.get('timestamp'))
                                .reverse()
                                .toList()
                            )
                            .update('ratings', Map(), ss =>
                              ss
                                .keySeq()
                                .map(seq => session.getIn(['ratings', seq]))
                                .toList()
                            )
                            .update('sessionType', id =>
                              sessionTypes.get(id, Map()).set('id', id)
                            )
                            .update('track', trackId =>
                              tracks
                                .get(trackId, Map())
                                .set('id', trackId)
                                .delete('order')
                            )
                        )
                      )
                    )
                  : List()
              )
              .set(
                'speakers',
                speakers
                  ? speakers
                      .toOrderedMap()
                      .sortBy(speaker => speaker.get('order'))
                      .map((speaker, speakerId) =>
                        speaker
                          .set('id', speakerId)
                          .update('color', color => color || '#f0f0f0')
                          .delete('order')
                          .set(
                            'sessions',
                            sessions
                              ? sessions
                                  .filter(session =>
                                    session
                                      .get('speakers', Map())
                                      .keySeq()
                                      .some(id => id === speakerId)
                                  )
                                  .map((session, sessionId) =>
                                    using(
                                      ...using(
                                        timeToMoment(
                                          dayOneMoment
                                            .clone()
                                            .add(session.get('day'), 'days')
                                        )
                                      )(toMoment => [
                                        toMoment(
                                          session.getIn(['time', 'startTime'])
                                        ),
                                        toMoment(
                                          session.getIn(['time', 'endTime'])
                                        ),
                                      ])
                                    )((startTime, endTime) =>
                                      session
                                        .set('id', sessionId)
                                        .delete('time')
                                        .delete('day')
                                        .set('startTime', startTime)
                                        .set('endTime', endTime)
                                        .set(
                                          'duration',
                                          Math.abs(
                                            startTime.diff(endTime, 'minutes')
                                          )
                                        )
                                        .update('speakers', Map(), ss =>
                                          ss
                                            .keySeq()
                                            .map(id =>
                                              (speakers || Map())
                                                .get(id, Map())
                                                .set('id', id)
                                            )
                                            .sortBy(s => s.get('order'))
                                            .map(s =>
                                              s
                                                .update(
                                                  'color',
                                                  color => color || '#f0f0f0'
                                                )
                                                .delete('order')
                                            )
                                            .toList()
                                        )
                                        .update('sessionType', id =>
                                          sessionTypes
                                            .get(id, Map())
                                            .set('id', id)
                                        )
                                        .update('track', trackId =>
                                          tracks
                                            .get(trackId, Map())
                                            .set('id', trackId)
                                            .delete('order')
                                        )
                                    )
                                  )
                                  .sortBy(session =>
                                    session.get('startTime').valueOf()
                                  )
                                  .toList()
                              : List()
                          )
                      )
                  : List()
              )
              .set(
                'sponsorGroups',
                sponsorGroups
                  ? sponsorGroups
                      .toOrderedMap()
                      .sortBy(sponsorGroup => sponsorGroup.get('order'))
                      .map((sponsorGroup, sponsorGroupId) =>
                        sponsorGroup
                          .set('id', sponsorGroupId)
                          .delete('order')
                          .set(
                            'sponsors',
                            (sponsors || List())
                              .filter(
                                sponsor =>
                                  sponsor.get('group') === sponsorGroupId
                              )
                              .toOrderedMap()
                              .sortBy(sponsor => sponsor.get('order'))
                              .map((sponsor, sponsorId) =>
                                sponsor
                                  .set('id', sponsorId)
                                  .delete('order')
                                  .delete('group')
                              )
                              .toList()
                          )
                      )
                      .filter(
                        sponsorGroup =>
                          sponsorGroup.get('sponsors', List()).count() > 0
                      )
                      .toList()
                  : List()
              )
              .set('theme', theme)
              .set('timezone', timezone)
              .set(
                'announcements',
                announcements
                  ? Map(announcements)
                      .valueSeq()
                      .toList()
                      .filter(annon =>
                        newerThan(annon.get('timestamp'), 30, timezone)
                      )
                      .sortBy(a => a.get('timestamp'))
                      .reverse()
                  : List()
              )
              .set(
                'tracks',
                tracks
                  ? Map(tracks)
                      .map((track, id) => track.set('id', id))
                      .valueSeq()
                      .toList()
                      .sortBy(track => track.get('order'))
                  : Map()
              )
              .set('users', users ? Map(users) : Map())
              .set('configuration', configuration ? Map(configuration) : Map())
              .set('embedLayout', embedLayout)
              .set('mobileEmbedLayout', mobileEmbedLayout)
              .set(
                'subscriptionPlan',
                subscriptionPlan ? subscriptionPlan : 'free'
              )
          )
      );
    case ADD_TAG_FILTER:
      return state.update('tagFilters', tagFilters =>
        tagFilters.push(action.tagFilter)
      );
    case ADD_SPEAKER_FILTER:
      return state.update('speakerFilters', speakerFilters =>
        speakerFilters.push(action.speakerFilter)
      );
    default:
      return state;
  }
}

export default embedReducer;
