/*
 * SpeakersPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import Flexbox from 'flexbox-react';
import { Map, List } from 'immutable';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
} from '../../selectors/user';

import messages from './messages';
import getTwitterImage from '../../utils/ui/getTwitterImage';

import reorderSpeakers from '../../sagas/user/speakers/reorder';

import Breadcrumb from '../../components/Breadcrumb';
import TooltipButton from '../../components/TooltipButton';
import { StickyActionBar } from '../../components/ActionBar';
import MultipleButtons from '../../components/buttons/MultipleButtons';
import EntityEmptyState from '../../components/EntityEmptyState';
import emptyStateIllustration from '../../assets/images/empty-state-speakers.svg';

import ModalRoute from '../../components/ModalRoute';
import EmbedWidgetGuidePopup from '../EmbedWidgetGuidePopup';
import CreateSpeakerPage from '../../containers/CreateSpeakerPage';
import EditSpeakerPage from '../../containers/EditSpeakerPage';
import Button from '../../components/buttons/Button';

import drag from '../../components/TooltipCards/assets/drag.png';

import Speaker from './Speaker';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

class SpeakersPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.any.isRequired,
    conferenceName: PropTypes.any.isRequired,
    speakers: PropTypes.any.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    onEnd: () => this.setState({ dragging: false }),
  });

  static handleDrag() {
    this.setState({
      dragging: true,
    });
  }

  static handleMove(from, to) {
    const { speakers } = this.state;

    let i;
    let e;

    if (from < to) {
      e = speakers[from];
      for (i = from; i < to; i += 1) {
        speakers[i] = speakers[i + 1];
      }
      speakers[to] = e;

      this.setState({ speakers });
    } else if (from > to) {
      e = speakers[from];
      for (i = from; i > to; i -= 1) {
        speakers[i] = speakers[i - 1];
      }
      speakers[to] = e;

      this.setState({ speakers });
    }
  }

  static async handleDrop() {
    await this.defaultUserRequestExecutor(
      reorderSpeakers,
      this.props.conferenceHandle,
      this.state.speakers.map(speaker => speaker.id)
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      speakers: props.speakers,
    };

    this.handleDrag = SpeakersPage.handleDrag.bind(this);
    this.handleMove = SpeakersPage.handleMove.bind(this);
    this.handleDrop = SpeakersPage.handleDrop.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.dragging) {
      this.setState({
        speakers: nextProps.speakers,
      });
    }
  }

  render() {
    const { conferenceHandle, conferenceName, match, intl } = this.props;
    const { speakers } = this.state;

    const optimalSize = 240;

    return (
      <div>
        <TooltipButton
          title="Speakers page"
          body="Here is the place for your speakers!"
          body2="Easily reorginize them by clicking and dragging."
          secondImage={drag}
        />
        <Helmet title={`${conferenceName} Speakers • Sava Events`} />
        <Breadcrumb to={`/@${conferenceHandle}/speakers`}>Speakers</Breadcrumb>
        <Flexbox flexDirection="column">
          <StickyActionBar justifyContent="flex-end">
            <MultipleButtons justifyContent="flex-end">
              {speakers.length > 0 && (
                <Button
                  to={`/@${conferenceHandle}/speakers/new`}
                  icon="add"
                  label="Add Speaker"
                  type="primary"
                />
              )}
              <Button
                to={`/@${conferenceHandle}/speakers/embed`}
                icon="code"
                label="Get Speakers Code"
                type="secondary"
              />
            </MultipleButtons>
          </StickyActionBar>
          <Flexbox
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            style={{ margin: '10px' }}
          >
            {speakers && speakers.length === 0 && (
              <EntityEmptyState
                illustration={emptyStateIllustration}
                title={intl.formatMessage(messages.speakersEmptyState)}
                hint={intl.formatMessage(messages.speakersEmptyStateHint)}
              >
                <Button
                  to={`/@${conferenceHandle}/speakers/new`}
                  icon="add"
                  label="Add Speaker"
                  type="primary"
                />
              </EntityEmptyState>
            )}
            {speakers.map((speaker, i) => (
              <Speaker
                key={speaker.id}
                index={i}
                onDrag={this.handleDrag}
                onMove={this.handleMove}
                onDrop={this.handleDrop}
                optimalSize={optimalSize}
                conferenceHandle={conferenceHandle}
                speaker={speaker}
              />
            ))}
          </Flexbox>
        </Flexbox>

        <ModalRoute
          exact={true}
          path={`${match.path}/embed`}
          params={{ context: 'speakers' }}
          component={EmbedWidgetGuidePopup}
        />
        <ModalRoute
          exact={true}
          path={`${match.path}/new`}
          component={CreateSpeakerPage}
        />
        <ModalRoute
          exact={true}
          path={`${match.path}/:id`}
          except={[`${match.path}/embed`, `${match.path}/new`]}
          component={EditSpeakerPage}
        />
      </div>
    );
  }
}

const selectSpeakers = createSelector(selectCurrentConference, conference =>
  conference
    .get('speakers', Map())
    .entrySeq()
    .map(([k, v]) => v.set('id', k))
    .map(speaker => {
      const twitter = speaker
        .get('profiles', List())
        .find(profile => profile.get('icon') === 'twitter');
      if (!speaker.get('image') && twitter) {
        return speaker.set('image', getTwitterImage(twitter.get('link')));
      }
      return speaker;
    })
    .toJS()
);

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectSpeakers,
  (conferenceHandle, conferenceName, speakers) => ({
    conferenceHandle,
    conferenceName,
    speakers,
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  withUserRequestExecutor
)(injectIntl(SpeakersPage));
