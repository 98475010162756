import { ButtonLink, DayPicker } from '../../containers/ConferencePage';
import React from 'react';
import styled from 'styled-components';
import { isFunction } from 'is-of-type';

const NavigationWrapper = styled.div`
  background-color: ${props => props.theme.page.backgroundColor};
  width: 100%;
  display: flex;
  justify-content: ${props => (props.centered ? 'center' : 'flex-end')};
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0 1em;
`;

const Navigation = ({
  conferenceHandle,
  dayIndex,
  days,
  onClick,
  provideLink,
  centered,
}) => {
  const linkProvider = dayIndex => {
    if (isFunction(provideLink)) {
      return provideLink(dayIndex);
    }

    return `/@${conferenceHandle}/embed/schedule/days/${dayIndex}`;
  };

  return (
    <NavigationWrapper centered={centered}>
      <DayPicker style={{ margin: '5px 10px' }}>
        {days.map((d, i) => (
          <ButtonLink
            key={i}
            to={linkProvider(i)}
            data={{ active: dayIndex === i }}
            onClick={onClick(i)}
          >
            Day {i + 1}
          </ButtonLink>
        ))}
      </DayPicker>
    </NavigationWrapper>
  );
};

export default Navigation;
