import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/messaging';

const firebaseConfig = require(`sava-shared/lib/config/${
  process.env.REACT_APP_GCLOUD_PROJECT
}`).default;

firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV !== 'production') {
  window.firebase = firebase;
}
