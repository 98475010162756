import { buffers, eventChannel, END } from 'redux-saga';
import { all, race, take, put } from 'redux-saga/effects';
import firebase from 'firebase/app';
import { fromJS, List, Map } from 'immutable';
import { push } from 'react-router-redux';
import consume from '../consume';
import loop from '../loop';
import { SUBMIT_QUESTION, SET_QUESTION_ARCHIVED } from '../../constants/embed';

import { acceptConference } from '../../actions/embed';
import omitBy from '../../utils/omitBy';
import isUndefined from '../../utils/isUndefined';

const commonProperties = [
  'dates',
  'embedLayout',
  'mobileEmbedLayout',
  'sessionTypes',
  'sessions',
  'speakers',
  'sponsorGroups',
  'sponsors',
  'theme',
  'tracks',
  'breaks',
  'announcements',
  'timezone',
  'configuration',
  'subscriptionPlan',
];

const properties = List([...commonProperties]);

const authenticatedProperties = List([...commonProperties, 'users']);

const channelProvider = (field, id) =>
  eventChannel(emitter => {
    const path = `/conferences/${decodeURI(id)}/${field}`.replace(/\/+/g, '/');

    if (window.localStorage) {
      const value = window.localStorage.getItem(`firebaseCache:${path}`);

      if (value) {
        const val = JSON.parse(value);

        emitter((val || false) && fromJS(val));
      }
    }

    const reference = firebase.database().ref(path);

    const handler = value => {
      const val = value.val();

      if (window.localStorage) {
        window.localStorage.setItem(
          `firebaseCache:${path}`,
          JSON.stringify(val)
        );
      }

      emitter((val || false) && fromJS(val));
    };

    const errorHandler = () => emitter(END);

    reference.on('value', handler, errorHandler);

    return () => reference.off('value', handler);
  }, buffers.sliding(5));

export function conferenceMonitorSagaProvider(handle, isAuthenticated) {
  return function* conferenceMonitorSaga() {
    const channels = (isAuthenticated
      ? authenticatedProperties
      : properties
    ).reduce(
      (map, property) => map.set(property, channelProvider(property, handle)),
      Map()
    );

    try {
      const conference = yield all(channels.map(take).toJS());
      yield put(acceptConference(Map(conference)));

      while (true) {
        // eslint-disable-next-line redux-saga/no-yield-in-race
        const newConference = yield race(channels.map(take).toJS());

        yield put(acceptConference(Map(conference).merge(newConference)));
      }
    } finally {
      channels.forEach(channel => channel.close());
    }
  };
}

// When the parent window that embeds this changes the hash we should
// listen and update the route in the iframe as well
const parentWindowHashChangeChannel = eventChannel(emitter => {
  const handler = e => {
    const { data: { pathname } = {} } = e;
    // changes path based on the hashChange in the parent window
    pathname && emitter(pathname);
  };

  window.addEventListener('message', handler);

  return () => window.removeEventListener('message', handler);
}, buffers.sliding(5));

function* parentWindowHashChangeHandlerSaga(pathname) {
  yield put(push(pathname || '/'));
}

export function* sessionQuestionSaga({
  conferenceHandle,
  sessionId,
  question,
  userId,
  client,
}) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sessions/${sessionId}/questions`)
    .push(
      omitBy(
        { question, userId, client, timestamp: new Date().getTime() },
        isUndefined
      )
    );
}

export function* sessionArchiveQuestionSaga({
  conferenceHandle,
  sessionId,
  questionId,
  archived
}) {
  console.log(conferenceHandle, sessionId, questionId, archived)

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sessions/${sessionId}/questions/${questionId}`)
    .update({archived});
}

export default (conferenceHandle, isAuthenticated) => [
  conferenceMonitorSagaProvider(conferenceHandle, isAuthenticated),
  loop(SUBMIT_QUESTION, sessionQuestionSaga),
  loop(SET_QUESTION_ARCHIVED, sessionArchiveQuestionSaga),
  consume(parentWindowHashChangeChannel, parentWindowHashChangeHandlerSaga),
];
