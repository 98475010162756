import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import CommentForm from './CommentForm';
import Reactions from './Reactions';
import { statusColors } from './styled';
import toArray from '../utils/toArray';
import ModalRoute from '../../../components/ModalRoute';
import { Tag } from '../../../components/Tags';

const LabelField = styled.p`
  margin-bottom: 0.25em;
  opacity: 0.7;
  font-weight: 700;
`;

const ValueField = styled.div`
  white-space: pre-line;
  padding: 0.5em;
  background-color: ${p => p.theme.colors.lightGray};
`;

const statusToLabel = {
  new: 'New!',
  review: 'In Review',
  accepted: 'Accepted',
  confirmed: 'Confirmed',
  rejected: 'Rejected',
};

const StatusTag = Tag.withComponent('span').extend`
  background-color: ${props => statusColors[props.status]};
  color: #fff;
  border-radius: 4px;
  padding: 0.4rem;
  opacity: 1;
  vertical-align: super;
  font-size: 0.8em;
  font-weight: 700;
`;

const DateTimeTag = styled.span`
  font-size: 0.8em;
  vertical-align: super;
`;

const Comment = styled.div`
  border-radius: 3px;
  margin-bottom: 0.5em;
  padding: 0.5em;
  border: 1px solid ${p => p.theme.colors.lightGray};
`;

const CommentContent = styled.div`
  white-space: pre-line;
`;

const AuthorDisplayName = styled.span`
  font-size: 0.8em;
  opacity: 0.8;
`;

const getDateTime = timestamp =>
  timestamp ? moment(timestamp).format('LLL') : '';

class SubmissionView extends Component {
  render() {
    const submission = (this.props.submissions || []).find(
      _ => _.id === this.props.match.params.id
    );
    if (!submission) {
      return (
        <div>
          <h4>404</h4>
          <p>Submission not found.</p>
        </div>
      );
    }
    const comments = toArray(submission.comments).sort(
      (l, r) => l.submittedAt - r.submittedAt
    );
    const reactions = toArray(submission.reactions);

    return (
      <div>
        <StatusTag status={submission.status}>
          {statusToLabel[submission.status]}
        </StatusTag>
        <DateTimeTag>{getDateTime(submission.submittedAt)}</DateTimeTag>
        {submission.responses.map((response, index) => (
          <div key={index}>
            <LabelField>{response.label}</LabelField>
            <ValueField>{response.value || 'No Response'}</ValueField>
          </div>
        ))}
        <div>
          <LabelField>Reactions</LabelField>
          <Reactions
            reactions={reactions}
            submissionId={submission.id}
            currentUserId={this.props.currentUserId}
            onSubmit={this.props.onSubmitReaction}
          />
          <LabelField>Comments</LabelField>
          {comments.map(comment => (
            <Comment key={comment.id}>
              <AuthorDisplayName>
                {comment.authorDisplayName},{' '}
                {moment(comment.submittedAt).fromNow()}
              </AuthorDisplayName>
              <CommentContent>{comment.content}</CommentContent>
            </Comment>
          ))}
          <CommentForm
            submissionId={submission.id}
            onSubmit={this.props.onSubmitComment}
          />
        </div>
      </div>
    );
  }
}

class SubmissionRoute extends Component {
  render() {
    return (
      <ModalRoute
        exact
        path={`${this.props.match.path}/:id`}
        component={props => (
          <SubmissionView
            {...props}
            currentUserId={this.props.currentUserId}
            submissions={this.props.submissions}
            onSubmitComment={this.props.onSubmitComment}
            onSubmitReaction={this.props.onSubmitReaction}
          />
        )}
        modalProps={{ style: { width: 640 } }}
      />
    );
  }
}

export default withRouter(SubmissionRoute);
