import { Map } from 'immutable';

const identity = value => value;
const areFalse = value => !value;
const inc = value => value + 1;

export default headlines => {
  const candidates = headlines.map(headline => {
    const chunks =
      (headline || '').match(/\s(at|from|@|for)\s([.\s\w\d]+)$/) || [];

    const candidate = chunks[2];

    const worksOn = (candidate || '').indexOf('working on');

    return worksOn > 0 ? candidate.slice(0, worksOn) : candidate;
  });

  const companies = candidates
    .filter(identity)
    .reduce((map, candidate) => map.update(candidate, 0, inc), Map())
    .entrySeq()
    .sortBy(entry => -entry[1])
    .map(entry => entry[0])
    .toList();

  const unknownExists = candidates.some(areFalse);

  if (headlines.count() === 1 && companies.count() === 0) {
    return headlines.get(0);
  }

  if (companies.count() === 0) {
    return '';
  }

  if (companies.count() === 1) {
    return `${companies.first()}${unknownExists ? ', and more' : ''}`;
  }

  const tokens = unknownExists ? companies.push('more') : companies;

  return `${tokens.butLast().join(', ')}, and ${tokens.last()}`;
};
