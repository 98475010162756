/*
 * ConferenceForm Messages
 *
 * This contains all the text for the ConferenceForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  handle: {
    id: 'app.components.ConferenceForm.handle',
    defaultMessage: 'Event Code',
  },
  handlePlaceholder: {
    id: 'app.components.ConferenceForm.handlePlaceholder',
    defaultMessage: `e.g., GTD${new Date().getFullYear()}`,
  },
  name: {
    id: 'app.components.ConferenceForm.name',
    defaultMessage: 'Event Name',
  },
  namePlaceholder: {
    id: 'app.components.ConferenceForm.namePlaceholder',
    defaultMessage: `e.g., Global Tech Disrupt ${new Date().getFullYear()}`,
  },
  dates: {
    id: 'app.components.ConferenceForm.dates',
    defaultMessage: 'Dates',
  },
  timezone: {
    id: 'app.components.ConferenceForm.timezone',
    defaultMessage: 'Timezone',
  },
  tracks: {
    id: 'app.components.ConferenceForm.tracks',
    defaultMessage: 'Tracks',
  },
  sessionTypes: {
    id: 'app.components.ConferenceForm.sessionTypes',
    defaultMessage: 'Session Types',
  },
  save: {
    id: 'app.components.ConferenceForm.save',
    defaultMessage: 'Save',
  },
  embedLayout: {
    id: 'app.components.ConferenceForm.embedLayout',
    defaultMessage: 'Desktop Embed Layout',
  },
  mobileEmbedLayout: {
    id: 'app.components.ConferenceForm.mobileEmbedLayout',
    defaultMessage: 'Mobile Embed Layout',
  },
});
