/**
 *
 * ModalRoute
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { injectGlobal } from 'styled-components';

import OverlayModal from '../../components/OverlayModal';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  .childRoute-enter {
    opacity: 0.01;
  }

  .childRoute-enter.childRoute-enter-active {
    opacity: 1;
    transition: opacity 160ms ease-in-out;
  }

  .childRoute-leave {
    opacity: 1;
  }

  .childRoute-leave.childRoute-leave-active {
    opacity: 0.01;
    transition: opacity 120ms ease-in-out;
  }
  
  .childRoute-enter > div {
    transform: translate(0, -40px);
  }

  .childRoute-enter.childRoute-enter-active > div {
    transform: translate(0, 0);
    transition: transform 160ms ease-in-out;
  }

  .childRoute-leave > div {
    transform: translate(0, 0);
  }

  .childRoute-leave.childRoute-leave-active > div {
    transform: translate(0, -40px);
    transition: transform 120ms ease-in-out;
  }
`;

const Modal = props => (
  <ReactCSSTransitionGroup
    transitionName="childRoute"
    transitionEnterTimeout={320}
    transitionLeaveTimeout={240}
  >
    {props.children && <OverlayModal {...props} />}
  </ReactCSSTransitionGroup>
);

Modal.propTypes = {
  children: PropTypes.any,
};

export default Modal;
