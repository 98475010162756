/**
 *
 *  SessionTypePicker
 *
 */

import { Map } from 'immutable';

import SelectOrCreate from '../SelectOrCreate';
import SessionTypeInput from '../SessionTypeInput';
import SessionTypeView from '../SessionTypeView';

/* eslint-disable */
export default SelectOrCreate(SessionTypeInput, SessionTypeView, state =>
  state
    .getIn(['user', 'userData', 'currentConference', 'sessionTypes'], Map())
    .entrySeq()
    .map(([k, v]) => v.set('id', k))
    .toJS()
);
/* eslint-enable */
