import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import SpeakerImage from '../SpeakerImage';

const Name = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0;
  text-align: center;
  line-height: 1.4;
  max-height: 3em;
`;

const About = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 0.85em;
  text-align: center;
  opacity: 0.65;
  height: 3em;
`;

export default class Speaker extends Component {
  static propTypes = {
    size: PropTypes.number.isRequired,
    speaker: ImmutablePropTypes.contains({
      id: PropTypes.string,
      name: PropTypes.string,
      headline: PropTypes.string,
      color: PropTypes.string,
      image: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { size, speaker } = this.props;

    return (
      <Flexbox
        flexDirection="column"
        alignItems="center"
        style={{
          width: size,
          paddingLeft: size / 32,
          paddingRight: size / 32,
          paddingTop: size / 8,
        }}
      >
        <SpeakerImage
          image={speaker.get('image')}
          backgroundColor={speaker.get('color', '#f0f0f0')}
          type="medium"
        />
        <Name>{speaker.get('name', <span>&nbsp;</span>)}</Name>
        <About>
          {speaker.get(
            'headline',
            <span style={{ visibility: 'hidden' }}>–</span>
          )}
        </About>
      </Flexbox>
    );
  }
}
