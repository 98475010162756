/**
 *
 *  SessionForm
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';
import { fromJS } from 'immutable';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';
import validateForm from './validate';

import CollectionInput from '../CollectionInput';
import DayInput from '../DayInput';
import DeleteButton from '../DeleteButton';
import Form from '../Form';
import RichTextInput from '../RichTextInput';
import { CollectionItem as SpeakerPickerCollectionItem } from '../SpeakerPicker';
import SessionTypePicker from '../SessionTypePicker';
import TimeRangeInput from '../TimeRangeInput';
import TrackPicker from '../TrackPicker';
import TagsInput from '../TagsInput';
import {
  ListWrapper as SpeakerPickerListWrapper,
  ItemWrapper as SpeakerPickerItemWrapper,
} from '../SpeakerPicker/CollectionInput';

import { selectCurrentConference } from '../../selectors/user';
import addDurationToTime from '../../utils/addDurationToTime';
import { button } from '../../utils/ui/interactive';
import { selectProps } from '../../selectors/common';

const Button = styled.button`
  padding: 12px;
  ${button()};
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SpeakersPicker = CollectionInput(
  SpeakerPickerCollectionItem,
  SpeakerPickerListWrapper,
  SpeakerPickerItemWrapper
);

/**
 * Update other form values after the session type changes.
 * @param {string} sessionTypeId
 * @param {Object} props
 */
const postSessionTypeChange = (sessionTypeId, props = {}) => {
  if (
    props.formValues &&
    props.formValues.startTime &&
    props.change &&
    props.sessionTypes &&
    props.sessionTypes[sessionTypeId] &&
    props.sessionTypes[sessionTypeId].duration
  ) {
    props.change(
      'time.endTime',
      addDurationToTime(
        props.formValues.startTime,
        Number(props.sessionTypes[sessionTypeId].duration)
      )
    );
  }
};

const SessionForm = ({
  conferenceHandle,
  onDelete,
  isCreateForm,
  handleSubmit,
  pristine,
  submitting,
  intl,
  ...props
}) => (
  <Form onSubmit={handleSubmit}>
    {!isCreateForm && (
      <Field name="id" component="input" type="hidden" required />
    )}
    <fieldset>
      <FormattedMessage {...messages.sessionType} />
      <Field
        name="sessionType"
        component={SessionTypePicker}
        props={{
          conferenceHandle,
          postChange: id => postSessionTypeChange(id, props),
        }}
        required
      />
    </fieldset>

    <fieldset>
      <FormattedMessage {...messages.track} />
      <Field
        name="track"
        component={TrackPicker}
        props={{ conferenceHandle }}
        required
      />
    </fieldset>

    <Flexbox flexDirection="row">
      <fieldset>
        <FormattedMessage {...messages.day} />
        <Field
          name="day"
          component={DayInput}
          props={{ conferenceHandle }}
          required
        />
      </fieldset>

      <Flexbox style={{ width: 20 }} />

      <fieldset>
        <FormattedMessage {...messages.time} />
        <Field name="time" component={TimeRangeInput} required />
      </fieldset>
    </Flexbox>

    <fieldset>
      <FormattedMessage {...messages.title} />
      <Field
        name="title"
        component="input"
        type="text"
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
        style={{ fontSize: 22 }}
        required
        autoFocus
      />
    </fieldset>

    <fieldset>
      <FormattedMessage {...messages.description} />
      <Field
        name="description"
        component={RichTextInput}
        style={styles.description}
        required
      />
    </fieldset>

    <fieldset>
      <FormattedMessage {...messages.tags} />
      <Field name="tags" component={TagsInput} required />
    </fieldset>

    <fieldset>
      <FormattedMessage {...messages.speakers} />
      <FieldArray
        name="speakers"
        component={SpeakersPicker}
        props={{ props: { conferenceHandle } }}
      />
    </fieldset>

    <fieldset>
      <FormattedMessage {...messages.video} />
      <Field
        name="video"
        component="input"
        type="text"
        placeholder={intl.formatMessage(messages.videoPlaceholder)}
      />
    </fieldset>

    <ActionBar>
      <Button disabled={pristine || submitting}>
        <FormattedMessage
          {...(isCreateForm ? messages.create : messages.update)}
        />
      </Button>

      {!isCreateForm && (
        <DeleteButton type="reset" onClick={onDelete}>
          <FormattedMessage {...messages.delete} />
        </DeleteButton>
      )}
    </ActionBar>
  </Form>
);

SessionForm.propTypes = {
  conferenceHandle: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

const styles = {
  description: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    padding: '15px',
  },
};

const formValues = formValueSelector('session');

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectProps,
  state => ({
    startTime: formValues(state, 'time.startTime'),
  }),
  (conference, props, values) => {
    const state = {};
    if (props.isCreateForm) {
      const day = Number(props.initialValues.get('day'));
      const track = props.initialValues.get('track') || null;
      const times = conference.has('sessions')
        ? conference
            .get('sessions')
            .filter(session => session.get('day') === day)
            .filter(session => session.get('track') === track)
            .map(session => session.getIn(['time', 'endTime']))
            .toArray()
            .sort()
        : [];
      const defaultSessionType = conference.has('sessionTypes')
        ? conference
            .get('sessionTypes')
            .keySeq()
            .first()
        : null;

      const startTime = times[times.length - 1] || '10:00';
      const endTime = addDurationToTime(
        startTime,
        defaultSessionType
          ? Number(
              conference.getIn(['sessionTypes', defaultSessionType, 'duration'])
            )
          : 45
      );

      state.initialValues = fromJS({
        sessionType: {
          id: defaultSessionType,
        },
        track: {
          id: track,
        },
        time: {
          startTime,
          endTime,
        },
        day,
      });
    }
    state.sessionTypes = conference.has('sessionTypes')
      ? conference.get('sessionTypes').toJS()
      : {};
    state.formValues = values;
    return state;
  }
);

export default connect(mapStateToProps)(
  reduxForm({
    form: 'session',
    initialValues: fromJS({
      sessionType: { id: null },
      track: { id: null },
      tags: [],
      time: {},
      video: null,
    }),
    validate: values => validateForm(values.toJS()),
  })(injectIntl(SessionForm))
);
