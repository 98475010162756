import { call } from 'redux-saga/effects';

import { BASE_URL } from './common';
import createFetchResource from '../../createFetchResource';

export default function* getBroadcastReached(
  messengerBroadcastId,
  setReached,
  conferenceHandle
) {
  const getFacebookDataFromDatabase = createFetchResource(
    `conferences/${conferenceHandle}/facebook`,
    { fallback: {}, throwError: false }
  );
  const facebookData = yield getFacebookDataFromDatabase();
  if (facebookData) {
    const { pageToken } = facebookData;
    if (messengerBroadcastId && pageToken) {
      const url = `${BASE_URL}/${messengerBroadcastId}/insights/messages_sent?access_token=${pageToken}`;
      const response = yield fetch(url);
      const result = yield response.json();
      if (
        result &&
        result.data &&
        result.data.length > 0 &&
        result.data[0].values &&
        result.data[0].values.length > 0
      ) {
        yield call(setReached, result.data[0].values[0].value);
      }
    }
  }
}
