import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Map, List } from 'immutable';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';

import {
  selectConferenceHandle,
  selectDay,
  selectSessions,
  selectDays,
} from '../../selectors/embed';
import { selectProp } from '../../selectors/common';
import Helmet from 'react-helmet';
import { ButtonLink, DayPicker } from '../ConferencePage';
import responsive from '../../utils/ui/responsive';
import Session from '../../components/Session';
import EntityEmptyState, {
  EmptyStateMarginWrapper,
} from '../../components/EntityEmptyState';
import emptyStatePastIllustration from '../../assets/images/empty-state-schedule.svg';
import withRefreshInterval from '../../hocs/withRefreshInterval';
import PoweredBy from '../../components/PoweredBy';
import withNow from "../../hocs/withNow";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Navigation = styled.div`
  background-color: ${props => props.theme.page.backgroundColor};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0 1em;
  ${responsive.sm.andSmaller`
    padding: 0;
    line-height: 1;
    > h2 {
      font-size: 1rem;
      margin-left: 0;
    }
  `};
`;

const PastSessions = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => props.theme.page.color};
`;

const Day = styled.section`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding: 0 2em;
  ${responsive.sm.andSmaller`
    padding: 0 1em;
    margin: 0;
  `};
`;

const ResponsiveSessions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    margin: 1.25rem;
    width: 25rem;
  }
`;

const CurrentSessionsPage = ({
  conferenceHandle,
  days,
  dayIndex,
  sessions,
  linkProvider,
  pastSessions,
}) => {
  const getSessionProps = (session, isPastEffectEnabled) => ({
    key: session.get('id'),
    session,
    conferenceHandle,
    linkProvider,
    showTrack: true,
    boldTrack: true,
    hideDuration: true,
    isPastEffectEnabled,
  });
  return (
    <Container>
      <Helmet title={`Questions - ${conferenceHandle}`} />

      <Navigation>
        <DayPicker style={{ margin: '5px 10px' }}>
          {days.map((day, i) => (
            <ButtonLink
              key={i}
              to={linkProvider('days', { conferenceHandle, dayIndex: i })}
              data={{ active: Number(dayIndex) === i }}
              style={{ minWidth: '5rem', textAlign: 'center' }}
            >
              Day {i + 1}
            </ButtonLink>
          ))}
        </DayPicker>
      </Navigation>

      {!sessions.size && !pastSessions.size ? (
        <EmptyStateMarginWrapper>
          <EntityEmptyState
            illustration={emptyStatePastIllustration}
            title={'Grab a cup of ☕'}
            hint={'And keep an eye for new sessions.'}
          />
        </EmptyStateMarginWrapper>
      ) : (
        <Day>
          <ResponsiveSessions>
            {sessions.map(session => (
              <Session {...getSessionProps(session, false)} />
            ))}
          </ResponsiveSessions>
          {pastSessions.size ? (
            <PastSessions>Past Sessions</PastSessions>
          ) : null}
          <ResponsiveSessions>
            {pastSessions.map(session => (
              <Session {...getSessionProps(session, true)} />
            ))}
          </ResponsiveSessions>
          <PoweredBy />
        </Day>
      )}
    </Container>
  );
};

CurrentSessionsPage.propTypes = {};

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectDay,
  selectDays,
  selectSessions,
  selectProp('showAllPassedSessions'),
  (
    conferenceHandle,
    dayIndex,
    days,
    sessions,
    showAllPassedSessions = false
  ) => {
    console.log(days.toJS(), dayIndex)
    return {
      conferenceHandle,
      dayIndex,
      days,
      sessions: List(
        sessions
          .keySeq()
          .map(id => sessions.get(id, Map()).set('id', id))
          .filter(
            session =>
              session.get('isNow') && session.get('day') === Number(dayIndex)
          )
      ),
      pastSessions: sessions
        .keySeq()
        .map(id => sessions.get(id, Map()).set('id', id))
        .sort((a, b) => b.get('endTime').unix() - a.get('endTime').unix())
        .reduce((result, session) => {
          /**
           * For moderator page we want to show all passed sessions
           * and for not authenticated users we want to show only one previous
           * session from each track.
           */
          if (
            session.get('isPast') &&
            session.get('day') === Number(dayIndex)
          ) {
            if (
              !showAllPassedSessions &&
              !result.getIn([session.getIn(['track', 'id'], false)], false)
            ) {
              return result
                .update('sessions', sessions => sessions.push(session))
                .set(session.getIn(['track', 'id']), true);
            }

            if (showAllPassedSessions) {
              return result.update('sessions', sessions =>
                sessions.push(session)
              );
            }

            return result;
          }

          return result;
        }, Map({ sessions: List([]) }))
        .get('sessions', List()),
    };
  }
);

export default compose(
  withRouter,
  withNow,
  withRefreshInterval(60),
  connect(mapStateToProps)
)(CurrentSessionsPage);
