/*
 * ChatbotSettingsForm Messages
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  send: {
    id: 'app.components.BroadcastForm.send',
    defaultMessage: 'Send',
  },
  update: {
    id: 'app.components.BroadcastForm.update',
    defaultMessage: 'Update',
  },
  schedule: {
    id: 'app.components.BroadcastForm.schedule',
    defaultMessage: 'Schedule',
  },
  scheduleBroadcastButton: {
    id: 'app.components.BroadcastForm.scheduleBroadcastButton',
    defaultMessage: 'Regular broadcast',
  },
  scheduleBroadcast: {
    id: 'app.components.BroadcastForm.scheduleBroadcast',
    defaultMessage: 'Schedule a broadcast',
  },
  scheduleTimeNotComplete: {
    id: 'app.components.BroadcastForm.scheduleTimeNotComplete',
    defaultMessage:
      'Please define both date and time if you want to schedule a broadcast.',
  },
  scheduleTimeAfterNowRequired: {
    id: 'app.components.BroadcastForm.scheduleTimeAfterNowRequired',
    defaultMessage: 'Past time is not allowed',
  },
});
