import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { fromJS } from 'immutable';
import Flexbox from 'flexbox-react';
import { Field, FieldArray, reduxForm } from 'redux-form/immutable';
import BlockInput from './BlockInput';
import Form from '../../../components/Form';
import FormLabel from '../../../components/FormLabel';
import CollectionInput from '../../../components/CollectionInput';
import { button } from '../../../utils/ui/interactive';
import Icon from '../../../components/Icon';

const Button = styled.button`
  padding: 12px;
  ${button()};
`;

const BlocksInput = CollectionInput(BlockInput);

class FormBuilder extends Component {
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit} autoComplete="off">
        <FormLabel
          htmlFor="config"
          message={
            <span>
              <Icon>notes</Icon> Basic Config
            </span>
          }
        />
        <fieldset id="config">
          <Flexbox flexDirection="column" flex="1">
            <span>CFP Title</span>
            <Field
              name="config.title"
              component="input"
              type="text"
              placeholder="Title for your public CFP page"
              style={{ marginBottom: '1em' }}
              required
            />
          </Flexbox>
          <Flexbox flexDirection="column" flex="1">
            <span>CFP Description</span>
            <Field
              name="config.description"
              component="textarea"
              placeholder="Short description and notes on your CFP"
              style={{ height: '8em' }}
            />
          </Flexbox>
        </fieldset>

        <fieldset>
          <FormLabel
            htmlFor="blocks"
            message={
              <span>
                <Icon>assignment</Icon> Form Blocks
              </span>
            }
          />
          <FieldArray id="blocks" name="blocks" component={BlocksInput} />
        </fieldset>
        <Button>Save Form</Button>
      </Form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'submissionFormBlocks',
    initialValues: fromJS({
      blocks: [],
      config: {},
    }),
  })
)(FormBuilder);
