import { addBreadcrumb, removeBreadcrumb } from '../../sagas/navigation';
import { withSagaMiddleware } from '../../providers/SagaMiddlewareProvider';
import { compose, lifecycle } from 'recompose';

const nothing = () => null;

export default compose(
  withSagaMiddleware,
  lifecycle({
    componentWillMount() {
      const { sagaMiddleware, to, children } = this.props;

      sagaMiddleware.run(addBreadcrumb, to, children);
    },
    componentWillUpdate(nextProps) {
      const { sagaMiddleware, to, children } = this.props;

      const stringifiedNextProps = JSON.stringify({
        to: nextProps.to,
        children: nextProps.children,
      });
      const stringifiedProps = JSON.stringify({ to, children });

      if (stringifiedNextProps !== stringifiedProps) {
        sagaMiddleware.run(removeBreadcrumb, to);
        sagaMiddleware.run(addBreadcrumb, nextProps.to, nextProps.children);
      }
    },
    componentWillUnmount() {
      const { sagaMiddleware, to } = this.props;

      sagaMiddleware.run(removeBreadcrumb, to);
    },
  })
)(nothing);
