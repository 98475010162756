import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { createSelector } from 'reselect';

import CFPForm from './CFPForm';
import background from './background.png';
import { createWithAsyncContent } from '../../../hocs/withAsyncContent';
import withUserRequestExecutor from '../../../hocs/withUserRequestExecutor';
import createFetchResource from '../../../sagas/user/createFetchResource';
import addSubmission from '../../../sagas/user/cfp/addSubmission';

const Main = styled.div`
  margin: 0 auto;
  max-width: 820px;
  text-align: center;
  margin-top: 24px;
`;

const SuccessBox = styled.div`
  background: #2ecc71;
  padding: 0.25em 0.5em;
  color: #fff;
  margin: 12px 0;
  h1 {
    font-size: 2em;
  }
  p {
    font-size: 1.1em;
  }
`;

const NotAcceptingSubmissionsWrapper = styled.div`
  background: transparent;
  color: black;
`;

const NotAcceptingSubmissions = ({ conferenceName }) => (
  <NotAcceptingSubmissionsWrapper>
    <h1>Call For Papers</h1>
    <p>
      {conferenceName} is currently not accepting calls for papers. Thank you
      for your interest!
    </p>
  </NotAcceptingSubmissionsWrapper>
);

const SuccessfullySubmitted = () => (
  <SuccessBox>
    <h1>Awesome!</h1>
    <p>
      Your proposal has been submitted. We’re going over all of the submissions,
      right now, and we’ll get back to you as soon as possible!
    </p>
  </SuccessBox>
);

class CallForPapersPage extends Component {
  state = { submitted: false, error: false };

  componentDidMount = () => {
    document.body.style = `
      background: url(${background});
      background-size: cover;
    `;
  };

  componentWillUnmount = () => {
    document.body.style = '';
  };

  handleSubmit = async raw => {
    const defaultUserRequestExecutor = this.props.createUserRequestExecutor({
      onSuccess: this.handleSuccess,
      onFailure: this.handleFailure,
    });

    const { data } = raw.toJS();
    const submissions = this.props.submissionFormBlocks.map((block, index) => ({
      ...block,
      value: data[index] || '',
    }));
    await defaultUserRequestExecutor(
      addSubmission,
      this.props.conferenceHandle,
      submissions
    );
  };

  handleSuccess = () => this.setState({ submitted: true, error: false });

  handleFailure = () => this.setState({ error: true });

  render() {
    const {
      acceptingSubmissions,
      submissionFormBlocks,
      conferenceName,
      submissionFormConfig,
    } = this.props;

    return (
      <Main>
        <Helmet title={`${conferenceName || ''} Call For Papers `} />
        {conferenceName &&
        acceptingSubmissions &&
        submissionFormBlocks.length > 0 ? (
          this.state.submitted ? (
            <SuccessfullySubmitted />
          ) : (
            <CFPForm
              config={submissionFormConfig}
              blocks={submissionFormBlocks}
              onSubmit={this.handleSubmit}
              hasError={this.state.error}
            />
          )
        ) : (
          <NotAcceptingSubmissions conferenceName={conferenceName} />
        )}
      </Main>
    );
  }
}

const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;

const mapStateToProps = createSelector(
  selectConferenceHandle,
  conferenceHandle => ({
    conferenceHandle,
  })
);

const asyncDataProvider = (key, fallback) => ({ conferenceHandle }) =>
  createFetchResource(`conferences/${conferenceHandle}/${key}`, {
    fallback,
    throwError: false,
  })();

export default compose(
  withRouter,
  connect(mapStateToProps),
  createWithAsyncContent({ propName: 'submissionFormBlocks' })(
    asyncDataProvider('cfp/submissionFormBlocks', []),
    () => false
  ),
  createWithAsyncContent({ propName: 'acceptingSubmissions' })(
    asyncDataProvider('cfp/acceptingSubmissions', false),
    () => false
  ),
  createWithAsyncContent({ propName: 'submissionFormConfig' })(
    asyncDataProvider('cfp/submissionFormConfig', {}),
    () => false
  ),
  createWithAsyncContent({ propName: 'conferenceName' })(
    asyncDataProvider('name', undefined),
    () => false
  ),
  withUserRequestExecutor
)(CallForPapersPage);
