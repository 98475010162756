import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { setPropTypes } from 'recompose';

import Auth from '../Auth';

const Title = styled.h1`
  text-align: center;
`;

const SubTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 2.125em;
  text-align: center;
  font-weight: 300;
`;

const FlexWrapper = styled(Flexbox)`
  background-color: #f5f5ff;
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
  background-size: 18%;
  box-shadow: 0 9px 17px 0 rgba(21, 11, 57, 0.15);
  padding: 2.125em 3.75em;
  text-align: center;
`;

export const AuthenticatedUserLogin = ({ location }) => (
  <Auth location={location}>
    <Helmet title="Sava Events" />
    <Title>Glad to see you back!</Title>
    <FlexWrapper flexDirection="column">
      <SubTitle>Choose</SubTitle>
    </FlexWrapper>
  </Auth>
);

export default setPropTypes({
  location: PropTypes.object,
  error: PropTypes.object,
})(AuthenticatedUserLogin);
