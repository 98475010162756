import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fromJS } from 'immutable';

import Breadcrumb from '../../components/Breadcrumb';
import Form from '../../components/Form';
import Button from '../../components/buttons/Button';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
} from '../../selectors/user';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import allTracksOverviewScreen from './images/alltracks.png';
import singleTrackOverviewScreen from './images/singletrack.png';
import scoreboardScreen from './images/scoreboard.png';
import publicScreen from './images/public.png';
import videomix from './images/videomix.png';
import questionsModeratorScreen from './images/moderator-screen.png';
import { injectIntl } from 'react-intl';

import {
  Container,
  ModuleWrapper,
  Title,
  SubTitle,
  ScreenTitle,
  ScreenRows,
  ScreenRow,
  ScreenImage,
  ScreenInfo,
  Link,
} from './components/styled';
import messages from './messages';
import 'react-simple-dropdown/styles/Dropdown.css';
import TextInputWithCopyToClipboardButton from '../../components/TextInputWithCopyToClipboardButton';
import TextSelectWithCopyToClipboardButton from '../../components/TextSelectWithCopyToClipboardButton';

const getInitialDate = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  return `${today.getFullYear()}-${
    month > 9 ? month : `0${month}`
  }-${today.getDate()}`;
};

const addNowParameter = ({ debug, date, time }) =>
  debug ? `?now=${date}T${time}:00+0000` : '';

class ModeratorPage extends React.Component {
  state = { debug: false, time: '10:15', date: getInitialDate() };

  toggleDebug = () => this.setState({ debug: !this.state.debug });

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const root = `/@${this.props.conference.get('id')}/moderator`;
    const intl = this.props.intl;
    const items = this.props.conference
      .get('tracks', fromJS({}))
      .entrySeq()
      .map(([k, v]) => v.set('id', k))
      .toJS();

    return (
      <div>
        <Helmet
          title={`${this.props.conference.get(
            'name'
          )} Screen Widgets • Sava Events`}
        />
        <Breadcrumb to={root}>Screen Widgets</Breadcrumb>
        <Container>
          <ModuleWrapper style={{ width: '100%' }}>
            {this.state.debug ? (
              <div>
                <p>
                  Choose the date and time you want to simulate. This will add a
                  special code to each URL that will make it behave as if your
                  date and time is the current moment.
                </p>
                <Form
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxWidth: 720,
                  }}
                  onSubmit={e => e.preventDefault()}
                >
                  <input
                    type="date"
                    name="date"
                    onChange={this.handleChange}
                    value={this.state.date}
                    style={{ marginRight: 12 }}
                  />
                  <input
                    type="time"
                    name="time"
                    onChange={this.handleChange}
                    value={this.state.time}
                  />
                  <Button
                    onClick={this.toggleDebug}
                    type="link"
                    label="Exit Test Mode"
                    style={{ minWidth: 200 }}
                  />
                </Form>
              </div>
            ) : (
              <div>
                <p>
                  You can get a link to all of your screen widgets here. You can
                  preview how all of these links will look like on the day of
                  your conference by <strong>enabling Test Mode.</strong>
                </p>
                <Button
                  onClick={this.toggleDebug}
                  type="secondary"
                  label="Enable Test Mode"
                />
                <Link
                  href="https://docs.sava.events/#/screenwidgets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  More about these screens
                </Link>
              </div>
            )}
          </ModuleWrapper>
        </Container>
        <Container>
          <ModuleWrapper>
            <div>
              <Title>{intl.formatMessage(messages.inVenueSectionTitle)}</Title>
              <SubTitle>
                {intl.formatMessage(messages.inVenueSectionSubTitle)}
              </SubTitle>
            </div>
            <ScreenRows>
              <ScreenRow>
                <ScreenImage
                  alt="Sava Events All Tracks Overview Screen"
                  src={allTracksOverviewScreen}
                />
                <ScreenInfo>
                  <div>
                    <ScreenTitle>
                      {intl.formatMessage(messages.inVenueAllTracksTitle)}
                    </ScreenTitle>
                    <SubTitle>
                      {intl.formatMessage(messages.inVenueAllTracksSubTitle)}
                    </SubTitle>
                  </div>
                  <TextInputWithCopyToClipboardButton
                    name="input"
                    type="text"
                    value={`${
                      process.env.REACT_APP_PUBLIC_URL
                    }/@${this.props.conference.get(
                      'id'
                    )}/embed/overlay${addNowParameter(this.state)}`}
                    required
                  />
                </ScreenInfo>
              </ScreenRow>

              <ScreenRow>
                <ScreenImage
                  alt="Sava Events Single Track Overview Screen"
                  src={singleTrackOverviewScreen}
                />
                <ScreenInfo>
                  <div>
                    <ScreenTitle>
                      {intl.formatMessage(messages.inVenueSingleTrackTitle)}
                    </ScreenTitle>
                    <SubTitle>
                      {intl.formatMessage(messages.inVenueSingleTrackSubTitle)}
                    </SubTitle>
                  </div>
                  <TextSelectWithCopyToClipboardButton
                    options={Array.from(items)}
                    value={`${
                      process.env.REACT_APP_PUBLIC_URL
                    }/@${this.props.conference.get('id')}/embed/overlay/`}
                    stringSuffix={addNowParameter(this.state)}
                  />
                </ScreenInfo>
              </ScreenRow>

              <ScreenRow>
                <ScreenImage
                  alt="Sava Events Scoreboard Screen"
                  src={scoreboardScreen}
                />
                <ScreenInfo>
                  <div>
                    <ScreenTitle>
                      {intl.formatMessage(messages.inVenueScoreboardTitle)}
                    </ScreenTitle>
                    <SubTitle>
                      {intl.formatMessage(messages.inVenueScoreboardSubTitle)}
                    </SubTitle>
                  </div>
                  <TextInputWithCopyToClipboardButton
                    name="input"
                    type="text"
                    value={`${
                      process.env.REACT_APP_PUBLIC_URL
                    }/@${this.props.conference.get(
                      'id'
                    )}/embed/scoreboard${addNowParameter(this.state)}`}
                    required
                  />
                </ScreenInfo>
              </ScreenRow>
            </ScreenRows>
          </ModuleWrapper>

          <ModuleWrapper>
            <div>
              <Title>{intl.formatMessage(messages.qaSectionTitle)}</Title>
              <SubTitle>
                {intl.formatMessage(messages.qaSectionSubTitle)}
              </SubTitle>
            </div>
            <ScreenRows>
              <ScreenRow>
                <ScreenImage
                  alt="Sava Events Questions Moderator Screen"
                  src={questionsModeratorScreen}
                />
                <ScreenInfo>
                  <div>
                    <ScreenTitle>
                      {intl.formatMessage(messages.qaModeratorTitle)}
                    </ScreenTitle>
                    <SubTitle>
                      {intl.formatMessage(messages.qaModeratorSubTitle)}
                    </SubTitle>
                  </div>
                  <TextInputWithCopyToClipboardButton
                    name="input"
                    type="text"
                    value={`${
                      process.env.REACT_APP_PUBLIC_URL
                    }/@${this.props.conference.get('id')}/m/q${addNowParameter(
                      this.state
                    )}`}
                    required
                  />
                </ScreenInfo>
              </ScreenRow>

              <ScreenRow>
                <ScreenImage
                  alt="Sava Events Public Screen"
                  src={publicScreen}
                />
                <ScreenInfo>
                  <div>
                    <ScreenTitle>
                      {intl.formatMessage(messages.qaPublicTitle)}
                    </ScreenTitle>
                    <SubTitle>
                      {intl.formatMessage(messages.qaPublicSubTitle)}
                    </SubTitle>
                  </div>
                  <TextSelectWithCopyToClipboardButton
                    addedValue="/questions"
                    options={Array.from(items)}
                    value={`${
                      process.env.REACT_APP_PUBLIC_URL
                    }/@${this.props.conference.get('id')}/embed/overlay/`}
                    stringSuffix={addNowParameter(this.state)}
                    required
                  />
                </ScreenInfo>
              </ScreenRow>

              <ScreenRow>
                <ScreenImage
                  alt="Sava Events Video Mixing Screen"
                  src={videomix}
                />
                <ScreenInfo>
                  <div>
                    <ScreenTitle>
                      {intl.formatMessage(messages.videoMixingTitle)}
                    </ScreenTitle>
                    <SubTitle>
                      {intl.formatMessage(messages.videoMixingSubTitle)}
                    </SubTitle>
                  </div>
                  <TextSelectWithCopyToClipboardButton
                    options={Array.from(items)}
                    value={`${
                      process.env.REACT_APP_PUBLIC_URL
                    }/@${this.props.conference.get('id')}/embed/videomix/`}
                    stringSuffix={addNowParameter(this.state)}
                  />
                </ScreenInfo>
              </ScreenRow>
            </ScreenRows>
          </ModuleWrapper>
        </Container>
      </div>
    );
  }
}

ModeratorPage.propTypes = {
  conference: PropTypes.object.isRequired,
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  (conferenceHandle, conference) => ({
    conferenceHandle,
    conference,
  })
);

export default compose(
  injectIntl,
  connect(mapStateToProps)
)(ModeratorPage);
