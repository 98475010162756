import { fromJS } from 'immutable';
import { ADD_BREADCRUMB, REMOVE_BREADCRUMB } from '../constants/navigation';

const initialState = fromJS({
  breadcrumbs: [],
});

function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BREADCRUMB:
      return state.update('breadcrumbs', breadcrumbs =>
        breadcrumbs.push(fromJS({ link: action.link, text: action.text }))
      );
    case REMOVE_BREADCRUMB:
      return state.update('breadcrumbs', breadcrumbs => {
        let isDeleted = false;

        return breadcrumbs.filter(breadcrumb => {
          const link = breadcrumb.get('link');

          if (link === action.link && !isDeleted) {
            isDeleted = true;
            return false;
          }

          return true;
        });
      });

    default:
      return state;
  }
}

export default navigationReducer;
