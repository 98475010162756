/*
 * EmbedWidgetGuidePopup Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Flexbox from 'flexbox-react';
import color from 'color';
import { createSelector } from 'reselect';

import { selectCurrentConferenceHandle } from '../../selectors/user';

import styled from 'styled-components';

const P = styled.p`
  margin-top: 0;
`;

const Textarea = styled.textarea`
  padding: 12px;
  margin: 0;
  margin-bottom: 20px;

  font-family: monospace;

  border: 1px solid rgba(0, 0, 0, 0.1);
  ${props => props.theme.altitude(-1)};

  &:focus {
    outline: none;

    border: 1px solid
      ${color('#248bd1')
        .alpha(0.4)
        .string()};
  }

  resize: none;
  height: 200px;
`;

class EmbedWidgetGuidePopup extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.any.isRequired,
    context: PropTypes.any.isRequired,
  };

  render() {
    const { conferenceHandle, context } = this.props;

    const code = `
  <div id="sava"></div>
  <script src="https://cdn.sava.events/sdk-0.2.0.js"></script>
  <script>
    new Sava.Iframe({
      rootElement:document.getElementById('sava'),
      conferenceHandle: '${conferenceHandle}',
      context: '/${context}'
    })
  </script>
`;

    const previewLink = `${
      window.location.origin
    }/@${conferenceHandle}/embed/${context}`;

    return (
      <Flexbox flexDirection="column">
        <P>Copy the code seen below and paste it to your website.</P>
        <Textarea value={code} readOnly />
        <small>You may need to adapt its width and height.</small>
        <small>
          You can find more in the{' '}
          <a
            href="https://docs.sava.events/#/gettingstarted?id=embedding-widgets-on-your-website"
            target="_blank"
            rel="noopener noreferrer"
          >
            API documentation
          </a>
          . If you need any help,{' '}
          <a href="mailto:devs@sava.events">contact us.</a>.
        </small>
        <p>
          To see a preview of this code{' '}
          <a href={previewLink} target="_blank" rel="noopener noreferrer">
            click here.
          </a>
        </p>
      </Flexbox>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  conferenceHandle => ({ conferenceHandle })
);

export default compose(
  withRouter,
  connect(mapStateToProps)
)(EmbedWidgetGuidePopup);
