import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const Switch = styled.div`
  border: 1px solid #ccc;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  cursor: pointer;
  display: inline-block;

  &.on {
    background: ${props => props.theme.colors.brandPrimary};
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const Toggle = styled.div`
  border: 1px solid #999;
  box-shadow: 1px 1px 1px #ccc;
  width: 25px;
  height: 24px;
  left: ${props => (props.on ? '23px' : '0')};
  border-radius: 12px;
  background: white;
  position: relative;
  transition: left 0.2s ease-in-out;
`;

class ToggleSwitch extends Component {
  static propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
    style: PropTypes.object,
  };

  static defaultProps = {
    value: false,
    onChange: () => {},
  };

  handleClick = e => {
    e.preventDefault();

    this.props.onChange(!this.props.value);
  };

  render() {
    return (
      <Switch
        className={this.props.value ? 'on' : ''}
        onClick={this.handleClick}
        stlye={this.props.style}
      >
        <Toggle on={this.props.value} />
      </Switch>
    );
  }
}

export default ToggleSwitch;
