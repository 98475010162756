import styled from 'styled-components';
import { ChatBubblePropTypes } from './propTypes';

const ChatBubble = styled.li`
  color: ${props => (props.isUser ? 'white' : 'black')};
  background: ${props => (props.isUser ? '#3c7efd' : '#f1f0f0')};
  margin: 0 0 5px 0;
  width: auto;
  max-width: 70%;
  padding: 10px 15px;

  ${props =>
    props.isUser
      ? `
    font-weight: 300;
    border-radius: 25px 5px 5px 25px;
    :first-child {
      border-radius: 25px 25px 5px 25px;
    }
    :last-child {
      border-radius: 25px 5px 25px 25px;
    }`
      : `
    font-weight: 400;
    border-radius: 5px 25px 25px 5px;
    :first-child {
      border-radius: 25px 25px 25px 5px;
    }
    :last-child {
      border-radius: 5px 25px 25px 25px;
    }    
  `};
`;

ChatBubble.propTypes = ChatBubblePropTypes;

export default ChatBubble;
