/*
 * EditSponsorPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { compose } from 'recompose';
import { Map, List } from 'immutable';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import withUserRequestExecutor, {
  errorMessages,
} from '../../hocs/withUserRequestExecutor';
import {
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectConferenceName,
  selectId,
} from '../../selectors/user';
import SponsorForm from '../../components/SponsorForm';
import updateSponsor from '../../sagas/user/sponsors/update';
import deleteSponsor from '../../sagas/user/sponsors/delete';
import { confirm } from '../../sagas/user/confirm';
import messages from './messages';

export class EditSponsorPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
    sponsor: PropTypes.any.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages,
  });

  handleSubmit = async (data, dispatch, { form }) => {
    const { id, name, description, image, profiles, group } = data
      .update('profiles', List(), _profiles =>
        _profiles.map(profile => {
          const icon = profile.get('icon');
          return [
            'facebook',
            'github',
            'google',
            'linkedIn',
            'twitter',
          ].indexOf(icon) >= 0
            ? profile
            : profile.delete('icon');
        })
      )
      .toJS();

    await this.defaultUserRequestExecutor(updateSponsor, {
      conferenceHandle: this.props.conferenceHandle,
      id,
      name,
      description,
      image: image || {},
      group,
      profiles: profiles || {},
      form,
    });
  };

  handleDelete = async () => {
    const { intl, conferenceHandle, sponsor } = this.props;

    const id = sponsor.get('id');

    if (confirm(intl.formatMessage(messages.confirmDelete))) {
      await this.defaultUserRequestExecutor(
        deleteSponsor,
        conferenceHandle,
        id
      );
    }
  };

  render() {
    const { conferenceHandle, conferenceName, sponsor } = this.props;

    return (
      <div>
        <Helmet title={`${conferenceName} • ${sponsor.get('name')}`} />
        <SponsorForm
          conferenceHandle={conferenceHandle}
          initialValues={sponsor}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectCurrentConference,
  selectId,
  (conferenceHandle, conferenceName, conference, id) => ({
    conferenceHandle,
    conferenceName,
    sponsor: conference
      .getIn(['sponsors', id], Map())
      .set('id', id)
      .update('group', groupId => Map({ id: groupId })),
  })
);

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withUserRequestExecutor
)(EditSponsorPage);
