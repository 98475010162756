import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    id: 'app.components.ContentError.heading',
    defaultMessage: 'Whoops!',
  },
  info: {
    id: 'app.components.ContentError.info',
    defaultMessage:
      'Something went wrong. Please try again or contact our support.',
  },
});
