import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import messages from './messages';

const Root = styled(Flexbox)`
  flex: 1;
`;

const ContentError = () => (
  <Root
    justifyContent={'center'}
    alignItems={'center'}
    flexDirection={'column'}
  >
    <FormattedMessage {...messages.heading}>
      {heading => <h1>{heading}</h1>}
    </FormattedMessage>
    <FormattedMessage {...messages.info}>
      {info => <div>{info}</div>}
    </FormattedMessage>
  </Root>
);

export default injectIntl(ContentError);
