/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { findLatestEnd } from '../../utils/schedule';
import { slugify } from 'sava-shared/lib/utils/core';

import TimeRowWithTag from '../../components/ListSchedule/TimeRowWithTag';
import TimeRowNoTag from '../../components/ListSchedule/TimeRowNoTag';
import SessionList from '../../components/ListSchedule/SessionList';

const SessionContainer = styled.div`
  width: 100%;
`;

const SessionWrapper = styled.div`
  width: 100%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Session = styled.div`
  width: 100%;
`;

const Day = styled.div`
  font-size: ${props => (props.em ? '1.8em' : '32px')};
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.theme.page.color};
  padding-left: ${props => (props.em ? '0.4em' : '8px')};
`;

const sessionsExist = tracks =>
  tracks.some(track => track.get('sessions').size > 0);

const InfoLink = styled(Link)`
  text-decoration: none;
`;

const ListSchedule = props => {
  const { conferenceHandle, tracks, breaks } = props;

  if (sessionsExist(tracks) || breaks.length > 0) {
    const allSessionsAndBreaks = tracks
      .flatMap(track => track.get('sessions'))
      .concat(breaks ? breaks : []); // eslint-disable-line

    const latestEnd = findLatestEnd(allSessionsAndBreaks);
    const day = latestEnd.format('dddd');

    const sorted = allSessionsAndBreaks.sortBy(v =>
      v.get('startTime').valueOf()
    );
    let lastTime = null;
    return (
      <SessionContainer>
        <Day>{day}</Day>
        {sorted.map((value, i, array) => {
          const {
            title,
            isBlock,
            duration,
            startTime,
            endTime,
            trackName,
          } = value.toJS();
          const tags = value.get('tags') ? value.get('tags').toJS() : [];
          let tag = null;
          if (tags.length > 0) {
            tag = tags[0];
          }
          const speakersList = value.get('speakers');
          const startAt =
            lastTime === startTime.format('HH:mm')
              ? null
              : startTime.format('HH:mm');
          lastTime = startTime.format('HH:mm');

          return (
            <Session key={i}>
              {startAt && isBlock ? (
                <TimeRowNoTag
                  startAt={startAt}
                  title={title}
                  duration={duration}
                />
              ) : (
                startAt && <TimeRowWithTag startAt={startAt} tag={tag} />
              )}
              {!isBlock && (
                <InfoLink
                  to={`/@${conferenceHandle}/embed/schedule/sessions/${slugify(
                    title
                  )}`}
                >
                  <SessionWrapper flexDirection="row">
                    <SessionList
                      title={title}
                      trackName={trackName}
                      startTime={startTime.format('HH:mm')}
                      endTime={endTime.format('HH:mm')}
                      duration={duration}
                      speakersList={speakersList}
                    />
                  </SessionWrapper>
                </InfoLink>
              )}
            </Session>
          );
        })}
      </SessionContainer>
    );
  }
  return null;
};

ListSchedule.propTypes = {
  conferenceHandle: PropTypes.string,
  tracks: PropTypes.object,
  breaks: PropTypes.object,
};

export default ListSchedule;
