import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../components/Icon';

const ButtonBase = styled(Link)`
  padding: 0.75em 1em;
  background-color: ${props => props.theme.buttons[props.type].backgroundColor};
  color: ${props => props.theme.buttons[props.type].color};
  font-weight: 600;
  text-decoration: none;
  border-radius: 0.25em;

  &:hover {
    opacity: 0.85;
  }
`;

const Label = styled.span`
  margin-left: 0.25em;
`;

const Button = ({ to, icon, label, type, ...rest }) => (
  <ButtonBase to={to || '#'} type={type} {...rest}>
    {icon && <Icon>{icon}</Icon>}
    <Label>{label}</Label>
  </ButtonBase>
);

Button.propTypes = {
  to: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'link']),
};

export default Button;
