/*
 * TelegramSettingsFomr Messages
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  apiToken: {
    id: 'app.components.TelegramSettingsForm.apiToken',
    defaultMessage: 'Chatbot Token',
  },
  save: {
    id: 'app.components.TelegramSettingsForm.save',
    defaultMessage: 'Save',
  },
  disconnect: {
    id: 'app.components.TelegramSettingsForm.disconnect',
    defaultMessage: 'Disconnect',
  },
});
