import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../buttons/Button';

const Input = styled.input`
  font-family: inherit;
  display: block;
  background: white;
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  outline: none;
  border: 1px solid #d0d4d8;
  padding: 11px 15px;
  width: 70%;
  height: 42px;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
`;

class TextInputWithCopyToClipboardButton extends React.Component {
  state = { copied: false };

  onCopy = () => {
    this.setState({ copied: true });
    this.timeout_ = setTimeout(() => this.setState({ copied: false }), 2000);
  };

  handleFocus = event => {
    event.target.select();
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout_);
  };

  render() {
    return (
      <Wrapper>
        <Input
          placeholder={this.props.placeholder}
          type={this.props.type}
          style={this.props.style}
          value={this.props.value}
          readOnly={this.props.readOnly}
          onFocus={this.handleFocus}
          required
        />

        <CopyToClipboard text={this.props.value} onCopy={this.onCopy}>
          <Button
            label={this.state.copied ? 'Copied' : 'Copy'}
            icon="link"
            type="secondary"
            style={{ height: '42px', padding: '0.55em 0.7em' }}
          />
        </CopyToClipboard>
      </Wrapper>
    );
  }
}

export default TextInputWithCopyToClipboardButton;
