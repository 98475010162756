import { List } from 'immutable';

/**
 *
 * @param {string} time
 * @return {boolean}
 */
const isValidTime = time =>
  List([
    /^00:[0-5]\d$/,
    /^0?\d:[0-5]\d$/,
    /^1\d:[0-5]\d$/,
    /^2[0-3]:[0-5]\d$/,
    /^\d:[0-5]\d\sAM$/,
    /^1[0-2]:[0-5]\d\sAM$/,
    /^\d:[0-5]\d\sPM$/,
    /^1[0-2]:[0-5]\d\sPM$/,
  ]).some(regex => time.match(regex));

/**
 *
 * @param {{
 *  sessionType: Object,
 *  track: { id: string, name: string, location: string },
 *  title: string,
 *  day: number,
 *  time: string
 * }} values
 * @return {{
 *    sessionType: string|undefined,
 *    track: string|undefined,
 *    title: string|undefined,
 *    day: number|undefined,
 *    time: string|undefined
 *  }}
 */
export default values => {
  const errors = {};
  const { sessionType = {}, track = {}, title, day, time } = values;

  if (sessionType.id === null) {
    errors.sessionType = 'Please select the type of session.';
  } else if (sessionType.id === undefined) {
    if (!sessionType.name) {
      errors.sessionType = 'Please enter the name of the session type.';
    } else if (!sessionType.color) {
      errors.sessionType = 'Please select the color of the session type.';
    }
  }

  if (track.id === null) {
    errors.track = 'Please select the track';
  } else if (track.id === undefined) {
    if (!track.name) {
      errors.track = 'Please enter the name of the track.';
    } else if (!track.location) {
      errors.track = 'Please enter the location of the track.';
    }
  }

  if (!title) {
    errors.title = 'Please enter the title of the session.';
  }

  if (day === null || day === undefined) {
    errors.day = 'Please enter the day of the session.';
  }

  if (!time.startTime) {
    errors.time = 'Please enter the start time of the session.';
  } else if (!isValidTime(time.startTime)) {
    errors.time = 'Please enter the full start time of the session.';
  } else if (!time.endTime) {
    errors.time = 'Please enter the end time of the session.';
  } else if (!isValidTime(time.endTime)) {
    errors.time = 'Please enter the full end time of the session.';
  } else if (
    time.startTime === time.endTime &&
    isValidTime(time.startTime) &&
    isValidTime(time.endTime)
  ) {
    errors.time = 'Please enter a longer duration period.';
  }

  return errors;
};
