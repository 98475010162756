import firebase from 'firebase/app';

import getLongLiveAccessToken from './getLongLiveAccessToken';

const createFacebookAuthProvider = () =>
  new firebase.auth.FacebookAuthProvider()
    .addScope('manage_pages')
    .addScope('pages_messaging')
    .addScope('pages_messaging_subscriptions');

export default async () => {
  try {
    const providers = await firebase.auth().currentUser.providerData;
    const alreadyAuthenticatedUsingFacebook = providers
      .map(p => p.providerId)
      .includes('facebook.com');
    const firebaseAuthResponse = alreadyAuthenticatedUsingFacebook
      ? await firebase
          .auth()
          .currentUser.reauthenticateWithPopup(createFacebookAuthProvider())
      : await firebase
          .auth()
          .currentUser.linkWithPopup(createFacebookAuthProvider());
    const shortLivedAccessToken = firebaseAuthResponse.credential.accessToken;
    const userId = firebaseAuthResponse.additionalUserInfo.profile.id;
    const {
      result: longLivedAccessToken,
      error,
    } = await getLongLiveAccessToken(
      process.env.REACT_APP_FACEBOOK_APP_ID,
      process.env.REACT_APP_FACEBOOK_APP_SECRET,
      shortLivedAccessToken
    );

    if (error) {
      return { error };
    }

    const accessToken = longLivedAccessToken.access_token;
    return { result: { userId, accessToken } };
  } catch (error) {
    return { error };
  }
};
