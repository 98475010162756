import firebase from 'firebase/app';

export default function* deleteSessionSpeaker(
  conferenceHandle,
  sessionId,
  speakerId
) {
  return yield firebase
    .database()
    .ref(
      `conferences/${conferenceHandle}/sessions/${sessionId}/speakers/${speakerId}`
    )
    .remove();
}
