import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  margin: 0 0.75em;
  background-color: white;
  ${props => props.theme.altitude(1)};
  border-radius: 0.25em;
  border: ${props => props.theme.borders.default};
  max-height: 980px;
`;

export const ScrollableBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
    linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
    radial-gradient(at top, rgba(177, 177, 177, 0.2), transparent 70%),
    radial-gradient(at bottom, rgba(177, 177, 177, 0.2), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 1.25em, 100% 1.25em, 100% 0.625em, 100% 0.625em;
  background-attachment: local, local, scroll, scroll;
`;
