import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form/immutable';
import { createSelector } from 'reselect';

import { selectCurrentConference } from '../../selectors/user';

import SelectInputControl from '../SelectInputControl';

const selectIntents = createSelector(
  selectCurrentConference,
  conference => {
    const customIntents = conference.getIn([
      'chatbotSettings',
      'customIntents',
    ]);
    if (customIntents) {
      return customIntents
        .entrySeq()
        .map(([id, value]) => ({ id, name: value.get('title') }))
        .toJS();
    }
    return [];
  }
);

const mapIntentsToProps = createSelector(
  selectIntents,
  intents => ({
    intents,
  })
);

const CustomIntentField = SelectInputControl((state, { intents }) => intents);

const CustomIntentPicker = ({ input, label, name, intents }) => {
  const inputName = (input && input.name) || name;

  return (
    <fieldset>
      <label htmlFor={`${inputName ? `${inputName}.` : ''}customIntentId`}>
        {label}
      </label>
      <Field
        name={`${inputName ? `${inputName}.` : ''}customIntentId`}
        component={CustomIntentField}
        props={{ intents }}
      />
    </fieldset>
  );
};

export default connect(mapIntentsToProps)(CustomIntentPicker);
