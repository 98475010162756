import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form/immutable';
import styled from 'styled-components';
import { compose } from 'recompose';

import FormLabel from '../../FormLabel';
import TextInput from '../../TextInput';
import UrlInput from '../../UrlInput';
import SelectInputControl from '../../SelectInputControl';
import CustomIntentPicker from '../../CustomIntentPicker';

const ListItemRoot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ButtonTypeInput = SelectInputControl(
  () => [
    { value: 'link', label: 'Link' },
    { value: 'customIntent', label: 'Custom Intent' },
  ],
  { valueKey: 'value', labelKey: 'label' }
);

const ButtonInput = ({
  subtitleRequired,
  fileRequired,
  buttonLabelRequired,
  buttonActionRequired,
  titleLimit,
  subtitleLimit,
  buttonLimit,
  messages,
  intl,
  name,
  value,
}) => (
  <ListItemRoot>
    <fieldset>
      <FormLabel
        htmlFor={`${name ? `${name}.` : ''}buttonLabel`}
        message={intl.formatMessage(messages.messageListButtonLabel)}
        messageInfo={intl.formatMessage(messages.messageListButtonLabelInfo)}
      />
      <Field
        name={`${name ? `${name}.` : ''}buttonLabel`}
        placeholder={intl.formatMessage(messages.messageListButtonPlaceholder)}
        component={TextInput}
        props={{
          limit: buttonLimit,
        }}
        required={buttonLabelRequired}
      />
    </fieldset>

    <fieldset>
      <FormLabel
        htmlFor={`${name ? `${name}.` : ''}buttonType`}
        message={intl.formatMessage(messages.messageListButtonTypeLabel)}
      />
      <Field
        name={`${name ? `${name}.` : ''}buttonType`}
        component={ButtonTypeInput}
        required={buttonActionRequired}
      />
    </fieldset>

    {value.get('buttonType') === 'link' && (
      <fieldset>
        <FormLabel
          htmlFor={`${name ? `${name}.` : ''}action`}
          message={intl.formatMessage(messages.messageListButtonLinkLabel)}
        />
        <Field
          name={`${name ? `${name}.` : ''}action`}
          placeholder={intl.formatMessage(
            messages.messageListButtonLinkPlaceholder
          )}
          component={UrlInput}
          required={buttonActionRequired}
        />
      </fieldset>
    )}

    {value.get('buttonType') === 'customIntent' && (
      <fieldset>
        <FormLabel
          htmlFor={`${name ? `${name}.` : ''}customIntent`}
          message={intl.formatMessage(messages.messageListButtonLinkLabel)}
        />
        <Field
          name={`${name ? `${name}.` : ''}customIntent`}
          component={CustomIntentPicker}
          required={buttonActionRequired}
        />
      </fieldset>
    )}
  </ListItemRoot>
);

ButtonInput.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(ButtonInput);
