import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Map } from 'immutable';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

import { FormErrorWithMargins as FormError } from '../../components/FormError';
import ChatQuickReplyForm from '../../components/ChatQuickReplyForm';
import saveQuickReply from '../../sagas/user/conferences/saveQuickReply';
import removeQuickReply from '../../sagas/user/conferences/removeQuickReply';
import setQuickRepliesToEmptyState from '../../sagas/user/conferences/setQuickRepliesToEmptyState';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import messages from '../../components/ChatQuickReplyForm/messages';

export class EditQuickReplyPage extends Component {
  constructor() {
    super();

    this.state = {
      error: false,
    };
  }

  defaultUserRequestExecutor = (history, errorMessage) =>
    this.props.createUserRequestExecutor({
      onStart: () => this.setState({ error: false }),
      onEnd: () => history.goBack(),
      onFailure: () => this.setState({ error: errorMessage }),
    });

  handleSubmit = async data => {
    const { conferenceHandle, history } = this.props;
    const formData = data.toJS();

    await this.defaultUserRequestExecutor(history, 'updateError')(
      saveQuickReply,
      conferenceHandle,
      formData
    );
  };

  handleDelete = async () => {
    const {
      conferenceHandle,
      quickReplyId,
      history,
      numberOfQuickReplies,
      intl,
    } = this.props;

    const sagaArgs =
      numberOfQuickReplies === 1
        ? [
            setQuickRepliesToEmptyState,
            conferenceHandle,
            intl.formatMessage(messages.confirmSetQuickRepliesToEmptyState),
          ]
        : [
            removeQuickReply,
            conferenceHandle,
            quickReplyId,
            intl.formatMessage(messages.confirmRemoveQuickReply),
          ];

    await this.defaultUserRequestExecutor(history, 'removeError')(...sagaArgs);
  };

  render() {
    const { error } = this.state;
    const { quickReply, intl } = this.props;
    return (
      <div>
        <Helmet title={`${quickReply.get('label')} • Sava Events`} />
        {error && <FormError>{intl.formatMessage(messages[error])}</FormError>}
        <ChatQuickReplyForm
          initialValues={quickReply}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { match } = props;
  const params = match.params || {};
  const { conferenceHandle, id: quickReplyId } = params;

  const quickReplies =
    state.getIn(
      [
        'user',
        'userData',
        'currentConference',
        'chatbotSettings',
        'quickReplies',
      ],
      Map()
    ) || Map();

  const quickReply = quickReplies.get(quickReplyId, Map());

  return {
    conferenceHandle,
    quickReplyId,
    quickReply: quickReply.set('id', quickReplyId),
    numberOfQuickReplies: quickReplies.size,
  };
};

EditQuickReplyPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  quickReply: PropTypes.any.isRequired,
};

export default compose(
  withUserRequestExecutor,
  withRouter,
  injectIntl,
  connect(mapStateToProps)
)(EditQuickReplyPage);
