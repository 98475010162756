import { isArray, isObject, isDefinedAndNotNull } from 'is-of-type';

import random from '../math/random';
import uploadImage from '../uploadImage';
import isNonEmptyFileList from '../isNonEmptyFileList';

/**
 *
 * Pass an entity you want to go through along with a key
 * where to look for a File. Once found it will upload it
 * based on the given path.
 *
 *
 * @param item {Any}
 * @param path {String}
 * @returns {Object}
 */
const replaceResourceWithUrl = async (item, path) => {
  if (isDefinedAndNotNull(item) && isNonEmptyFileList(item)) {
    const resourcePath = `${path}/${random()}-${item[0].name}`;
    return {
      value: await uploadImage(resourcePath, item[0]),
      isPrimitive: true,
    };
  } else if (isDefinedAndNotNull(item) && isArray(item)) {
    return {
      value: await Promise.all(item.map(_ => replaceResourceWithUrl(_, path))),
      isArray: true,
    };
  } else if (isDefinedAndNotNull(item) && isObject(item)) {
    return {
      value: await Promise.all(
        Object.keys(item).map(async key => {
          return [key, await replaceResourceWithUrl(item[key], path)];
        })
      ),
      isObject: true,
    };
  }

  return { value: item, isPrimitive: true };
};

const formatResult = item => {
  if (!isObject(item)) {
    return item;
  }

  if (item.isPrimitive) {
    return item.value;
  } else if (item.isArray) {
    return item.value.reduce((acc, item) => [...acc, formatResult(item)], []);
  } else if (item.isObject) {
    return item.value.reduce(
      (acc, item) => ({
        ...acc,
        [item[0]]: formatResult(item[1]),
      }),
      {}
    );
  }

  return item;
};

export default async (item, path) =>
  formatResult(await replaceResourceWithUrl(item, path));
