import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  padding: 1.5em;
  border: ${props => props.theme.borders.default};
  padding: 2.5em 2em 1.5em;
  text-decoration: none;
  text-align: center;
  color: inherit;
  margin-bottom: 1em;
  border-radius: 0.25em;
  flex: 0 0 calc(33.33% - 0.75em);
  ${props => props.theme.altitude(1)};
  transition: all 0.15s ease-in-out;

  &:hover {
    ${props => props.theme.altitude(2)};
  }
`;

const Illustration = styled.div`
  width: 12em;
  height: 8em;
  display: inline-block;
  background: url(${props =>
    props.illustration}) center center / contain no-repeat};
`;

const Label = styled.h3`
  color: ${props => props.theme.page.color};
  margin-bottom: 0.5em;
`;

const Description = styled.p`
  opacity: 0.45;
  margin-top: 0;
`;

const ModuleNavigation = ({ to, title, description, illustration, style }) => (
  <Container to={to} style={style}>
    <Illustration illustration={illustration} />
    <Label>{title}</Label>
    <Description>{description}</Description>
  </Container>
);

ModuleNavigation.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  illustration: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default ModuleNavigation;
