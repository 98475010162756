import firebase from 'firebase/app';

export default async path => {
  const resourceSnapshot = await firebase
    .database()
    .ref(path)
    .once('value');

  return resourceSnapshot.exists();
};
