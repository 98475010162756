import {
  ACCEPT_CONFERENCE,
  ACCEPT_USER_DATA,
  ACCEPT_USER,
  CLEAR_USER,
  LOG_IN_ERROR,
  LOG_OUT,
  LOG_OUT_ERROR,
  SIGN_UP_ERROR,
  RESET_PASSWORD_SUCCESS,
  SELECT_CONFERENCE,
  DESELECT_CONFERENCE,
  INITIATE_GOOGLE_AUTH,
  INITIATE_FACEBOOK_AUTH,
  INITIATE_LINKEDIN_AUTH,
  ACCEPT_FB_PAGES,
} from '../constants/user';

export function acceptConference(conference) {
  return {
    type: ACCEPT_CONFERENCE,
    conference,
  };
}

export function acceptUserData(userData) {
  return {
    type: ACCEPT_USER_DATA,
    userData,
  };
}

export function acceptUser(user) {
  return {
    type: ACCEPT_USER,
    user,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

export function logInError(error) {
  return {
    type: LOG_IN_ERROR,
    error,
  };
}

export function logOut() {
  return {
    type: LOG_OUT,
  };
}

export function logOutError(error) {
  return {
    type: LOG_OUT_ERROR,
    error,
  };
}

export function signUpError(error) {
  return {
    type: SIGN_UP_ERROR,
    error,
  };
}

export const resetPasswordSuccess = success => ({
  type: RESET_PASSWORD_SUCCESS,
  success,
});

export function selectConference(handle) {
  return {
    type: SELECT_CONFERENCE,
    handle,
  };
}

export function deselectConference() {
  return {
    type: DESELECT_CONFERENCE,
  };
}

export function initiateGoogleAuth(errorAction) {
  return {
    type: INITIATE_GOOGLE_AUTH,
    errorAction,
  };
}

export function initiateFacebookAuth(errorAction) {
  return {
    type: INITIATE_FACEBOOK_AUTH,
    errorAction,
  };
}

export function initiateLinkedInAuth(errorAction) {
  return {
    type: INITIATE_LINKEDIN_AUTH,
    errorAction,
  };
}

export function acceptFbPages(pages) {
  return {
    type: ACCEPT_FB_PAGES,
    pages,
  };
}
