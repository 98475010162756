import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import color from 'color';

import SessionTypeBar from '../../components/SessionTypeBar';

const barColor = color('#FDBE2C');
const backgroundColor = barColor.fade(0.8);

const BreakBlock = styled.div`
  padding: 1rem 1rem 10px;
  color: ${props => props.theme.card.textColor};
  background-color: ${props => props.theme.card.backgroundColor};
  height: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const BreakBlockContent = styled(Flexbox)`
  height: 100%;
  flex-direction: column;
`;

const BreakBlockDetails = styled(Flexbox)`
  height: 13px;
  font-size: 13px;
  white-space: nowrap;
`;

BreakBlockDetails.Time = styled(Flexbox)``;

const BreakBlockTitle = styled.div`
  margin: 0;
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 1.33;
  font-weight: 700;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const BreakSide = styled(Flexbox)`
  flex-direction: column;
  color: ${props => props.theme.card.textColor};
  border-top: 4px solid ${barColor.string()};
  background-color: white;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const BreakSideOverlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${backgroundColor.string()};
`;

const BreakSideContent = styled.div`
  transform: rotate(-90deg)
    translateX(${props => `-${props.translateDefect}px`});
  flex-direction: row-reverse;

  & > * {
    padding: 0 0.5em;
  }
`;

const Break = props => {
  const { breakObj } = props;

  const isBlock = breakObj.get('isBlock');

  if (isBlock) {
    return (
      <BreakBlock>
        <SessionTypeBar backgroundColor={barColor.string()} />
        <BreakBlockContent>
          <BreakBlockDetails flexDirection="row" flexShrink={0}>
            <Flexbox flexGrow={2}>
              <BreakBlockDetails.Time>
                {`${breakObj.get('startTime').format('HH:mm')} - ${breakObj
                  .get('endTime')
                  .format('HH:mm')}`}
              </BreakBlockDetails.Time>
            </Flexbox>
          </BreakBlockDetails>
          <BreakBlockTitle>{breakObj.get('title')}</BreakBlockTitle>
        </BreakBlockContent>
      </BreakBlock>
    );
  }

  const title = breakObj.get('title');
  const duration = breakObj.get('duration');
  const durationMin = `${duration} mins`;

  // Fitted coefficients
  const k = 6.95;
  const x = title.length + durationMin.length;
  const m = 27.5;
  const translateDefect = k * x + m;

  return (
    <BreakSide>
      <BreakSideOverlay>
        <BreakSideContent translateDefect={translateDefect}>
          <strong>{title}</strong>
          <i>{durationMin}</i>
        </BreakSideContent>
      </BreakSideOverlay>
    </BreakSide>
  );
};

Break.propTypes = {
  breakObj: PropTypes.any,
};

export default Break;
