import React, { Fragment } from 'react';

import Saga from '../components/Saga';

export default saga => Component => props => (
  <Fragment>
    <Saga saga={saga} />
    <Component {...props} />
  </Fragment>
);
