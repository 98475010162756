import React from 'react';

export default ({ size = 24, color = '#051A25', style = {}, opacity = 1 }) => (
  <svg
    style={{
      ...style,
      width: size,
      height: size,
    }}
    viewBox="0 0 24 24"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity={opacity}
    >
      <g
        id="Powered-by"
        transform="translate(-95.000000, -4.000000)"
        fill={color}
      >
        <path
          d="M112.688635,6.02578218 C115.556088,6.51887268 117.453797,9.56532455 116.905686,12.7956049 C116.357575,16.0259907 113.5631,18.2654523 110.695543,17.7723617 L109.965043,17.6467175 L105.748306,19.8125 L106.684439,17.1211618 L99.3112749,15.8146512 C96.4463355,15.3070146 94.5461132,12.2751088 95.094224,9.04482842 C95.6423349,5.81444261 98.4369151,3.57498111 101.304367,4.06807162 L112.688635,6.02578218 Z M100.010585,7.94820272 C98.9793826,7.77090938 98.0006356,8.46869885 97.8245281,9.50684516 C97.6484207,10.5448861 98.3415427,11.5302238 99.372745,11.7076226 C100.403947,11.8849159 101.38259,11.1871264 101.558802,10.1489801 C101.734909,9.11083382 101.041787,8.12549605 100.010585,7.94820272 Z M114.169351,12.5021223 C114.345459,11.463976 113.652337,10.4786382 112.621134,10.3013449 C111.589932,10.1240515 110.611185,10.821841 110.435078,11.8599873 C110.25897,12.8980282 110.952092,13.8834714 111.983295,14.0607647 C113.014497,14.2380581 113.993139,13.5402686 114.169351,12.5021223 Z M103.195377,13.1037118 C104.303801,14.373872 105.772199,14.7480778 107.561908,14.0877895 C107.765914,14.0125246 107.870687,13.7850175 107.795925,13.5796383 C107.721164,13.3742591 107.495178,13.2687807 107.291173,13.3440456 C105.806085,13.8919481 104.680187,13.6050248 103.786476,12.5809091 C103.643074,12.4165822 103.394501,12.4004024 103.231274,12.5447704 C103.068046,12.6891384 103.051974,12.939385 103.195377,13.1037118 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);
