import moment from 'moment-timezone';
import { Map, List } from 'immutable';

const MINUTE_SCALAR = 5;
const BREAK_BLOCK_SCALAR = 15;

export const mergeDateAndTime = (date, time) => {
  const [h, m] = time.split(':');
  return moment(date)
    .hours(h)
    .minutes(m);
};

export const diffMomentDates = (left, right, arg = 'milliseconds') =>
  left.diff(right, arg);

export const transformSimpleTimestamp = (date, { startTime, endTime }) => {
  const transformed = {
    time: {
      start: mergeDateAndTime(new Date(date), startTime),
      end: mergeDateAndTime(new Date(date), endTime),
    },
  };
  transformed.blockSize = diffMomentDates(
    transformed.time.end,
    transformed.time.start,
    'minutes'
  );
  return transformed;
};

export const sortByStart = array =>
  array.sort((left, right) =>
    diffMomentDates(left.time.start, right.time.start)
  );

export const sortByEnd = array =>
  array.sort((left, right) => diffMomentDates(right.time.end, left.time.end));

export const findShortestBlock = array =>
  array.reduce((shortestBlock, item) => {
    const diff = diffMomentDates(item.time.end, item.time.start, 'minutes');
    return shortestBlock > diff ? diff : shortestBlock;
  }, Number.MAX_SAFE_INTEGER);

export const findShortestBlockAmongLists = (...lists) =>
  lists.reduce((shortestBlock, list) => {
    const diff = findShortestBlock(list);
    return shortestBlock > diff ? diff : shortestBlock;
  }, Number.MAX_SAFE_INTEGER);

export const findEarliestStart = sessions =>
  (sessions.minBy(session => session.get('startTime').valueOf()) || Map()).get(
    'startTime'
  );
export const findLatestEnd = sessions =>
  (sessions.maxBy(session => session.get('endTime').valueOf()) || Map()).get(
    'endTime'
  );

const createFiller = (
  previousEnd,
  currentStart,
  previousWasBlock,
  previousBlockDiff,
  isBreakFiller
) => {
  const blockSize = currentStart.diff(previousEnd, 'minutes');

  return Map({
    isFiller: true,
    startTime: previousEnd,
    endTime: currentStart,
    blockSize: previousWasBlock ? blockSize + previousBlockDiff : blockSize,
    isBreakFiller,
  });
};

export const fillWithEmptySlots = (sessions, earliestStart) =>
  sessions
    .reduce(
      (context, session) => {
        const previousEnd = context.getIn(['previousSession', 'endTime']);
        const previousWasBlock = context.get('previousWasBlock');
        const previousBlockDiff = previousWasBlock
          ? context
              .getIn(['previousSession', 'endTime'])
              .diff(
                context.getIn(['previousSession', 'startTime']),
                'minutes'
              ) - BREAK_BLOCK_SCALAR
          : 0;

        const currentStart = session.get('startTime');
        const currentEnd = session.get('endTime');

        const gap = currentStart.diff(previousEnd, 'minutes');

        const isBreakFiller = session.has('isBlock');

        return context
          .set(
            'previousWasBlock',
            session.has('isBlock') && session.get('isBlock')
          )
          .set('previousSession', session)
          .update('sessions', es =>
            es
              .push(
                gap > 0
                  ? createFiller(
                      previousEnd,
                      currentStart,
                      previousWasBlock,
                      previousBlockDiff,
                      isBreakFiller
                    )
                  : undefined
              )
              .push(
                session
                  .set('blockSize', currentEnd.diff(currentStart, 'minutes'))
                  .set(
                    'breakBlockSize',
                    session.get('isBlock') ? BREAK_BLOCK_SCALAR : undefined
                  )
              )
              .filterNot(e => e === undefined)
          );
      },
      Map({
        sessions: List(),
        wasPreviousBlockBreak: false,
        previousSession: Map({ endTime: earliestStart }),
      })
    )
    .get('sessions');

// Grid Schedule

const getStartEndRow = (earliestStart, startTime, duration) => {
  const startRow = startTime.diff(earliestStart, 'minutes') + 1;
  const endRow = startRow + duration;

  return { startRow, endRow };
};

export const calcRowTemplate = (earliestStart, breaks, totalMinutes) => {
  const condensedRows = breaks.reduce((template, breakObj) => {
    if (!breakObj.get('isBlock')) return template;

    const startTime = breakObj.get('startTime');
    const duration = breakObj.get('duration');

    const { startRow, endRow } = getStartEndRow(
      earliestStart,
      startTime,
      duration
    );

    const scalar = (BREAK_BLOCK_SCALAR / duration) * MINUTE_SCALAR;

    return template.push({ startRow, endRow, scalar });
  }, List());

  const template = [];
  for (let i = 1; i <= totalMinutes; i += 1) {
    const condensedRow = condensedRows.find(
      row => i >= row.startRow && i <= row.endRow
    );
    const scalar = condensedRow ? condensedRow.scalar : MINUTE_SCALAR;

    template.push(`[row-${i}] ${scalar.toFixed(2)}px `);
  }

  return template;
};

export const getPosition = (earliestStart, startTime, duration) => {
  const { startRow, endRow } = getStartEndRow(
    earliestStart,
    startTime,
    duration
  );

  return `row-${startRow} / row-${endRow}`;
};
