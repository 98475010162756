import { List, Map, fromJS } from 'immutable';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import theme from '../theme';

export const selectConferenceHandle = (state, props) =>
  (props.match.params || {}).conferenceHandle;

export const selectDay = (state, props) => (props.match.params || {}).day;

export const selectDays = state => state.getIn(['embed', 'days'], List());

export const selectSessions = (state, props) => {
  const sessions = state.getIn(['embed', 'sessions'], List());
  return sessions.map(session => {
    const startTime = session.get('startTime');
    const endTime = session.get('endTime');
    const now = props.now || moment();
    return session
      .set('isPast', endTime.isBefore(now))
      .set('isNow', startTime.isBefore(now) && endTime.isAfter(now))
      .set('isFuture', startTime.isAfter(now));
  });
};

export const selectConference = state => state.get('embed', Map());

export const selectConferenceUsers = createSelector(
  selectConference,
  conference => conference.get('users', Map())
);

export const selectDates = state => state.getIn(['embed', 'dates']);

export const selectEmbedLayout = state =>
  state.getIn(['embed', 'embedLayout'], 'grid');

export const selectMobileEmbedLayout = state =>
  state.getIn(['embed', 'mobileEmbedLayout'], 'flat');

export const selectConfiguration = state =>
  state.getIn(['embed', 'configuration'], Map());

export const selectGeneralConfiguration = createSelector(
  selectConfiguration,
  configuration => configuration.get('general', Map())
);

export const selectTheme = state =>
  fromJS(theme)
    .merge(state.getIn(['embed', 'theme'], Map()) || Map())
    .toJS();

export const selectSubscriptionPlan = state =>
  state.getIn(['embed', 'subscriptionPlan'], 'free');
