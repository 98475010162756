import { defineMessages } from 'react-intl';

export default defineMessages({
  loggedInAs: {
    id: 'app.components.AccountPage.loggedInAs',
    defaultMessage: 'You are logged in as {displayName} ({email}).',
  },
  loggedInAsEmailOnly: {
    id: 'app.components.AccountPage.loggedInAsEmailOnly',
    defaultMessage: 'You are logged in with {email}.',
  },
  yourAccount: {
    id: 'app.components.AccountPage.yourAccount',
    defaultMessage: 'Your Account',
  },
  logOut: {
    id: 'app.components.AccountPage.logout',
    defaultMessage: 'Log Out',
  },
});
