import { actions as toastrActions } from 'react-redux-toastr';
import { call, put, select } from 'redux-saga/effects';
import firebase from 'firebase/app';

import { selectUser } from '../../../selectors/user';

const duplicateConferenceToastrOptions = {
  type: 'light',
  title: 'Error',
  message:
    'The chosen event code is already taken. Please, choose another one.',
  options: {
    showCloseButton: true,
    icon: 'error',
    status: 'error',
    timeOut: 5000,
  },
};

const unexpectedErrorToastrOptions = {
  ...duplicateConferenceToastrOptions,
};
unexpectedErrorToastrOptions.message =
  'An unexpected error occurred. If this persists, please contact support.';

const successCreation = {
  type: 'light',
  title: 'Success',
  options: {
    showCloseButton: true,
    icon: 'success',
    status: 'info',
  },
};

export default function* createConference({
  id,
  name,
  dates,
  tracks,
  sessionTypes,
  timezone,
  embedLayout,
  mobileEmbedLayout,
}) {
  const user = yield select(selectUser());
  const uid = user.get('uid');

  const conference = {
    name,
    timezone,
    dates,
    tracks,
    sessionTypes,
    owner: uid,
    users: {},
    embedLayout,
    mobileEmbedLayout,
    cfp: {
      acceptingSubmissions: false,
      submissionFormBlocks: [
        {
          label: 'Full Name',
          type: 'smallText',
          required: true,
        },
      ],
      submissionFormConfig: {
        title: `${name} Call for Papers`,
        description: 'We’re looking forward to reading your talk proposals!',
      },
      submissions: [],
    },
  };

  try {
    const possibleExistingConference = yield call(() =>
      firebase
        .database()
        .ref(`conferences/${id}/name`)
        .once('value')
    );
    if (possibleExistingConference.exists()) {
      yield put(toastrActions.add(duplicateConferenceToastrOptions));
      return;
    }
  } catch (error) {
    if (error.code === 'PERMISSION_DENIED') {
      yield put(toastrActions.add(unexpectedErrorToastrOptions));
      return;
    }
  }

  yield firebase
    .database()
    .ref(`conferences/${id}`)
    .set(conference);

  yield firebase
    .database()
    .ref(`users/${uid}/conferences`)
    .update({ [id]: true });

  yield firebase
    .database()
    .ref(`users/${uid}/currentConference`)
    .set(id);

  yield put(toastrActions.add(successCreation));

  try {
    window.$crisp.push([
      'set',
      'session:event',
      [
        [
          [
            'conference_create',
            {
              id: id,
              name: name,
              dates: dates,
              tracks: JSON.stringify(tracks),
              sessionTypes: JSON.stringify(sessionTypes),
              timezone: timezone,
            },
            'green',
          ],
        ],
      ],
    ]);
  } catch (e) {
    // crisp not ready
    console.log('crisp error', e);
  }
}
