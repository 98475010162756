import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TimeRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lightOpacity};
  border-top: 1px solid ${props => props.theme.colors.lightOpacity};
  width: 100%;
  display: grid;
  grid-template-columns: 20% 80% 100% 100%;
  grid-gap: 5px;
  padding: ${props => (props.em ? '0.4em' : '8px')};
`;

const TimeLeft = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  font-size: ${props => (props.em ? '1.3em' : '22px')};
  color: ${props => props.theme.colors.accent};
`;

const TimeRightTitle = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  font-size: ${props => (props.em ? '1.3em' : '22px')};
  color: ${props => props.theme.colors.accent};
  padding-left: ${props => (props.em ? '0.4em' : '8px')};
`;

const TimeRowWithTag = ({ startAt, tag }) => (
  <TimeRow flexDirection="row">
    <TimeLeft>{startAt}</TimeLeft>
    <TimeRightTitle>{tag}</TimeRightTitle>
  </TimeRow>
);

TimeRowWithTag.propTypes = {
  startAt: PropTypes.string,
  tag: PropTypes.string,
};

export default TimeRowWithTag;
