/*
 * CreateSpeakerPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { List } from 'immutable';
import { createSelector } from 'reselect';

import {
  selectCurrentConferenceHandle,
  selectConferenceName,
} from '../../selectors/user';

import SpeakerForm from '../../components/SpeakerForm';

import createSpeaker from '../../sagas/user/speakers/create';
import withUserRequestExecutor, {
  errorMessages,
} from '../../hocs/withUserRequestExecutor';

export class CreateSpeakerPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages,
  });

  handleSubmit = async (data, dispatch, { form }) => {
    const { conferenceHandle } = this.props;

    const { name, headline, description, tags, image, profiles } = data
      .update('profiles', List(), _profiles =>
        _profiles.map(profile => {
          const icon = profile.get('icon');
          return [
            'facebook',
            'github',
            'google',
            'linkedIn',
            'twitter',
            'instagram',
          ].indexOf(icon) >= 0
            ? profile
            : profile.delete('icon');
        })
      )
      .toJS();

    await this.defaultUserRequestExecutor(
      createSpeaker,
      conferenceHandle,
      name,
      headline,
      description,
      tags,
      image,
      profiles || [],
      false
    );
  };

  render() {
    const { conferenceName } = this.props;

    return (
      <div>
        <Helmet title={`${conferenceName} • Create Speaker • Sava Events`} />
        <SpeakerForm onSubmit={this.handleSubmit} isCreateForm />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  (conferenceHandle, conferenceName) => ({
    conferenceHandle,
    conferenceName,
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  withUserRequestExecutor
)(CreateSpeakerPage);
