/**
 *
 * IconPicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

import Icon from '../Icon';
import FacebookIcon from '../icons/FacebookIcon';
import GithubIcon from '../icons/GithubIcon';
import GoogleIcon from '../icons/GoogleIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import TwitterIcon from '../icons/TwitterIcon';
import InstagramIcon from '../icons/InstagramIcon';

const Root = styled.div`
  position: relative;

  &:focus {
    outline: none;
  }
`;

const Handle = styled.span`
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.brandPrimary};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
`;

const Dropdown = styled(Flexbox)`
  background: white;

  position: absolute;

  top: 28px;
  left: 0px;

  z-index: 1400;

  ${props => props.theme.altitude(1)};
`;

const IconWrapperButton = styled(Flexbox)`
  width: 32px;
  height: 32px;

  ${button({ backgroundColor: 'white' })};
`;

const FacebookIconButton = styled(FacebookIcon)`
  ${button({ backgroundColor: 'white' })} padding: 3px;
`;

const GithubIconButton = styled(GithubIcon)`
  ${button({ backgroundColor: 'white' })};
`;

const GoogleIconButton = styled(GoogleIcon)`
  ${button({ backgroundColor: 'white' })};
`;

const LinkedInIconButton = styled(LinkedInIcon)`
  ${button({ backgroundColor: 'white' })};
`;

const TwitterIconButton = styled(TwitterIcon)`
  ${button({ backgroundColor: 'white' })};
`;

const InstagramIconButton = styled(InstagramIcon)`
  ${button({ backgroundColor: 'white' })};
`;

/* eslint-disable react/no-find-dom-node */

export default class IconPicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.oneOf([
        '',
        'facebook',
        'github',
        'google',
        'linkedIn',
        'twitter',
        'instagram',
      ]),
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  };

  static handleFocus() {
    this.setState({ focused: true });
  }

  static handleBlur() {
    this.setState({ focused: false });
  }

  static handleMouseDown() {
    this.wasFocused = document.activeElement === ReactDOM.findDOMNode(this);
  }

  static handleClick() {
    if (this.wasFocused) {
      ReactDOM.findDOMNode(this).blur();
    }
  }

  static handleChange(value) {
    ReactDOM.findDOMNode(this).blur();

    this.props.input.onChange(value);
  }

  static handleMouseEnter() {
    this.setState({ hover: true });
  }

  static handleMouseLeave() {
    this.setState({ hover: false });
  }

  constructor(props) {
    super(props);

    this.state = { hover: false, focused: false };

    this.handleFocus = IconPicker.handleFocus.bind(this);
    this.handleBlur = IconPicker.handleBlur.bind(this);
    this.handleMouseDown = IconPicker.handleMouseDown.bind(this);
    this.handleClick = IconPicker.handleClick.bind(this);
    this.handleChange = IconPicker.handleChange.bind(this);
    this.handleMouseEnter = IconPicker.handleMouseEnter.bind(this);
    this.handleMouseLeave = IconPicker.handleMouseLeave.bind(this);
  }

  render() {
    const {
      input: { value },
    } = this.props;
    const { hover, focused } = this.state;

    let SocialIcon;

    if (value === 'facebook') {
      SocialIcon = FacebookIcon;
    } else if (value === 'github') {
      SocialIcon = GithubIcon;
    } else if (value === 'google') {
      SocialIcon = GoogleIcon;
    } else if (value === 'linkedIn') {
      SocialIcon = LinkedInIcon;
    } else if (value === 'twitter') {
      SocialIcon = TwitterIcon;
    } else if (value === 'instagram') {
      SocialIcon = InstagramIcon;
    }

    let icon;

    if (SocialIcon) {
      icon = (
        <SocialIcon
          width={32}
          height={32}
          padding={6}
          color={hover ? '#248bd1' : '#333'}
          style={{ padding: 3 }}
        />
      );
    } else {
      icon = (
        <IconWrapper>
          <Icon>launch</Icon>
        </IconWrapper>
      );
    }

    return (
      <Root
        tabIndex={-1}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
      >
        <Handle
          onMouseDown={this.handleMouseDown}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {icon}
        </Handle>
        {focused && (
          <Dropdown>
            <IconWrapperButton
              justifyContent="center"
              alignItems="center"
              onClick={() => this.handleChange(null)}
            >
              <Icon>launch</Icon>
            </IconWrapperButton>
            <TwitterIconButton
              width={32}
              height={32}
              padding={6}
              color="#333"
              onClick={() => this.handleChange('twitter')}
            />
            <LinkedInIconButton
              width={32}
              height={32}
              padding={6}
              color="#333"
              onClick={() => this.handleChange('linkedIn')}
            />
            <FacebookIconButton
              width={32}
              height={32}
              padding={6}
              color="#333"
              onClick={() => this.handleChange('facebook')}
            />
            <GithubIconButton
              width={32}
              height={32}
              padding={6}
              color="#333"
              onClick={() => this.handleChange('github')}
            />
            <GoogleIconButton
              width={32}
              height={32}
              padding={6}
              color="#333"
              onClick={() => this.handleChange('google')}
            />
            <InstagramIconButton
              width={32}
              height={32}
              padding={6}
              color="#333"
              onClick={() => this.handleChange('instagram')}
            />
          </Dropdown>
        )}
      </Root>
    );
  }
}

/* eslint-enable react/no-find-dom-node */
