import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';

import NotFound from '../../NotFoundPage';
import SessionPage from '../SessionPage';
import EmbedScheduleDaysWrapper from '../EmbedScheduleDaysWrapper';
import { selectMatchParam } from '../../../selectors/navigation';
import { getLastDayViewed } from '../../../utils/lastDayViewed';

class EmbedScheduleWrapper extends Component {
  render() {
    const { conferenceHandle } = this.props;
    const lastDayViewed = getLastDayViewed(conferenceHandle);

    return (
      <Switch>
        <Redirect
          exact
          from="/@:conferenceHandle/embed/schedule"
          to={`/@:conferenceHandle/embed/schedule/days/${lastDayViewed}`}
        />
        <Route
          exact
          path="/@:conferenceHandle/embed/schedule/sessions/:id"
          component={SessionPage}
        />
        <Route
          path="/@:conferenceHandle/embed/schedule/days"
          component={EmbedScheduleDaysWrapper}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = createSelector(
  selectMatchParam('conferenceHandle'),
  conferenceHandle => ({
    conferenceHandle,
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps)
)(EmbedScheduleWrapper);
