import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';
import { fromJS } from 'immutable';

import { acceptUser, signUpError } from '../../../actions/user';

export default function* signUp({ fullName, email, password }) {
  try {
    const ref = yield firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const uid = ref.user.uid;
    yield firebase
      .database()
      .ref(`users/${uid}`)
      .update({
        fullName,
        email,
        createdAt: Date.now(),
        uid,
      });

    yield put(acceptUser(fromJS(ref.user.toJSON())));
    yield put(push('/'));
  } catch (error) {
    yield put(signUpError(error));
  }
}
