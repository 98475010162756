import { combineReducers } from 'redux-immutable';
import { routerReducer as router } from 'react-router-redux';
import { reducer as form } from 'redux-form/immutable';
import { reducer as toastr } from 'react-redux-toastr';
import { loadingBarReducer } from 'react-redux-loading-bar';

import language from './language';
import user from './user';
import navigation from './navigation';
import embed from './embed';

export default combineReducers({
  language,
  user,
  navigation,
  embed,
  router,
  form,
  toastr,
  loadingBar: loadingBarReducer,
});
