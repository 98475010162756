import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';

import Main from './Main';
import { unregister } from './registerServiceWorker';

ReactDOM.hydrate(<Main />, document.getElementById('app'));

// TODO: Turn the service worker on when we’ve actually fixed it.
unregister();
