/*
 * SponsorGroupForm Messages
 *
 * This contains all the text for the SponsorGroupForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.SponsorGroupForm.name',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'app.components.SponsorGroupForm.namePlaceholder',
    defaultMessage: 'e.g., Platinum Sponsors',
  },
  create: {
    id: 'app.components.SponsorGroupForm.create',
    defaultMessage: 'Add New Sponsor Group',
  },
  update: {
    id: 'app.components.SponsorGroupForm.update',
    defaultMessage: 'Update Sponsor Group',
  },
  delete: {
    id: 'app.components.SponsorGroupForm.delete',
    defaultMessage: 'Delete',
  },
});
