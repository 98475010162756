import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import Flexbox from 'flexbox-react';

import styled from 'styled-components';

const Item = styled(Flexbox)`
  height: 42px;
`;

const ItemView = ({ name }) => (
  <Item flexDirection="column" justifyContent="center">
    {name}
  </Item>
);

ItemView.propTypes = {
  title: PropTypes.string.isRequired,
};

const LayoutPicker = ({ layouts, input: { value, onChange }, ...props }) => (
  <Select
    clearable={false}
    searchable={false}
    options={layouts}
    optionRenderer={ItemView}
    matchProp="name"
    labelKey="name"
    valueKey="id"
    value={value}
    valueRenderer={ItemView}
    onChange={val => onChange(val.id)}
    {...props}
  />
);

LayoutPicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, props) => {
  const layouts = [{ id: 'flat', name: 'Flat' }, { id: 'grid', name: 'Grid' }];

  return { layouts };
})(LayoutPicker);
