/*
 *  CreateSessionPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fromJS } from 'immutable';
import queryString from 'qs';
import { createSelector } from 'reselect';

import { selectCurrentConferenceHandle } from '../../selectors/user';

import SessionForm from '../../components/SessionForm';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import createSession from '../../sagas/user/sessions/create';

import convertTime from '../../utils/convertTime';

export class CreateSessionPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    location: PropTypes.object,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages: error => {
      switch ((error || {}).code) {
        case 'session/duration':
          return 'Session can not have 0 or negative duration. Please correct start/end time of this session.';
        case 'session/overlapping':
          return 'Session overlaps with another session. Please correct start/end time of this session.';
        default:
          return 'Please try again. If error persists, contact our support.';
      }
    },
  });

  handleSubmit = async data => {
    const { conferenceHandle } = this.props;
    const {
      sessionType,
      track,
      title,
      description,
      tags,
      speakers,
      day,
      video,
      time,
    } = data
      .updateIn(['time', 'startTime'], convertTime)
      .updateIn(['time', 'endTime'], convertTime)
      .toJS();

    await this.defaultUserRequestExecutor(createSession, {
      conferenceHandle,
      sessionType,
      track,
      title,
      description,
      tags,
      speakers,
      day,
      time,
      video,
    });
  };

  render() {
    const { conferenceHandle, location } = this.props;
    const parsedQuery = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const initialValues = fromJS({
      track: parsedQuery.track,
      day: parsedQuery.day,
    });

    return (
      <div>
        <Helmet title="Create Session • Sava Events" />
        <SessionForm
          conferenceHandle={conferenceHandle}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          isCreateForm
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  conferenceHandle => ({
    conferenceHandle,
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  withUserRequestExecutor
)(CreateSessionPage);
