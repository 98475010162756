import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { compose, withState, setPropTypes } from 'recompose';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { Map, fromJS } from 'immutable';
import moment from 'moment-timezone';

import { FormErrorWithMargins as FormError } from '../../components/FormError';
import BroadcastForm from '../../components/BroadcastForm';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import messages from './messages';
import {
  selectCurrentConferenceHandle,
  selectBroadcastBasedOnMatchParam,
  selectTimezone,
} from '../../selectors/user';
import { updateBroadcast } from '../../sagas/user/conferences/broadcasts';

const EditBroadcastForm = BroadcastForm('editBroadcastForm');

const EditBroadcastPage = ({
  error,
  setError,
  broadcast,
  intl,
  createUserRequestExecutor,
  conferenceHandle,
  history,
  timezone,
}) => {
  const defaultUserRequestExecutor = createUserRequestExecutor({
    onStart: () => setError(false),
    onEnd: () => history.goBack(),
    onFailure: () =>
      setError(intl.formatMessage(messages.updateBroadcastError)),
    errorMessages: () => intl.formatMessage(messages.updateBroadcastError),
  });

  const handleSubmit = async data => {
    const formData = data.toJS();

    await defaultUserRequestExecutor(
      updateBroadcast,
      conferenceHandle,
      broadcast.get('id'),
      formData
    );
  };

  return (
    <div>
      <Helmet title={intl.formatMessage(messages.title)} />
      {error && <FormError>{error}</FormError>}
      <EditBroadcastForm
        onSubmit={handleSubmit}
        initialValues={broadcast}
        timezone={timezone}
      />
    </div>
  );
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectBroadcastBasedOnMatchParam,
  selectTimezone,
  (conferenceHandle, broadcast, timezone) => {
    const firstMessage = (
      broadcast.get('messages', Map()).first() || Map()
    ).toJS();
    const extractedFirstMessage = {
      type: firstMessage.type,
      ...firstMessage,
    };

    const scheduleDate = moment(broadcast.get('scheduleTime'));
    const schedule = {
      date: scheduleDate,
      time: scheduleDate.format('HH:mm'),
    };

    const broadcastForUpdate = fromJS({
      id: broadcast.get('id'),
      ...extractedFirstMessage,
      schedule: schedule,
      timestamp: broadcast.get('timestamp'),
    });

    return {
      conferenceHandle,
      broadcast: broadcastForUpdate,
      timezone,
    };
  }
);

export default compose(
  withUserRequestExecutor,
  withRouter,
  injectIntl,
  withState('error', 'setError', false),
  connect(mapStateToProps),
  setPropTypes({
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    broadcast: PropTypes.any.isRequired,
    timezone: PropTypes.string.isRequired,
  })
)(EditBroadcastPage);
