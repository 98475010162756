import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { fromJS } from 'immutable';
import intlMessages from './messages';
import Icon from '../../components/Icon';

import { link } from '../../utils/ui/interactive';

const background = {
  error: '#c33',
  warning: '#dd4',
  success: '#5c5',
};

const Messages = styled(Flexbox)``;

const ClearAllButton = styled(Flexbox)`
  padding: 20px;
  margin-top: -20px;

  ${link({ color: '#333', fade: true, decorate: false })};
`;

const Message = styled(Flexbox)`
  padding: 20px;
  margin: 0;
  margin-bottom: 20px;

  color: white;
  background-color: ${props => background[props.type]};

  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const ClearButton = styled(Flexbox)`
  margin: -20px;
  padding: 20px;

  ${link({ color: 'white', fade: true, decorate: false })};
`;

const GlobalMessages = ({ location, history, className, style }) => {
  const messages = (location.state || {}).messages || [];

  if (messages.length > 0) {
    const clearAllMessages = () =>
      history.replace({
        pathname: location.pathname,
        search: location.search,
        state: fromJS(location.state)
          .delete('messages')
          .toJS(),
      });
    const clearMessage = i =>
      history.replace({
        pathname: location.pathname,
        search: location.search,
        state: fromJS(location.state)
          .deleteIn(['messages', i])
          .toJS(),
      });

    return (
      <Messages
        flexDirection="column"
        alignItems="stretch"
        className={className}
        style={style}
      >
        {messages.length > 1 && (
          <ClearAllButton onClick={clearAllMessages} alignSelf="flex-end">
            <FormattedMessage {...intlMessages.clearAll}>
              {message => message}
            </FormattedMessage>
            {''}
            <Icon>clear</Icon>
          </ClearAllButton>
        )}
        {messages.map((message, i) => (
          <Message key={i} justifyContent="space-between" type={message.type}>
            {message.text}
            <ClearButton onClick={() => clearMessage(i)}>
              <Icon>clear</Icon>
            </ClearButton>
          </Message>
        ))}
      </Messages>
    );
  }

  return null;
};

GlobalMessages.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({
      messages: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
          text: PropTypes.string.isRequired,
        }).isRequired
      ),
    }),
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.any,
  style: PropTypes.any,
};

export default withRouter(injectIntl(GlobalMessages));
