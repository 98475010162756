import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { button } from '../../utils/ui/interactive';
import interactive from '../../utils/ui/interactive';

const block = css`
  margin: 0;
  padding: 0;
  line-height: 1;
  text-decoration: none;
  height: 100%;

  ${props => css`
    background-color: ${props.theme.card.backgroundColor};
    color: ${props.theme.card.textColor};
  `};
`;

export const LinkedSessionBlock = styled(Link)`
  ${block}

  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${props =>
    button({
      backgroundColor: props.theme.card.backgroundColor,
      color: props.theme.card.textColor,
    })}

  ${interactive.styles`
    z-index: 60;
  `}

  ${interactive.hover`
    z-index: 80;
  `}
`;
