import text from './text';
import generic from './generic';
import list from './list';

export default data => {
  if (data.type === 'text') {
    return { type: data.type, message: text(data.message) };
  }

  if (data.type === 'generic' || data.type === 'followUp') {
    return {
      type: data.type,
      item: generic(data.item),
    };
  }

  if (data.type === 'list') {
    return {
      type: data.type,
      items: list(data.items),
    };
  }

  return data;
};
