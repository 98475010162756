/**
 *
 *  Session
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled, { css } from 'styled-components';

import interactive from '../../utils/ui/interactive';
import SavaPropTypes from '../../utils/SavaPropTypes';

import SpeakersList from '../../components/SpeakersList';
import SessionTitle from '../SessionTitle';
import SessionCard from './SessionCard';
import { LinkedSessionBlock } from '../../components/LinkedSessionBlock';

const CondensedSessionTitle = styled.div`
  margin-top: 0.5em;
  height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const block = css`
  margin: 0;
  padding: 0;
  line-height: 1;
  text-decoration: none;
  height: 100%;

  ${props => css`
    background-color: ${props.theme.card.backgroundColor};
    color: ${props.theme.card.textColor};
  `};
`;

const SessionBlock = styled.div`
  ${block} ${interactive.styles`
    z-index: 40;
  `};
`;

const using = (...values) => f => f(...values);

const Session = ({
  conferenceHandle,
  linkProvider,
  session,
  height = '100%',
  hideStartTime,
  showDay,
  showTrack,
  hideSessionType,
  listSpeakers,
  isPastEffectEnabled,
  ignoreDuration,
}) =>
  using(
    linkProvider('session', {
      conferenceHandle,
      day: session.get('day'),
      sessionId: session.get('id'),
      sessionTitle: session.get('title'),
    })
  )(sessionLink => {
    const duration =
      session.get('duration') ||
      Math.abs(
        session.get('startTime').diff(session.get('endTime'), 'minutes')
      );
    const sessionColor = session.getIn(['sessionType', 'color'], '#e0e0e0');

    if (duration < 15 && !ignoreDuration) {
      return React.createElement(
        sessionLink ? LinkedSessionBlock : SessionBlock,
        {
          to: sessionLink,
          style: { height },
        },
        <SessionCard
          style={{ padding: '0.5rem 1rem 10px' }}
          isPast={isPastEffectEnabled && session.get('isPast')}
          sessionColor={sessionColor}
          session={session}
          hideStartTime={true}
          showDay={showDay}
          showTrack={showTrack}
          hideSessionType={true}
        >
          <CondensedSessionTitle>{session.get('title')}</CondensedSessionTitle>
        </SessionCard>
      );
    }

    if (duration < 25 && !ignoreDuration) {
      return React.createElement(
        sessionLink ? LinkedSessionBlock : SessionBlock,
        {
          to: sessionLink,
          style: { height },
        },
        <SessionCard
          isPast={isPastEffectEnabled && session.get('isPast')}
          sessionColor={sessionColor}
          session={session}
          hideStartTime={hideStartTime}
          showDay={showDay}
          showTrack={showTrack}
          hideSessionType={hideSessionType}
        >
          <CondensedSessionTitle>{session.get('title')}</CondensedSessionTitle>
        </SessionCard>
      );
    }

    return React.createElement(
      sessionLink ? LinkedSessionBlock : SessionBlock,
      {
        to: sessionLink,
        style: { height },
      },
      <SessionCard
        isPast={isPastEffectEnabled && session.get('isPast')}
        sessionColor={sessionColor}
        session={session}
        hideStartTime={hideStartTime}
        showDay={showDay}
        showTrack={showTrack}
        hideSessionType={hideSessionType}
      >
        <SessionTitle>{session.get('title')}</SessionTitle>
        {using(session.get('speakers', List()))(speakers =>
          speakers.count() > 1 && !listSpeakers ? (
            <SpeakersList
              {...{ speakers, linkProvider, conferenceHandle }}
              type="tiny"
            />
          ) : (
            speakers.count() > 0 && (
              <SpeakersList {...{ speakers, linkProvider, conferenceHandle }} />
            )
          )
        )}
      </SessionCard>
    );
  });

Session.propTypes = {
  conferenceHandle: PropTypes.string.isRequired,
  linkProvider: PropTypes.func.isRequired,
  session: SavaPropTypes.session,
  height: PropTypes.number,
  hideStartTime: PropTypes.bool,
  showDay: PropTypes.bool,
  showTrack: PropTypes.bool,
  hideSessionType: PropTypes.bool,
  listSpeakers: PropTypes.bool,
  ignoreDuration: PropTypes.bool,
};

export default Session;
