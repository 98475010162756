import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';

import CardLink from './CardLink';
import Icon from '../../components/Icon/index';
import SpeakerImage from '../../components/SpeakerImage';

const Name = styled(Flexbox)`
  text-align: center;
`;

const Headline = styled(Flexbox)`
  text-align: center;
`;

const DragIcon = styled(Icon)`
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #e7e7e7;
`;

const Card = styled(CardLink)`
  :hover {
    > i {
      cursor: move;
      opacity: 1;
    }
  }
`;

class Speaker extends Component {
  static propTypes = {
    optimalSize: PropTypes.any.isRequired,
    conferenceHandle: PropTypes.any.isRequired,
    speaker: PropTypes.any.isRequired,

    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    isDragged: PropTypes.bool,
  };

  render() {
    const {
      optimalSize,
      conferenceHandle,
      speaker,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isDragged,
    } = this.props;

    const styles = {
      card: {
        width: optimalSize,
        margin: 0,
        visibility: isDragging ? 'hidden' : undefined,
        background: isDragged ? 'white' : undefined,
        boxShadow: isDragged ? '0 2px 8px rgba(0, 0, 0, 0.1)' : undefined,
        cursor: isDragged ? 'move' : undefined,
      },
      icon: {
        opacity: isDragged ? '1' : undefined,
      },
    };

    return connectDragSource(
      connectDropTarget(
        <div
          style={{
            margin: 10,
            background: isDragging && !isDragged ? '#e7e7e7' : undefined,
          }}
        >
          <Card
            to={`/@${conferenceHandle}/speakers/${speaker.id}`}
            style={styles.card}
          >
            <DragIcon style={styles.icon}>open_with</DragIcon>
            <Flexbox
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <SpeakerImage
                image={speaker.image}
                backgroundColor={speaker.color || '#f0f0f0'}
                type="medium"
              />
              <Name
                style={{
                  fontSize: optimalSize / 12,
                  margin: `${optimalSize / 32}px 0 0 0`,
                }}
              >
                {speaker.name}
              </Name>
              <Headline style={{ fontSize: optimalSize / 24 }}>
                {speaker.headline || '—'}
              </Headline>
            </Flexbox>
          </Card>
        </div>
      )
    );
  }
}

const ItemTypes = {
  CARD: 'CARD',
};

const cardSource = {
  beginDrag(props) {
    props.onDrag();

    return {
      index: props.index,
      optimalSize: props.optimalSize,
      conferenceHandle: props.conferenceHandle,
      speaker: props.speaker,
    };
  },
};

const cardTarget = {
  drop(props) {
    props.onDrop();
  },
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    /* eslint-disable no-param-reassign */
    monitor.getItem().index = hoverIndex;
    /* eslint-enable no-param-reassign */
  },
};

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function collectDropTarget(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default DragSource(ItemTypes.CARD, cardSource, collectDragSource)(
  DropTarget(ItemTypes.CARD, cardTarget, collectDropTarget)(Speaker)
);
