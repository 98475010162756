import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import responsive from '../../../utils/ui/responsive';

export const Container = styled(Flexbox)`
  padding: 1.5em;

  ${responsive.md.andSmaller`
    flex-wrap: wrap;
    background-image: none;
  `};
  align-items: baseline;
`;
