import { defineMessages } from 'react-intl';

export default defineMessages({
  breaksEmptyState: {
    id: 'app.containers.BreaksPage.breaksEmptyState',
    defaultMessage: 'People need a break',
  },
  breaksEmptyStateHint: {
    id: 'app.containers.BreaksPage.breaksEmptyStateHint',
    defaultMessage:
      'Create a break to get started. You can later customize how they’re displayed within the Schedule.',
  },
});
