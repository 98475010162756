import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';

import {
  selectConferenceName,
  selectCurrentConferenceHandle,
} from '../../selectors/user';

import { FormErrorWithMargins as FormError } from '../../components/FormError';
import ChatQuickReplyForm from '../../components/ChatQuickReplyForm';
import saveQuickReply from '../../sagas/user/conferences/saveQuickReply';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import messages from '../../components/ChatQuickReplyForm/messages';

export class CreateQuickReplyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    onStart: () => this.setState({ error: false }),
    onEnd: () => this.props.history.goBack(),
    onFailure: () => this.setState({ error: true }),
  });

  handleSubmit = async data => {
    const { conferenceHandle } = this.props;
    const formData = data.toJS();

    await this.defaultUserRequestExecutor(
      saveQuickReply,
      conferenceHandle,
      formData
    );
  };

  render() {
    const { error } = this.state;
    const { conferenceName, intl } = this.props;

    return (
      <div>
        <Helmet
          title={`${conferenceName} | ${intl.formatMessage(
            messages.createTitle
          )}`}
        />
        {error && (
          <FormError>{intl.formatMessage(messages.createError)}</FormError>
        )}
        <ChatQuickReplyForm isCreateForm onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  (conferenceHandle, conferenceName) => ({
    conferenceHandle,
    conferenceName,
  })
);

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
  withUserRequestExecutor
)(CreateQuickReplyPage);
