import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SessionTitle from '../SessionTitle';

const SessionDetails = styled.div`
  margin-top: 0.25em;
`;

const SessionTitleCustom = styled(SessionTitle)`
  margin-bottom: 0.25em;
`;

const Duration = styled.div`
  display: inline-block;
  font-size: ${props => (props.em ? '0.7em' : '14px')};
  color: ${props => props.theme.colors.dark};
  margin-right: 1em;
`;

const TrackName = styled.div`
  display: inline-block;
  font-size: ${props => (props.em ? '0.7em' : '14px')};
  color: ${props => props.theme.colors.accent};
`;

const Right = styled.div`
  color: ${props => props.theme.page.color};
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.lightOpacity};
  padding: ${props => (props.em ? '0.4em' : '0.6em')};
`;

const SpeakerName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 0;
  text-align: center;
  line-height: 1.4;
  max-height: 3em;
`;

const SpeakerTitle = styled.div`
  width: 100%;
  font-size: 0.85em;
  opacity: 0.65;
  margin-bottom: 0.5em;
`;

const SessionList = ({
  title,
  trackName,
  startTime,
  endTime,
  speakersList,
}) => (
  <Right>
    <SessionDetails>
      <Duration>
        {startTime} - {endTime}
      </Duration>
      <TrackName>{trackName}</TrackName>
    </SessionDetails>
    <SessionTitleCustom>{title}</SessionTitleCustom>
    {speakersList.map((speaker, i) => (
      <div>
        <SpeakerName>{speaker.get('name')}</SpeakerName>
        <SpeakerTitle>{speaker.get('headline')}</SpeakerTitle>
      </div>
    ))}
  </Right>
);

SessionList.propTypes = {
  title: PropTypes.string,
  trackName: PropTypes.string,
  duration: PropTypes.number,
};

export default SessionList;
