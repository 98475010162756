import firebase from 'firebase/app';

export default function* closeTicket(conferenceHandle, ticketId, userId) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/tickets/${ticketId}`)
    .update({
      status: 'closed',
      closedAt: new Date().toJSON(),
      closedBy: userId,
    });
}
