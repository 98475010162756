import firebase from 'firebase/app';
import excludeFromMap from 'sava-shared/lib/utils/excludeFromMap';
import { compose as transformQuickReply } from 'recompose';

export default function* saveQuickReply(conferenceHandle, quickReply) {
  const quickRepliesRef = yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/chatbotSettings/quickReplies`);

  const quickReplies = yield quickRepliesRef.once('value');
  const newOrderIndex = Object.keys(quickReplies).length + 1;

  if (quickReply.id) {
    return yield quickRepliesRef
      .child(quickReply.id)
      .set(transformQuickReply(excludeId, setOrder(newOrderIndex))(quickReply));
  }

  return yield quickRepliesRef.push(
    transformQuickReply(setOrder(newOrderIndex))(quickReply)
  );
}

const excludeId = quickReply => excludeFromMap(quickReply, ['id']);
const setOrder = (order = 0) => quickReply => ({
  ...quickReply,
  order: quickReply.order ? quickReply.order : order,
});
