/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Flexbox from 'flexbox-react';

import Icon from '../../components/Icon';

import messages from './messages';

export default () => (
  <Flexbox justifyContent="center" alignContent="center" alignItems="center">
    <Helmet title="Page Not Found • Sava Events" />
    <div>
      <p>
        <Icon>search</Icon> <FormattedMessage {...messages.header} />
      </p>
      <Link to="/">
        <FormattedMessage {...messages.action} />
      </Link>
    </div>
  </Flexbox>
);
