import { defineMessages } from 'react-intl';

export default defineMessages({
  speakersEmptyState: {
    id: 'app.containers.SpeakersPage.speakersEmptyState',
    defaultMessage: 'No Speakers Yet',
  },
  speakersEmptyStateHint: {
    id: 'app.containers.SpeakersPage.speakersEmptyStateHint',
    defaultMessage:
      'Create a speaker to get started. You can also create speakers from the Schedule page as you add sessions.',
  },
});
