import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { Field } from 'redux-form/immutable';
import styled from 'styled-components';
import ColorPicker from '../ColorPicker';

const ColorText = styled.p`
  margin: 0.5em 0;
`;

const colors = [
  '#ffffff',
  '#000000 ',
  '#697689',
  '#37D67A',
  '#2CCCE4',
  '#555555',
  '#dce775',
  '#ff8a65',
  '#ba68c8',
  'transparent',
];

class ThemeColorInput extends React.Component {
  static propTypes = {
    input: PropTypes.any,
    label: PropTypes.any.isRequired,
  };

  render() {
    const { input, label } = this.props;

    return (
      <Flexbox
        flexDirection="row"
        alignItems="center"
        style={{ marginBottom: '5px' }}
        justifyContent="space-between"
      >
        <ColorText>{label}</ColorText>
        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
          <Field
            name={input.name}
            colors={colors}
            component={ColorPicker}
            normalize={value => value.hex}
            required
          />
        </div>
      </Flexbox>
    );
  }
}

export default ThemeColorInput;
