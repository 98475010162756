/**
 *
 * DayInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Range } from 'immutable';
import Flexbox from 'flexbox-react';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';

import styled from 'styled-components';

const Item = styled(Flexbox)`
  height: 42px;
`;

const Day = styled(Flexbox)`
  font-size: 15px;
  line-height: 1;
`;

const Date = styled(Flexbox)`
  font-size: 11px;
  line-height: 1;
`;

const ItemView = ({ day, date }) => (
  <Item flexDirection="column" justifyContent="center">
    <Day>Day {day + 1}</Day>
    <Date>{date.format('dddd, MMMM Do')}</Date>
  </Item>
);

ItemView.propTypes = {
  day: PropTypes.number.isRequired,
  date: MomentPropTypes.momentObj.isRequired,
};

const DayInput = ({ dates, input: { value, onChange } }) => (
  <Select
    clearable={false}
    searchable={false}
    options={dates}
    optionRenderer={ItemView}
    matchProp="name"
    labelKey="name"
    valueKey="id"
    value={value}
    valueRenderer={ItemView}
    onChange={val => onChange(val.id)}
  />
);

DayInput.propTypes = {
  dates: PropTypes.any.isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, props) => {
  const [firstDateStr, durationStr] = state
    .getIn(['user', 'userData', 'currentConference', 'dates'])
    .split(',', 2);
  const [year, month, day] = firstDateStr
    .split('/', 3)
    .map(n => parseInt(n, 10));
  const dates = Range(0, parseInt(durationStr, 10))
    .map(i => ({
      id: i,
      day: i,
      date: moment([year, month - 1, day]).add(i, 'days'),
    }))
    .toJS();

  return { dates };
})(DayInput);
