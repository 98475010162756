/*
 * EmbedPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'qs';
import { createSelector } from 'reselect';

import actions from '../../../actions/embed';
import NotFound from '../../NotFoundPage';
import SponsorPage from '../SponsorPage';
import SpeakerPage from '../SpeakerPage';
import SponsorsPage from '../SponsorsPage';
import EmbedScheduleWrapper from '../EmbedScheduleWrapper';
import EmbedContainer from '../EmbedContainer';
import SpeakersPage from '../SpeakersPage';
import CallForPapersPage from '../CallForPapersPage';
import { getLastDayViewed } from '../../../utils/lastDayViewed';
import { selectMatchParam } from '../../../selectors/navigation';
import { selectCurrentDay } from '../../../selectors/common';
import PreserveScrollPosition from '../../../components/PreserveScrollPosition';

class EmbedPage extends Component {
  static propTypes = {
    addTagFilter: PropTypes.func,
    addSpeakerFilter: PropTypes.func,
  };

  componentWillMount() {
    const { location } = this.props;
    const { search } = location;

    const { tagFilters, speakerFilters } = queryString.parse(search, {
      ignoreQueryPrefix: true,
    });

    if (tagFilters) {
      tagFilters.split(',').map(this.props.addTagFilter);
    }

    if (speakerFilters) {
      speakerFilters.split(',').map(this.props.addSpeakerFilter);
    }
  }

  render() {
    const { conferenceHandle, currentDay } = this.props;
    const lastDayViewed = getLastDayViewed(conferenceHandle);
    const withThemeColors = !this.props.location.pathname.includes(
      '/embed/cfp'
    );

    return (
      <EmbedContainer withThemeColors={withThemeColors}>
        <PreserveScrollPosition />
        <Switch>
          <Redirect
            exact
            from="/@:conferenceHandle/embed"
            to={`/@:conferenceHandle/embed/schedule/days/${lastDayViewed ||
              currentDay}`}
          />
          <Route
            exact
            path="/@:conferenceHandle/embed/speakers"
            component={SpeakersPage}
          />
          <Route
            exact
            path="/@:conferenceHandle/embed/speakers/:id"
            component={SpeakerPage}
          />
          <Route
            exact
            path="/@:conferenceHandle/embed/sponsors"
            component={SponsorsPage}
          />
          <Route
            exact
            path="/@:conferenceHandle/embed/sponsors/:id"
            component={SponsorPage}
          />
          <Route
            path="/@:conferenceHandle/embed/schedule"
            component={EmbedScheduleWrapper}
          />
          <Route
            path="/@:conferenceHandle/embed/cfp"
            component={CallForPapersPage}
          />
          <Route component={NotFound} />
        </Switch>
      </EmbedContainer>
    );
  }
}

const mapStateToProps = createSelector(
  selectMatchParam('conferenceHandle'),
  selectCurrentDay,
  (conferenceHandle, currentDay) => ({
    conferenceHandle,
    currentDay,
  })
);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    actions
  )
)(EmbedPage);
