import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Icon = ({ children, className, style }) => (
  <i className={classNames('material-icons', className)} style={style}>
    {children}
  </i>
);

Icon.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.any,
  style: PropTypes.any,
};

export default Icon;
