/**
 *
 * ColorPicker
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, withState } from 'recompose';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;

  width: 22px;
  height: 22px;

  &:focus {
    outline: none;
  }
`;

const Head = styled.div`
  width: 22px;
  height: 22px;

  border-radius: 4px;

  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;

  top: 28px;
  left: -74px;

  z-index: 1400;

  ${props => props.theme.altitude(1)};
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1001;
`;

const ColorPicker = ({ focused, setFocused, input, colors }) => {
  const handleClick = () => {
    setFocused(!focused);
  };

  const handleClose = () => {
    setFocused(false);
  };

  const handleChange = value => {
    input.onChange(value);
  };

  const { value } = input;
  return (
    <Root>
      <Head
        style={{
          backgroundColor: value,
          border: '1px solid rgba(0, 0, 0, 0.1)',
        }}
        onClick={handleClick}
      />
      {focused && (
        <Fragment>
          <Cover onClick={handleClose} />
          <Dropdown>
            <SketchPicker
              disableAlpha
              color={value}
              presetColors={colors}
              onChange={handleChange}
            />
          </Dropdown>
        </Fragment>
      )}
    </Root>
  );
};

export default compose(
  withState('focused', 'setFocused', false),
  setPropTypes({
    input: PropTypes.shape({
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  })
)(ColorPicker);
