/**
 *
 * SpeakerPicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Field } from 'redux-form/immutable';

import SelectOrCreate from '../SelectOrCreate';
import SpeakerInput from '../SpeakerInput';
import SpeakerView from '../SpeakerView';

const SpeakerPicker = SelectOrCreate(
  SpeakerInput,
  SpeakerView,
  (state, props) =>
    state
      .getIn(['user', 'userData', 'currentConference', 'speakers'], Map())
      .entrySeq()
      .map(([k, v]) => v.set('id', k))
      .toJS()
);

export const CollectionItem = ({ conferenceHandle, name }) => (
  <Field
    name={name}
    component={SpeakerPicker}
    props={{ conferenceHandle }}
    required
  />
);

CollectionItem.propTypes = {
  conferenceHandle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

CollectionItem.defaultValue = Map({ id: null });

export default SpeakerPicker;
