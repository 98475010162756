import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { Map } from 'immutable';
import styled from 'styled-components';

import Icon from '../Icon';
import FormError from '../FormError';

import { button } from '../../utils/ui/interactive';
import { lifecycle } from 'recompose';

const Button = styled.button`
  padding: 6px;
  padding-bottom: 4px;

  ${button({ backgroundColor: 'white' })};
`;

const AddButton = styled(Button)`
  padding-right: 10px;
`;

const Block = styled.div`
  ${props => props.theme.altitude(0)};
`;

const DefaultListWrapper = ({
  children,
  onAdd,
  touched,
  error,
  max,
  fieldsCount,
}) => {
  const handleAdd = e => {
    e.preventDefault();
    onAdd();
  };

  return (
    <Flexbox flexDirection="column">
      {touched && error && <FormError>{error}</FormError>}
      <div style={{ marginTop: 1 }}>{children}</div>

      {fieldsCount < max ? (
        <div style={{ marginTop: 12 }}>
          <AddButton type={'button'} onClick={handleAdd}>
            <Icon>add</Icon> Add
          </AddButton>
        </div>
      ) : (
        <div>You have reached maximum of collection inputs</div>
      )}
    </Flexbox>
  );
};

DefaultListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onAdd: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.any,
};

const DefaultItemWrapper = ({
  children,
  onRemove,
  min,
  current,
  fieldsCount,
}) => {
  const handleRemove = e => {
    e.preventDefault();
    onRemove();
  };

  return (
    <Flexbox
      flexDirection="row"
      flexGrow={2}
      alignItems="flex-start"
      style={{ marginTop: -1 }}
    >
      <Block>{children}</Block>
      <div style={{ marginLeft: 6 }}>
        {fieldsCount > min && (
          <Button type={'button'} onClick={handleRemove}>
            <Icon>clear</Icon>
          </Button>
        )}
      </div>
    </Flexbox>
  );
};

DefaultItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default function CollectionInput(
  ItemInput,
  ListWrapper = DefaultListWrapper,
  ItemWrapper = DefaultItemWrapper,
  min = 0,
  max = Number.POSITIVE_INFINITY
) {
  const renderField = props => (field, i, fields) => {
    const handleRemove = () => fields.remove(i);
    const fieldsCount = fields && fields.getAll() ? fields.getAll().size : 0;

    if (i >= max) {
      console.warn('Collection input items exceeded maximum');
    }
    return (
      <ItemWrapper
        onRemove={handleRemove}
        key={i}
        min={min}
        current={i}
        fieldsCount={fieldsCount}
      >
        <ItemInput name={field} {...props} />
      </ItemWrapper>
    );
  };

  const collectionInput = ({ props, values, fields, meta }) => {
    const handleAdd = () => fields.push(ItemInput.defaultValue || Map());
    const fieldsCount = fields && fields.getAll() ? fields.getAll().size : 0;

    return (
      <ListWrapper
        onAdd={fieldsCount < max ? handleAdd : () => undefined}
        {...meta}
        max={max}
        fieldsCount={fieldsCount}
      >
        {fields.map((field, i, fields) =>
          renderField({
            ...(props || {}),
            value: values ? (values.get ? values.get(i) : values[i]) : values,
          })(field, i, fields)
        )}
      </ListWrapper>
    );
  };

  collectionInput.propTypes = {
    props: PropTypes.object,
    fields: PropTypes.any.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
  };

  function componentWillMount() {
    const { fields } = this.props;
    let remaining = Math.max(0, min - fields.length);
    while (remaining-- > 0) {
      fields.push(ItemInput.defaultValue || Map());
    }
  }

  return lifecycle({ componentWillMount })(collectionInput);
}
