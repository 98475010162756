/*
 * ChatbotSettingsForm Messages
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  messageTextLabel: {
    id: 'app.components.MessageInput.text.label',
    defaultMessage: 'Message',
  },
  messageTextLabelInfo: {
    id: 'app.components.MessageInput.text.labelInfo',
    defaultMessage: '(max 640 characters)',
  },
  messageTextPlaceholder: {
    id: 'app.components.MessageInput.text.placeholder',
    defaultMessage: 'e.g. You can get a burger near hall B!',
  },

  messageListTitleLabel: {
    id: 'app.components.MessageInput.list.titleLabel',
    defaultMessage: 'Item Title',
  },
  messageListTitlePlaceholder: {
    id: 'app.components.MessageInput.list.titleLabel.placeholder',
    defaultMessage: 'e.g. Mike’s BBQ Joint',
  },
  messageListSubtitleLabel: {
    id: 'app.components.MessageInput.list.subtitle',
    defaultMessage: 'Item description',
  },
  messageListSubtitlePlaceholder: {
    id: 'app.components.MessageInput.list.subtitle.placeholder',
    defaultMessage:
      'e.g. Mike’s BBQ Joint is located at Charlston Avenue 40, right near hall B.',
  },
  messageListImageLabel: {
    id: 'app.components.MessageInput.list.imageLabel',
    defaultMessage: 'Item image',
  },
  messageListButtonLabel: {
    id: 'app.components.MessageInput.list.buttonLabel',
    defaultMessage: 'Button label',
  },
  messageListButtonLabelInfo: {
    id: 'app.components.MessageInput.list.buttonLabelInfo',
    defaultMessage: '(max 20 characters)',
  },
  messageListButtonPlaceholder: {
    id: 'app.components.MessageInput.list.buttonLabel.placeholder',
    defaultMessage: 'e.g. Visit Website',
  },
  messageListButtonTypeLabel: {
    id: 'app.components.MessageInput.list.buttonTypeLabel',
    defaultMessage: 'Button Type',
  },
  messageListButtonLinkLabel: {
    id: 'app.components.MessageInput.list.buttonLinkLabel',
    defaultMessage: 'Button Link',
  },
  messageListButtonActionLabel: {
    id: 'app.components.MessageInput.list.buttonActionLabel',
    defaultMessage: 'Button Action',
  },
  messageListButtonLinkPlaceholder: {
    id: 'app.components.MessageInput.list.buttonLink.placeholder',
    defaultMessage: 'e.g. https://mikesbbqjoint.com',
  },
  titleAndSubtitleLabelInfo: {
    id: 'app.components.MessageInput.list.titleAndSubtitleLabelInfo',
    defaultMessage: '(max 80 characters)',
  },
});

export const followUpTranslationMessages = defineMessages({
  messageListTitleLabel: {
    id: 'app.components.MessageInput.list.title',
    defaultMessage: 'Title',
  },
  messageListTitlePlaceholder: {
    id: 'app.components.MessageInput.list.title.placeholder',
    defaultMessage: 'e.g. Share Your Feedback!',
  },
  messageListSubtitleLabel: {
    id: 'app.components.MessageInput.list.subtitle',
    defaultMessage: 'Description',
  },
  messageListSubtitlePlaceholder: {
    id: 'app.components.MessageInput.list.subtitle.placeholder',
    defaultMessage: 'e.g. Take this 5-minute survey to help us improve!',
  },
  messageListImageLabel: {
    id: 'app.components.MessageInput.list.image',
    defaultMessage: 'Image',
  },
  messageListButtonLabel: {
    id: 'app.components.MessageInput.list.buttonLabel',
    defaultMessage: 'Button label',
  },
  messageListButtonLabelInfo: {
    id: 'app.components.MessageInput.list.buttonLabelInfo',
    defaultMessage: '(max 20 characters)',
  },
  messageListButtonPlaceholder: {
    id: 'app.components.MessageInput.list.buttonLabel.placeholder',
    defaultMessage: 'e.g. Take Survey!',
  },
  messageListButtonTypeLabel: {
    id: 'app.components.MessageInput.list.buttonTypeLabel',
    defaultMessage: 'Button Type',
  },
  messageListButtonLinkLabel: {
    id: 'app.components.MessageInput.list.buttonLinkLabel',
    defaultMessage: 'Button Link',
  },
  messageListButtonActionLabel: {
    id: 'app.components.MessageInput.list.buttonActionLabel',
    defaultMessage: 'Button Action',
  },
  messageListButtonLinkPlaceholder: {
    id: 'app.components.MessageInput.list.buttonLink.placeholder',
    defaultMessage: 'e.g. a link to your Google Forms, Typeform, etc.',
  },
  titleAndSubtitleLabelInfo: {
    id: 'app.components.MessageInput.list.titleAndSubtitleLabelInfo',
    defaultMessage: '(max 80 characters)',
  },
});
