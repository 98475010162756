import styled from 'styled-components';

export default styled.div`
  font-family: 'Nunito Sans', sans-serif;
  padding: ${props => props.theme.baseSpacing}px 0;
  ${props =>
    props.withThemeColors
      ? `
    background-color: ${props => props.theme.page.backgroundColor};
    color: ${props => props.theme.page.color};  
  `
      : ''}
`;
