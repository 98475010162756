import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';

import BroadcastText from '../Broadcasts/BroadcastText';
import BroadcastGeneric from '../Broadcasts/BroadcastGeneric';
import BroadcastList from '../Broadcasts/BroadcastList';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';

import formatTimestamp from '../../utils/formatTimestamp';
import getFacebookBroadcastReach from '../../sagas/user/integrations/facebook/getFacebookBroadcastReach';
import messages from './messages';

const ActionButtons = ({ match, item, onScheduledBroadcastRemove }) => (
  <Fragment>
    <EditButton to={`${match.url}/${item.id}`}>
      <FormattedMessage {...messages.editBroadcast} />
    </EditButton>
    <DeleteButton onClick={onScheduledBroadcastRemove}>
      <FormattedMessage {...messages.removeBroadcast} />
    </DeleteButton>
  </Fragment>
);

const BroadcastFragment = styled.div`
  width: 100%;
  padding: 12px;
  overflow: auto;
`;

const Broadcast = ({
  item,
  timezone,
  delivered,
  conferenceHandle,
  sagaMiddleware,
  reach,
  scheduled,
  onScheduledBroadcastRemove,
  match,
}) => {
  if (item && item.messages) {
    const broadcastId =
      item.messenger && item.messenger.broadcastId
        ? item.messenger.broadcastId
        : undefined;
    const messengerBroadcastFailed = item.messenger && item.messenger.failed;
    return item.messages.map((message, key) => {
      if (message.type === 'generic' || message.type === 'followUp') {
        const {
          action,
          title,
          subtitle,
          imageUrl,
          buttonLabel,
          buttons,
        } = message.item;
        return (
          <BroadcastFragment key={key}>
            {scheduled && (
              <ActionButtons
                match={match}
                item={item}
                onScheduledBroadcastRemove={onScheduledBroadcastRemove}
              />
            )}
            <BroadcastGeneric
              scheduled={scheduled}
              scheduleTime={item.scheduleTime}
              timezone={timezone}
              buttons={buttons}
              action={action}
              buttonLabel={buttonLabel}
              title={title}
              subtitle={subtitle}
              imageUrl={imageUrl}
              delivered={delivered}
              messengerBroadcastId={broadcastId}
              messengerBroadcastFailed={messengerBroadcastFailed}
              conferenceHandle={conferenceHandle}
              sentAt={formatTimestamp(item.timestamp, timezone)}
              getBroadcastReached={getFacebookBroadcastReach}
              sagaMiddleware={sagaMiddleware}
            />
          </BroadcastFragment>
        );
      } else if (message.type === 'list') {
        return (
          <BroadcastFragment key={key}>
            {scheduled && (
              <ActionButtons
                match={match}
                item={item}
                onScheduledBroadcastRemove={onScheduledBroadcastRemove}
              />
            )}
            <BroadcastList
              scheduled={scheduled}
              scheduleTime={item.scheduleTime}
              timezone={timezone}
              items={message.items}
              delivered={delivered}
              messengerBroadcastId={broadcastId}
              messengerBroadcastFailed={messengerBroadcastFailed}
              conferenceHandle={conferenceHandle}
              sentAt={formatTimestamp(item.timestamp, timezone)}
              getBroadcastReached={getFacebookBroadcastReach}
              sagaMiddleware={sagaMiddleware}
            />
          </BroadcastFragment>
        );
      } else if (message.type === 'text') {
        return (
          <BroadcastFragment key={key}>
            {scheduled && (
              <ActionButtons
                match={match}
                item={item}
                onScheduledBroadcastRemove={onScheduledBroadcastRemove}
              />
            )}
            <BroadcastText
              scheduled={scheduled}
              scheduleTime={item.scheduleTime}
              timezone={timezone}
              message={message.message}
              type={message.type}
              delivered={delivered}
              messengerBroadcastId={broadcastId}
              messengerBroadcastFailed={messengerBroadcastFailed}
              conferenceHandle={conferenceHandle}
              sentAt={formatTimestamp(item.timestamp, timezone)}
              getBroadcastReached={getFacebookBroadcastReach}
              sagaMiddleware={sagaMiddleware}
            />
          </BroadcastFragment>
        );
      } else {
        return null;
      }
    });
  } else if (item && item.message) {
    return (
      <BroadcastFragment>
        <BroadcastText
          message={item.message}
          scheduled={scheduled}
          scheduleTime={item.scheduleTime}
          timezone={timezone}
          type={'text'}
          delivered={delivered}
          reached={reach}
          sentAt={formatTimestamp(item.timestamp, timezone)}
          sagaMiddleware={sagaMiddleware}
          getBroadcastReached={getFacebookBroadcastReach}
          conferenceHandle={conferenceHandle}
        />
      </BroadcastFragment>
    );
  }
};

export default compose(withRouter)(Broadcast);
