import firebase from 'firebase/app';

export default function* addComment(
  conferenceHandle,
  submissionId,
  author,
  authorDisplayName,
  type
) {
  yield firebase
    .database()
    .ref(
      `conferences/${conferenceHandle}/cfp/submissions/${submissionId}/reactions/${author}`
    )
    .set({
      author,
      authorDisplayName,
      type,
    });
}
