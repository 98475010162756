import { put } from 'redux-saga/effects';
import { fromJS } from 'immutable';

import { acceptUser, clearUser } from '../../../actions/user';

export default function* syncUser(user) {
  if (user) {
    yield put(acceptUser(fromJS(user.toJSON())));
  } else {
    yield put(clearUser());
  }
}
