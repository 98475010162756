/*
 * ConferenceSettingsEventPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Flexbox from 'flexbox-react';
import Helmet from 'react-helmet';
import { Map, List } from 'immutable';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import Breadcrumb from '../../components/Breadcrumb';
import ConferenceForm from '../../components/ConferenceForm';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import updateConference from '../../sagas/user/conferences/update';
import { selectCurrentConference } from '../../selectors/user';
import { selectProps } from '../../selectors/common';

function nameKey(item) {
  return item
    .get('name')
    .toLowerCase()
    .replace(/[-.$[\]#/\s]+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

function listToMap(key, itemToKey) {
  return l => {
    const list = l || List();
    return list.reduce(
      (acc, item) =>
        acc.set(item.get(key) || itemToKey(item), item.delete(key)),
      Map()
    );
  };
}

const Root = styled(Flexbox)`
  > div {
    margin: 20px;
    padding: 20px;

    background: white;
    ${props => props.theme.altitude(1)};
    min-width: 35em;
  }
`;

class ConferenceSettingsEventPage extends Component {
  static propTypes = {
    conference: PropTypes.object.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  static async handleSubmit(data) {
    const conference = data.toJS();
    const { startDate, endDate } = conference.dates;

    const dates = `${startDate.format('YYYY/M/D')},${endDate.diff(
      startDate,
      'days'
    ) + 1}`;

    const tracks = listToMap('id', nameKey)(data.get('tracks')).toJS();
    const sessionTypes = listToMap('id', nameKey)(
      data.get('sessionTypes')
    ).toJS();

    await this.defaultUserRequestExecutor(updateConference, {
      id: conference.id,
      name: conference.name,
      dates,
      tracks,
      sessionTypes,
      timezone: conference.timezone,
      embedLayout: conference.embedLayout,
      mobileEmbedLayout: conference.mobileEmbedLayout,
    });
  }

  constructor(props) {
    super(props);
    this.handleSubmit = ConferenceSettingsEventPage.handleSubmit.bind(this);
  }

  render() {
    const { conference } = this.props;
    const root = `/@${conference.id}/settings`;

    return (
      <Root
        flexDirection="column"
        justifyContent="flex-start"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Helmet title={`Edit ${conference.name} • Sava Events`} />
        <Breadcrumb to={root}>Settings</Breadcrumb>
        <Breadcrumb to={`${root}/event`}>Event</Breadcrumb>
        <div>
          <ConferenceForm
            initialValues={conference}
            onSubmit={this.handleSubmit}
          />
        </div>
      </Root>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectProps,
  (conference, props) => {
    const [firstDateStr, durationStr] = conference
      .get('dates', '')
      .split(',', 2);
    const [year, month, date] = firstDateStr.split('/', 3);
    const firstDate = moment([year, month - 1, date]);
    const lastDate = firstDate
      .clone()
      .add(parseInt(durationStr, 10) - 1, 'days');

    const tracks = conference
      .get('tracks', Map())
      .entrySeq()
      .map(([k, v]) => v.set('id', k));

    const sessionTypes = conference
      .get('sessionTypes', Map())
      .entrySeq()
      .map(([k, v]) => v.set('id', k));

    return {
      conference: Map({
        id: conference.get('id'),
        name: conference.get('name'),
        dates: Map({
          startDate: firstDate,
          endDate: lastDate,
        }),
        tracks,
        sessionTypes,
        timezone: conference.get('timezone'),
        embedLayout: conference.get('embedLayout', 'grid'),
        mobileEmbedLayout: conference.get('mobileEmbedLayout', 'flat'),
      }).toJS(),
    };
  }
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor
)(ConferenceSettingsEventPage);
