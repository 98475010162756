import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import { FormattedMessage } from 'react-intl';
import { compose, setPropTypes, withState } from 'recompose';

import ModalRoute from '../../components/ModalRoute';
import Breadcrumb from '../../components/Breadcrumb';
import ChatbotSettingsForm from '../../components/ChatbotSettingsForm';
import TelegramSettingsForm from '../../components/TelegramSettingsForm';
import ApiSettingsForm from '../../components/ApiSettingsForm';
import Icon from '../../components/Icon';

import updateApiSettings from '../../sagas/user/conferences/apiSettings';
import getPages from '../../sagas/user/integrations/facebook/getPages';

import updateChatbotSettings from '../../sagas/user/conferences/chatbotSettings';
import updateSettings from '../../sagas/user/conferences/updateSettings';
import disconnectFromFacebook from '../../sagas/user/integrations/facebook/disconnect';
import disconnectTelegramWebhook from '../../sagas/user/integrations/telegram/disconnect';
import withUserRequestExecutor, {
  errorMessages,
} from '../../hocs/withUserRequestExecutor';

import interactive, { button } from '../../utils/ui/interactive';
import { Link } from 'react-router-dom';
import ConnectToFbPage from '../ConnectToFbPage';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectUserFacebookPages,
} from '../../selectors/user';
import { createSelector } from 'reselect';

const Root = styled(Flexbox)`
  > div {
    margin: 20px;
    flex-wrap: wrap;
  }
`;

const FormColumn = styled(Flexbox)`
  background: white;
  ${props => props.theme.altitude(1)};
  padding: 20px;
  width: 400px;
  flex-direction: column;
  margin: 0px 8px 20px;
`;

const InfoBox = styled.div`
  padding: 20px 0px;
`;

const DisconnectButton = styled.div`
  padding: 12px;
  ${interactive.styles`
    color: #333;
  `}${button({ backgroundColor: '#248bd1' })};
`;

const ConnectToFb = styled(Link)`
  padding: 12px;
  ${interactive.styles`
    color: #333;
  `}${button({ backgroundColor: '#248bd1' })};
`;

const PageWrap = styled(Flexbox)`
  width: 100%;
  justify-content: space-between;
`;

const PageTitle = styled.div`
  text-align: left;
  padding: 12px;
`;

const ConferenceSettingsPage = ({
  createUserRequestExecutor,
  match,
  conference,
  page,
  disconnecting,
  setDisconnecting,
}) => {
  const defaultUserRequestExecutor = createUserRequestExecutor({
    onFailure: () => {
      setDisconnecting(false);
    },
    errorMessages,
    onSuccess: () => {
      setDisconnecting(false);
    },
  });

  const handleSubmitTelegramConfig = async data => {
    defaultUserRequestExecutor(updateSettings, {
      id: conference.get('id'),
      settings: { telegram: data.toJS() },
    });
  };

  const handleDisconnectTelegramWebhook = () => {
    setDisconnecting(true);
    defaultUserRequestExecutor(disconnectTelegramWebhook, conference.get('id'));
  };

  const handleSubmitChatbotSettings = data => {
    defaultUserRequestExecutor(updateChatbotSettings, {
      id: conference.get('id'),
      settings: data.toJS(),
    });
  };

  const handleSubmitApiSettings = data => {
    defaultUserRequestExecutor(updateApiSettings, {
      id: conference.get('id'),
      settings: data.toJS(),
    });
  };

  const handleDisconnect = () => {
    defaultUserRequestExecutor(disconnectFromFacebook, conference.get('id'));
  };

  const handleGetFbPages = () => {
    defaultUserRequestExecutor(getPages);
  };

  const telegramSettings = conference.get('telegram', Map());
  const chatbotSettings = conference.get('chatbotSettings', Map());
  const apiSettings = conference.get('api', Map());
  const root = `/@${conference.get('id')}/settings`;

  return (
    <Root
      flexDirection="column"
      justifyContent="flex-start"
      alignContent="flex-start"
      alignItems="flex-start"
    >
      <Helmet title={`${conference.get('name')} Configure Integrations`} />
      <Breadcrumb to={root}>Settings</Breadcrumb>
      <Breadcrumb to={`${root}/integrations`}>Integrations</Breadcrumb>
      <Flexbox flexDirection="row">
        <FormColumn>
          <h2>Facebook Messenger Settings</h2>

          {!page.name && (
            <div>
              <ConnectToFb
                to={`${root}/integrations/facebook`}
                onClick={handleGetFbPages}
              >
                <FormattedMessage
                  id={'connect'}
                  defaultMessage={'Connect with Facebook Page'}
                />
              </ConnectToFb>
            </div>
          )}

          {page.name && (
            <PageWrap>
              <PageTitle>{page.name}</PageTitle>
              <DisconnectButton onClick={handleDisconnect}>
                <FormattedMessage
                  id={'connect'}
                  defaultMessage={`Disconnect`}
                />
              </DisconnectButton>
            </PageWrap>
          )}
        </FormColumn>

        <FormColumn>
          <h2>Telegram Messenger Settings</h2>
          <TelegramSettingsForm
            initialValues={telegramSettings}
            enableReinitialize={true}
            isDisconnectVisible={!!telegramSettings.get('webhook')}
            onSubmit={handleSubmitTelegramConfig}
            onDisconnect={handleDisconnectTelegramWebhook}
            disconnecting={disconnecting}
          />
          <InfoBox>
            <p>
              {telegramSettings.get('webhook') ? (
                <strong>
                  <Icon>check</Icon> Telegram bot is ready to chat
                </strong>
              ) : (
                'Telegram bot not live, please enter token'
              )}
            </p>
            <p>
              Help on configuring the bot is available in the{' '}
              <a
                href="https://docs.sava.events/#/buildabot?id=telegram"
                target="_blank"
                rel="noopener noreferrer"
              >
                docs.
              </a>
            </p>
          </InfoBox>
        </FormColumn>

        <FormColumn flexDirection="column">
          <h2>Chatbot Settings</h2>
          <ChatbotSettingsForm
            initialValues={chatbotSettings}
            onSubmit={handleSubmitChatbotSettings}
          />
        </FormColumn>

        <FormColumn flexDirection="column">
          <h2>API Settings</h2>
          <ApiSettingsForm
            initialValues={apiSettings}
            onSubmit={handleSubmitApiSettings}
          />
          <InfoBox>
            <p>
              <strong>
                {apiSettings.get('apiWanted') && apiSettings.get('apiToken') ? (
                  <Icon>check</Icon>
                ) : null}{' '}
                API Token
              </strong>
            </p>
            {apiSettings.get('apiWanted') && apiSettings.get('apiToken') ? (
              <code>{apiSettings.get('apiToken')}</code>
            ) : (
              <p>
                <Icon>info</Icon> To get your API token, enable API usage first.
              </p>
            )}
            <p>
              More info in the{' '}
              <a
                href="https://docs.sava.events/#/api"
                target="_blank"
                rel="noopener noreferrer"
              >
                docs.
              </a>
            </p>
          </InfoBox>
        </FormColumn>
      </Flexbox>

      <ModalRoute
        exact
        path={`${match.path}/facebook`}
        component={ConnectToFbPage}
      />
    </Root>
  );
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectUserFacebookPages,
  (conferenceHandle, conference, pages) => {
    const page = conference.get('facebook', Map()).toJS();

    return {
      conferenceHandle,
      conference,
      pages,
      page,
    };
  }
);

export default compose(
  withUserRequestExecutor,
  connect(mapStateToProps),
  withState('disconnecting', 'setDisconnecting', false),
  setPropTypes({
    conference: PropTypes.object.isRequired,
    conferenceHandle: PropTypes.string.isRequired,
  })
)(ConferenceSettingsPage);
