import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { List } from 'immutable';
import { slugify } from 'sava-shared/lib/utils/core';

import { selectSessions, selectConferenceHandle } from '../../selectors/embed';
import { selectMatchParam } from '../../selectors/navigation';
import { selectProp } from '../../selectors/common';
import withNow from '../../hocs/withNow';
import { SessionPage } from '../SessionPage';
import emptyStateIllustration from '../../assets/images/coffee_breaks.svg';
import EntityEmptyState, {
  EmptyStatePaddingWrapper,
} from '../../components/EntityEmptyState';
import { EmbedBackground } from '../embed/common';
import PoweredBy from '../../components/PoweredBy';

const TrackSessionQuestions = ({ conferenceHandle, session }) => {
  if (!session) {
    return (
      <EmbedBackground>
        <EmptyStatePaddingWrapper>
          <EntityEmptyState
            illustration={emptyStateIllustration}
            title={'Grab a cup of ☕'}
            hint={
              'No active sessions at this moment. But keep an eye for new ones...'
            }
          />
        </EmptyStatePaddingWrapper>
        <PoweredBy />
      </EmbedBackground>
    );
  }

  return (
    <EmbedBackground>
      <SessionPage
        session={session}
        conferenceHandle={conferenceHandle}
        showDescription={false}
        moderator={false}
        showBackButton={false}
        showQuestionForm={false}
      />
    </EmbedBackground>
  );
};

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectSessions,
  selectMatchParam('track'),
  selectProp('now'),
  (conferenceHandle, sessions, trackId, now) => {
    const session = List(sessions.valueSeq())
      .filter(
        session =>
          slugify(session.getIn(['track', 'id'], false)) === slugify(trackId) &&
          now.isBetween(session.get('startTime'), session.get('endTime'))
      )
      .first();

    return {
      conferenceHandle,
      session,
    };
  }
);

export default compose(
  withRouter,
  withNow,
  connect(mapStateToProps)
)(TrackSessionQuestions);
