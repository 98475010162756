import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.EditBroadcastPage.title',
    defaultMessage: 'Broadcast edit • Sava Events',
  },
  updateBroadcastError: {
    id: 'app.containers.EditBroadcastPage.updateBroadcastError',
    defaultMessage:
      'Failed to update broadcast. If error persists, contact our 🌶️ support.',
  },
});
