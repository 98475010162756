/*
 * EditChatIntentPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, fromJS } from 'immutable';
import Helmet from 'react-helmet';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectId,
} from '../../selectors/user';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import {
  updateChatbotIntent,
  deleteChatbotIntent,
} from '../../sagas/user/conferences/chatbotIntents';
import ChatIntentForm from '../../components/ChatIntentForm';

export class EditChatIntentPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
    chatIntent: PropTypes.object,
    intentId: PropTypes.string,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({});

  static async handleSubmit(data) {
    await this.defaultUserRequestExecutor(updateChatbotIntent, {
      conferenceHandle: this.props.conferenceHandle,
      id: this.props.intentId,
      intent: data.toJS(),
    });
  }

  static async handleDelete() {
    await this.defaultUserRequestExecutor(deleteChatbotIntent, {
      conferenceHandle: this.props.conferenceHandle,
      id: this.props.intentId,
    });
  }

  constructor(props) {
    super(props);
    this.handleSubmit = EditChatIntentPage.handleSubmit.bind(this);
    this.handleDelete = EditChatIntentPage.handleDelete.bind(this);
  }

  render() {
    const { conferenceName, chatIntent } = this.props;

    let ci = chatIntent;

    const updateItem = item => {
      const action = item.get('action', '').trim();
      const buttonLabel = item.get('buttonLabel', '').trim();
      const buttonType = item.get('buttonType', '').trim();
      const buttons = item.get('buttons', fromJS([]));

      if (buttons.isEmpty() && action && buttonLabel) {
        return item
          .delete('action')
          .delete('buttonLabel')
          .delete('buttonType')
          .set(
            'buttons',
            fromJS([
              buttonType === 'customIntent'
                ? {
                    buttonLabel,
                    buttonType,
                    customIntent: { customIntentId: action },
                  }
                : { buttonLabel, buttonType: 'link', action },
            ])
          );
      }

      return item;
    };

    const item = ci.getIn(['response', 'item']);

    if (item) {
      ci = ci.updateIn(['response', 'item'], updateItem);
    }

    const items = ci.getIn(['response', 'items']);

    if (items) {
      ci = ci.setIn(['response', 'items'], items.map(updateItem));
    }

    return (
      <div>
        <Helmet title={`${conferenceName} | Edit Chat Intent`} />
        <ChatIntentForm
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
          initialValues={ci}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectCurrentConference,
  selectId,
  (conferenceHandle, conferenceName, conference, id) => ({
    conferenceHandle,
    conferenceName,
    chatIntent: conference.getIn(
      ['chatbotSettings', 'customIntents', id],
      Map()
    ),
    intentId: id,
  })
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor
)(EditChatIntentPage);
