import React from 'react';

const { Provider, Consumer } = React.createContext();

export const withSagaMiddleware = Component => props => (
  <Consumer>
    {sagaMiddleware => <Component {...props} sagaMiddleware={sagaMiddleware} />}
  </Consumer>
);

export default ({ sagaMiddleware, children }) => (
  <Provider value={sagaMiddleware}>{children}</Provider>
);
