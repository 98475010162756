import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import NotFound from '../../NotFoundPage';
import SchedulePage from '../SchedulePage';

class EmbedScheduleDaysWrapper extends Component {
  componentDidMount() {
    const { conferenceHandle, history, match } = this.props;

    if (match.isExact) {
      history.push(`/@${conferenceHandle}/embed/schedule/days/0`);
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/@:conferenceHandle/embed/schedule/days" />
        <Route
          exact
          path="/@:conferenceHandle/embed/schedule/days/:day"
          component={SchedulePage}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = (state, props) => {
  const conferenceHandle = (props.match.params || {}).conferenceHandle;
  return { conferenceHandle };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(EmbedScheduleDaysWrapper);
