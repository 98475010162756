import firebase from 'firebase/app';

import { buffers, eventChannel } from 'redux-saga';

export default () =>
  eventChannel(
    emitter =>
      firebase.auth().onAuthStateChanged(user => emitter(user || false)),
    buffers.sliding(5)
  );
