import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form/immutable';
import { compose } from 'recompose';

import withAsyncContent from '../../hocs/withAsyncContent';
import createFetchResource from '../../sagas/user/createFetchResource';
import SelectInputControl from '../SelectInputControl';

const mapSystemMessagesToProps = (state, props) => {
  const { content: systemMessages } = props;

  return {
    systemMessages: Object.entries(systemMessages).map(([key, value]) => ({
      id: key,
      name: value,
    })),
  };
};

const SystemMessageField = SelectInputControl(
  (state, { systemMessages }) => systemMessages
);

const SystemMessagePicker = ({ label, name, systemMessages }) => {
  return (
    <fieldset>
      <label htmlFor={`${name}.systemMessageId`}>{label}</label>
      <Field
        name={`${name}.systemMessageId`}
        component={SystemMessageField}
        props={{ systemMessages }}
      />
    </fieldset>
  );
};

const fetchDefaultChatbotSettings = createFetchResource(
  'configuration/defaultChatbotSettings/systemMessageActions',
  { fallback: {}, throwError: false }
);

export default compose(
  withAsyncContent(fetchDefaultChatbotSettings),
  connect(mapSystemMessagesToProps)
)(SystemMessagePicker);
