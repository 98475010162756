import { call, cancelled, take } from 'redux-saga/effects';

export default (channel, saga) =>
  function* consumerSaga() {
    try {
      while (!(yield cancelled())) {
        const value = yield take(channel);

        yield call(saga, value);
      }
    } finally {
      channel.close();
    }
  };
