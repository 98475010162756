/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import { Route, Switch, Link, withRouter, matchPath } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { List, Map } from 'immutable';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar';
import messages from './messages';
import menuItems from './menuItems';
import TopBar from './TopBar';
import {
  NAV_HEIGHT,
  MENU_WIDTH,
  topbarBg,
  logoBg,
  sidebarBg,
} from './constants';

import {
  selectUser,
  selectUserData,
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferences,
} from '../../selectors/user';
import { button } from '../../utils/ui/interactive';
import ActionLink from '../../components/ActionLink';
import Icon from '../../components/Icon';
import logo from '../../assets/images/logo.svg';
import ProfilePicture from '../../components/ProfilePicture';
import CustomDragLayer from '../../components/CustomDragLayer';
import Toastr from '../../components/Toastr';
import { deselectConference } from '../../actions/user';
import styled, { css } from 'styled-components';
import { compose, setPropTypes } from 'recompose';
import CreateConferencePage from '../CreateConferencePage';
import AccountPage from '../AccountPage';
import ConferenceWrapper from '../ConferenceWrapper';
import ConferencesPage from '../ConferencesPage';
import RTUpdater from '../../utils/RTUpdater';
import { subscribeToPushNotificationTopic } from '../../sagas/user/pushNotifications';

const NewTicketsCounter = new RTUpdater();

const logoStyled = css`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 1em;
  padding: 0.75em 1em;
  height: ${NAV_HEIGHT}px;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  ${button({ backgroundColor: logoBg, darken: true, shadow: false })};
`;

const LogoButtonActionLink = styled(ActionLink)`
  ${logoStyled};
`;

const LogoButtonLink = styled(Link)`
  ${logoStyled};
`;

const Logo = styled.img`
  height: 1.5em;
`;

const Sidebar = styled.div`
  position: fixed;
  display: flex;
  flex-flow: column;

  top: 0;
  bottom: 0;
  left: 0;
  width: ${MENU_WIDTH}px;

  z-index: 1000;

  background: ${sidebarBg.string()};
`;

const NotificationLabel = styled.span`
  background-color: ${props => props.theme.colors.attention};
  position: absolute;
  color: #fff;
  border-radius: 50%;
  margin-left: 0.75em;
  text-align: center;
  font-size: 14px;
  width: 25px;
  display: inline-block;
  line-height: 25px;
  top: -0.05em;
`;

const MainMenu = ({ children }) => <div>{children}</div>;

MainMenu.propTypes = {
  children: PropTypes.any.isRequired,
};

const ItemLink = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 0.75em 1em;
  margin: 0.25em 0;
  transition: background-color 0.15s ease-in-out;

  ${props =>
    props.active === 'true' &&
    `
    background-color: rgba(255, 255, 255, 0.1);
    > * {color: white !important; }
  `} &:hover {
    background-color: rgba(255, 255, 255, 0.1);

    * {
      color: white;
    }
  }
`;

const IconHolder = styled(Icon)`
  display: inline-block;
  margin-right: 0.5em;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.55);
  transition: color 0.15s ease-in-out;
`;

const ItemText = styled.p`
  position: relative;
  display: inline;
  font-weight: 600;
  color: white;
  letter-spacing: 0.2px;
`;

const Item = ({ link, children, active }) => (
  <ItemLink active={String(active)} to={link}>
    {children}
  </ItemLink>
);

Item.propTypes = {
  link: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

const SegmentLink = styled(Link)`
  position: relative;
  padding: 0 20px;
  color: #444;

  border-right: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    margin-left: -1px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  &::after {
    content: ' ';

    position: absolute;

    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.1);
  }

  ${button({ backgroundColor: topbarBg, shadow: false })};
`;

const Segment = ({ link, children }) => (
  <SegmentLink to={link} style={{ display: 'block' }}>
    <Flexbox
      flexGrow={0}
      justifyContent="center"
      alignItems="center"
      style={{ height: NAV_HEIGHT }}
    >
      {children}
    </Flexbox>
  </SegmentLink>
);

Segment.propTypes = {
  link: PropTypes.any.isRequired,
  children: PropTypes.any,
};

const BodyWrapper = styled.div`
  padding-left: ${MENU_WIDTH}px;
  padding-top: ${NAV_HEIGHT}px;
`;

const Body = styled.div`
  position: relative;
`;

const SecondaryMenu = styled.div`
  margin-top: auto;
`;

class App extends React.Component {
  state = { openTickets: [] };

  async componentDidMount() {
    const { conferences, currentConference, history, match } = this.props;
    if (match.isExact) {
      if (currentConference && typeof currentConference === typeof '') {
        history.push(`/@${currentConference}`);
      } else if (conferences.size === 0) {
        history.push(`/new`);
      }
    }
    this.startTicketUpdater();

    if (process.env.NODE_ENV === 'production') {
      try {
        await firebase.messaging().requestPermission();
        await subscribeToPushNotificationTopic(currentConference.get('id'));
      } catch (error) {
        console.log(error);
      }
    }
  }

  componentWillUnmount = () => {
    this.stopTicketUpdater();
  };

  startTicketUpdater = () => {
    if (this.props.conferenceHandle) {
      NewTicketsCounter.subscribe(this.handleOpenTicketsChange);
      NewTicketsCounter.start(
        `conferences/${this.props.conferenceHandle}/usersWithOpenTickets`,
        { onAdded: true, onRemoved: true }
      );
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.conferenceHandle !== this.props.conferenceHandle) {
      this.stopTicketUpdater();
      this.stopTicketUpdater();
    }
  };

  stopTicketUpdater = () => {
    this.setState({ openTickets: [] });
    NewTicketsCounter.stop();
    NewTicketsCounter.unsubscribe(this.handleOpenTicketsChange);
  };

  handleOpenTicketsChange = (type, ticket) => {
    let { openTickets } = this.state;
    if (type === 'add') {
      openTickets.push(ticket);
    } else if (type === 'remove') {
      openTickets = openTickets.filter(_ => _.id !== ticket.id);
    }
    this.setState({ openTickets });
  };

  render = () => {
    const {
      conferenceHandle,
      user,
      userColor,
      features,
      breadcrumbs,
      location,
      intl,
      currentConference,
    } = this.props;
    const openTicketsCount = this.state.openTickets.length;
    const dynamicValues = { openTicketsCount };

    return (
      <div>
        <Sidebar>
          {currentConference.get('id', false) ? (
            <LogoButtonActionLink action={deselectConference}>
              <Logo src={logo} />
            </LogoButtonActionLink>
          ) : (
            <LogoButtonLink to="/">
              <Logo src={logo} />
            </LogoButtonLink>
          )}

          <MainMenu>
            {menuItems.map((item, i) => {
              const { feature, getLink, icon, label, pathToMatch } = item;
              const featureAvailable = feature === true || features[feature];
              const match = matchPath(location.pathname, {
                path: pathToMatch,
                exact: false,
              });

              return conferenceHandle && featureAvailable ? (
                <Item link={getLink(conferenceHandle)} active={!!match} key={i}>
                  <IconHolder>{icon}</IconHolder>
                  <ItemText>
                    {intl.formatMessage(messages[label])}
                    {item.dynamicValue && dynamicValues[item.dynamicValue] ? (
                      <NotificationLabel>
                        {dynamicValues[item.dynamicValue] > 9
                          ? '9+'
                          : dynamicValues[item.dynamicValue]}
                      </NotificationLabel>
                    ) : (
                      ''
                    )}
                  </ItemText>
                </Item>
              ) : null;
            })}
          </MainMenu>

          <SecondaryMenu>
            <Item
              link={
                conferenceHandle ? `/@${conferenceHandle}/account` : '/account'
              }
            >
              <ProfilePicture
                image={user.photoURL}
                color={userColor}
                width={64}
                height={64}
                padding={12}
              />
            </Item>
          </SecondaryMenu>
        </Sidebar>

        <LoadingBar
          style={{ backgroundColor: logoBg, zIndex: 1000 }}
          showFastActions
        />

        <TopBar>
          {breadcrumbs.map((breadcrumb, i) => (
            <Segment key={i} link={breadcrumb.link}>
              {breadcrumb.text}
            </Segment>
          ))}
        </TopBar>

        <BodyWrapper>
          <Body>
            <Switch>
              <Route exact path="/" component={ConferencesPage} />
              <Route exact path="/new" component={CreateConferencePage} />
              <Route exact path="/account" component={AccountPage} />
              <Route path="/@:conferenceHandle" component={ConferenceWrapper} />
            </Switch>
            <CustomDragLayer />
          </Body>
        </BodyWrapper>

        <Toastr />
      </div>
    );
  };
}

const selectUserColor = createSelector(selectUserData, userData =>
  userData.get('color', '#f0f0f0')
);

const selectFeatures = createSelector(selectCurrentConference, conference =>
  conference.getIn(['features'], Map()).toJS()
);

const selectBreadcrumbs = state =>
  state.getIn(['navigation', 'breadcrumbs'], List()).toJS();

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectUser(),
  selectUserColor,
  selectConferences,
  selectCurrentConference,
  selectFeatures,
  selectBreadcrumbs,
  (
    conferenceHandle,
    user,
    userColor,
    conferences,
    currentConference,
    features,
    breadcrumbs
  ) => ({
    conferenceHandle,
    user: user.toJS(),
    userColor,
    conferences,
    currentConference,
    features,
    breadcrumbs,
  })
);

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
  setPropTypes({
    conferenceHandle: PropTypes.string,
    user: PropTypes.any.isRequired,
    breadcrumbs: PropTypes.any.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }),
  DragDropContext(HTML5Backend)
)(App);
