import { select } from 'redux-saga/effects';
import firebase from 'firebase/app';

import { selectUser } from '../../../selectors/user';

export default function* selectConference({ handle }) {
  const user = yield select(selectUser());

  yield firebase
    .database()
    .ref(`users/${user.get('uid')}`)
    .update({ currentConference: handle });
}
