import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EmbedPage from '../EmbedPage';

class EmbedWrapperPage extends Component {
  componentDidMount() {
    const { history, location } = this.props;

    const matches = (location.search || '').match(/[&?]id=(sava-[\w\d]+)/);

    if (matches) {
      window.savaId = matches[1];
    }

    // do this message passing only if embedded in a page as iframe
    if (window !== window.parent) {
      let lastHeight;

      this.interval = setInterval(() => {
        let currentHeight = document.body.scrollHeight;

        if (
          document.getElementById('app') &&
          document.getElementById('app').children &&
          document.getElementById('app').children[0] &&
          document.getElementById('app').children[0].offsetHeight &&
          Number.isFinite(
            Number(document.getElementById('app').children[0].offsetHeight)
          )
        ) {
          currentHeight =
            document.getElementById('app').children[0].offsetHeight + 12;
        }

        if (currentHeight !== lastHeight) {
          lastHeight = currentHeight;
          window.parent.postMessage(
            { height: currentHeight, id: window.savaId },
            '*'
          );
        }
      }, 250);

      let prevPathname = location.pathname;

      this.unlisten = history.listen(location => {
        if (location) {
          window.parent.postMessage(
            {
              pathname: location.pathname,
              prevPathname,
              id: window.savaId,
            },
            '*'
          );

          prevPathname = location.pathname;
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.interval && this.unlisten) {
      clearInterval(this.interval);
      this.unlisten();
    }
  }

  render() {
    return <EmbedPage />;
  }
}

const mapStateToProps = (state, props) => {
  const conferenceHandle = (props.match.params || {}).conferenceHandle;
  return { conferenceHandle };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(EmbedWrapperPage);
