import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  formValueSelector,
  change,
  Field,
} from 'redux-form/immutable';
import styled from 'styled-components';
import { Map, fromJS } from 'immutable';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';

import Form from '../Form';
import { button } from '../../utils/ui/interactive';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';
import MessageInput from '../MessageInput';
import validate from '../MessageInput/validate';
import getDateAndTimeInMilliseconds from '../../utils/getDateAndTimeInMilliseconds';
import DateTimeInput from '../DateTimeInput';

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled.button`
  margin: 0px;
  float: right;
  border-radius: 3px;
  padding: 0.75em 1em;
  font-weight: 600;
  ${props => button({ backgroundColor: props.theme.colors.accent })};
  color: ${props => props.theme.colors.light};
`;

const ScheduleActionButton = styled.button`
  margin-right: 10px;
  color: ${props => props.theme.colors.brandPrimary};
  darken: true;
  text-decoration: none;
  cursor: pointer;
  transition: 160ms all;
  outline: none;
  :hover {
    color: ${props => props.theme.colors.accent};
  }
`;

const BroadcastForm = ({
  dispatch,
  handleSubmit,
  pristine,
  submitting,
  intl,
  invalid,
  timezone,
  type,
  value,
  schedule,
  isCreateForm,
  isScheduledBroadcast,
  setIsScheduledBroadcast,
  formId,
}) => {
  const isScheduleFilled = () => schedule && schedule.date && schedule.time;
  const isFirstTimeInEditMode = !isScheduledBroadcast && isScheduleFilled();
  const isScheduleEmptyInEditMode =
    !isCreateForm && (!isScheduledBroadcast && !isScheduleFilled());

  const onScheduleActionButtonClick = () => {
    if (isScheduledBroadcast || isFirstTimeInEditMode) {
      dispatch(change(formId, 'schedule', Map()));
    }

    setIsScheduledBroadcast(
      isFirstTimeInEditMode ? false : !isScheduledBroadcast
    );
  };

  const getSubmitLabel = () => {
    if (isScheduleEmptyInEditMode) {
      return messages['send'];
    }

    if (!isCreateForm) {
      return messages['update'];
    }

    if (isScheduledBroadcast || isScheduleFilled()) {
      return messages['schedule'];
    }

    return messages['send'];
  };

  const getScheduleActionButtonLabel = () =>
    messages[
      isScheduledBroadcast || isScheduleFilled()
        ? 'scheduleBroadcastButton'
        : 'schedule'
    ];

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset>
        <Field
          name="message"
          component={MessageInput}
          props={{
            type,
            value,
          }}
        />
      </fieldset>

      {isScheduledBroadcast || isScheduleFilled() ? (
        <fieldset>
          <label htmlFor="schedule">
            <FormattedMessage {...messages.scheduleBroadcast} />
          </label>
          <Field
            name="schedule"
            component={DateTimeInput}
            props={{ timezone }}
          />
        </fieldset>
      ) : null}

      <fieldset>
        <Buttons>
          <ScheduleActionButton
            type="button"
            onClick={onScheduleActionButtonClick}
          >
            {intl.formatMessage(getScheduleActionButtonLabel())}
          </ScheduleActionButton>
          <Button disabled={pristine || submitting || invalid}>
            <FormattedMessage {...getSubmitLabel()} />
          </Button>
        </Buttons>
      </fieldset>
    </Form>
  );
};

BroadcastForm.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  intl: intlShape.isRequired,
};

const formValidation = (values, props) => {
  const form = values.toJS();

  const { intl } = props;
  const errors = validate(form, props);

  if (
    (form.schedule && form.schedule.date && !form.schedule.time) ||
    (form.schedule && !form.schedule.date && form.schedule.time)
  ) {
    errors.schedule = intl.formatMessage(messages.scheduleTimeNotComplete);
  }

  if (form.schedule && form.schedule.date && form.schedule.time) {
    try {
      const scheduleTime = getDateAndTimeInMilliseconds(
        form.schedule.date,
        form.schedule.time
      );
      const now = new Date().getTime();

      if (now > scheduleTime) {
        errors.schedule = intl.formatMessage(
          messages.scheduleTimeAfterNowRequired
        );
      }
    } catch (error) {}
  }

  return errors;
};

const formProps = formId => ({
  form: formId,
  validate: formValidation,
});

const mapStateToProps = (selector, formId) => state => ({
  type: selector(state, 'type'),
  schedule: (selector(state, 'schedule') || Map()).toJS(),
  value: state.getIn(['form', formId, 'values'], fromJS({})),
  formId,
});

export default formId => {
  const selector = formValueSelector(formId);

  return compose(
    injectIntl,
    withState('isScheduledBroadcast', 'setIsScheduledBroadcast', false),
    connect(mapStateToProps(selector, formId)),
    reduxForm(formProps(formId))
  )(BroadcastForm);
};
