import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import { compose } from 'recompose';
import color from 'color';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';

import {
  selectUser,
  selectCurrentConferenceHandle,
  selectCurrentConference,
} from '../../selectors/user';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import withAsyncContent from '../../hocs/withAsyncContent';
import withBreadcrumbs from '../../hocs/withBreadcrumbs';
import Icon from '../../components/Icon';
import ModalRoute from '../../components/ModalRoute';

import { DefaultList } from '../../components/List';
import TeamListItem from '../../components/TeamListItem';

import fetchUsers from '../../sagas/user/users/fetch';
import removeEditor from '../../sagas/user/conferences/removeEditor';
import { withSagaMiddleware } from '../../providers/SagaMiddlewareProvider';
import { button } from '../../utils/ui/interactive';
import AddNewTeamMemberPage from '../AddNewTeamMemberPage';

import messages from './messages';

const Root = styled(Flexbox)`
  > div {
    margin: 20px;
    flex-wrap: wrap;
  }
`;

const FormColumn = styled(Flexbox)`
  background: white;
  width: 500px;
  flex-direction: column;
  margin: 0px 8px 20px;
`;

const linkBackgroundColor = color('#248BD1');

const InviteTeamMemberWrapper = styled(Flexbox)`
  margin-bottom: 10px;
`;

const InviteTeamMember = styled(Link)`
  padding: 8px 12px;
  text-align: center;

  ${button({ backgroundColor: linkBackgroundColor })};
`;

const TeamList = DefaultList();

const isUserAnOwner = (uid, ownerId) => uid === ownerId;
const isCurrentUser = (currentUserId, candidate) => currentUserId === candidate;

class ConferenceSettingsTeamPage extends Component {
  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  handleMemberRemoval = userId => async () => {
    const { conferenceHandle, intl, user } = this.props;
    const confirmMessage = intl.formatMessage(messages.confirmMemberDeletion);

    await this.defaultUserRequestExecutor(
      removeEditor,
      conferenceHandle,
      userId,
      user.get('uid'),
      confirmMessage
    ).done;
  };

  render() {
    const { conference, content, match, user, intl } = this.props;
    const ownerId = conference.get('owner');
    const users = sortConferenceUsersByOwner(content, ownerId).toJS();
    const currentUser = user.get('uid');
    const isAuthAnOwnerOfTheConference = currentUser === ownerId;

    const TeamListItemComponent = TeamListItem({
      onDelete: this.handleMemberRemoval,
      currentUser,
      conferenceOwner: ownerId,
      isDeleteIconVisible: userId =>
        !(
          isUserAnOwner(userId, ownerId) ||
          isAuthAnOwnerOfTheConference ||
          isCurrentUser(currentUser, userId)
        ),
      intl,
    });

    return (
      <Fragment>
        <Root
          flexDirection="column"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Helmet title={`${conference.get('name')} Team Settings`} />
          <Flexbox flexDirection="row">
            <FormColumn>
              <h2>Team</h2>
              <InviteTeamMemberWrapper justifyContent={'flex-end'}>
                <InviteTeamMember to={`${match.url}/members/new`}>
                  <Icon>add</Icon> <span>Add new</span>
                </InviteTeamMember>
              </InviteTeamMemberWrapper>
              <TeamList
                items={users}
                component={TeamListItemComponent}
                keyExtractor={user => user.id}
              />
            </FormColumn>
          </Flexbox>
        </Root>

        <ModalRoute
          exact={true}
          path={`${match.path}/members/new`}
          component={AddNewTeamMemberPage}
        />
      </Fragment>
    );
  }
}

ConferenceSettingsTeamPage.propTypes = {
  conference: PropTypes.object.isRequired,
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectUser(),
  (conferenceHandle, conference, user) => {
    const conferenceUsers = conference.get('users', Map());
    const usersWithConferenceOwner = conferenceUsers.update(
      conference.get('owner'),
      () => true
    );
    const userIds = usersWithConferenceOwner.keySeq();
    return {
      conferenceHandle,
      conference,
      userIds,
      user,
    };
  }
);

function sortConferenceUsersByOwner(users, ownerId) {
  if (users.size) {
    const ownerIndex = users.findIndex(user => user.get('id') === ownerId);
    const owner = users.get(ownerIndex);
    return users.delete(ownerIndex).insert(0, owner);
  }

  return users;
}

const breadcrumbsProvider = ({ conference }) => [
  {
    to: `/@${conference.get('id')}/settings`,
    title: 'Settings',
  },
  {
    to: `/@${conference.get('id')}/settings/team`,
    title: 'Team',
  },
];

const asyncContentProvider = ({ sagaMiddleware, conferenceHandle, userIds }) =>
  sagaMiddleware.run(fetchUsers, conferenceHandle, userIds.toJS()).done;

const asyncContentRefreshProvider = (props, prevProps) =>
  props.userIds.size !== prevProps.userIds.size;

export default compose(
  withRouter,
  withUserRequestExecutor,
  connect(mapStateToProps),
  withSagaMiddleware,
  withBreadcrumbs(breadcrumbsProvider),
  withAsyncContent(asyncContentProvider, asyncContentRefreshProvider),
  injectIntl
)(ConferenceSettingsTeamPage);
