import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';
import { fromJS, Map } from 'immutable';

import { createSelector } from 'reselect';

import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
} from '../../selectors/user';

import Form from '../../components/Form';
import CustomIntentPicker from '../../components/CustomIntentPicker';
import SystemMessagePicker from '../../components/SystemMessagePicker';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

import { button } from '../../utils/ui/interactive';

import CollectionInput from '../../components/CollectionInput';
import SelectInput from '../../components/SelectInput';
import Icon from '../../components/Icon';
import SubmitButton from '../../components/SubmitButton';
import savePersistentMenu from '../../sagas/user/conferences/savePersistentMenu';
import { injectIntl } from 'react-intl';
import withBreadcrumbs from '../../hocs/withBreadcrumbs';
import messages from './messages';
import settingsMessages from '../ChatbotConfiguration/messages';

const Root = styled.div`
  background: white;
  padding: 20px 20px 40px 20px;
  max-width: 800px;
  flex-direction: column;
  margin: 40px;
`;

const menuItemTypes = [
  {
    value: 'submenu',
    label: messages.menuItemSubmenu,
  },
  {
    value: 'link',
    label: messages.menuItemLink,
  },
  {
    value: 'customIntent',
    label: messages.menuItemCustomIntent,
  },
  {
    value: 'systemMessage',
    label: messages.menuItemSystemMessage,
  },
];

const selector = formValueSelector('persistentMenu');

const mapMenuItemTypeToProps = (state, { name }) => {
  const itemType = selector(state, `${name}.type`) || 'submenu';
  return { itemType };
};

const ActionInput = connect(mapMenuItemTypeToProps)(
  ({ name, maxDepth, itemType }) => {
    let menuItemInputs = null;

    if (itemType === 'submenu' && maxDepth > 0) {
      menuItemInputs = (
        <div style={{ marginLeft: '4rem' }}>
          <Menu name={`${name}.actions`} maxDepth={maxDepth} />
        </div>
      );
    } else if (itemType === 'link') {
      menuItemInputs = (
        <fieldset>
          <label htmlFor={`${name}.url`}>URL</label>
          <Field
            name={`${name}.url`}
            component="input"
            type="text"
            placeholder="e.g. https://google.com"
            required
          />
        </fieldset>
      );
    } else if (itemType === 'customIntent') {
      menuItemInputs = (
        <CustomIntentPicker label={'Custom Intent'} name={name} />
      );
    } else if (itemType === 'systemMessage') {
      menuItemInputs = (
        <SystemMessagePicker label={'System Message'} name={name} />
      );
    }

    const filteredMenuItemTypes = menuItemTypes.filter(
      menuItem => !(menuItem.value === 'submenu' && maxDepth <= 0)
    );

    return (
      <Fragment>
        <fieldset>
          <SelectInput
            label={messages.menuItemType}
            name={`${name}.type`}
            values={filteredMenuItemTypes}
          />
        </fieldset>
        {menuItemInputs}
      </Fragment>
    );
  }
);

const MenuItemField = ({ name, maxDepth }) => (
  <Fragment>
    <fieldset>
      <label htmlFor={`${name}.label`}>Label</label>
      <Field name={`${name}.label`} component="input" type="text" required />
    </fieldset>
    <ActionInput name={`${name}.action`} maxDepth={maxDepth} />
  </Fragment>
);

const MenuListWrapperRoot = styled.div`
  margin-bottom: 1rem;
`;

const MenuListWrapperError = styled.div``;

const MenuListWrapperChildren = styled.div``;

const AddButton = styled.button`
  ${button({ backgroundColor: 'rgba(255, 255, 255, 0.1)' })};
  padding: 0.5rem;
`;

const MenuItemWrapperRoot = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.05);
  padding: 1rem;
  margin-bottom: 1rem;
`;

const MenuItemWrapperChildren = styled.div`
  flex: 1;
`;

const RemoveButton = styled.button`
  ${button({ backgroundColor: 'rgba(255, 255, 255, 0.1)' })};
  padding: 0.5rem;
  align-self: start;
`;

const MenuListWrapper = ({ children, onAdd, touched, error }) => (
  <MenuListWrapperRoot>
    {touched && error && <MenuListWrapperError>{error}</MenuListWrapperError>}

    <MenuListWrapperChildren>{children}</MenuListWrapperChildren>

    <AddButton
      onClick={e => {
        e.preventDefault();
        onAdd();
      }}
    >
      <Icon>add</Icon> Add Menu Item
    </AddButton>
  </MenuListWrapperRoot>
);

const MenuItemWrapper = ({ children, onRemove }) => (
  <MenuItemWrapperRoot>
    <MenuItemWrapperChildren>{children}</MenuItemWrapperChildren>
    <RemoveButton
      onClick={e => {
        e.preventDefault();
        onRemove();
      }}
    >
      <Icon>clear</Icon>
    </RemoveButton>
  </MenuItemWrapperRoot>
);

const MenuItemsInput = CollectionInput(
  MenuItemField,
  MenuListWrapper,
  MenuItemWrapper
);

const Menu = ({ name, maxDepth }) => (
  <FieldArray
    name={name}
    component={MenuItemsInput}
    props={{ props: { maxDepth: maxDepth - 1 } }}
  />
);

const MenuForm = reduxForm({
  form: 'persistentMenu',
  initialValues: fromJS({}),
})(({ pristine, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Menu name="persistentMenu" maxDepth={3} />
    {!pristine && <SubmitButton label="Save Menu" />}
  </Form>
));

const ChatMenuPage = ({
  match,
  createUserRequestExecutor,
  conferenceHandle,
  conferenceName,
  persistentMenu,
}) => {
  const defaultUserRequestExecutor = createUserRequestExecutor({
    onFailure: () => {
      throw SubmissionError({
        '': 'Failed to save chat persistentMenu',
      });
    },
  });

  const handleSubmit = async data => {
    const formData = data.toJS();

    await defaultUserRequestExecutor(
      savePersistentMenu,
      conferenceHandle,
      formData.persistentMenu
    );
  };

  return (
    <Root>
      <Helmet title={`${conferenceName} Chat Menu`} />
      <MenuForm initialValues={persistentMenu} onSubmit={handleSubmit} />
    </Root>
  );
};

const selectPersistentMenu = createSelector(
  selectCurrentConference,
  conference => conference.getIn(['chatbotSettings', 'persistentMenu'])
);

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectPersistentMenu,
  (conference, conferenceHandle, conferenceName, persistentMenu) => ({
    conference,
    conferenceHandle,
    conferenceName,
    persistentMenu: persistentMenu ? Map({ persistentMenu }) : Map(),
  })
);

const breadcrumbsProvider = ({ conference, intl, match }) => [
  {
    to: `/@${conference.get('id')}/conversations`,
    title: intl.formatMessage(settingsMessages.breadcrumb),
  },
  {
    to: match.url,
    title: intl.formatMessage(messages.breadcrumb),
  },
];

export default compose(
  withRouter,
  withUserRequestExecutor,
  injectIntl,
  connect(mapStateToProps),
  withBreadcrumbs(breadcrumbsProvider)
)(ChatMenuPage);
