/**
 *
 * ChatbotSettingsForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import Form from '../Form';

import { button } from '../../utils/ui/interactive';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';

const Button = styled.button`
  padding: 12px;
  margin: 0px;

  ${button()};
`;

const HelperText = styled.p`
  font-size: 0.8em;
  font-style: italic;
`;

const ChatbotSettingsForm = props => {
  const { handleSubmit, pristine, submitting, intl } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset>
        <FormattedMessage {...messages.venueLocation} />
        <Field
          name="venueLocation"
          component="input"
          type="text"
          placeholder={intl.formatMessage(messages.venueLocationPlaceholder)}
        />
      </fieldset>
      <HelperText>
        <FormattedMessage {...messages.venueLocationInfo} />
      </HelperText>

      <fieldset>
        <FormattedMessage {...messages.wifiNote} />
        <Field
          name="wifiNote"
          component="input"
          type="text"
          placeholder={intl.formatMessage(messages.wifiNotePlaceholder)}
        />
      </fieldset>
      <HelperText>
        <FormattedMessage {...messages.wifiNoteInfo} />
      </HelperText>
      <fieldset>
        <Button disabled={pristine || submitting}>
          <FormattedMessage {...messages.save} />
        </Button>
      </fieldset>
    </Form>
  );
};

ChatbotSettingsForm.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  intl: intlShape.isRequired,
};

export default reduxForm({
  form: 'chatbotSettings',
})(injectIntl(ChatbotSettingsForm));
