import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  renderNothing,
  lifecycle,
  withState,
} from 'recompose';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import { toListOfMapsWithIds } from 'sava-shared/lib/utils/mapOfMapsToListOfMaps';

import messagingClients from '../../constants/messagingClients';
import SavaPropTypes from '../../utils/SavaPropTypes';
import withNow from '../../hocs/withNow';
import { getAverage } from '../../utils/math/ratings';
import makeHeadline from '../../utils/makeHeadline';
import makeName from '../../utils/makeName';
import validateTimeDifference from '../../utils/validateTimeDifference';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import getMessagingClientsData from '../../sagas/user/integrations/getMessagingClientsData';
import { generateChatLink } from '../OverlayPage';

const logo = `${process.env.PUBLIC_URL}/icons/logo.png`;
const background = `${process.env.PUBLIC_URL}/images/widget-bg.svg`;
const phone = `${process.env.PUBLIC_URL}/icons/hand_phone.svg`;
const stars = `${process.env.PUBLIC_URL}/icons/stars.svg`;
const bot = `${process.env.PUBLIC_URL}/icons/bot.svg`;

const listOfMessagingClients = toListOfMapsWithIds(messagingClients);

const Overlay = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 1.15;
  background: url(${background}) no-repeat;
  background-size: contain;
  background-position-y: -110%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-style: normal;
    margin: 0;
  }
  h2 {
    font-weight: 800;
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: white;
  color: #16073c;
  > div > div {
    overflow: hidden;
    padding: 3rem 2.75rem;
    display: flex;
    flex-direction: column;
    > h2 {
      font-size: 3em;
      flex: 0;
    }
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  > div.best {
    justify-content: center;
    align-items: flex-start;
  }
  > div.sava {
    color: black;
    background: rgb(255, 244, 226);

    > h2 {
      white-space: pre;
    }
    > div {
      align-items: flex-start;
    }
  }
`;

const TalkScore = styled.div`
  margin-top: 60px;
  > h3 {
    margin-bottom: 0.2em;
    font-size: 26px;
  }
`;

const CompactSpeakers = ({ speakers }) => (
  <div>
    <p style={{ fontSize: '20px' }}>{makeName(speakers)}</p>
    <p style={{ fontSize: '16px' }}>
      {makeHeadline(speakers.map(speaker => speaker.get('headline')))}
    </p>
  </div>
);

CompactSpeakers.propTypes = {
  speakers: PropTypes.any,
};

const Score = styled.p`
  font-size: 2em;
`;

const Channels = styled.div`
  flex: ${props => (props.zero ? '0' : '1')};
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 5rem;
  justify-content: ${props => (props.jcenter ? 'center' : 'flex-start')};
  align-items: ${props => (props.acenter ? 'center' : 'flex-end')};

  > img {
    position: relative;
    left: 30px;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
`;

const Channel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  > div > h3 {
    margin-bottom: 0.4em;
    > img {
      height: 2em;
      margin-right: 0.4em;
      margin-top: -0.2em;
    }
  }
  > div > p {
    font-size: 1.17em;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  > img {
    width: 6em;
  }
`;

const Logo = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  padding-right: 44px;

    > img {
      height: 200px;
    }
  }
`;

const NoRatings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h2 {
    margin-bottom: 1em;
    font-size: 30px;
    width: 80%;
    text-align: center;
  }

  > img {
    margin: 3em 0em;
  }
`;

const Best = styled.div`
  align-items: flex-start;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
`;

const Wrap = styled.div`
  justify-content: ${props =>
    props.center ? 'center' : 'space-between'}!important;

  > h2 {
    white-space: pre;
    font-size: 3em;
  }
`;

const ScoreboardPage = ({
  next,
  upcoming,
  best,
  status,
  messagingClientsData,
}) => (
  <Overlay>
    <Sidebar>
      <div className="best">
        {best.count() > 0 ? (
          <Wrap>
            <h2>Most Rated Talks</h2>
            <Best>
              {best.count() > 0 ? (
                best.map(talk => (
                  <TalkScore key={talk.get('id')}>
                    <Score>{talk.get('score').toFixed(1)}</Score>
                    <h3>{talk.get('title')}</h3>
                    <CompactSpeakers speakers={talk.get('speakers')} />
                  </TalkScore>
                ))
              ) : (
                <NoRatings>
                  <h2>Help speakers improve and rate their talks.</h2>
                  <img src={stars} alt="rating" />
                </NoRatings>
              )}
            </Best>
            {messagingClientsData.length ? (
              <Channels row jcenter acenter>
                <Channel>
                  <img src={bot} alt="Bot info" />
                </Channel>
                <Channel>
                  {messagingClientsData.map((messagingClient, index) => (
                    <div key={index}>
                      <h3>
                        <img
                          src={messagingClient.icon}
                          alt={messagingClient.username}
                        />
                        {messagingClient.baseUrl}
                        <span>/</span>
                        {messagingClient.username}
                      </h3>
                    </div>
                  ))}
                </Channel>
                <Channel>
                  <img src={phone} alt="Bot phone" />
                </Channel>
              </Channels>
            ) : null}
          </Wrap>
        ) : (
          <Wrap center>
            <Best center>
              <NoRatings>
                <h2 style={{ marginBottom: '1em' }}>
                  Help speakers improve and rate their talks.
                </h2>
                <img src={stars} alt="rating" />
              </NoRatings>
              {messagingClientsData.length ? (
                <Channels row jcenter acenter>
                  <Channel>
                    <img src={bot} alt="Bot info" />
                  </Channel>
                  <Channel>
                    {messagingClientsData.map((messagingClient, index) => (
                      <div key={index}>
                        <h3>
                          <img
                            src={messagingClient.icon}
                            alt={messagingClient.username}
                          />
                          {messagingClient.baseUrl}
                          <span>/</span>
                          {messagingClient.username}
                        </h3>
                      </div>
                    ))}
                  </Channel>
                  <Channel>
                    <img src={phone} alt="Bot phone" />
                  </Channel>
                </Channels>
              ) : null}
            </Best>
          </Wrap>
        )}
      </div>
    </Sidebar>
    <Logo>
      <img src={logo} alt="Company logo" />
    </Logo>
  </Overlay>
);

ScoreboardPage.propTypes = {
  next: ImmutablePropTypes.contains({
    time: MomentPropTypes.momentObj,
    title: PropTypes.string,
    speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
  }),
  upcoming: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string,
      time: MomentPropTypes.momentObj,
      title: PropTypes.string,
      speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
    })
  ).isRequired,
  best: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.any,
      score: PropTypes.number,
      title: PropTypes.string,
      speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
    })
  ).isRequired,
  status: PropTypes.string,
};

const using = (...args) => f => f(...args);

const mapStateToProps = (state, props) => {
  if (!state.getIn(['embed', 'sessions'])) {
    return {};
  }

  const cutoff = props.now.clone().subtract(10, 'minutes');
  const tomorrow = props.now
    .clone()
    .add(1, 'day')
    .startOf('day');

  const sessions = state
    .getIn(['embed', 'sessions'], List())
    .valueSeq()
    .toList();

  const trackSessions = sessions
    .filter(
      session => session.getIn(['track', 'id']) === props.match.params.track
    )
    .filter(session => session.get('startTime').isAfter(cutoff))
    .filter(session => session.get('startTime').isBefore(tomorrow))
    .sortBy(session => session.get('startTime'), (a, b) => a.unix() - b.unix());

  const next = using(trackSessions.first())(
    talk =>
      talk &&
      Map({
        time: talk.get('startTime'),
        title: talk.get('title'),
        speakers: talk.get('speakers'),
      })
  );

  const upcoming = trackSessions.slice(1, 4).map(talk =>
    Map({
      id: talk.get('id'),
      time: talk.get('startTime'),
      title: talk.get('title'),
      speakers: talk.get('speakers'),
    })
  );

  const best = sessions
    .map(talk =>
      using(talk.get('ratings', List()))(ratings =>
        talk
          .set('score', getAverage(ratings.toJS()))
          .set('scores', ratings.count())
      )
    )
    .filter(talk => talk.get('scores') > 2)
    .sortBy(talk => talk.get('scores'))
    .reverse()
    .slice(0, 4)
    .sortBy(talk => talk.get('score'))
    .reverse()
    .map(talk =>
      Map({
        id: talk.get('id'),
        score: talk.get('score'),
        title: talk.get('title'),
        speakers: talk.get('speakers'),
      })
    );

  const diff =
    next &&
    validateTimeDifference(
      null,
      moment().format('HH:mm'),
      next.get('time').format('HH:mm')
    );
  let status;

  if (diff) {
    for (let i = 0; i < diff.length; i += 1) {
      if (diff[0] === 0 && diff[1] <= 5) {
        status = 5 - (5 - diff[1]);
      }
    }
  }

  return { next, upcoming, best, status };
};

const limitScores = Component =>
  class LimitScores extends React.Component {
    static propTypes = {
      best: PropTypes.any.isRequired,
      location: PropTypes.any.isRequired,
    };

    render() {
      const { best, location, ...props } = this.props;

      const { scores } = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      return (
        <Component
          {...props}
          best={best.take(
            using(parseInt(scores, 10))(scores =>
              Number.isFinite(scores)
                ? Math.max(0, scores)
                : Number.POSITIVE_INFINITY
            )
          )}
        />
      );
    }
  };

const lifecycleMethods = {
  async componentDidMount() {
    const {
      conferenceHandle,
      createUserRequestExecutor,
      setMessagingClientsData,
    } = this.props;
    const defaultUserRequestExecutor = createUserRequestExecutor({
      shouldNotifyToastr: false,
    });

    const messagingClientsData = await defaultUserRequestExecutor(
      getMessagingClientsData,
      conferenceHandle
    );

    const result = listOfMessagingClients
      .map(generateChatLink(messagingClientsData))
      .filter(_ => !!_);

    setMessagingClientsData(result);
  },
};

export default compose(
  withUserRequestExecutor,
  withRouter,
  withNow,
  connect(mapStateToProps),
  withState('messagingClientsData', 'setMessagingClientsData', {}),
  branch(props => !props.upcoming || !props.best, renderNothing),
  limitScores,
  lifecycle(lifecycleMethods)
)(ScoreboardPage);
