import moment from 'moment-timezone';

/**
 *
 * @param {string} startTime
 * @param {Number} duration
 * @returns {string}
 */
export default (startTime, duration) => {
  const addedHours = Math.floor(Number(duration) / 60);
  const addedMinutes = Number(duration) - addedHours * 60;
  return moment
    .utc(startTime, 'HH:mm')
    .add(addedHours, 'h')
    .add(addedMinutes, 'm')
    .format('HH:mm');
};
