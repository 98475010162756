/**
 *
 * TrackView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const TrackView = ({ name }) => <div>{name}</div>;

TrackView.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TrackView;
