/*
 *  SchedulePage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { slugify } from 'sava-shared/lib/utils/core';

import ListSchedule from '../../../components/ListSchedule';
import GridSchedule from '../../../components/GridSchedule';
import FlatGridSchedule from '../../../components/FlatGridSchedule';
import Spinner from '../../../components/Spinner';
import { setLastDayViewed } from '../../../utils/lastDayViewed';
import {
  selectConferenceHandle,
  selectDay,
  selectDays,
  selectEmbedLayout,
  selectGeneralConfiguration,
  selectMobileEmbedLayout,
} from '../../../selectors/embed';
import emptyStatePastIllustration from '../../../assets/images/coffee_breaks.svg';
import EntityEmptyState, {
  EmptyStateMarginWrapper,
} from '../../../components/EntityEmptyState';
import withIsMobile from '../../../hocs/withIsMobile';
import Navigation from '../../../components/Navigation';
import responsive from '../../../utils/ui/responsive';
import PoweredBy from '../../../components/PoweredBy';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Day = styled.section`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  margin: 2em auto 0em auto;
  padding: 0 0.5em;
`;

const DayFlat = styled.section`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 1em 2em 0em 2em;

  ${responsive.md.andLarger`
    width: 700px;
  `};

  ${responsive.md.andSmaller`
    width: 100%;
  `};
`;

const Center = styled.div`
  display: block;
  margin: 0 auto;
`;

class SchedulePage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    tracks: PropTypes.object,
    breaks: PropTypes.object,
    dayIndex: PropTypes.number,
    days: PropTypes.object,
    day: PropTypes.object,
  };

  static provideLink = (route, { conferenceHandle, sessionTitle } = {}) => {
    switch (route) {
      case 'session':
        return `/@${conferenceHandle}/embed/schedule/sessions/${slugify(
          sessionTitle
        )}`;
      default:
        return undefined;
    }
  };

  handleDayPickerClick = dayIndex => () => {
    const { conferenceHandle } = this.props;
    setLastDayViewed(conferenceHandle, dayIndex);
  };

  /**
   * If user comes to this page using a link we need to set new
   * day index and conference handle.
   */
  componentDidMount() {
    const { dayIndex, conferenceHandle } = this.props;
    setLastDayViewed(conferenceHandle, dayIndex);
  }

  hasTracks = () => {
    return this.props.tracks.size > 0;
  };

  hasBreaks = () => {
    return this.props.breaks.size > 0;
  };

  hasNoTracks = () => this.props.tracks.size === 0;

  hasTracksOrBreaks = () => this.hasTracks() || this.hasBreaks();

  noTracksAndBreaks = () => !this.hasTracks() && !this.hasBreaks();

  isFlatLayout = () => this.props.embedLayout === 'flat';

  isGridLayout = () =>
    !this.props.embedLayout || this.props.embedLayout === 'grid';

  hasMultipleTracks = () => this.props.tracks.size > 1;

  hasOneTrack = () => this.props.tracks.size === 1;

  isFlatMobileEmbedLayout = () =>
    !!this.props.isMobile && this.props.mobileEmbedLayout === 'flat';

  shouldRenderFlatLayout = () =>
    this.hasTracksOrBreaks() &&
    (this.isFlatLayout() || this.isFlatMobileEmbedLayout());

  shouldRenderDefaultGridLayout = () =>
    (this.hasMultipleTracks() &&
      this.isGridLayout() &&
      !this.isFlatMobileEmbedLayout()) ||
    (this.hasOneTrack() &&
      this.isGridLayout() &&
      this.props.singleTrackDefaultGridLayout) ||
    (this.hasBreaks() &&
      this.isGridLayout() &&
      (this.props.singleTrackDefaultGridLayout || this.props.showEmptyTracks));

  shouldRenderFlatGridLayout = () =>
    !this.props.showEmptyTracks &&
    !this.isFlatMobileEmbedLayout() &&
    ((this.hasOneTrack() &&
      this.isGridLayout() &&
      !this.props.singleTrackDefaultGridLayout) ||
      (this.hasNoTracks() &&
        this.hasBreaks() &&
        this.isGridLayout() &&
        !this.props.singleTrackDefaultGridLayout));

  render() {
    const { conferenceHandle, tracks, breaks, dayIndex, days } = this.props;

    if (tracks && breaks) {
      return (
        <Container>
          <Helmet title="Schedule" />

          <Navigation
            days={days}
            conferenceHandle={conferenceHandle}
            dayIndex={dayIndex}
            onClick={this.handleDayPickerClick}
          />
          {this.noTracksAndBreaks() ? (
            <EmptyStateMarginWrapper>
              <EntityEmptyState
                illustration={emptyStatePastIllustration}
                title={'No sessions for this day'}
                hint={`Currently there are no session for this day. But keep an eye for new ones.`}
              />
              <PoweredBy conferenceHandle={conferenceHandle} />
            </EmptyStateMarginWrapper>
          ) : null}

          {this.shouldRenderFlatLayout() ? (
            <DayFlat>
              <ListSchedule
                conferenceHandle={conferenceHandle}
                linkProvider={SchedulePage.provideLink}
                day={dayIndex}
                tracks={tracks}
                breaks={breaks}
              />
              <PoweredBy conferenceHandle={conferenceHandle} />
            </DayFlat>
          ) : null}
          {this.shouldRenderDefaultGridLayout() ? (
            <Day className={'webkit-scroll-touch'}>
              <Center>
                <GridSchedule
                  conferenceHandle={conferenceHandle}
                  linkProvider={SchedulePage.provideLink}
                  day={dayIndex}
                  tracks={tracks}
                  breaks={breaks}
                  isFlatLayout={true}
                />
                <PoweredBy conferenceHandle={conferenceHandle} />
              </Center>
            </Day>
          ) : null}
          {this.shouldRenderFlatGridLayout() ? (
            <Day className={'webkit-scroll-touch'}>
              <Center>
                <FlatGridSchedule
                  conferenceHandle={conferenceHandle}
                  linkProvider={SchedulePage.provideLink}
                  day={dayIndex}
                  tracks={tracks}
                  breaks={breaks}
                />
                <PoweredBy conferenceHandle={conferenceHandle} />
              </Center>
            </Day>
          ) : null}
        </Container>
      );
    }

    return (
      <Container>
        <Spinner />
      </Container>
    );
  }
}

const mapStateToProps = createSelector(
  selectConferenceHandle,
  selectDay,
  selectDays,
  selectEmbedLayout,
  selectMobileEmbedLayout,
  selectGeneralConfiguration,
  (
    conferenceHandle,
    dayParam,
    days,
    embedLayout,
    mobileEmbedLayout,
    generalConfiguration
  ) => {
    const dayIndex = Number(dayParam);
    const day = days.get(dayIndex, Map());

    const tracks = generalConfiguration.get('showEmptyTracks')
      ? day.get('tracks')
      : day.get('tracks').filter(track => track.get('sessions').size > 0);

    return {
      conferenceHandle,
      tracks,
      breaks: day.get('breaks'),
      dayIndex,
      days,
      day,
      embedLayout,
      mobileEmbedLayout,
      showEmptyTracks: generalConfiguration.get('showEmptyTracks'),
      singleTrackDefaultGridLayout: generalConfiguration.get(
        'singleTrackDefaultGridLayout'
      ),
    };
  }
);

export default compose(
  withRouter,
  withIsMobile,
  connect(mapStateToProps)
)(SchedulePage);
