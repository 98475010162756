import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import { inMinutes, isOverlapping } from '../../../utils/time';

export default function* createBreak({
  conferenceHandle,
  title,
  description,
  day,
  time,
  isBlock,
}) {
  // TODO Bla…
  const breakObj = {
    title,
    day,
    time,
    isBlock,
  };

  if (description) {
    breakObj.description = description;
  }

  if (inMinutes(time.endTime) <= inMinutes(time.startTime)) {
    throw {
      code: 'break/duration',
      message: 'Break duration can not be 0 or negative.',
    };
  }

  const breaks = yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/breaks`)
    .once('value');

  const hasConflicts = Object.entries(breaks.val() || {}).some(([_, b]) => {
    return (
      b.day === breakObj.day &&
      (b.isBlock || breakObj.isBlock || b.track === breakObj.track) &&
      isOverlapping(b.time, breakObj.time)
    );
  });

  if (hasConflicts) {
    throw {
      code: 'break/overlapping',
      message: 'Break overlaps with another break.',
    };
  }

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/breaks`)
    .push(breakObj);

  yield put(goBack());
}
