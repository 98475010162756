import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import { compose, withState } from 'recompose';

const ProfilePicture = ({
  image,
  color,
  alt,
  width,
  height,
  padding,
  style,
  displayImage,
  setDisplayImage,
}) => {
  const frameWidth = width - 2 * padding;
  const frameHeight = height - 2 * padding;

  const frameAspectRatio = frameWidth / frameHeight;

  const size = frameAspectRatio > 1 ? frameHeight : frameWidth;

  const styles = Object.assign(style || {}, {
    width: size,
    height: size,
    borderRadius: '50%',
    overflow: 'hidden',
    background: color,
  });

  return (
    <Flexbox
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      style={styles}
    >
      {image && (
        <img
          src={image}
          alt={alt}
          style={{
            height: '100%',
            display: displayImage ? 'inline' : 'none',
          }}
          onError={() => setDisplayImage(false)}
        />
      )}
    </Flexbox>
  );
};

ProfilePicture.propTypes = {
  image: PropTypes.any,
  color: PropTypes.any.isRequired,
  alt: PropTypes.any,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  padding: PropTypes.number,
  style: PropTypes.any,
};

export default compose(withState('displayImage', 'setDisplayImage', true))(
  ProfilePicture
);
