import React from 'react';
import styled from 'styled-components';
import UserImage from './UserImage';
import UserInfo from './UserInfo';

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.lightOpacity};
`;

export default ({ profile }) => {
  return (
    <Summary>
      <UserImage initials={profile.getInitials()} color={profile.getColor()} />
      <UserInfo profile={profile} />
    </Summary>
  );
};
