import UserProfile from './UserProfile';

export const compareAsString = (a, b) => String(a) === String(b);

export const flatten = (data = {}) =>
  Object.keys(data).map(key => ({ id: key, ...data[key] }));

export const findUserProfile = (users, tickets, ticketId) => {
  const ticket = tickets.find(_ => _.id === ticketId);
  if (ticket) {
    return users.find(_ => compareAsString(_.getId(), ticket.openedBy));
  }
};

export const mergeTicketsWithProfiles = (tickets, users) =>
  tickets.map(t => ({
    ...t,
    profile:
      users.find(_ => compareAsString(_.getId(), t.openedBy)) ||
      new UserProfile({}),
  }));

export const transduceMessengerUsers = users =>
  flatten(users).map(
    ({ id, data = {} }) =>
      new UserProfile({
        id: id,
        firstName: data.first_name,
        lastName: data.last_name,
        ...data,
      })
  );

export const transduceTelegramUsers = users =>
  flatten(users).map(
    ({ id, data = {} }) =>
      new UserProfile({
        id: id,
        firstName: data.first_name,
        username: data.username,
        ...data,
      })
  );

export const getOpenTickets = tickets =>
  tickets
    .filter(_ => _.status === 'new' || _.status === 'active')
    .sort((a, b) => new Date(b.openedAt) - new Date(a.openedAt));

export const getClosedTickets = tickets =>
  tickets
    .filter(_ => _.status === 'closed')
    .sort((a, b) => new Date(b.closedAt) - new Date(a.closedAt));
