export const formatLinkToHandle = link => {
  if (link.match(/^https?:\/\//)) {
    const split = link.split('/');

    // Check if link ends with / i.e. https://instagram.com/spfr/
    return split[split.length - 1]
      ? split[split.length - 1]
      : split[split.length - 2];
  }

  return link;
};
