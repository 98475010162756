import { defineMessages } from 'react-intl';

export default defineMessages({
  tracksEmptyState: {
    id: 'app.containers.ConferencePage.tracksEmptyState',
    defaultMessage: 'No Tracks Added',
  },
  tracksEmptyStateHint: {
    id: 'app.containers.ConferencePage.tracksEmptyStateHint',
    defaultMessage: 'Add tracks so you can create and assign sessions.',
  },
});
