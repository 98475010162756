/*
 *  CreateSessionPage Messages
 *
 *  This contains all the text for the UsersPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultFullName: {
    id: 'app.components.TeamListItem.defaultFullName',
    defaultMessage: 'Unknown user',
  },
});
