import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.ErrorPage.title',
    defaultMessage: 'Error • Sava Events',
  },
  UNKNOWN: {
    id: 'app.containers.ErrorPage.unknown',
    defaultMessage: 'Unknown error occurred.',
  },
  PERMISSION_DENIED: {
    id: 'app.containers.ErrorPage.permissionDenied',
    defaultMessage: 'Your account is missing some permissions.',
  },
  defaultDescription: {
    id: 'app.containers.ErrorPage.defaultDescription',
    defaultMessage:
      'Please try again and if the error persists, contact our 🌶️ support.',
  },
  goBack: {
    id: 'app.containers.ErrorPage.goBack',
    defaultMessage: 'Go Back',
  },
});
