import { css } from 'styled-components';
import color from 'color';

const using = value => f => f(value);

const animatedProperties = [
  'background',
  'color',
  'box-shadow',
  'opacity',
  'transform',
];

const interactiveStyles = css`
  transition: ${animatedProperties
    .map(p => `${p} 80ms ease-in-out`)
    .join(', ')};
`;

const interactive = {
  styles: (...args) =>
    css`
      &,
      &:link,
      &:visited {
        ${interactiveStyles} ${css(...args)};
      }
    `,
  hover: (...args) =>
    css`
      &:hover,
      &:focus:hover {
        ${css(...args)};
      }
    `,
  focus: (...args) =>
    css`
      &:focus {
        ${css(...args)};
      }
    `,
  active: (...args) =>
    css`
      &:active,
      &:focus:active {
        ${css(...args)};
      }
    `,
  disabled: (...args) =>
    css`
      &:disabled {
        ${css(...args)};
      }
    `,
};

export default interactive;

export const button = (props = {}) => {
  const backgroundColor = using(props.backgroundColor || '#f0f0f0')(bg =>
    bg instanceof color ? bg : color(bg)
  );

  const darken = props.darken && !props.lighten;
  const lighten = !props.darken && props.lighten;

  const highlight = amount => {
    const factor = Math.exp(Math.abs(backgroundColor.luminosity() - 0.5));
    const adaptedAmount =
      (darken && backgroundColor.isDark()) ||
      (lighten && backgroundColor.isLight())
        ? amount * factor
        : amount / factor;

    return darken || (!lighten && backgroundColor.isLight())
      ? backgroundColor.darken(adaptedAmount)
      : backgroundColor.lighten(adaptedAmount);
  };

  const shadow = props.shadow !== false;

  return css`
    ${interactive.styles`
      text-decoration: none;
      font-family: inherit;
      background-color: ${backgroundColor.string()};
      ${
        props.skipColorCreation
          ? ''
          : backgroundColor.isDark() &&
            css`
              color: white;
            `
      }
      
      cursor: pointer;
    `}

    ${interactive.hover`
      background-color: ${highlight(0.1).string()};
      ${p => shadow && p.theme.altitude(1)};
    `}
    
    ${interactive.focus`
      background-color: ${highlight(0.05).string()};
      outline: none;
      ${p => shadow && p.theme.altitude(1)};
    `}
    
    ${interactive.active`
      background-color: ${highlight(0.2).string()};
      ${p => shadow && p.theme.altitude(1).inset};
    `}
    
    ${interactive.disabled`
      background-color: ${backgroundColor.string()};
      ${p => shadow && p.theme.altitude(0)};
      cursor: initial;
      opacity: 0.5;
    `}
  `;
};

export const link = (props = {}) => {
  const textColor = using(props.color || '#333')(c =>
    c instanceof color ? c : color(c)
  );

  const darken = props.darken && !props.lighten;
  const lighten = !props.darken && props.lighten;

  const highlight = amount => {
    if (props.fade) {
      return textColor.fade(amount);
    }

    const adaptedAmount =
      Math.exp(Math.abs(textColor.luminosity() - 0.5)) * amount;

    return darken || (!lighten && textColor.isLight())
      ? textColor.darken(adaptedAmount)
      : textColor.lighten(adaptedAmount);
  };

  return css`
    ${interactive.styles`
      color: ${textColor.string()};
      text-decoration: ${props.alwaysUnderline ? 'underline' : 'none'};
      cursor: pointer;
    `}

    ${interactive.hover`
      color: ${highlight(0.2).string()};
      ${props.decorate !== false && 'text-decoration: underline;'}
    `}

    ${interactive.focus`
      color: ${highlight(0.1).string()};
      ${props.decorate !== false && 'text-decoration: underline;'}
    `}

    ${interactive.active`
      color: ${highlight(0.4).string()};
    `}
  `;
};

export const card = (props = {}) => {
  const backgroundColor = using(props.backgroundColor || '#f0f0f0')(bg =>
    bg instanceof color ? bg : color(bg)
  );

  const darken = props.darken && !props.lighten;
  const lighten = !props.darken && props.lighten;

  const highlight = amount =>
    darken || (!lighten && backgroundColor.isLight())
      ? backgroundColor.darken(amount)
      : backgroundColor.lighten(amount);

  return css`
    ${interactive.styles`
      background-color: ${backgroundColor.string()};
      ${
        props.color
          ? css`
              color: ${props.color};
            `
          : backgroundColor.isDark() &&
            css`
              color: white;
            `
      }
      text-decoration: none;
      cursor: pointer;
    `}

    ${interactive.hover`
      backgroundColor: ${highlight(0.1).string()};
      ${p => p.theme.altitude(1)};
    `}

    ${interactive.focus`
      backgroundColor: ${highlight(0.05).string()};
      outline: none;
      ${p => p.theme.altitude(1)};
    `}

    ${interactive.active`
      backgroundColor: ${highlight(0.2).string()};
      ${p => p.theme.altitude(0)};
    `}
  `;
};
