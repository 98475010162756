/*
 * SpeakerInput Messages
 *
 * This contains all the text for the SpeakerInput component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.SpeakerInput.name',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'app.components.SpeakerInput.namePlaceholder',
    defaultMessage: 'e.g., John Doe',
  },
  headline: {
    id: 'app.components.SpeakerInput.headline',
    defaultMessage: 'Headline',
  },
  headlinePlaceholder: {
    id: 'app.components.SpeakerInput.headlinePlaceholder',
    defaultMessage: 'e.g., Awesomization Guru at Some Tech Company',
  },
  image: {
    id: 'app.components.SpeakerInput.image',
    defaultMessage: 'Image',
  },
});
