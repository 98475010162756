import firebase from 'firebase/app';

export default function* updateSubmissionStatus(
  conferenceHandle,
  submissionId,
  status
) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/cfp/submissions/${submissionId}`)
    .update({ status });
}
