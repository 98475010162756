import { defineMessages } from 'react-intl';

export default defineMessages({
  totalSubscribers: {
    id: 'app.containers.BroadcastPage.totalSubscribers',
    defaultMessage: 'Total subscribers',
  },
  totalSubscribersInfo: {
    id: 'app.containers.BroadcastPage.totalSubscribersInfo',
    defaultMessage:
      'The number of people that will be reached once the broadcast is sent.',
  },
  totalSubscribersZero: {
    id: 'app.containers.BroadcastPage.totalSubscribersZero',
    defaultMessage: 'Waiting for subscribers.',
  },
  totalSubscribersLoading: {
    id: 'app.containers.BroadcastPage.totalSubscribersLoading',
    defaultMessage: 'Calculating subscribers...',
  },
});
