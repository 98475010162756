import Flexbox from 'flexbox-react';
import styled from 'styled-components';

const MultipleButtons = styled(Flexbox)`
  > * + * {
    margin-left: 1em;
  }
`;

export default MultipleButtons;
