import firebase from 'firebase/app';

export default function* addSubmission(conferenceHandle, submission) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/cfp/submissions`)
    .push({
      submittedAt: Date.now(),
      status: 'new',
      responses: submission,
    });
}
