import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';

export default function* deleteSponsor(
  conferenceHandle,
  id,
  shouldGoBack = true
) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsors/${id}`)
    .remove();

  if (shouldGoBack) {
    yield put(goBack());
  }
}
