import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { compose, setPropTypes, withState } from 'recompose';
import { injectIntl } from 'react-intl';

import messages from './messages';

import Auth from '../../containers/Auth';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import requestPasswordReset from '../../sagas/user/account/requestPasswordReset';

const ForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5ff;
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
  background-size: 18%;
  box-shadow: 0 9px 17px 0 rgba(21, 11, 57, 0.15);
  padding: 2.125em 3.75em;
  text-align: center;
`;

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
`;

const Error = styled.p`
  color: ${props => props.theme.colors.attention};
  margin: 0;
`;

export const ForgotPasswordPage = ({
  sagaMiddleware,
  location,
  error,
  setError,
  intl,
  createUserRequestExecutor,
}) => {
  const defaultUserRequestExecutor = createUserRequestExecutor({
    onFailure: error => {
      if (error.code === 'auth/internal-error') {
        return setError({ message: intl.formatMessage(messages.limit) });
      }
      setError(error);
    },
    shouldNotifyToastr: false,
  });

  const handleSubmit = async data => {
    const { email } = data.toJS();
    await defaultUserRequestExecutor(requestPasswordReset, email);
  };

  return (
    <Auth location={location}>
      <Helmet title={intl.formatMessage(messages.title)} />
      <ForgotPassword>
        <Title>{intl.formatMessage(messages.header)}</Title>
        {error && <Error>{error && error.message}</Error>}
        <ForgotPasswordForm onSubmit={handleSubmit} />
      </ForgotPassword>
    </Auth>
  );
};

const propTypes = {
  location: PropTypes.object,
  children: PropTypes.any,
};

export default compose(
  injectIntl,
  withUserRequestExecutor,
  withState('error', 'setError'),
  setPropTypes(propTypes)
)(ForgotPasswordPage);
