import React from 'react';
import styled from 'styled-components';

import TextFieldCounter from '../TextFieldCounter';

const TextArea = styled.textarea`
  ::-webkit-input-placeholder {
    text-align: center;
    line-height: 4em;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
    line-height: 4em;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
    line-height: 4em;
  }

  :-ms-input-placeholder {
    text-align: center;
    line-height: 4em;
  }
`;

export default ({ input, limit, ...props }) => (
  <div>
    {limit ? (
      <TextFieldCounter limit={limit} length={input.value.length} />
    ) : null}
    <TextArea id={input.name} {...input} {...props} />
  </div>
);
