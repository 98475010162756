/*
 * ChatbotSettingsForm Messages
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  venueLocation: {
    id: 'app.components.ChatbotSettingsForm.venueLocation',
    defaultMessage: 'Venue location',
  },
  venueLocationPlaceholder: {
    id: 'app.components.ChatbotSettingsForm.venueLocationPlaceholder',
    defaultMessage: 'Any link – e.g. a Google map link',
  },
  venueLocationInfo: {
    id: 'app.components.ChatbotSettingsForm.venueLocationInfo',
    defaultMessage:
      'Note: This link will be sent to attendees asking about how to get to the venue.',
  },
  wifiNote: {
    id: 'app.components.ChatbotSettingsForm.wifiNote',
    defaultMessage: 'WiFi Template',
  },
  wifiNotePlaceholder: {
    id: 'app.components.ChatbotSettingsForm.wifiNotePlaceholder',
    defaultMessage: 'e.g. The WiFi password is disrupt1234',
  },
  wifiNoteInfo: {
    id: 'app.components.ChatbotSettingsForm.wifiNoteInfo',
    defaultMessage:
      'Note: This message will be sent to attendees asking about WiFi info such as the network password. You control what the bot will answer.',
  },
  save: {
    id: 'app.components.ChatbotSettingsForm.save',
    defaultMessage: 'Save',
  },
});
