import { fromJS, Map } from 'immutable';
import { isString } from 'is-of-type';

import {
  ACCEPT_CONFERENCE,
  ACCEPT_USER_DATA,
  ACCEPT_USER,
  CLEAR_USER,
  LOG_IN_ERROR,
  LOG_OUT_ERROR,
  SIGN_UP_ERROR,
  RESET_PASSWORD_SUCCESS,
  ACCEPT_FB_PAGES,
} from '../constants/user';

const initialState = fromJS({
  error: {},
  success: {},
  fbPages: [],
  administrators: [],
});

function userReducer(state = initialState, action) {
  try {
    switch (action.type) {
      case ACCEPT_CONFERENCE:
        return state.setIn(
          ['userData', 'currentConference'],
          action.conference
            .update('speakers', Map(), speakers =>
              speakers.toOrderedMap().sortBy(speaker => speaker.get('order'))
            )
            .update('tracks', Map(), tracks =>
              tracks.toOrderedMap().sortBy(track => track.get('order'))
            )
            .update('features', features =>
              Map({
                schedule: true,
                speakers: true,
                cfp: true,
                sponsors: true,
                broadcasts: true,
                moderator: true,
              })
                .merge(features)
                .merge(state.getIn(['userData', 'features'], Map()))
            )
        );
      case ACCEPT_USER_DATA:
        return state.set(
          'userData',
          action.userData
            .update('conferences', Map(), conferences =>
              conferences.merge(
                state
                  .getIn(['userData', 'conferences'], Map())
                  .filter((value, key) => conferences.get(key) === true)
              )
            )
            .update('currentConference', currentConference => {
              /**
               * This is a case when something changes in users/:userId
               * we need to make sure not to overwrite currentConference
               * with a conference id, because currentConference is the place
               * where we store all the data once ACCEPT_CONFERENCE action is matched.
               */
              const stateCurrentConferences = state.getIn(
                ['userData', 'currentConference'],
                Map()
              );
              if (
                !isString(stateCurrentConferences) &&
                currentConference !== stateCurrentConferences.get('id')
              ) {
                return currentConference;
              }

              return stateCurrentConferences;
            })
        );
      case ACCEPT_USER:
        return setUserData(action, state);
      case CLEAR_USER:
        return state
          .set('loggedIn', false)
          .delete('user')
          .delete('userData');
      case LOG_IN_ERROR:
        return state
          .set('loggedIn', false)
          .setIn(['error', 'login'], action.error);
      case LOG_OUT_ERROR:
        return state
          .set('loggedIn', false)
          .delete('user')
          .deleteIn(['error', 'login']);
      case SIGN_UP_ERROR:
        return state
          .set('loggedIn', false)
          .setIn(['error', 'signup'], action.error);
      case RESET_PASSWORD_SUCCESS:
        return state.setIn(['success', 'resetPassword'], action.success);
      case ACCEPT_FB_PAGES:
        return state.set('fbPages', action.pages);
      default:
        return state;
    }
  } catch (e) {
    console.error(`Failed to process ${action.type} in userReducer`, e);
    return state;
  }
}

function setUserData(action, state) {
  return state
    .set('loggedIn', true)
    .set('user', action.user)
    .deleteIn(['success', 'resetPassword'])
    .deleteIn(['error', 'login'])
    .deleteIn(['error', 'signup']);
}

export default userReducer;
