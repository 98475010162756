import React from 'react';
import styled from 'styled-components';

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
`;

export const Tag = styled.div`
  opacity: 0.67;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem 0.8rem;
  background-color: ${props => props.theme.tag.backgroundColor}
  border-radius: 2px;
  color: ${props => props.theme.tag.color}
`;

const Tags = ({ tags }) => {
  return (
    <TagsWrapper>
      {tags.map((tag, i) => (
        <Tag key={i}>{tag}</Tag>
      ))}
    </TagsWrapper>
  );
};

export default Tags;
