import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ActionHeader from './components/ActionHeader';
import Queries from './components/Queries';
import Responses from './components/Responses';

const IntentSection = styled.div`
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid whitesmoke;
  background: white;
  ${props => props.theme.altitude(1)};
  padding: 1em 1.5em;
  border-radius: 0.25em;
  border: ${props => props.theme.borders.default};
  margin-bottom: 0.75em;
`;

const IntentList = injectIntl(({ intents, root }) => (
  <div>
    {intents.map(intent => {
      return (
        <IntentSection key={intent.id}>
          <ActionHeader
            title={intent.title}
            isResponseSet={intent.response && intent.response.type}
            hasQueries={intent.queries && intent.queries.length > 0}
            rootUrl={root}
            intentId={intent.id}
          />

          <Queries
            queries={intent.queries || []}
            rootUrl={root}
            intentId={intent.id}
          />

          <Responses
            response={intent.response || {}}
            rootUrl={root}
            intentId={intent.id}
          />
        </IntentSection>
      );
    })}
  </div>
));

IntentList.propTypes = {
  intents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      queries: PropTypes.array,
      response: PropTypes.shape({
        type: PropTypes.string.isRequired,
        message: PropTypes.string,
        items: PropTypes.array,
      }),
    })
  ),
  root: PropTypes.string,
};

export default IntentList;
