import firebase from 'firebase/app';

const createFetchResource = (
  path,
  { fallback, throwError = true } = {}
) => async () => {
  try {
    const resourceSnapshot = await firebase
      .database()
      .ref(path)
      .once('value');

    if (!resourceSnapshot.exists()) {
      return fallback;
    }

    return resourceSnapshot.val();
  } catch (error) {
    if (throwError) {
      throw error;
    }

    return fallback;
  }
};

export default createFetchResource;
