/*
 *  CreateSessionPage Messages
 *
 *  This contains all the text for the UsersPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'app.components.NewTeamMemberForm.email',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'app.components.NewTeamMemberForm.emailPlaceholder',
    defaultMessage: 'e.g., jhn@doe.com',
  },
  emailRequired: {
    id: 'app.components.NewTeamMemberForm.emailRequired',
    defaultMessage: 'Please enter an email of the user',
  },
  invite: {
    id: 'app.components.NewTeamMemberForm.invite',
    defaultMessage: 'Invite',
  },
  inviteError: {
    id: 'app.components.NewTeamMemberForm.inviteError',
    defaultMessage:
      'Whoops. Something went wrong. Please try again or contact our support.',
  },
  'permission-denied': {
    id: 'app.components.NewTeamMemberForm.permissionDenied',
    defaultMessage: 'You are not authorized to make this request.',
  },
  'already-exists': {
    id: 'app.components.NewTeamMemberForm.alreadyExists',
    defaultMessage: 'User already exists in the specified event.',
  },
  'not-found': {
    id: 'app.components.NewTeamMemberForm.notFound',
    defaultMessage: 'User with given email address does not exist.',
  },
});
