import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, setPropTypes } from 'recompose';
import { FormattedMessage, injectIntl } from 'react-intl';

import Icon from '../Icon';
import TooltipHover from '../TooltipHover';
import LoadingIndicator from '../LoadingIndicator';
import ThinLabel from '../ThinLabel';

import messages from './messages';

const Root = styled.div`
  display: flex;
  background: white;
  ${props => props.theme.altitude(1)};
  padding: 20px;
  flex-direction: row;
  border-radius: 0.25em;
  border: ${props => props.theme.borders.default};
  margin: 0 0.75em;
`;

const TotalSubscribersWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleWrapper = styled.div`
  opacity: 0.5;

  > p {
    font-size: 1em;
  }

  div > i {
    margin-left: 0.25em;
  }
`;

const ValueLabel = styled.div`
  font-size: 1.5em;
  font-weight: 600;
`;

const BroadcastSubscribers = ({ intl, value, isLoading, hasError }) => {
  return (
    <Root>
      <FormattedMessage {...messages.totalSubscribers}>
        {message => (
          <LoadingIndicator
            isLoading={isLoading}
            loadingMessage={intl.formatMessage(
              messages.totalSubscribersLoading
            )}
            hasError={hasError}
            errorMessage={intl.formatMessage(messages.totalSubscribersZero)}
          >
            <TotalSubscribersWrapper>
              <InfoBox>
                <TitleWrapper>
                  <ThinLabel>{message}</ThinLabel>

                  <TooltipHover
                    text={intl.formatMessage(messages.totalSubscribersInfo)}
                    position="right"
                  >
                    <Icon>info</Icon>
                  </TooltipHover>
                </TitleWrapper>
                <ValueLabel>{intl.formatNumber(value)}</ValueLabel>
              </InfoBox>
            </TotalSubscribersWrapper>
          </LoadingIndicator>
        )}
      </FormattedMessage>
    </Root>
  );
};

export default compose(
  injectIntl,
  setPropTypes({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    hasError: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
  })
)(BroadcastSubscribers);
