/*
 * SponsorsPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Flexbox from 'flexbox-react';
import { Map } from 'immutable';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectCurrentConference,
} from '../../selectors/user';

import messages from './messages';

import TooltipButton from '../../components/TooltipButton';
import EntityEmptyState from '../../components/EntityEmptyState';
import addSponsorsImage from '../../components/TooltipCards/assets/addsponsor.svg';
import sponsorInfoImage from '../../components/TooltipCards/assets/sponsor.png';
import emptyStateIllustration from '../../assets/images/empty-state-sponsors.svg';

import reorderSponsorGroups from '../../sagas/user/sponsorGroups/reorder';
import reorderSponsors from '../../sagas/user/sponsors/reorder';
import scaleSponsorGroup from '../../sagas/user/sponsorGroups/scale';
import scaleSponsor from '../../sagas/user/sponsors/scale';
import offsetSponsor from '../../sagas/user/sponsors/offset';

import Breadcrumb from '../../components/Breadcrumb';
import { StickyActionBar } from '../../components/ActionBar';
import MultipleButtons from '../../components/buttons/MultipleButtons';
import Button from '../../components/buttons/Button';

import ModalRoute from '../../components/ModalRoute';
import CreateSponsorGroupPage from '../CreateSponsorGroupPage';
import CreateSponsorPage from '../CreateSponsorPage';
import EmbedWidgetGuidePopup from '../EmbedWidgetGuidePopup';
import EditSponsorGroupPage from '../EditSponsorGroupPage';
import EditSponsorPage from '../EditSponsorPage';

import Group from './Group';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

class SponsorsPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.any.isRequired,
    conferenceName: PropTypes.any.isRequired,
    groups: ImmutablePropTypes.listOf(
      ImmutablePropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        sponsors: ImmutablePropTypes.listOf(
          ImmutablePropTypes.contains({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            image: PropTypes.string,
          })
        ),
      })
    ).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({});

  reorderUserRequestExecutor = this.props.createUserRequestExecutor({
    onEnd: () => this.setState({ dragging: false }),
  });

  static handleGroupDrag() {
    this.setState({
      dragging: true,
    });
  }

  static handleGroupMove(from, to) {
    const { groups } = this.state;

    this.setState({
      groups: groups.set(to, groups.get(from)).set(from, groups.get(to)),
    });
  }

  static handleGroupDrop() {
    this.reorderUserRequestExecutor(
      reorderSponsorGroups,
      this.props.conferenceHandle,
      this.state.groups.map(group => group.get('id')).toJS()
    );
  }

  static handleSponsorDrag() {
    this.setState({
      dragging: true,
    });
  }

  static handleSponsorMove(groupId) {
    return (from, to) => {
      const { groups } = this.state;

      this.setState({
        groups: groups.updateIn(
          [groups.findIndex(group => group.get('id') === groupId), 'sponsors'],
          sponsors =>
            sponsors.set(to, sponsors.get(from)).set(from, sponsors.get(to))
        ),
      });
    };
  }

  static handleSponsorDrop(groupId) {
    return () => {
      const { groups } = this.state;

      this.reorderUserRequestExecutor(
        reorderSponsors,
        this.props.conferenceHandle,
        groupId,
        this.state.groups
          .getIn([
            groups.findIndex(group => group.get('id') === groupId),
            'sponsors',
          ])
          .map(sponsor => sponsor.get('id'))
          .toJS()
      );
    };
  }

  static handleGroupScaleChange(scale, group) {
    this.defaultUserRequestExecutor(
      scaleSponsorGroup,
      this.props.conferenceHandle,
      group.get('id'),
      scale
    );
  }

  static handleSponsorScaleChange(scale, sponsor) {
    this.defaultUserRequestExecutor(
      scaleSponsor,
      this.props.conferenceHandle,
      sponsor.get('id'),
      scale
    );
  }

  static handleSponsorOffsetChange(offset, sponsor) {
    this.defaultUserRequestExecutor(
      offsetSponsor,
      this.props.conferenceHandle,
      sponsor.get('id'),
      offset
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      groups: props.groups,
    };

    this.handleGroupDrag = SponsorsPage.handleGroupDrag.bind(this);
    this.handleGroupMove = SponsorsPage.handleGroupMove.bind(this);
    this.handleGroupDrop = SponsorsPage.handleGroupDrop.bind(this);

    this.handleSponsorDrag = SponsorsPage.handleSponsorDrag.bind(this);
    this.handleSponsorMove = SponsorsPage.handleSponsorMove.bind(this);
    this.handleSponsorDrop = SponsorsPage.handleSponsorDrop.bind(this);

    this.handleGroupScaleChange = SponsorsPage.handleGroupScaleChange.bind(
      this
    );
    this.handleSponsorScaleChange = SponsorsPage.handleSponsorScaleChange.bind(
      this
    );
    this.handleSponsorOffsetChange = SponsorsPage.handleSponsorOffsetChange.bind(
      this
    );
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.dragging) {
      this.setState({
        groups: nextProps.groups,
      });
    }
  }

  render() {
    const { conferenceHandle, conferenceName, match, intl } = this.props;
    const { groups } = this.state;

    const optimalSize = 240;
    /* eslint-disable react/jsx-closing-bracket-location */
    /* eslint-disable react/jsx-indent-props */
    return (
      <div>
        <TooltipButton
          title={intl.formatMessage(messages.tooltipTitle)}
          body={intl.formatMessage(messages.tooltipSectionOne)}
          firstImage={addSponsorsImage}
          body2={intl.formatMessage(messages.tooltipSectionTwo)}
          secondImage={sponsorInfoImage}
        />
        <Helmet title={`${conferenceName} Sponsors • Sava Events`} />
        <Breadcrumb to={`/@${conferenceHandle}/sponsors`}>Sponsors</Breadcrumb>
        <Flexbox flexDirection="column">
          {groups.count() > 0 ? (
            <StickyActionBar flexDirection="row" justifyContent="space-between">
              {groups.count() > 0 && (
                <Button
                  to={`/@${conferenceHandle}/sponsors/groups/new`}
                  icon="add"
                  label={intl.formatMessage(messages.sponsorGroupCreate)}
                  type="secondary"
                />
              )}
              <MultipleButtons>
                {groups.count() > 0 ? (
                  <Button
                    to={`/@${conferenceHandle}/sponsors/new`}
                    icon="add"
                    label={intl.formatMessage(messages.sponsorCreate)}
                    type="secondary"
                  />
                ) : (
                  <Button
                    to={`/@${conferenceHandle}/sponsors/groups/new`}
                    icon="add"
                    label={intl.formatMessage(messages.sponsorGroupCreate)}
                    type="secondary"
                  />
                )}
                <Button
                  to={`/@${conferenceHandle}/sponsors/embed`}
                  icon="code"
                  label={intl.formatMessage(messages.sponsorCode)}
                  type="primary"
                />
              </MultipleButtons>
            </StickyActionBar>
          ) : (
            <StickyActionBar flexDirection="row" justifyContent="flex-end">
              {groups.count() > 0 && (
                <Button
                  to={`/@${conferenceHandle}/sponsors/groups/new`}
                  icon="add"
                  label={intl.formatMessage(messages.sponsorGroupCreate)}
                  type="primary"
                />
              )}
              <MultipleButtons>
                {groups.count() > 0 ? (
                  <Button
                    to={`/@${conferenceHandle}/sponsors/new`}
                    icon="add"
                    label={intl.formatMessage(messages.sponsorCreate)}
                    type="primary"
                  />
                ) : (
                  <Button
                    to={`/@${conferenceHandle}/sponsors/groups/new`}
                    icon="add"
                    label={intl.formatMessage(messages.sponsorGroupCreate)}
                    type="primary"
                  />
                )}
                <Button
                  to={`/@${conferenceHandle}/sponsors/embed`}
                  icon="code"
                  label={intl.formatMessage(messages.sponsorCode)}
                  type="primary"
                />
              </MultipleButtons>
            </StickyActionBar>
          )}
          {groups.count() === 0 && (
            <EntityEmptyState
              illustration={emptyStateIllustration}
              title={intl.formatMessage(messages.sponsorsEmptyState)}
              hint={intl.formatMessage(messages.sponsorsEmptyStateHint)}
            >
              <Button
                to={`/@${conferenceHandle}/sponsors/groups/new`}
                icon="add"
                label={intl.formatMessage(messages.sponsorGroupCreate)}
                type="primary"
              />
            </EntityEmptyState>
          )}

          {groups.map((group, i) => (
            <Group
              key={group.get('id')}
              index={i}
              onScaleChange={this.handleGroupScaleChange}
              onDrag={this.handleGroupDrag}
              onMove={this.handleGroupMove}
              onDrop={this.handleGroupDrop}
              onSponsorDrag={this.handleSponsorDrag}
              onSponsorMove={this.handleSponsorMove(group.get('id'))}
              onSponsorDrop={this.handleSponsorDrop(group.get('id'))}
              onSponsorScaleChange={this.handleSponsorScaleChange}
              onSponsorOffsetChange={this.handleSponsorOffsetChange}
              optimalSize={optimalSize}
              conferenceHandle={conferenceHandle}
              group={group}
            />
          ))}
        </Flexbox>

        <ModalRoute
          exact
          path={`${match.path}/groups/new`}
          component={CreateSponsorGroupPage}
        />
        <ModalRoute
          exact
          path={`${match.path}/new`}
          component={CreateSponsorPage}
        />
        <ModalRoute
          exact
          path={`${match.path}/embed`}
          params={{ context: 'sponsors' }}
          component={EmbedWidgetGuidePopup}
        />
        <ModalRoute
          exact
          path={`${match.path}/groups/:id`}
          except={[`${match.path}/groups/new`]}
          component={EditSponsorGroupPage}
        />

        <ModalRoute
          exact
          path={`${match.path}/:id`}
          except={[`${match.path}/new`, `${match.path}/embed`]}
          component={EditSponsorPage}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectCurrentConference,
  (conferenceHandle, conferenceName, conference) => ({
    conferenceHandle,
    conferenceName,
    groups: conference
      .get('sponsorGroups', Map())
      .entrySeq()
      .map(([k, v]) => v.set('id', k))
      .map(group =>
        group.set(
          'sponsors',
          conference
            .get('sponsors', Map())
            .entrySeq()
            .map(([k, v]) => v.set('id', k))
            .filter(sponsor => sponsor.get('group') === group.get('id'))
            .sortBy(sponsor => sponsor.get('order'))
            .toList()
        )
      )
      .sortBy(group => group.get('order'))
      .toList(),
  })
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor
)(injectIntl(SponsorsPage));
