import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { card } from '../../utils/ui/interactive';

export default styled(Link)`
  position: relative;
  display: block;

  color: #333;

  padding: 20px;
  margin: 10px;

  ${card({ backgroundColor: 'white' })};
`;
