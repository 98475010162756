/*
 * CreateChatIntentPage Container
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

import {
  selectCurrentConferenceHandle,
  selectConferenceName,
} from '../../selectors/user';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import { createChatbotIntent } from '../../sagas/user/conferences/chatbotIntents';
import ChatIntentForm from '../../components/ChatIntentForm';

export class CreateChatIntentPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  static async handleSubmit(data) {
    await this.defaultUserRequestExecutor(createChatbotIntent, {
      conferenceHandle: this.props.conferenceHandle,
      intent: data.toJS(),
    });
  }

  constructor(props) {
    super(props);

    this.handleSubmit = CreateChatIntentPage.handleSubmit.bind(this);
  }

  render() {
    const { conferenceName } = this.props;

    const initialValues = Map({ response: Map({ type: 'text' }) });

    return (
      <div>
        <Helmet title={`${conferenceName} | New Chat Intent`} />
        <ChatIntentForm
          isCreateForm
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  (conferenceHandle, conferenceName) => ({
    conferenceHandle,
    conferenceName,
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  withUserRequestExecutor
)(CreateChatIntentPage);
