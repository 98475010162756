import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, lifecycle, withState } from 'recompose';

import BroadcastMetrics from './BroadcastMetrics';
import BroadcastScheduledTime from './BroadcastScheduledTime';
import { componentWillMount } from './shared';
import MessengerConversation from '../MessengerPreviewMocks/MessengerConversation';

const BroadcastItem = styled.div``;

const BroadcastText = ({
  message,
  sentAt,
  delivered,
  messengerBroadcastId,
  messengerBroadcastFailed,
  reached,
  scheduled,
  scheduleTime,
  timezone,
}) => (
  <BroadcastItem>
    {!scheduled && (
      <BroadcastMetrics
        sentAt={sentAt}
        messengerBroadcastId={messengerBroadcastId}
        messengerBroadcastFailed={messengerBroadcastFailed}
        reached={reached}
      />
    )}
    {scheduled && (
      <BroadcastScheduledTime timestamp={scheduleTime} timezone={timezone} />
    )}
    <MessengerConversation
      conversation={[
        { type: 'platform', messages: [{ type: 'text', content: message }] },
      ]}
    />
  </BroadcastItem>
);

BroadcastText.propTypes = {
  message: PropTypes.string,
  sentAt: PropTypes.string.isRequired,
  reach: PropTypes.number,
  delivered: PropTypes.bool,
};

export default compose(
  withState('reached', 'setReached', 0),
  lifecycle({ componentWillMount })
)(BroadcastText);
