import React from 'react';
import styled from 'styled-components';

import Broadcast from './Broadcast';

const BroadcastMessagesWrapper = styled.div``;

const Broadcasts = ({
  broadcasts,
  timezone,
  conferenceHandle,
  sagaMiddleware,
  scheduled,
  onScheduledBroadcastRemove,
}) => (
  <BroadcastMessagesWrapper>
    {broadcasts.map((item, key) => (
      <Broadcast
        key={key}
        item={item}
        timezone={timezone}
        delivered={item.sent}
        reach={item.reach}
        scheduled={scheduled}
        conferenceHandle={conferenceHandle}
        sagaMiddleware={sagaMiddleware}
        onScheduledBroadcastRemove={
          onScheduledBroadcastRemove && onScheduledBroadcastRemove(item.id)
        }
      />
    ))}
  </BroadcastMessagesWrapper>
);

export default Broadcasts;
