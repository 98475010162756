/**
 *
 * SpeakerForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

import CollectionInput from '../CollectionInput';
import ProfileInput from '../ProfileInput';
import ImageInput from '../ImageInput';
import Form from '../Form';
import DeleteButton from '../DeleteButton';
import RichTextInput from '../RichTextInput';
import TagsInput from '../TagsInput';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';

const Button = styled.button`
  padding: 12px;

  ${button()};
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProfilesInput = CollectionInput(ProfileInput);

const SpeakerForm = props => {
  const {
    isCreateForm,
    onDelete,
    handleSubmit,
    pristine,
    submitting,
    intl,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      {!isCreateForm && (
        <Field name="id" component="input" type="hidden" required />
      )}

      <fieldset>
        <FormattedMessage {...messages.name} />
        <Field
          name="name"
          component="input"
          type="text"
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          required
        />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.headline} />
        <Field
          name="headline"
          component="input"
          type="text"
          placeholder={intl.formatMessage(messages.headlinePlaceholder)}
          required
        />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.description} />
        <Field name="description" component={RichTextInput} required />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.tags} />
        <Field name="tags" component={TagsInput} required />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.profiles} />
        <FieldArray name="profiles" component={ProfilesInput} />
      </fieldset>

      <fieldset>
        <FormattedMessage {...messages.image} />
        <Field name="image" component={ImageInput} />
      </fieldset>

      <ActionBar>
        <Button disabled={pristine || submitting}>
          <FormattedMessage
            {...(isCreateForm ? messages.create : messages.update)}
          />
        </Button>

        {!isCreateForm && (
          <DeleteButton type="reset" onClick={onDelete}>
            Delete
          </DeleteButton>
        )}
      </ActionBar>
    </Form>
  );
};

SpeakerForm.propTypes = {
  isCreateForm: PropTypes.bool,
  onDelete: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default reduxForm({
  form: 'speaker',
  initialValues: fromJS({
    tags: [],
    image: null,
    profiles: [],
  }),
})(injectIntl(SpeakerForm));
