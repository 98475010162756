/**
 *
 * ImageInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import FileInput from '../FileInput';
import styled from 'styled-components';
import { isNonEmptyString } from 'is-of-type';
import { withProps } from 'recompose';
import isNonEmptyFileList from '../../utils/isNonEmptyFileList';

const Img = styled.img`
  margin: 6px 0;
  width: 100%;
`;

const ImageInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75em 1em;
  border: 4px dashed ${props => props.theme.buttons.secondary.backgroundColor};
  width: 50%;
`;

const previewFromString = value => (isNonEmptyString(value) ? value : null);

const previewFromFileList = value => {
  return isNonEmptyFileList(value)
    ? window.URL.createObjectURL(value[0])
    : previewFromString(value);
};

const ImageInput = ({
  required,
  preview,
  accept = 'image/png,image/gif,image/svg+xml,image/jpeg',
  alt = 'Selected Image Preview',
  ...rest
}) => (
  <ImageInputWrapper>
    {!!preview && <Img src={previewFromFileList(preview)} alt={alt} />}
    <FileInput
      className="uploadCaptureInputFile"
      accept={accept || ''}
      required={required && !preview}
      {...rest}
    />
  </ImageInputWrapper>
);

ImageInput.propTypes = {
  multiple: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.any,
  style: PropTypes.any,
  required: PropTypes.bool,
  preview: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default withProps(({ input }) => ({
  preview: input ? previewFromFileList(input.value) : null,
}))(ImageInput);
