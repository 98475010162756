/* eslint react/jsx-indent-props: 0 */
/* eslint react/jsx-closing-bracket-location: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { List, Map, fromJS } from 'immutable';

import { button } from '../../utils/ui/interactive';
import Form from '../../components/Form';
import DeleteButton from '../DeleteButton';
import messages from './messages';
import MessageInput from '../MessageInput';
import validate from '../MessageInput/validate';
import TagsInput from '../TagsInput';
import ThinLabel from '../ThinLabel';

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Title = styled.h3`
  margin-top: 0;
`;

const Button = styled.button`
  padding: 12px;

  ${button()};
`;

const formId = 'intent';
const namePrefix = 'response';

const ChatIntentForm = ({
  onDelete,
  isCreateForm,
  handleSubmit,
  pristine,
  submitting,
  responseType,
  value,
  invalid,
  intl,
}) => (
  <Form onSubmit={handleSubmit}>
    <Title>Intent</Title>
    <fieldset>
      <label htmlFor="title">
        <FormattedMessage {...messages.intentName} />
      </label>
      <FormattedMessage {...messages.intentNamePlaceholder}>
        {message => (
          <Field
            name="title"
            component="input"
            type="text"
            placeholder={message}
            required
            autoFocus
          />
        )}
      </FormattedMessage>
    </fieldset>

    <fieldset>
      <label htmlFor="queries">
        <FormattedMessage {...messages.queries} />
      </label>
      <ThinLabel>{intl.formatMessage(messages.queryDescription)}</ThinLabel>
      <Field
        name="queries"
        component={TagsInput}
        props={{
          transformProvider: value =>
            (value || List()).map(item => ({
              id: item.get('query'),
              text: item.get('query'),
            })),
          additionTransformProvider: (value, tag) =>
            (value || List()).push(Map({ query: tag })),
          placeholder: intl.formatMessage(messages.queryInputPlaceholder),
          isHintVisible: true,
          hintMessage: intl.formatMessage(messages.queryHintMessage),
        }}
      />
    </fieldset>

    <Field
      name="message"
      component={MessageInput}
      props={{
        type: responseType,
        value,
        namePrefix,
      }}
    />

    <ActionBar>
      <Button
        type={'submit'}
        disabled={pristine || submitting || invalid}
        marginTop
      >
        <FormattedMessage
          {...messages[isCreateForm ? 'createIntent' : 'updateIntent']}
        >
          {message => message}
        </FormattedMessage>
      </Button>

      {!isCreateForm && (
        <DeleteButton type="reset" onClick={onDelete}>
          <FormattedMessage {...messages.deleteIntent}>
            {message => message}
          </FormattedMessage>
        </DeleteButton>
      )}
    </ActionBar>
  </Form>
);

ChatIntentForm.propTypes = {
  onDelete: PropTypes.func,
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  responseType: PropTypes.string,
};

const formValidation = (values, props) => {
  const form = values.toJS();
  const errors = validate(form, {
    ...props,
    formKey: namePrefix,
  });

  if (form && !form.title) {
    errors.title = 'Intent name must contain at least one character.';
  }

  return errors;
};

const formProps = formId => ({
  form: formId,
  validate: formValidation,
});

const selector = formValueSelector(formId);

const mapStateToProps = state => ({
  responseType: selector(state, 'response.type'),
  value: selector(state, 'response') || fromJS({}),
});

export default compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm(formProps(formId))
)(ChatIntentForm);
