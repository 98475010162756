/**
 *
 *  Session
 *
 */

import styled from 'styled-components';

const SessionTitle = styled.div`
  margin: 0;
  font-size: 18px;
  margin-top: 0.5rem;
  margin-bottom: 16px;
  line-height: 1.33;
  font-weight: 700;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export default SessionTitle;
