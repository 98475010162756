import ReactReduxToastr from 'react-redux-toastr/lib';
import { injectGlobal } from 'styled-components';
import React from 'react';

import styles from './styles';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  ${styles}
 `;

const Toastr = () => (
  <ReactReduxToastr
    timeOut={4000}
    position="top-right"
    transitionIn="fadeIn"
    transitionOut="fadeOut"
    showCloseButton={true}
  />
);

export default Toastr;
