/*
 *  CreateSessionPage Messages
 *
 *  This contains all the text for the UsersPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmMemberDeletion: {
    id: 'app.containers.ConferenceSettingsTeamPage.confirmMemberDeletion',
    defaultMessage: 'Deleting a team member is permanent. Proceed?',
  },
});
