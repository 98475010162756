/*
 * ResetPasswordForm Messages
 *
 * This contains all the text for the ResetPasswordForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'app.components.ResetPasswordForm.description',
    defaultMessage: 'Fill the form below to reset your password.',
  },
  password: {
    id: 'app.components.ResetPasswordForm.password',
    defaultMessage: 'New Password',
  },
  passwordPlaceholder: {
    id: 'app.components.ResetPasswordForm.passwordPlaceholder',
    defaultMessage: 'e.g., ••••••',
  },
  resetPassword: {
    id: 'app.components.ResetPasswordForm.resetPassword',
    defaultMessage: 'Reset Password',
  },
});
