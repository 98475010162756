import React, { Component } from 'react';
import Flexbox from 'flexbox-react';
import { Field } from 'redux-form/immutable';
import styled from 'styled-components';
import SelectInputControl from '../../../components/SelectInputControl';
import Icon from '../../../components/Icon';

const SelectWrap = styled(Flexbox)`
  top: 12px;
  .Select-value {
    top: 4px !important;
  }
`;

const options = [
  {
    value: 'smallText',
    label: (
      <span>
        <Icon>short_text</Icon> Short Answer
      </span>
    ),
  },
  {
    value: 'largeText',
    label: (
      <span>
        <Icon>notes</Icon> Paragraph(s)
      </span>
    ),
  },
];

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 640px;
  margin-top: 12px;
  > div {
    margin-right: 12px;
  }
`;

const RequiredCheckbox = styled.div`
  flex: 1;
  flex-direction: row;
  margin-top: 32px;
  > input,
  label {
    display: inline-flex !important;
    font-size: 18px;
  }
  > input {
    width: 20px !important;
    height: 20px !important;
    margin-right: 12px;
  }
`;

const Select = SelectInputControl(() => options, {
  labelKey: 'label',
  valueKey: 'value',
});

class BlockInput extends Component {
  render() {
    const { name } = this.props;
    return (
      <MainWrapper>
        <Flexbox flexDirection="column" flex="1">
          <span>Label</span>
          <Field
            name={`${name ? `${name}.` : ''}label`}
            component="input"
            type="text"
            placeholder="Label for the field"
            required
          />
        </Flexbox>

        <SelectWrap flexDirection="column" flex="1">
          <span>Type</span>
          <Field
            name={`${name ? `${name}.` : ''}type`}
            component={Select}
            required
          />
        </SelectWrap>

        <RequiredCheckbox>
          <Field
            name={`${name ? `${name}.` : ''}required`}
            component="input"
            type="checkbox"
          />
          <label htmlFor={`${name ? `${name}.` : ''}required`}>Required</label>
        </RequiredCheckbox>
      </MainWrapper>
    );
  }
}

export default BlockInput;
