import React, { Component } from 'react';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import { button } from '../../utils/ui/interactive';
import interactive from '../../utils/ui/interactive';
import MessengerIcon from '../MessengerIcon';
import TooltipHover from '../TooltipHover';
import MessengerConversation from '../MessengerPreviewMocks/MessengerConversation';
import MessengerPagePreview from '../MessengerPreviewMocks/MessengerPagePreview';

const Header = styled.div`
  font-size: 18px;
  position: relative;
`;

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 0.25em;
`;

const Description = styled.div`
  margin-bottom: 1.5em;
  opacity: 0.75;
  line-height: 1.4;
`;

const Edit = styled.div`
 padding: 10px 24px 10px 24px;
 margin: 5px;
  ${interactive.styles`
    color: #333;
  `}${button()};
`;

const Cancel = styled.div`
  margin: 5px;
  padding: 10px 24px 10px 24px;
  ${interactive.styles`
    color: #333;
  `}${button()};
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 10px 24px 10px 24px;
  ${interactive.styles`
    color: #333;
  `}${button()};
`;

const DropdownItem = styled.div`
                              margin-left: 5px;
                              margin-right: 5px;
                              padding: 10px 24px 10px 24px;
                              ${interactive.styles`
                                color: #333;
                              `}${button()};
                              position: relative;
                              z-index: 2;
                            `;

const DropdownItemsWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

const Save = styled.div`
  margin: 5px;
  padding: 10px 24px 10px 24px;
  color: white;
  ${interactive.styles`
    color: #333;
  `}${props => button({ backgroundColor: props.theme.colors.accent })};
`;

const IconWrap = styled.div`
  position: absolute;
  right: 5px;
  top: 0px;
`;

const PreviewLink = styled.span`
  font-size: 12px;
  color: ${props => props.theme.colors.brandPrimary};
  padding: 0 12px;
`;

const ButtonWrap = styled(Flexbox)``;

class MessengerCustomMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      message: props.initialValue,
      showDropdownItems: false,
    };
  }

  handleEdit = () => {
    this.setState({ edit: true });
    if (!this.state.message) {
      this.setState({ message: this.props.initialValue });
    }
  };

  handleCancel = () => {
    this.setState({ edit: false, message: this.props.initialValue });
  };

  handleSave = () => {
    this.setState({ edit: false });
    this.props.onSubmit(this.state.message);
  };

  getMessengerChatPreview = () => (
    <MessengerConversation
      width={300}
      conversation={[
        {
          type: 'user',
          messages: [
            {
              type: 'text',
              content: this.props.previewConfig.userMessage,
            },
          ],
        },
        {
          type: 'platform',
          messages: [
            {
              type: 'text',
              content: this.state.message || this.props.initialValue,
            },
          ],
        },
      ]}
    />
  );

  getMessengerPagePreview = () => (
    <MessengerPagePreview
      width={300}
      pageName={this.props.previewConfig.pageName}
      message={this.state.message || this.props.initialValue}
    />
  );

  getPreview = type => {
    if (type === 'conversation') {
      return this.getMessengerChatPreview();
    } else if (type === 'page') {
      return this.getMessengerPagePreview();
    }
  };

  render() {
    const { message } = this.state;
    const {
      title,
      description,
      initialValue,
      disabled,
      previewConfig,
      messages,
    } = this.props;

    return (
      <div>
        <Header>
          <Title>{title}</Title>
          <IconWrap>
            {previewConfig && (
              <TooltipHover html={this.getPreview(previewConfig.type)}>
                <PreviewLink>Preview</PreviewLink>
              </TooltipHover>
            )}
            <MessengerIcon />
          </IconWrap>
        </Header>

        <Description>{description}</Description>

        <textarea
          placeholder={initialValue}
          style={{
            background: this.state.edit ? '#f0f0f0' : 'white',
            width: '100%',
          }}
          value={message}
          onChange={event => this.setState({ message: event.target.value })}
          onClick={this.handleEdit}
          disabled={disabled}
        />
        {!this.state.edit && (
          <ButtonWrap>
            <Edit onClick={this.handleEdit} disabled={disabled}>
              Edit
            </Edit>
          </ButtonWrap>
        )}

        {this.state.edit && (
          <ButtonWrap>
            <Save onClick={this.handleSave} disabled={disabled}>
              Save
            </Save>
            <Cancel onClick={this.handleCancel} disabled={disabled}>
              Cancel
            </Cancel>

            {messages && (
              <Dropdown>
                <DropdownButton
                  onClick={() =>
                    this.setState({
                      showDropdownItems: !this.state.showDropdownItems,
                    })
                  }
                >
                  Insert Personal Information
                </DropdownButton>
                {this.state.showDropdownItems && (
                  <DropdownItemsWrapper>
                    {messages.map((m, i) => (
                      <DropdownItem
                        key={i}
                        onClick={() =>
                          this.setState({
                            showDropdownItems: !this.state.showDropdownItems,
                            message: message + m,
                          })
                        }
                      >
                        {m}
                      </DropdownItem>
                    ))}
                  </DropdownItemsWrapper>
                )}
              </Dropdown>
            )}
          </ButtonWrap>
        )}
      </div>
    );
  }
}

export default MessengerCustomMessage;
