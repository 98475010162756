/**
 * @param {{ scheduleTime: number }} broadcast
 * @return {boolean}
 */
export const isBroadcastScheduledAndGreaterThanNow = broadcast => {
  const { scheduleTime } = broadcast;
  const nowInUnixTimestamp = Math.floor(new Date().getTime() / 1000);
  return scheduleTime && scheduleTime / 1000 > nowInUnixTimestamp;
};

/**
 * @param {{ scheduleTime: number }} broadcast
 * @return {boolean}
 */
export const isBroadcastRegularOrScheduledAndLowerThanNow = broadcast => {
  const { scheduleTime } = broadcast;
  const nowInUnixTimestamp = Math.floor(new Date().getTime() / 1000);
  return (
    !scheduleTime || (scheduleTime && scheduleTime / 1000 <= nowInUnixTimestamp)
  );
};

/**
 * @param {{ scheduleTime: number }} a
 * @param {{ scheduleTime: number }} b
 * @return {number}
 */
export const fromEarliestToLatest = (a, b) => a.scheduleTime - b.scheduleTime;

/**
 * @param {{ timestamp: number }} a
 * @param {{ timestamp: number }} b
 * @return {number}
 */
export const fromNewestToOldest = (a, b) => b.timestamp - a.timestamp;

/**
 * @param {number} limit
 * @return {function(number, number): boolean}
 */
export const bySize = limit => (_, index) => index < limit;

export const flattenScheduledAndRegularBroadcasts = broadcast =>
  broadcast.scheduleTime
    ? { ...broadcast, timestamp: broadcast.scheduleTime }
    : broadcast;
