/*
 *
 * Embed actions
 *
 */

import {
  ACCEPT_CONFERENCE,
  ADD_TAG_FILTER,
  ADD_SPEAKER_FILTER,
  SUBMIT_QUESTION,
  SET_QUESTION_ARCHIVED
} from '../constants/embed';

export function acceptConference(conference) {
  return {
    type: ACCEPT_CONFERENCE,
    conference,
  };
}

export function addTagFilter(tagFilter) {
  return {
    type: ADD_TAG_FILTER,
    tagFilter,
  };
}

export function addSpeakerFilter(speakerFilter) {
  return {
    type: ADD_SPEAKER_FILTER,
    speakerFilter,
  };
}

export function submitQuestion(
  conferenceHandle,
  sessionId,
  userId,
  client,
  question,
  form
) {
  return {
    type: SUBMIT_QUESTION,
    conferenceHandle,
    sessionId,
    question,
    userId,
    client,
    form,
  };
}

export function setQuestionArchived(
  conferenceHandle,
  sessionId,
  questionId,
  archived
) {
  return {
    type: SET_QUESTION_ARCHIVED,
    conferenceHandle,
    sessionId,
    questionId,
    archived
  }
}

export default {
  acceptConference,
  addTagFilter,
  addSpeakerFilter,
  submitQuestion,
  setQuestionArchived
};
