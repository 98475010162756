import React, { Component } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'react-router-redux';

import { history, sagaMiddleware } from './store';

// Store
import store from './store';

// Saga Provider
import SagaMiddlewareProvider from './providers/SagaMiddlewareProvider';

// Language Provider
import LanguageProvider from './providers/LanguageProvider';

// Vendor CSS
import 'react-tippy/dist/tippy.css';

// Theme
import theme from './theme';
import './styles';

import Website from './Website';

// Firebase
import './firebase';

class Main extends Component {
  render() {
    return (
      <StoreProvider store={store}>
        <SagaMiddlewareProvider sagaMiddleware={sagaMiddleware}>
          <LanguageProvider>
            <ThemeProvider theme={theme}>
              <ConnectedRouter history={history}>
                <Website />
              </ConnectedRouter>
            </ThemeProvider>
          </LanguageProvider>
        </SagaMiddlewareProvider>
      </StoreProvider>
    );
  }
}

export default Main;
