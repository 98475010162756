import React from 'react';
import styled from 'styled-components';
import { MessengerPagePreviewPropTypes } from './propTypes';

const Page = styled.div`
  ${props => (props.width ? `width: ${props.width};` : '')} padding: 12px;
`;

const Header = styled.div`
  display: flex;
`;

const PageIcon = styled.div`
  width: 64px;
  height: 64px;
  background-color: #3c7efd;
  border-radius: 999px;
  margin: 0px 12px;
`;

const PageInfo = styled.div`
  flex: 1;
  text-align: left;
`;

const PageTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin: 0;
`;

const PageText = styled.p`
  opacity: 0.75;
  margin: 0;
`;

const Button = styled.div`
  border: 1px solid #3c7efd;
  border-radius: 4px;
  background-color: #3c7efd;
  color: #fff;
  flex: 1;
  text-align: center;
  padding: 12px;
`;

const Message = styled.p`
  font-size: 18px;
  text-align: left;
`;

const Line = styled.hr`
  opacity: 0.5;
`;

const replaceDynamicName = message =>
  message
    .replace('{{user_first_name}}', 'Jon')
    .replace('{{user_last_name}}', 'Doe')
    .replace('{{user_full_name}}', 'Jon Doe');

const MessengerPagePreview = ({ width, message, pageName }) => (
  <Page width={width}>
    <Header>
      <PageIcon />
      <PageInfo>
        <PageTitle>@{pageName}</PageTitle>
        <PageText>
          <span role="img" aria-label="Thunder icon">
            ⚡️
          </span>Automated messaging App Page.
        </PageText>
        <PageText>
          4012 people like this including Jane Doe, and 24 friends.
        </PageText>
      </PageInfo>
    </Header>
    <Line />
    <Message>{replaceDynamicName(message)}</Message>
    <Line />
    <Button>Get Started</Button>
  </Page>
);

MessengerPagePreview.propTypes = MessengerPagePreviewPropTypes;

export default MessengerPagePreview;
