import { defineMessages } from 'react-intl';

export default defineMessages({
  breadcrumb: {
    id: 'app.containers.ChatQuickRepliesPage.breadcrumb',
    defaultMessage: 'Chat Qucik Replies',
  },
  addNew: {
    id: 'app.containers.ChatQuickRepliesPage.addNew',
    defaultMessage: 'Add New Quick Reply',
  },
  helmet: {
    id: 'app.containers.ChatQuickRepliesPage.helmet',
    defaultMessage: 'Chat Quick Replies • Sava Events',
  },
  saveDefaultQuickRepliesError: {
    id: 'app.containers.ChatQuickRepliesPage.saveDefaultQuickRepliesError',
    defaultMessage: 'Failed to save default quick replies.',
  },
  noItemsTitle: {
    id: 'app.containers.ChatQuickRepliesPage.noItemsTitle',
    defaultMessage: 'No Quick Replies Added',
  },
  noItemsHint: {
    id: 'app.containers.ChatQuickRepliesPage.noItemsHint',
    defaultMessage:
      'Create a quick reply and ask the chatbot anything to see the results. You can later customize how they’re displayed on the chatbot.',
  },
  resetToDefaultQuickReplies: {
    id: 'app.containers.ChatQuickRepliesPage.resetToDefaultQuickReplies',
    defaultMessage: 'Reset to defaults',
  },
  confirmResetToDefaults: {
    id: 'app.containers.ChatQuickRepliesPage.confirmResetToDefaults',
    defaultMessage: 'Resetting quick replies to default is permanent. Proceed?',
  },
  sortQuickRepliesError: {
    id: 'app.containers.ChatQuickRepliesPage.sortQuickRepliesError',
    defaultMessage:
      'Failed to sort quick replies. If error persists, contact our 🌶️ support.',
  },
});
