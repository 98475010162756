import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0 0 50%;
`;

const Name = styled.p`
  font-weight: 800;
  margin: 0;
`;

const MoreInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  div > p {
    font-size: 0.875em;
  }
`;

/*
  Name
  Gender
  Id?
  Timezone
  Language
*/

export default ({ profile }) => {
  return (
    <UserInfo>
      <Name>{profile.getFullName()}</Name>
      <MoreInfo>
        <div>
          {profile.getGender() && (
            <p>
              <Icon>person</Icon> {profile.getGender()}
            </p>
          )}
          <p>
            <Icon>assignment_ind</Icon> {profile.getId()}
          </p>
          {profile.getUsername() && (
            <p>
              <Icon>assignment_ind</Icon> {profile.getUsername()}
            </p>
          )}
        </div>
        <div>
          <p>
            <Icon>access_time</Icon> UTC{' '}
            {profile.getTimezone() >= 0 ? '+' : '-'}
            {profile.getTimezone() || 'Unknown'}
          </p>
          <p>
            <Icon>language</Icon>
            {''}
            {profile.getLocaleLabel() || profile.getLocale() || 'Unknown'}
          </p>
        </div>
      </MoreInfo>
    </UserInfo>
  );
};
