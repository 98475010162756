import Flexbox from 'flexbox-react';
import styled from 'styled-components';

const ActionBar = styled(Flexbox)`
  padding: 1em;
  background-color: white;

  > * + * {
    margin-left: 1em;
  }
`;

export const StickyActionBar = styled(ActionBar)`
  position: sticky;
  top: 4em;
  z-index: 1;
`;

export default ActionBar;
