/**
 *
 * HandleInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import slug from 'slugify';
import { injectIntl } from 'react-intl';

import messages from './messages';
import Icon from '../Icon';

const prefix = `${process.env.REACT_APP_PUBLIC_URL}/@`;

const Hint = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0;

  font-size: 0.75rem;

  > span::after {
    content: ' ';
  }
`;

const InfoBox = styled.div`
  background-color: ${p => p.theme.colors.brandPrimary};
  padding: 1em;
  margin-top: 0.5em;
  margin-bottom: 1em;
  border-radius: 4px;
  max-width: 520px;
  p {
    color: #fff;
    margin: 0;
  }
  i {
    font-size: 1.25em;
    margin-right: 4px;
    vertical-align: text-top;
  }
`;

const HandleInput = ({
  input: { value, onChange, onBlur, onFocus },
  intl,
  placeholder,
  isCreateForm,
}) => (
  <div>
    <input
      type="text"
      value={value}
      onChange={e => onChange(slug(e.target.value))}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={!isCreateForm}
      placeholder={placeholder}
    />
    <Hint>
      <span>
        {intl.formatMessage(
          isCreateForm ? messages.address : messages.addressCurrent
        )}
      </span>
      <strong>
        {prefix}
        {value}
      </strong>
    </Hint>
    {isCreateForm && (
      <InfoBox>
        <p>
          <Icon>info</Icon>
          {intl.formatMessage(messages.eventCodeImmutable)}
        </p>
      </InfoBox>
    )}
  </div>
);

HandleInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(HandleInput);
