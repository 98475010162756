import firebase from 'firebase/app';

import confirm from '../confirm';

export default function* removeQuickReply(
  conferenceHandle,
  quickReplyId,
  confirmMessage
) {
  if (yield confirm(confirmMessage)) {
    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/chatbotSettings/quickReplies`)
      .child(quickReplyId)
      .remove();
  }
}
