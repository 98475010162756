/**
 *
 * SocialIcon
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';

export default svgContentProvider => {
  const SocialIcon = ({
    width,
    height,
    padding,
    color,
    tabIndex,
    onClick,
    className,
    style,
  }) => {
    const frameWidth = width - 2 * padding;
    const frameHeight = height - 2 * padding;

    const frameAspectRatio = frameWidth / frameHeight;

    const scaleFactor =
      frameAspectRatio > 1 ? frameHeight / 24 : frameWidth / 24;

    const styles = Object.assign(style || {}, {
      width,
      height,
    });

    return (
      <Flexbox
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        tabIndex={tabIndex}
        onClick={onClick}
        className={className}
        style={styles}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ transform: `scale(${scaleFactor})` }}
        >
          {svgContentProvider(
            (color && color.string && color.string()) || color || 'white'
          )}
        </svg>
      </Flexbox>
    );
  };

  SocialIcon.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    padding: PropTypes.number,
    color: PropTypes.any,
    tabIndex: PropTypes.any,
    onClick: PropTypes.any,
    className: PropTypes.any,
    style: PropTypes.any,
  };

  return SocialIcon;
};
