/**
 *
 * InfoButton
 *
 */

/* eslint-disable jsx-a11y/img-has-alt */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';

import { button } from '../../utils/ui/interactive';
import Icon from '../Icon/index';

const InfoTooltip = styled(Tooltip)`
  position: fixed;
  display: flex !important;
  align-items: center;
  justify-content: center;
  bottom: 90px;
  right: 32px;
  width: 40px;
  height: 40px;

  ${button({ lighten: true, backgroundColor: '#248BD1' })} border-radius: 50%;
  z-index: 100;
`;

const icon = {
  fontSize: '30px',
};

const Cards = styled.ol`
  counter-reset: li;
  margin-left: 0;
  padding-left: 0;
  text-align: left;

  > li {
    position: relative;
    margin: 0 0 6px 2em;
    padding: 0px 8px;
    list-style: none;

    > img {
      width: 100%;
    }
  }

  > li:before {
    content: counter(li);
    counter-increment: li;

    position: absolute;
    top: 0px;
    left: -2em;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 2em;

    margin-right: 8px;
    padding: 4px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const TooltipButton = props => {
  const {
    body,
    title,
    body2,
    body3,
    firstImage,
    secondImage,
    thirdImage,
  } = props;

  return (
    <InfoTooltip
      useContext
      html={
        <div style={{ padding: '0.4rem 1rem 0 0', width: '100%' }}>
          <h2>{title}</h2>
          {body3 ? (
            <Cards>
              <li>
                <p>{body}</p>
                <img src={firstImage} alt="" />
              </li>
              <li>
                <p>{body2}</p>
                <img src={secondImage} alt="" />
              </li>
              <li>
                <p>{body3}</p>
                <img src={thirdImage} alt="" />
              </li>
            </Cards>
          ) : (
            <Cards>
              <li>
                <p>{body}</p>
                <img src={firstImage} alt="" />
              </li>
              <li>
                <p>{body2}</p>
                <img src={secondImage} alt="" />
              </li>
            </Cards>
          )}
        </div>
      }
      position="top-end"
      trigger="click"
      interactive
      arrow="true"
    >
      <Icon style={icon}>info_outline</Icon>
    </InfoTooltip>
  );
};

TooltipButton.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  body2: PropTypes.string,
  body3: PropTypes.string,
  firstImage: PropTypes.any,
  secondImage: PropTypes.any,
  thirdImage: PropTypes.any,
};

export default TooltipButton;
