import { defineMessages } from 'react-intl';

export default defineMessages({
  breadcrumb: {
    id: 'app.containers.ChatMenuPage.breadcrumb',
    defaultMessage: 'Chat Menu',
  },
  menuItemType: {
    id: 'app.containers.ChatMenuPage.menuItemType',
    defaultMessage: 'Menu Item Type',
  },
  menuItemSubmenu: {
    id: 'app.containers.ChatMenuPage.menuItemSubmenu',
    defaultMessage: 'Submenu',
  },
  menuItemLink: {
    id: 'app.containers.ChatMenuPage.menuItemLink',
    defaultMessage: 'Link',
  },
  menuItemCustomIntent: {
    id: 'app.containers.ChatMenuPage.menuItemCustomIntent',
    defaultMessage: 'Custom Intent',
  },
  menuItemSystemMessage: {
    id: 'app.containers.ChatMenuPage.menuItemSystemMessage',
    defaultMessage: 'System Message',
  },
});
