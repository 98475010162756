/**
 *
 * RichTextInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import 'draft-js/dist/Draft.css';
import { button } from '../../utils/ui/interactive';

import Icon from '../Icon';

const Toolbar = styled(Flexbox)`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -2px;
  margin-right: -6px;
`;
const ToolbarItem = styled(Flexbox)`
  line-height: 15px;
  font-size: 15px;
  padding: 3px;
  margin: 3px;
  padding-top: 5px;
  align-items: center;

  > i {
    font-size: 17px
    margin-top: -2px;
  }
  
  ${button()}
`;

const Tool = ({ onClick, children }) => (
  <ToolbarItem onClick={onClick}>
    <Icon>{children}</Icon>
  </ToolbarItem>
);

Tool.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

const StyleButton = ({ onToggle, icon, label, style }) => {
  const handleMouseDown = e => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <ToolbarItem onMouseDown={handleMouseDown}>
      {(icon && <Icon>{icon}</Icon>) || label}
    </ToolbarItem>
  );
};

StyleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  icon: PropTypes.any,
  label: PropTypes.any,
  style: PropTypes.any.isRequired,
};

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', icon: 'format_quote', style: 'blockquote' },
  { label: 'UL', icon: 'format_list_bulleted', style: 'unordered-list-item' },
  { label: 'OL', icon: 'format_list_numbered', style: 'ordered-list-item' },
  { label: 'Code Block', icon: 'code', style: 'code-block' },
];

const BlockStyleControls = props => (
  <Toolbar justifyContent="flex-start">
    {BLOCK_TYPES.map(type => (
      <StyleButton
        key={type.label}
        icon={type.icon}
        label={type.label}
        onToggle={props.onToggle}
        style={type.style}
      />
    ))}
  </Toolbar>
);

BlockStyleControls.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

const INLINE_STYLES = [
  { label: 'Bold', icon: 'format_bold', style: 'BOLD' },
  { label: 'Italic', icon: 'format_italic', style: 'ITALIC' },
  { label: 'Underline', icon: 'format_underline', style: 'UNDERLINE' },
  { label: 'Monospace', icon: 'space_bar', style: 'CODE' },
];

const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <Toolbar justifyContent="flex-start">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          icon={type.icon}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </Toolbar>
  );
};

InlineStyleControls.propTypes = {
  editorState: PropTypes.any.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default class RichTextInput extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    className: PropTypes.any,
    autoFocus: PropTypes.bool,
  };

  static handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.setState({ editorState: newState });
      return true;
    }
    return false;
  }

  static onTab(e) {
    const maxDepth = 4;
    this.setState({
      editorState: RichUtils.onTab(e, this.state.editorState, maxDepth),
    });
  }

  static toggleBlockType(blockType) {
    this.setState({
      editorState: RichUtils.toggleBlockType(this.state.editorState, blockType),
    });
  }

  static toggleInlineStyle(inlineStyle) {
    this.setState({
      editorState: RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      ),
    });
  }

  static handleChange(editorState) {
    this.setState({ editorState });

    this.props.input.onChange(
      JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
    );
  }

  static handleFocus() {
    this.domEditor.focus();
  }

  static handleBlur() {
    this.props.input.onBlur();
  }

  static stateFromValue(value) {
    return value
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
      : EditorState.createEmpty();
  }

  constructor(props) {
    super(props);
    this.handleKeyCommand = RichTextInput.handleKeyCommand.bind(this);
    this.onTab = RichTextInput.onTab.bind(this);
    this.toggleBlockType = RichTextInput.toggleBlockType.bind(this);
    this.toggleInlineStyle = RichTextInput.toggleInlineStyle.bind(this);
    this.handleChange = RichTextInput.handleChange.bind(this);
    this.handleFocus = RichTextInput.handleFocus.bind(this);
    this.handleBlur = RichTextInput.handleBlur.bind(this);
    /* eslint-disable no-return-assign */
    this.setDomEditorRef = ref => (this.domEditor = ref);
  }

  componentWillMount() {
    this.setState({
      editorState: RichTextInput.stateFromValue(this.props.input.value),
    });
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.domEditor.focus();
    }
  }

  render() {
    const { className } = this.props;
    const { editorState } = this.state;
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Flexbox
        flexDirection="column"
        className={className}
        style={styles.description}
      >
        <BlockStyleControls
          editorState={editorState}
          onToggle={this.toggleBlockType}
        />

        <InlineStyleControls
          editorState={editorState}
          onToggle={this.toggleInlineStyle}
        />
        <div style={styles.editor} onClick={this.handleFocus} role="button">
          <Editor
            placeholder="Type your description here"
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.handleChange}
            onTab={this.onTab}
            onBlur={this.handleBlur}
            ref={this.setDomEditorRef}
          />
        </div>
      </Flexbox>
    );
  }
}

const styles = {
  editor: {
    borderTop: '1px solid #ddd',
    cursor: 'text',
    fontSize: 16,
    marginTop: 20,
    minHeight: 300,
    paddingTop: 20,
  },
  description: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    padding: '15px',
  },
};
