import firebase from 'firebase/app';

export default function* updateTheme({
  id,
  colors,
  page,
  card,
  tag,
  navigation,
}) {
  yield firebase
    .database()
    .ref(`conferences/${id}/theme`)
    .update({ colors, page, card, tag, navigation });
}
