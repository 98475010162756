import { defineMessages } from 'react-intl';

export default defineMessages({
  createIntent: {
    id: 'app.components.ChatIntentForm.intent.create',
    defaultMessage: 'Create Intent',
  },
  updateIntent: {
    id: 'app.components.ChatIntentForm.intent.update',
    defaultMessage: 'Update Intent',
  },
  deleteIntent: {
    id: 'app.components.ChatIntentForm.intent.delete',
    defaultMessage: 'Delete',
  },
  intentName: {
    id: 'app.components.ChatIntentForm.intent.name',
    defaultMessage: 'Intent name',
  },
  intentNamePlaceholder: {
    id: 'app.components.ChatIntentForm.intent.placeholder',
    defaultMessage: 'e.g. Food Search Intent',
  },
  queries: {
    id: 'app.components.ChatIntentForm.intent.queries',
    defaultMessage: 'Queries',
  },
  queryDescription: {
    id: 'app.components.ChatIntentForm.intent.queryDescription',
    defaultMessage:
      'Type in phrases that you may expect from your participants to type. We match similarity in phrases based on the input here vs user to match the intent.',
  },
  queryInputPlaceholder: {
    id: 'app.components.ChatIntentForm.intent.queryInputPlaceholder',
    defaultMessage: 'e.g. Where is the food?',
  },
  queryTrigger: {
    id: 'app.components.ChatIntentForm.intent.queries.trigger',
    defaultMessage: 'Phrase',
  },
  queryTriggerPlacholder: {
    id: 'app.components.ChatIntentForm.intent.queries.trigger.placeholder',
    defaultMessage: 'e.g. food near me',
  },
  queryHintMessage: {
    id: 'app.components.ChatIntentForm.intent.queries.hintMessage',
    defaultMessage: 'Press Enter to insert a query',
  },
  queryExactMatch: {
    id: 'app.components.ChatIntentForm.intent.queries.isExact',
    defaultMessage: 'Exact?',
  },
  responseType: {
    id: 'app.components.ChatIntentForm.intent.responseType',
    defaultMessage: 'Response type',
  },
  responseTypeText: {
    id: 'app.components.ChatIntentForm.intent.responseType.text',
    defaultMessage: 'Text Message',
  },
  responseTypeTextPlaceholder: {
    id: 'app.components.ChatIntentForm.intent.responseTypeTextPlaceholder.text',
    defaultMessage: 'e.g. You can get a burger near hall B!',
  },
  responseTypeList: {
    id: 'app.components.ChatIntentForm.intent.responseType.list',
    defaultMessage: 'Item List',
  },
  responseTypeListTitle: {
    id: 'app.components.ChatIntentForm.intent.responseType.list.itemTitle',
    defaultMessage: 'Item title',
  },
  responseTypeListTitlePlaceholder: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.itemTitle.placeholder',
    defaultMessage: 'e.g. Mike’s BBQ Joint',
  },
  responseTypeListDescription: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.itemDescription',
    defaultMessage: 'Item description',
  },
  responseTypeListDescriptionPlaceholder: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.itemDescription.placeholder',
    defaultMessage:
      'e.g. Mike’s BBQ Joint is located at Charlston Avenue 40, right near hall B.',
  },
  responseTypeListImage: {
    id: 'app.components.ChatIntentForm.intent.responseType.list.itemImage',
    defaultMessage: 'Item image',
  },
  responseTypeListButtonLabel: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.item.button.label',
    defaultMessage: 'Button label',
  },
  buttonLabelFormatting: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.item.button.label.formatting',
    defaultMessage: ' (max 20 characters)',
  },
  responseTypeListButtonLabelPlaceholder: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.item.button.label.placeholder',
    defaultMessage: 'e.g. Visit Website',
  },
  responseTypeListButtonLink: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.item.button.link',
    defaultMessage: 'Button link',
  },
  responseTypeListButtonLinkPlaceholder: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.item.button.link.placeholder',
    defaultMessage: 'e.g. https://mikesbbqjoint.com',
  },
  titleAndDescriptionFormatting: {
    id:
      'app.components.ChatIntentForm.intent.responseType.list.item.titleAndDescriptionFormatting',
    defaultMessage: ' (max 80 characters)',
  },
});
