import { call, put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import { isString } from 'is-of-type';
import isNonEmptyFileList from '../../../utils/isNonEmptyFileList';
import uploadFile from '../file/uploadFile';

export default function* updateSpeaker(
  conferenceHandle,
  id,
  name,
  headline,
  description,
  tags,
  image,
  profiles
) {
  let imagePath;

  if (isNonEmptyFileList(image)) {
    const { url } = yield uploadFile(
      image[0],
      `/@${conferenceHandle}/speakers/${id}`
    );

    if (url) {
      imagePath = url;
    }
  }

  const speaker = {
    name,
    headline,
    profiles,
    image: '',
  };

  if (tags) {
    speaker.tags = tags;
  }

  if (description) {
    speaker.description = description;
  }

  if (isString(imagePath)) {
    speaker.image = imagePath;
  }

  yield call(() =>
    firebase
      .database()
      .ref(`conferences/${conferenceHandle}/speakers/${id}`)
      .update(speaker)
  );

  try {
    window.$crisp.push([
      'set',
      'session:event',
      [
        [
          [
            'speaker_update',
            {
              id: id,
              name: name,
              headline: headline,
            },
            'orange',
          ],
        ],
      ],
    ]);
  } catch (e) {
    // crisp not ready
    console.log('crisp error', e);
  }

  yield put(goBack());
}
