import { ADD_BREADCRUMB, REMOVE_BREADCRUMB } from '../constants/navigation';

export function addBreadcrumb(link, text) {
  return {
    type: ADD_BREADCRUMB,
    link,
    text,
  };
}

export function removeBreadcrumb(link) {
  return {
    type: REMOVE_BREADCRUMB,
    link,
  };
}
