/*
 * SignupForm Messages
 *
 * This contains all the text for the SignupForm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.SignupForm.name',
    defaultMessage: 'Full Name',
  },
  namePlaceholder: {
    id: 'app.components.SignupForm.namePlaceholder',
    defaultMessage: 'e.g., John Doe',
  },
  email: {
    id: 'app.components.SignupForm.email',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'app.components.LoginForm.emailPlaceholder',
    defaultMessage: 'e.g., jhn@doe.com',
  },
  password: {
    id: 'app.components.SignupForm.password',
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: 'app.components.LoginForm.passwordPlaceholder',
    defaultMessage: 'e.g., ••••••••••••',
  },
  signUp: {
    id: 'app.components.SignupForm.signUp',
    defaultMessage: 'Take me to my dashboard',
  },
  termsLabel: {
    id: 'app.components.SignupForm.signUp',
    defaultMessage: 'I agree to the {termsOfService} and {privacyPolicy}',
  },
  termsOfService: {
    id: 'app.components.SignupForm.termsOfService',
    defaultMessage: 'Terms of Service',
  },
  privacyPolicy: {
    id: 'app.components.SignupForm.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  mustAgreeWarning: {
    id: 'app.components.SignupForm.mustAgreeWarning',
    defaultMessage: 'You should agree to our terms in order to use Sava',
  },
});
