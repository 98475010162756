/*
 * ChatBuilderPage Container
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Map } from 'immutable';
import Flexbox from 'flexbox-react';
import { injectIntl } from 'react-intl';
import { toListOfMapsWithIds } from 'sava-shared/lib/utils/mapOfMapsToListOfMaps';
import { createSelector } from 'reselect';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';

import messages from './messages';
import settingsMessages from '../ChatbotConfiguration/messages';

import { DefaultList } from '../../components/List';
import QuickReplyItem from '../../components/QuickReplyItem';
import { FormErrorWithMargins as FormError } from '../../components/FormError';
import QuickRepliesWarnings from '../../components/QuickRepliesWarnings';
import ActionBar from '../../components/ActionBar';
import Button from '../../components/buttons/Button';

import ModalRoute from '../../components/ModalRoute';
import CreateQuickReplyPage from '../CreateQuickReplyPage';
import EditQuickReplyPage from '../EditQuickReplyPage';

import withAsyncContent from '../../hocs/withAsyncContent';
import withBreadcrumbs from '../../hocs/withBreadcrumbs';

import createFetchResource from '../../sagas/user/createFetchResource';
import saveQuickReplies from '../../sagas/user/conferences/saveQuickReplies';
import sortQuickReplies from '../../sagas/user/conferences/sortQuickReplies';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
} from '../../selectors/user';
import { selectProp } from '../../selectors/common';

const Root = styled(Flexbox)``;

const ChatQuickReplies = styled.div`
  background: white;
  ${props => props.theme.altitude(1)};
  padding: 20px 20px 40px 20px;
  max-width: 500px;
  flex-direction: row;
  margin: 40px;
  max-height: 600px;
  flex-grow: 3;
`;

const QuickRepliesList = DefaultList({ sortable: true });

class ChatQuickRepliesPage extends Component {
  constructor(props) {
    super(props);

    const { content } = props;
    const { quickReplies = [] } = content || {};

    this.state = {
      busy: false,
      error: undefined,
      defaultQuickReplies: quickReplies,
    };
  }

  defaultUserRequestExecutor = (error, message) =>
    this.props.createUserRequestExecutor({
      onStart: () => this.setState({ error: false }),
      onFailure: () => this.setState({ error: error }),
      errorMessages: () => message,
    });

  async componentDidMount() {
    const { quickReplies } = this.props;

    if (
      (!quickReplies && quickReplies !== false) ||
      (quickReplies && !quickReplies.length)
    ) {
      await this.resetToDefaultQuickReplies();
    }
  }

  resetToDefaultQuickReplies = (resetToDefaults = false) => {
    const { defaultQuickReplies } = this.state;
    const { conferenceHandle, intl } = this.props;
    const error = 'saveDefaultQuickRepliesError';
    const message = intl.formatMessage(messages[error]);
    return this.defaultUserRequestExecutor(error, message)(
      saveQuickReplies,
      conferenceHandle,
      defaultQuickReplies,
      resetToDefaults,
      intl.formatMessage(messages.confirmResetToDefaults)
    );
  };

  getQuickReplies = () => {
    const { quickReplies } = this.props;

    return quickReplies && quickReplies.length ? quickReplies : [];
  };

  resetToDefaults = async () => {
    await this.resetToDefaultQuickReplies(true);
  };

  onSortEnd = async sortedQuickReplies => {
    const { conferenceHandle, intl } = this.props;

    const error = 'saveDefaultQuickRepliesError';
    const message = intl.formatMessage(messages[error]);

    await this.defaultUserRequestExecutor(error, message)(
      sortQuickReplies,
      conferenceHandle,
      sortedQuickReplies
    );
  };

  render() {
    const { error } = this.state;
    const { conference, match, intl } = this.props;
    const quickReplies = this.getQuickReplies();

    return (
      <Fragment>
        <Helmet
          title={`${conference.get('name')} ${intl.formatMessage(
            messages.helmet
          )}`}
        />
        <Root>
          <ChatQuickReplies>
            <ActionBar justifyContent="space-between" style={{ padding: '0' }}>
              <Button
                onClick={this.resetToDefaults}
                label={intl.formatMessage(messages.resetToDefaultQuickReplies)}
                type="link"
                to="#"
              />
              <Button
                to={`${match.url}/new`}
                label={intl.formatMessage(messages.addNew)}
                type="primary"
              />
            </ActionBar>
            {error && (
              <FormError>{intl.formatMessage(messages[error])}</FormError>
            )}
            <QuickRepliesWarnings numberOfQuickReplies={quickReplies.length} />
            <QuickRepliesList
              items={quickReplies}
              component={QuickReplyItem}
              keyExtractor={quickReply => quickReply.id}
              noItemsTitle={intl.formatMessage(messages.noItemsTitle)}
              noItemsHint={intl.formatMessage(messages.noItemsHint)}
              noItemsIcon={'chat_bubble'}
              onSortEnd={this.onSortEnd}
            />
          </ChatQuickReplies>

          <ModalRoute
            exact
            path={`${match.path}/new`}
            component={CreateQuickReplyPage}
          />
          <ModalRoute
            exact
            path={`${match.path}/:id`}
            except={[`${match.path}/new`]}
            component={EditQuickReplyPage}
          />
        </Root>
      </Fragment>
    );
  }
}

ChatQuickRepliesPage.propTypes = {
  conference: PropTypes.object.isRequired,
  conferenceHandle: PropTypes.string.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

const selectQuickReplies = createSelector(
  selectCurrentConference,
  conference => conference.get('chatbotSettings', Map()).toJS().quickReplies
);

const sortQuickRepliesByOrder = (a, b) => a.order - b.order;

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectQuickReplies,
  selectProp('content'),
  (conferenceHandle, conference, quickReplies, content) => ({
    conferenceHandle,
    conference,
    quickReplies:
      quickReplies === false
        ? false
        : toListOfMapsWithIds(quickReplies || []).sort(sortQuickRepliesByOrder),
    content: {
      ...content,
      quickReplies: toListOfMapsWithIds(content.quickReplies || []),
    },
  })
);

const breadcrumbsProvider = ({ conference, intl }) => [
  {
    to: `/@${conference.get('id')}/conversations`,
    title: intl.formatMessage(settingsMessages.breadcrumb),
  },
  {
    to: `/@${conference.get('id')}/conversations/chat-quick-replies`,
    title: intl.formatMessage(messages.breadcrumb),
  },
];

const fetchDefaultChatbotSettings = createFetchResource(
  'configuration/defaultChatbotSettings',
  { fallback: {}, throwError: false }
);

export default compose(
  withRouter,
  withUserRequestExecutor,
  injectIntl,
  withAsyncContent(fetchDefaultChatbotSettings),
  connect(mapStateToProps),
  withBreadcrumbs(breadcrumbsProvider)
)(ChatQuickRepliesPage);
