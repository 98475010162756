import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { link } from '../../utils/ui/interactive';

export const CreateLinkButton = Component => props => {
  const Edit = (Component ? styled(Component) : styled.button)`
    ${props => css`
      ${link({
        color: props.theme.colors.brandPrimary,
        darken: true,
      })} text-decoration: none;
    `};
  `;

  return <Edit {...props} />;
};

export default CreateLinkButton(Link);
