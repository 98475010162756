import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../../../components/Icon';

const StyledIcon = styled(Icon)`
  font-size: 7.5em;
`;

const NoTicketsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0.3;
`;

const NoTickets = ({ type }) => {
  return (
    <NoTicketsWrapper>
      <StyledIcon>forum</StyledIcon>
      {type === 'open' && 'No new tickets!'}
      {type === 'closed' && 'No closed tickets!'}
      {type === 'notStarted' && 'Please select a thread to start messaging.'}
    </NoTicketsWrapper>
  );
};

NoTickets.propTypes = {
  type: PropTypes.oneOf(['open', 'closed', 'notStarted']),
};

export default NoTickets;
