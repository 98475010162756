import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Flexbox from 'flexbox-react';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import styled from 'styled-components';

import withBreadcrumbs from '../../hocs/withBreadcrumbs';

import messages from './messages';

import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
} from '../../selectors/user';
import { createSelector } from 'reselect';
import ModuleNavigation from '../../components/ModuleNavigation';
import illoChatMenu from '../../assets/images/illo-chat-menu.svg';
import illoQuickReplies from '../../assets/images/illo-quick-replies.svg';
import illoChatBuilder from '../../assets/images/illo-chat-builder.svg';

const Container = styled(Flexbox)`
  padding: 1.5em;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ChatbotConfigurationPage = props => {
  const root = `/@${props.conference.get('id')}/conversations`;
  return (
    <div>
      <Helmet
        title={`${props.conference.get(
          'name'
        )} Bot Configuration • Sava Events`}
      />
      <Container>
        <ModuleNavigation
          to={`${root}/chatbuilder`}
          title="Conversation Automation"
          description="Set up automatic replies to user’s questions and comments"
          illustration={illoChatBuilder}
        />
        <ModuleNavigation
          to={`${root}/chatmenu`}
          title="Chat Menu"
          description="Configure menu options for supported messaging apps"
          illustration={illoChatMenu}
        />
        <ModuleNavigation
          to={`${root}/chat-quick-replies`}
          title="Quick Replies"
          description="Configure quick chat phrases user can easily interact with"
          illustration={illoQuickReplies}
        />
      </Container>
    </div>
  );
};

const breadcrumbsProvider = ({ conference, intl }) => [
  {
    to: `/@${conference.get('id')}/conversations`,
    title: intl.formatMessage(messages.breadcrumb),
  },
];

ChatbotConfigurationPage.propTypes = {
  conference: PropTypes.object.isRequired,
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  (conferenceHandle, conference) => ({
    conferenceHandle,
    conference,
  })
);

export default compose(
  injectIntl,
  connect(mapStateToProps),
  withBreadcrumbs(breadcrumbsProvider)
)(ChatbotConfigurationPage);
