import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import { compose } from 'recompose';

import withSaga from '../../hocs/withSaga';
import userSaga from '../../sagas/user';

import Spinner from '../../components/Spinner';
import App from '../App';
import AuthenticatedUserLogin from '../AuthenticatedUserLogin';
import NotFound from '../NotFoundPage';
import ForgotPasswordPage from '../ForgotPasswordPage';
import ForgotPasswordPageSuccess from '../ForgotPasswordPage/Success';
import LoginPage from '../LoginPage';
import SingupPage from '../SignupPage';
import AccountAction from '../AccountAction';
import ResetPasswordPage from '../ResetPasswordPage';
import EmbedProvider from '../../providers/EmbedProvider';
import { AuthenticatedQuestionsPage } from '../QuestionsPage';
import IsAdministratorProvider from '../../providers/IsAdministratorProvider';

const userIsLoaded = user => {
  const userData = user.get('userData');

  if (!userData) {
    return false;
  }

  const conferencesAreLoaded = userData
    .get('conferences', Map())
    .valueSeq()
    .every(value => value !== true);

  const currentConferenceIsLoaded =
    typeof userData.get('currentConference') !== typeof '';

  return conferencesAreLoaded && currentConferenceIsLoaded;
};

const AppWrapper = ({ user }) => {
  const loggedIn = user.get('loggedIn');
  const storeIsReady =
    loggedIn === false || (loggedIn === true && userIsLoaded(user));

  if (storeIsReady) {
    if (loggedIn) {
      const displayName =
        user.getIn(['userData', 'fullName']) ||
        user.getIn(['user', 'displayName']);

      /*eslint no-undef: "warn"*/
      $crisp.push(['set', 'user:email', [user.getIn(['user', 'email'])]]);
      $crisp.push(['set', 'user:nickname', [displayName]]);
      return (
        <IsAdministratorProvider userId={user.getIn(['userData', 'id'], false)}>
          <Switch>
            <Route
              path="/@:conferenceHandle/m/q"
              component={EmbedProvider(loggedIn)(AuthenticatedQuestionsPage)}
            />
            <Route exact path="/login" component={AuthenticatedUserLogin} />
            <Route exact path="/signup" component={AuthenticatedUserLogin} />
            <Route path="/" component={App} />
            <Route component={NotFound} />
          </Switch>
        </IsAdministratorProvider>
      );
    } else {
      return (
        <Switch>
          <Route exact path="/" component={SingupPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/forgot" component={ForgotPasswordPage} />
          <Route exact path="/account/action" component={AccountAction} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPasswordPage}
          />
          <Route
            exact
            path="/forgot/success"
            component={ForgotPasswordPageSuccess}
          />
          <Route component={NotFound} />
        </Switch>
      );
    }
  }

  return <Spinner />;
};

export default compose(
  withRouter,
  connect(state => ({ user: state.get('user', Map()) })),
  withSaga(userSaga)
)(AppWrapper);
