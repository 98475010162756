import React, { Component } from 'react';
import { connect } from 'react-redux';
import Flexbox from 'flexbox-react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import { selectCurrentConference } from '../../selectors/user';
import { selectProps } from '../../selectors/common';
import playstoreButton from '../../assets/images/playstore-button.png';
import appstoreButton from '../../assets/images/appstore-button.svg';

const Root = styled(Flexbox)`
  > div {
    margin: 20px;
  }
`;

const Content = styled(Flexbox)`
  max-width: 700px;
  flex-direction: column;
`;

const Link = styled.a``;

class ConferenceSettingsMobileAppCustomization extends Component {
  render() {
    const { conferenceHandle } = this.props;
    const root = `/@${conferenceHandle}/settings`;

    return (
      <Root>
        <Helmet title="Mobile App Configuration • Sava Events" />
        <Breadcrumb to={root}>Settings</Breadcrumb>
        <Breadcrumb to={`${root}/theme-customization`}>
          Mobile App Configuration
        </Breadcrumb>
        <Content>
          <div>
            <p>
              The Sava Events app can be downloaded from the Google Play and
              Apple App Store.
            </p>
          </div>
          <div>
            <Link
              href="https://play.google.com/store/apps/details?id=events.sava.mobile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img style={{ height: 64 }} src={playstoreButton} />
            </Link>
            <Link
              href="https://itunes.apple.com/us/app/sava-events/id1426234442?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img style={{ height: 43 }} src={appstoreButton} />
            </Link>
          </div>
          <div>
            <p>
              Your event code for the app is:{' '}
              <strong>{conferenceHandle}</strong>
            </p>
          </div>
        </Content>
      </Root>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectProps,
  conference => {
    return {
      conferenceHandle: conference.get('id'),
      conferenceName: conference.get('name'),
    };
  }
);

export default compose(connect(mapStateToProps))(
  ConferenceSettingsMobileAppCustomization
);
