import React from 'react';
import { compose } from 'recompose';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import SavaIcon from '../icons/Sava';
import { link } from '../../utils/ui/interactive';
import { selectSubscriptionPlan } from '../../selectors/embed';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${props => (props.margin ? props.margin : '5em')};
  margin-bottom: 1.2em;
  font-size: 0.875em;
  font-weight: 600;
  justify-content: center;
  opacity: 0.67;
`;

const SavaIconContainer = styled.span`
  margin: 0.2em 0.4em 0em 0.4em;
`;

export const SavaEventsLink = styled.a`
  ${props => link({ color: props.theme.page.color })};
  display: flex;
`;

const PoweredBy = ({ theme, subscriptionPlan, conferenceHandle, margin }) => {
  const UTM = `?utm_source=embed&utm_medium=poweredby&utm_campaign=${conferenceHandle}`;

  return subscriptionPlan === 'free' ? (
    <Root margin={margin}>
      <SavaEventsLink
        href={process.env.REACT_APP_LANDING_URL + UTM}
        target={'_blank'}
      >
        <span>Powered by</span>
        <SavaIconContainer>
          <SavaIcon color={theme.page.color} size={theme.sizes.base * 1.4} />
        </SavaIconContainer>
        <span>Sava Events</span>
      </SavaEventsLink>
    </Root>
  ) : null;
};

const mapStateToProps = createSelector(
  selectSubscriptionPlan,
  subscriptionPlan => ({
    subscriptionPlan,
  })
);

export default compose(
  withTheme,
  connect(mapStateToProps)
)(PoweredBy);
