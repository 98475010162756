import CollectionInput from '../../CollectionInput';
import ImageInput, { MessageListItemWrapper } from './ImageInput';

const MIN_COLLECTION_INPUT_ITEMS = 2;
const MAX_COLLECTION_INPUT_ITEMS = 4;

export default CollectionInput(
  ImageInput,
  undefined,
  MessageListItemWrapper,
  MIN_COLLECTION_INPUT_ITEMS,
  MAX_COLLECTION_INPUT_ITEMS
);
