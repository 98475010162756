/*
 * CreateSponsorPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { List } from 'immutable';
import { compose } from 'recompose';
import { createSelector } from 'reselect';

import {
  selectCurrentConferenceHandle,
  selectConferenceName,
} from '../../selectors/user';

import SponsorForm from '../../components/SponsorForm';

import createSponsor from '../../sagas/user/sponsors/create';
import withUserRequestExecutor, {
  errorMessages,
} from '../../hocs/withUserRequestExecutor';

export class CreateSponsorPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    errorMessages,
  });

  handleSubmit = async (data, dispatch, { form }) => {
    const { name, description, image, group, profiles } = data
      .update('profiles', List(), _profiles =>
        _profiles.map(profile => {
          const icon = profile.get('icon');
          return [
            'facebook',
            'github',
            'google',
            'linkedIn',
            'twitter',
          ].indexOf(icon) >= 0
            ? profile
            : profile.delete('icon');
        })
      )
      .toJS();

    await this.defaultUserRequestExecutor(
      createSponsor,
      this.props.conferenceHandle,
      name,
      description,
      image,
      group,
      profiles || [],
      form
    );
  };

  render() {
    const { conferenceHandle, conferenceName } = this.props;

    return (
      <div>
        <Helmet title={`${conferenceName} • Create Sponsor • Sava Events`} />
        <SponsorForm
          conferenceHandle={conferenceHandle}
          onSubmit={this.handleSubmit}
          isCreateForm
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectConferenceName,
  (conferenceHandle, conferenceName) => ({
    conferenceHandle,
    conferenceName,
  })
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor
)(CreateSponsorPage);
