/*
 * EditSponsorGroupPage Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Map } from 'immutable';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import {
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectConferenceName,
  selectId,
} from '../../selectors/user';

import messages from './messages';
import SponsorGroupForm from '../../components/SponsorGroupForm';
import updateSponsorGroup from '../../sagas/user/sponsorGroups/update';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import deleteSponsorGroup from '../../sagas/user/sponsorGroups/delete';
import { confirm } from '../../sagas/user/confirm';

export class EditSponsorGroupPage extends React.Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string.isRequired,
    sponsorGroup: PropTypes.any.isRequired,
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor();

  handleSubmit = async (data, dispatch, { form }) => {
    const { id, name } = data.toJS();
    await this.defaultUserRequestExecutor(
      updateSponsorGroup,
      this.props.conferenceHandle,
      id,
      name,
      form
    );
  };

  handleDelete = async () => {
    const { intl, conferenceHandle, sponsorGroup } = this.props;

    const id = sponsorGroup.get('id');

    if (confirm(intl.formatMessage(messages.confirmDelete))) {
      await this.defaultUserRequestExecutor(
        deleteSponsorGroup,
        conferenceHandle,
        id
      );
    }
  };

  render() {
    const { conferenceHandle, conferenceName, sponsorGroup } = this.props;

    return (
      <div>
        <Helmet title={`${conferenceName} • ${sponsorGroup.get('name')}`} />
        <SponsorGroupForm
          conferenceHandle={conferenceHandle}
          initialValues={sponsorGroup}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectConferenceName,
  selectId,
  (conference, conferenceHandle, conferenceName, id) => ({
    conferenceHandle,
    conferenceName,
    sponsorGroup: conference
      .getIn(['sponsorGroups', id], Map())
      .set('id', id)
      .update('group', groupId => Map({ id: groupId })),
  })
);

export default compose(
  connect(mapStateToProps),
  injectIntl,
  withUserRequestExecutor
)(EditSponsorGroupPage);
