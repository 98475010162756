/**
 *
 *  QuestionForm
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { button } from '../../utils/ui/interactive';
import Form from '../Form';

const Button = styled.button`
  padding: 12px;

  ${button()};
  background-color: #f3f4f5;
  color: #333;
`;

const SubmissionNote = styled.p`
  font-size: 0.8em;
  min-height: 14px;
  opacity: 0.75;
`;

class QuestionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMinLengthReached: false,
    };
  }

  validate = question => {
    if (typeof question === 'string' && question.length >= 10) {
      this.setState({ isMinLengthReached: true });
    } else {
      this.setState({ isMinLengthReached: false });
    }
    return undefined;
  };

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const { isMinLengthReached } = this.state;
    return (
      <Form onSubmit={handleSubmit}>
        <fieldset>
          <Field
            name="question"
            component="textarea"
            type="text"
            placeholder="Enter your question here 😀"
            style={{
              fontSize: 16,
              background: 'white',
              borderRadius: 3,
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
              border: '1px solid rgba(0, 0, 0, 0.2)',
              width: '100%',
              maxWidth: 720,
              padding: '8px',
              outline: 'none',
              color: '#333',
              height: '3.5em',
            }}
            required
            autoFocus
            validate={this.validate}
          />
        </fieldset>
        <SubmissionNote>
          The question should be at least 10 characters long.
        </SubmissionNote>
        <Button disabled={!isMinLengthReached || (pristine && submitting)}>
          Submit question
        </Button>
      </Form>
    );
  }
}

QuestionForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default connect()(
  reduxForm({
    form: 'questionFrom',
  })(QuestionForm)
);
