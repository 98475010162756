import { call, take, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form/immutable';
import loglevel from 'loglevel';

export default (constant, saga) =>
  function* sagaLoop() {
    while (true) {
      // eslint-disable-next-line no-unused-vars
      const { type, form, ...params } = yield take(constant);

      if (form) {
        yield put(startSubmit(form));
      }

      try {
        yield call(saga, params);
      } catch (error) {
        loglevel.error(`Saga listening on ${constant} failed`, error);
      } finally {
        if (form) {
          yield put(stopSubmit(form));
        }
      }
    }
  };
