import firebase from 'firebase/app';

import confirm from '../confirm';

export default function* removeQuickReplies(conferenceHandle, confirmMessage) {
  if (yield confirm(confirmMessage)) {
    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/chatbotSettings/quickReplies`)
      .set(false);
  }
}
