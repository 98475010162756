// TODO: Support other button types other than web_url
export default ({
  action = '',
  buttons = [],
  buttonLabel = '',
  buttonType = 'web_url',
  subtitle = '',
  title = '',
  imageUrl,
}) => {
  const result = {
    action,
    buttons,
    buttonLabel,
    buttonType,
    subtitle,
    title,
  };

  if (imageUrl) {
    result.imageUrl = imageUrl;
  }

  return result;
};
