import { defineMessages } from 'react-intl';

export default defineMessages({
  orderProperly: {
    id: 'app.components.QuickRepliesWarnings.orderProperly',
    defaultMessage: 'Make sure to order items properly because:',
  },
  facebook: {
    id: 'app.components.QuickRepliesWarnings.facebook',
    defaultMessage: ' • Facebook supports maximum 11 quick replies',
  },
});
