import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EditLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.brandPrimary};

  &:visited {
    color: ${props => props.theme.colors.brandPrimary};
  }
`;

export const LightEditLink = styled(EditLink)`
  color: white;
  word-wrap: break-word;

  &:visited {
    color: white;
  }
`;
