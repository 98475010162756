import { randomColorWithCache } from '../../../utils/randomColor';
const randomColor = randomColorWithCache({});

const genderMap = {
  male: 'Male',
  female: 'Female',
};

const localeMatch = {
  en_US: 'English',
};

export default class UserProfile {
  constructor({ id, firstName, lastName, timezone, locale, gender, username }) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
    this._timezone = timezone;
    this._locale = locale;
    this._localeLabel = localeMatch[locale];
    this._gender = genderMap[gender];
    this._username = username;
    this._color = randomColor(id);
  }

  getId = () => this._id;

  getFirstName = () => this._firstName;

  getLastName = () => this._lastName;

  getUsername = () => this._username;

  getFullName = () => {
    if (this._firstName && this._lastName) {
      return `${this._firstName} ${this._lastName}`;
    } else if (!this._firstName && this._lastName) {
      return this._lastName;
    } else if (this._firstName && !this._lastName) {
      return this._firstName;
    } else {
      return 'Unknown User';
    }
  };

  getTimezone = () => this._timezone;

  getLocale = () => this._locale;

  getLocaleLabel = () => this._localeLabel;

  getGender = () => this._gender;

  getColor = () => this._color;

  getInitials = () => {
    if (this._firstName && this._lastName) {
      return `${this._firstName[0]}. ${this._lastName[0]}`;
    }
    if (this._username) {
      return this._username[0];
    }
    return '';
  };
}
