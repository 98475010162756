import React from 'react';
import styled from 'styled-components';

import ThinLabel from '../ThinLabel';

const MetricsWrapper = styled.div``;

const getMetricInfo = (
  messengerBroadcastId,
  messengerBroadcastFailed,
  reached = 0
) => {
  if (messengerBroadcastId) {
    return `${reached} attendee${reached === 1 ? '' : 's'} reached.`;
  }
  if (messengerBroadcastFailed) {
    return (
      <span style={{ color: 'crimson', opacity: 0.75 }}>
        There was an error sending this broadcast.
      </span>
    );
  }
  return 'Not delivered yet.';
};

const BroadcastMetrics = ({
  sentAt,
  messengerBroadcastId,
  messengerBroadcastFailed,
  reached,
}) => (
  <MetricsWrapper>
    <ThinLabel style={{ fontWeight: 600, marginRight: '1em' }}>
      {sentAt}
    </ThinLabel>{' '}
    <ThinLabel>
      {getMetricInfo(messengerBroadcastId, messengerBroadcastFailed, reached)}
    </ThinLabel>
  </MetricsWrapper>
);

export default BroadcastMetrics;
