import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';
import { fromJS } from 'immutable';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { compose } from 'recompose';
import slug from 'slugify';

import EventHandleInput from '../EventHandleInput';
import CollectionInput from '../../components/CollectionInput';
import TimezonePicker from '../../components/TimezonePicker';
import DateRangeInput from '../../components/DateRangeInput';
import Form from '../../components/Form';
import FormLabel from '../../components/FormLabel';
import SessionTypeInput from '../../components/SessionTypeInput';
import TrackInput from '../../components/TrackInput';
import LayoutPicker from '../../components/LayoutPicker';

import { button } from '../../utils/ui/interactive';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import messages from './messages';
import { connect } from 'react-redux';

const Button = styled.button`
  padding: 12px;
  margin: 0px;

  ${button()};
`;

const TracksInput = CollectionInput(TrackInput);

const SessionTypesInput = CollectionInput(SessionTypeInput);

const ConferenceForm = props => {
  const {
    dispatch,
    change,
    handle,
    name,
    isCreateForm,
    handleSubmit,
    pristine,
    submitting,
    intl,
  } = props;

  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <fieldset>
        <FormLabel
          htmlFor={'name'}
          message={intl.formatMessage(messages.name)}
        />
        <Field
          name="name"
          id="name"
          component="input"
          type="text"
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          style={{ fontSize: 22 }}
          required
        />
      </fieldset>

      <fieldset>
        <FormLabel
          htmlFor={'id'}
          message={intl.formatMessage(messages.handle)}
        />
        <Field
          name="id"
          id="id"
          component={EventHandleInput}
          props={{
            placeholder: intl.formatMessage(messages.handlePlaceholder),
            isCreateForm,
          }}
          required
        />
      </fieldset>

      <fieldset>
        <FormLabel
          htmlFor={'dates'}
          message={intl.formatMessage(messages.dates)}
        />
        <Field
          name="dates"
          id="dates"
          component={DateRangeInput}
          isOutsideRange={() => false}
          required
        />
      </fieldset>

      <fieldset>
        <FormLabel
          htmlFor={'timezone'}
          message={intl.formatMessage(messages.timezone)}
        />
        <Field
          id="timezone"
          name="timezone"
          component={TimezonePicker}
          required
        />
      </fieldset>

      {!isCreateForm && (
        <fieldset>
          <FormLabel
            htmlFor={'tracks'}
            message={intl.formatMessage(messages.tracks)}
          />
          <FieldArray id="tracks" name="tracks" component={TracksInput} />
        </fieldset>
      )}

      {!isCreateForm && (
        <fieldset>
          <FormLabel
            htmlFor={'embedLayout'}
            message={intl.formatMessage(messages.embedLayout)}
          />
          <br />
          <Field
            id="embedLayout"
            name="embedLayout"
            component={LayoutPicker}
            required
          />
        </fieldset>
      )}

      {!isCreateForm && (
        <fieldset>
          <FormLabel
            htmlFor={'mobileEmbedLayout'}
            message={intl.formatMessage(messages.mobileEmbedLayout)}
          />
          <br />
          <Field
            id="mobileEmbedLayout"
            name="mobileEmbedLayout"
            component={LayoutPicker}
            required
          />
        </fieldset>
      )}

      {!isCreateForm && (
        <fieldset>
          <FormLabel
            htmlFor={'sessionTypes'}
            message={intl.formatMessage(messages.sessionTypes)}
          />
          <FieldArray
            id="sessionTypes"
            name="sessionTypes"
            component={SessionTypesInput}
          />
        </fieldset>
      )}

      <fieldset>
        <Button disabled={pristine || submitting}>
          <FormattedMessage {...messages.save} />
        </Button>
      </fieldset>
    </Form>
  );
};

ConferenceForm.propTypes = {
  isCreateForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: intlShape.isRequired,
};

const selectFormValue = formValueSelector('conference');

export default compose(
  reduxForm({
    form: 'conference',
    validate: values => {
      const errors = {};

      if (
        !moment.isMoment(values.getIn(['dates', 'startDate'])) ||
        !moment.isMoment(values.getIn(['dates', 'endDate']))
      ) {
        errors.dates = 'Please, enter the start and end date.';
      }

      if (!values.get('timezone')) {
        errors.timezone = 'Please, select a timezone.';
      }

      return errors;
    },
    initialValues: fromJS({
      timezone: 'America/New_York',
      sessionTypes: [
        {
          name: 'Talk',
          color: '#248bd1',
        },
      ],
    }),
  }),
  connect(state => ({
    handle: selectFormValue(state, 'id') || '',
    name: selectFormValue(state, 'name') || '',
  })),
  injectIntl
)(ConferenceForm);
