import firebase from 'firebase/app';

export default function* saveFacebookGreetingMessage(
  conferenceHandle,
  greetingMessage
) {
  yield firebase.functions().httpsCallable('saveFacebookGreetingMessage')({
    conferenceHandle,
    greetingMessage,
  });
}
