import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { Field } from 'redux-form/immutable';
import ThemeColorInput from '../../ColorInput';
import { session } from '../../../mocks/session';
import FormLabel from '../../FormLabel';
import Tags from '../../Tags';
import { FieldWrapper, InputWrapper, PreviewWrapper } from '../common';

const TagsThemeInput = ({ messages, intl }) => (
  <InputWrapper>
    <FieldWrapper>
      <FormLabel htmlFor={'tags'} message={intl.formatMessage(messages.tags)} />

      <Field
        id="tag.backgroundColor"
        name="tag.backgroundColor"
        label={intl.formatMessage(messages.tagBackgroundColor)}
        component={ThemeColorInput}
      />

      <Field
        id="tag.color"
        name="tag.color"
        label={intl.formatMessage(messages.tagColor)}
        component={ThemeColorInput}
      />
    </FieldWrapper>
    <PreviewWrapper>
      <Tags tags={session.get('tags')} />
    </PreviewWrapper>
  </InputWrapper>
);

TagsThemeInput.propTypes = {
  messages: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(TagsThemeInput);
