import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'recompose';
import { ellipsisText } from '../../utils/ui/content';

const ButtonList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonItem = styled.div`
  flex-basis: content;
  padding: 1em 0.1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 6em;
  max-width: 6em;
  width: 6em;
  margin: 0 0.5em;
  box-shadow: ${props => props.theme.shadows.formElement};
  border: ${props => props.theme.borders.formElement};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.lightGray : 'transparent'};
  border-radius: 3px;

  :first-child {
    margin-left: 0;
  }

  :nth-last-child(2) {
    margin-right: 0;
  }
`;

const ButtonLabel = styled.div`
  margin-top: 0.5em;
  display: flex;
  align-self: stretch;
  justify-content: center;

  > span {
    align-self: center;
    ${ellipsisText};
  }
`;

const ButtonIcon = styled.img`
  height: 1.7em;
`;

const ButtonListInputControl = ({
  items,
  keyExtractor,
  name,
  value,
  intl,
  onChange,
  labelKey = 'label',
  valueKey = 'value',
  iconKey = 'icon',
}) => {
  const handleClick = value => () => onChange(value);

  return (
    <ButtonList>
      {items.map((item, index) => (
        <ButtonItem
          key={keyExtractor(item, index)}
          onClick={handleClick(item[valueKey])}
          selected={item[valueKey] === value}
        >
          <ButtonIcon src={item[iconKey]} />
          <ButtonLabel>
            <span>{intl.formatMessage(item[labelKey])}</span>
          </ButtonLabel>
        </ButtonItem>
      ))}
      <input type="hidden" id={name} name={name} value={value || ''} />
    </ButtonList>
  );
};

ButtonListInputControl.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  keyExtractor: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default compose(
  injectIntl,
  withTheme
)(ButtonListInputControl);
