/**
 *
 * ProfilesView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FacebookIcon from '../icons/FacebookIcon';
import GithubIcon from '../icons/GithubIcon';
import GoogleIcon from '../icons/GoogleIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import TwitterIcon from '../icons/TwitterIcon';
import InstagramIcon from '../icons/InstagramIcon';

import SocialLink from './SocialLink';

const Container = styled.div`
  display: flex;
`;

const iconFor = {
  facebook: FacebookIcon,
  github: GithubIcon,
  google: GoogleIcon,
  linkedIn: LinkedInIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
};

const ProfilesView = ({ profiles, ...props }) => (
  <Container {...props}>
    {profiles.map((profile, i) => (
      <SocialLink
        key={i}
        size={43}
        SocialIcon={iconFor[profile.get('icon')]}
        iconName={profile.get('icon')}
        link={profile.get('link')}
      />
    ))}
  </Container>
);

ProfilesView.propTypes = {
  profiles: PropTypes.any.isRequired,
};

export default ProfilesView;
