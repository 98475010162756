import styled from 'styled-components';

import { button } from '../../utils/ui/interactive';

export default backgroundColor => component => styled(component)`
  font-size: 14px;
  padding: 12px;
  margin-bottom: 20px;
  display: inline;
  width: 100%;

  ${button({ backgroundColor, darken: true })};
`;
