import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../buttons/Button';
import Select from 'react-select';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;

  & > .Select {
    flex: 1;
  }
`;

class TextSelectWithCopyToClipboardButton extends React.Component {
  state = {
    copied: false,
    selectedOption: {
      id: '',
    },
  };

  handleSelect = selectedOption => {
    this.setState({ selectedOption, copied: false });
  };

  onChange = ({ target: { value } }) => {
    this.setState({ value, copied: false });
  };

  onCopy = () => {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 2000);
  };

  render() {
    return (
      <Wrapper>
        <Select
          menuPlacement="auto"
          clearable={false}
          options={this.props.options}
          value={this.state.selectedOption.id}
          onChange={this.handleSelect}
          labelKey="name"
          valueKey="id"
          placeholder="Select a track"
        />

        <CopyToClipboard
          text={
            this.props.addedValue
              ? `${this.props.value}${this.state.selectedOption.id}${
                  this.props.addedValue
                }${this.props.stringSuffix}`
              : `${this.props.value}${this.state.selectedOption.id}${
                  this.props.stringSuffix
                }`
          }
          onCopy={this.onCopy}
        >
          <Button
            label={this.state.copied ? 'Copied' : 'Copy'}
            icon="link"
            type="secondary"
            style={{ height: '42px', padding: '0.55em 0.7em' }}
          />
        </CopyToClipboard>

        <div />
      </Wrapper>
    );
  }
}

export default TextSelectWithCopyToClipboardButton;
