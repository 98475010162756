import { Component } from 'react';
import PropTypes from 'prop-types';

import { withSagaMiddleware } from '../providers/SagaMiddlewareProvider';

class Saga extends Component {
  componentWillMount() {
    const { sagaMiddleware, saga } = this.props;

    this.handle = sagaMiddleware.run(saga);
  }

  componentWillUnmount() {
    this.handle.cancel();
  }

  render() {
    return this.props.children || null;
  }
}

Saga.propTypes = {
  saga: PropTypes.any.isRequired,
};

export default withSagaMiddleware(Saga);
