/*
 *  CreateBreakPage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { selectCurrentConferenceHandle } from '../../selectors/user';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import createBreak from '../../sagas/user/breaks/create';
import BreakForm from '../../components/BreakForm';
import convertTime from '../../utils/convertTime';

export class CreateBreakPage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    location: PropTypes.object,
  };

  handleSubmit = async (data, dispatch, { form }) => {
    const { conferenceHandle } = this.props;
    const { title, description, day, time, isBlock } = data
      .updateIn(['time', 'startTime'], convertTime)
      .updateIn(['time', 'endTime'], convertTime)
      .toJS();

    const defaultUserRequestExecutor = this.props.createUserRequestExecutor({
      errorMessages: error => {
        switch ((error || {}).code) {
          case 'break/duration':
            return 'Break can not have 0 or negative duration. Please correct start/end time of this break.';
          case 'break/overlapping':
            return 'Break overlaps with another break. Please correct start/end time of this break.';
          default:
            return 'Please try again. If error persists, contact our support.';
        }
      },
    });

    await defaultUserRequestExecutor(createBreak, {
      conferenceHandle,
      title,
      description,
      day,
      time,
      isBlock,
    });
  };

  render() {
    const { conferenceHandle, match } = this.props;
    const { params } = match;
    const { day } = params;
    const initialValues = fromJS({ day });

    return (
      <div>
        <Helmet title="Create Break • Sava Events" />
        <BreakForm
          conferenceHandle={conferenceHandle}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          isCreateForm
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  conferenceHandle => ({
    conferenceHandle,
  })
);

export default compose(
  withRouter,
  withUserRequestExecutor,
  connect(mapStateToProps)
)(CreateBreakPage);
