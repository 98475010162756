import { defineMessages } from 'react-intl';

export default defineMessages({
  sponsorsEmptyState: {
    id: 'app.containers.SponsorsPage.sponsorsEmptyState',
    defaultMessage: 'No Sponsors Yet',
  },
  sponsorsEmptyStateHint: {
    id: 'app.containers.SponsorsPage.sponsorsEmptyStateHint',
    defaultMessage: 'Create a sponsor group to get started.',
  },
  sponsorCreate: {
    id: 'app.containers.SponsorsPage.sponsor.create',
    defaultMessage: 'Add Sponsor',
  },
  sponsorGroupCreate: {
    id: 'app.containers.SponsorsPage.sponsorGroup.create',
    defaultMessage: 'Create Sponsor Group',
  },
  sponsorCode: {
    id: 'app.containers.SponsorsPage.sponsorCode',
    defaultMessage: 'Get Sponsor Code',
  },
  tooltipTitle: {
    id: 'app.containers.SponsorsPage.tooltip.title',
    defaultMessage: 'Sponsors Page',
  },
  tooltipSectionOne: {
    id: 'app.containers.SponsorsPage.tooltip.section.1',
    defaultMessage:
      'First you create a sponsor group to manage multiple sponsors.',
  },
  tooltipSectionTwo: {
    id: 'app.containers.SponsorsPage.tooltip.section.2',
    defaultMessage:
      'You can reorganize your sponsors by clicking and dragging the card.',
  },
});
