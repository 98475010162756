import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form/immutable';
import { compose } from 'recompose';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

import messages, { followUpTranslationMessages } from './messages';
import {
  BROADCAST_BUTTON_LIMIT,
  BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT,
  BROADCAST_GENERIC_ITEM_TITLE_LIMIT,
  BROADCAST_LIST_ITEM_SUBTITLE_LIMIT,
  BROADCAST_LIST_ITEM_TITLE_LIMIT,
  BROADCAST_TEXT_LIMIT,
} from '../../constants';
import MessageTypeInput from '../MessageTypeInput';
import TextInput from './components/TextInput';
import ImageInput from './components/ImageInput';
import GalleryInput from './components/GalleryInput';

const MessageInput = ({ namePrefix, listName, type, value }) => {
  const sanitizedNamePrefix = namePrefix ? namePrefix : '';
  const getName = strings =>
    `${sanitizedNamePrefix ? `${sanitizedNamePrefix}.` : ''}${strings.join(
      ''
    )}`;

  return (
    <Fragment>
      <fieldset>
        <Field name={getName`type`} component={MessageTypeInput} />
      </fieldset>

      {type === 'text' && (
        <Field
          name={getName`message`}
          component={TextInput}
          props={{
            limit: BROADCAST_TEXT_LIMIT,
            messages,
          }}
        />
      )}

      {type === 'generic' && (
        <Field
          name={getName`item`}
          component={ImageInput}
          props={{
            name: getName`item`,
            fileRequired: true,
            subtitleRequired: true,
            buttonActionRequired: true,
            buttonLabelRequired: true,
            messages,
            titleLimit: BROADCAST_GENERIC_ITEM_TITLE_LIMIT,
            subtitleLimit: BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT,
            buttonLimit: BROADCAST_BUTTON_LIMIT,
            value: value.get('item', fromJS({})),
          }}
        />
      )}

      {type === 'followUp' && (
        <Field
          name={getName`item`}
          component={ImageInput}
          props={{
            name: getName`item`,
            fileRequired: false,
            subtitleRequired: true,
            buttonActionRequired: true,
            buttonLabelRequired: true,
            messages: followUpTranslationMessages,
            titleLimit: BROADCAST_GENERIC_ITEM_TITLE_LIMIT,
            subtitleLimit: BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT,
            buttonLimit: BROADCAST_BUTTON_LIMIT,
            value: value.get('item', fromJS({})),
          }}
        />
      )}

      {type === 'list' && (
        <FieldArray
          name={getName`items`}
          component={GalleryInput}
          props={{
            props: {
              fileRequired: true,
              buttonActionRequired: true,
              buttonLabelRequired: true,
              subtitleRequired: true,
              messages: messages,
              titleLimit: BROADCAST_LIST_ITEM_TITLE_LIMIT,
              subtitleLimit: BROADCAST_LIST_ITEM_SUBTITLE_LIMIT,
              buttonLimit: BROADCAST_BUTTON_LIMIT,
            },
          }}
          values={value.get('items', fromJS([]))}
        />
      )}
    </Fragment>
  );
};

MessageInput.propTypes = {
  type: PropTypes.string,
  namePrefix: PropTypes.string,
};

export default compose(injectIntl)(MessageInput);
