import { call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

export default function* authenticateUsingLinkedIn({ errorAction }) {
  try {
    const w = 400;
    const h = 600;

    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;

    yield call(
      () =>
        new Promise(resolve => {
          const popup = window.open(
            'ln-auth.html',
            'Login with LinkedIn',
            `toolbar=no, location=yes, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, 
          width=${w}, height=${h}, top=${top}, left=${left}`
          );

          const intervalId = window.setInterval(() => {
            if (popup && popup.closed) {
              window.clearInterval(intervalId);
              resolve();
            }
          }, 500);
        })
    );

    yield put(push('/'));
  } catch (error) {
    yield put(errorAction(error));
  }
}
