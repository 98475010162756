import { defineMessages } from 'react-intl';

export default defineMessages({
  breadcrumb: {
    id: 'app.containers.ChatBuilderPage.breadcrumb',
    defaultMessage: 'Conversation Automation',
  },
  helmet: {
    id: 'app.containers.ChatBuilderPage.helmet',
    defaultMessage: 'Conversation Automation',
  },
  personalized: [
    '{{user_first_name}}',
    '{{user_last_name}}',
    '{{user_full_name}}',
  ],
});
