/*
 * ConferencePage Container
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { Map, Range } from 'immutable';
import styled from 'styled-components';
import color from 'color';
import moment from 'moment-timezone';
import { compose } from 'recompose';
import messages from './messages';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import reorderTracks from '../../sagas/user/conferences/reorderTracks';
import { button } from '../../utils/ui/interactive';
import Breadcrumb from '../../components/Breadcrumb';
import ReorderableTrack from '../../components/Schedule/ReorderableTrack';
import schedule from '../../components/Schedule';
import ActionBar from '../../components/ActionBar';
import MultipleButtons from '../../components/buttons/MultipleButtons';
import Button from '../../components/buttons/Button';
import EntityEmptyState from '../../components/EntityEmptyState';
import emptyStateIllustration from '../../assets/images/empty-state-schedule.svg';

import ModalRoute from '../../components/ModalRoute';
import EmbedWidgetGuidePopup from '../../containers/EmbedWidgetGuidePopup';
import CreateSessionPage from '../../containers/CreateSessionPage';
import EditSessionPage from '../../containers/EditSessionPage';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
} from '../../selectors/user';
import { createSelector } from 'reselect';
import { selectProps } from '../../selectors/common';

const Schedule = schedule(ReorderableTrack);

export const DayPicker = styled(Flexbox)`
  flex-wrap: wrap;
`;

const NoTrackExists = DayPicker;

export const ButtonLink = styled(Link)`
  margin: 0;
  padding: 12px;

  margin-left: -1px;

  border: 1px solid rgba(0, 0, 0, 0.1);

  z-index: ${props => (props.data.active ? 30 : 10)};

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    z-index: 20;
  }

  ${props =>
    button({
      backgroundColor: color(
        props.data.active
          ? props.theme.navigation.activeBackgroundColor
          : props.theme.navigation.backgroundColor
      ),
      skipColorCreation: true,
    })};

  color: ${props =>
    props.data.active
      ? props.theme.navigation.activeColor
      : props.theme.navigation.color};
`;

class ConferencePage extends Component {
  static propTypes = {
    conferenceHandle: PropTypes.string.isRequired,
    conferenceName: PropTypes.string,
    conferenceDays: PropTypes.any,
    day: PropTypes.number.isRequired,
    tracks: PropTypes.any,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static provideLink = (route, { conferenceHandle, day, sessionId } = {}) => {
    switch (route) {
      case 'session':
        return `/@${conferenceHandle}/days/${day}/sessions/${sessionId}`;
      case 'newSession':
        return `/@${conferenceHandle}/days/${day}/sessions/new`;
      default:
        return undefined;
    }
  };

  defaultUserRequestExecutor = this.props.createUserRequestExecutor({
    onEnd: () => this.setState({ dragging: false }),
  });

  static handleDrag() {
    this.setState({
      dragging: true,
    });
  }

  static handleMove(from, to) {
    const { tracks } = this.state;

    this.setState({
      tracks: tracks.set(to, tracks.get(from)).set(from, tracks.get(to)),
    });
  }

  static async handleDrop() {
    await this.defaultUserRequestExecutor(reorderTracks, {
      conferenceHandle: this.props.conferenceHandle,
      tracks: this.state.tracks.map(track => track.get('id')).toJS(),
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      tracks: props.tracks,
    };

    this.handleDrag = ConferencePage.handleDrag.bind(this);
    this.handleMove = ConferencePage.handleMove.bind(this);
    this.handleDrop = ConferencePage.handleDrop.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.dragging) {
      this.setState({
        tracks: nextProps.tracks,
      });
    }
  }

  render() {
    const {
      conferenceHandle,
      conferenceName,
      conferenceDays,
      day,
      match,
      intl,
    } = this.props;

    const { tracks } = this.state;

    if (conferenceName && conferenceDays && tracks) {
      return (
        <div>
          <Helmet title={conferenceName} />
          <Breadcrumb key={day} to={`/@${conferenceHandle}/days/${day}`}>
            Day {day + 1}
          </Breadcrumb>
          <Flexbox flexDirection="column">
            <ActionBar justifyContent="space-between" flexDirection="row">
              <DayPicker>
                {conferenceDays.map((d, i) => (
                  <ButtonLink
                    key={i}
                    to={`/@${conferenceHandle}/days/${i}`}
                    data={{ active: day === i }}
                  >
                    Day {i + 1}
                  </ButtonLink>
                ))}
              </DayPicker>
              <MultipleButtons>
                <Button
                  to={`/@${conferenceHandle}/m/q`}
                  target="_blank"
                  rel="noopener noreferrer"
                  type="secondary"
                  label="See Session Questions"
                />
                <Button
                  to={`/@${conferenceHandle}/days/${day}/sessions/embed`}
                  type="secondary"
                  label="Get Schedule Code"
                  icon="code"
                />
              </MultipleButtons>
            </ActionBar>

            {tracks && tracks.count() === 0 && (
              <EntityEmptyState
                illustration={emptyStateIllustration}
                title={intl.formatMessage(messages.tracksEmptyState)}
                hint={intl.formatMessage(messages.tracksEmptyStateHint)}
              >
                {tracks && tracks.count() === 0 ? (
                  <NoTrackExists>
                    <Button
                      to={`/@${conferenceHandle}/settings/event`}
                      data={{}}
                      label="Add Tracks to get started!"
                      type="primary"
                      icon="add"
                    />
                  </NoTrackExists>
                ) : null}
              </EntityEmptyState>
            )}

            <Schedule
              conferenceHandle={conferenceHandle}
              linkProvider={ConferencePage.provideLink}
              day={day}
              tracks={tracks}
              onDrag={this.handleDrag}
              onMove={this.handleMove}
              onDrop={this.handleDrop}
            />
          </Flexbox>

          <ModalRoute
            exact={true}
            path={`${match.path}/sessions/embed`}
            params={{ context: 'schedule' }}
            component={EmbedWidgetGuidePopup}
          />
          <ModalRoute
            exact={true}
            path={`${match.path}/sessions/new`}
            component={CreateSessionPage}
          />
          <ModalRoute
            exact={true}
            path={`${match.path}/sessions/:id`}
            except={[
              `${match.path}/sessions/embed`,
              `${match.path}/sessions/new`,
            ]}
            component={EditSessionPage}
          />
        </div>
      );
    }

    return (
      <div>
        <Helmet title="Conference Not Found" />
        <h1>Conference Not Found</h1>
      </div>
    );
  }
}

const toMoment = day => time => {
  const [hours, minutes] = time.split(':', 2).map(n => parseInt(n, 10));

  return day
    .clone()
    .hours(hours)
    .minutes(minutes)
    .milliseconds(0);
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectProps,
  (conferenceHandle, conference, { match }) => {
    const params = match.params || {};
    const currentDay = parseInt(params.day, 10);
    const [firstDateStr, durationStr] = conference
      .get('dates', '')
      .split(',', 2);
    const [year, month, day] = firstDateStr
      .split('/', 3)
      .map(n => parseInt(n, 10));
    const dates = Range(0, parseInt(durationStr, 10)).map(i =>
      moment([year, month - 1, day]).add(i, 'days')
    );

    const tracks = conference
      .get('tracks', Map())
      .entrySeq()
      .map(([k, v]) => v.set('id', k));

    const toThisDaysMoment = toMoment(dates.get(currentDay));

    return {
      conferenceHandle,
      conferenceName: conference.get('name'),
      conferenceDays: dates,
      day: currentDay,
      tracks: tracks
        .map(track =>
          track.set(
            'sessions',
            conference
              .get('sessions', Map())
              .entrySeq()
              .map(([k, v]) => v.set('id', k))
              .toList()
              .filter(session => session.get('day') === currentDay)
              .filter(session => session.get('track') === track.get('id'))
              .map(session =>
                session
                  .merge(session.get('time'))
                  .delete('time')
                  .update('startTime', toThisDaysMoment)
                  .update('endTime', toThisDaysMoment)
                  .update('speakers', Map(), speakers =>
                    speakers
                      .keySeq()
                      .map(id =>
                        conference.getIn(['speakers', id], Map()).set('id', id)
                      )
                      .toList()
                  )
                  .update('sessionType', id =>
                    conference.getIn(['sessionTypes', id], Map()).set('id', id)
                  )
              )
              .sortBy(session => session.get('startTime').valueOf())
          )
        )
        .toList(),
    };
  }
);

export default compose(
  connect(mapStateToProps),
  withUserRequestExecutor,
  injectIntl
)(ConferencePage);
