import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ChatInput from './ChatInput';
import RTUpdater from '../../../utils/RTUpdater';
import MessengerConversation from '../../../components/MessengerPreviewMocks/MessengerConversation';
import addMessage from '../../../sagas/user/supportinbox/addMessage';
import { ScrollableBox } from '../common';
import transformRegexMessage from '../domain/transformRegexMessage';
const Updater = new RTUpdater();

const RTWrapper = styled.div``;

export default class RTChat extends React.Component {
  state = { messages: {}, sortedLog: [], logGroups: [], chatInput: '' };

  componentDidMount = () => {
    if (this.props.ticketId) {
      this.subscribe();
    }
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  subscribe = () => {
    const { conferenceHandle, ticketId } = this.props;
    Updater.subscribe(this.onMessage);
    Updater.start(
      `conferences/${conferenceHandle}/ticketMessageLogs/${ticketId}`,
      { onAdded: true, onRemoved: true, onChanged: true }
    );
  };

  unsubscribe = () => {
    this.setState({ messages: {}, sortedLog: [], logGroups: [] });
    Updater.stop();
    Updater.unsubscribe();
  };

  componentDidUpdate = prevProps => {
    if (this.props.ticketId === undefined) {
      this.unsubscribe();
    } else if (
      this.props.ticketId &&
      this.props.ticketId !== prevProps.ticketId
    ) {
      this.unsubscribe();
      this.subscribe();
    }
  };

  onMessage = (type, message) => {
    if (type === 'add') {
      this.setState(
        state => ({
          messages: { ...state.messages, [message.id]: message },
          sortedLog: state.sortedLog.concat(message.id),
        }),
        this.bucketize
      );
    }
  };

  bucketize = () => {
    const buckets = [];
    let bucket = [];
    let sentBySupportAgent = false;
    this.state.sortedLog.forEach(key => {
      if (this.state.messages[key].sentBySupportAgent === sentBySupportAgent) {
        bucket.push(this.state.messages[key]);
      } else {
        buckets.push(bucket);
        sentBySupportAgent = this.state.messages[key].sentBySupportAgent;
        bucket = [this.state.messages[key]];
      }
    });
    buckets.push(bucket);
    this.setState({ logGroups: buckets }, this.scrollToBottom);
  };

  handleChatInputChange = e => {
    this.setState({ chatInput: e.target.value });
  };

  handleSubmit = () => {
    if (this.state.chatInput.length > 0) {
      this.props.createUserRequestExecutor(
        addMessage,
        this.state.chatInput,
        this.props.conferenceHandle,
        this.props.ticketId,
        this.props.currentUserId
      );
      this.setState({ chatInput: '' });
    }
  };

  scrollToBottom = () => this.el.scrollIntoView({ behavior: 'smooth' });

  render() {
    const conversations = this.state.logGroups.map(group => ({
      type: group[0]
        ? group[0].sentBySupportAgent
          ? 'user'
          : 'platform'
        : 'user',
      messages: group.map(_ => ({
        ..._,
        content: transformRegexMessage(_.content),
      })),
    }));

    return (
      <RTWrapper>
        <ScrollableBox
          style={{
            height: '570px',
            alignItems: 'center',
            border: '1px solid rgba(5, 26, 31, 0.05)',
            borderRadius: '0.25em',
          }}
        >
          <MessengerConversation width={90} conversation={conversations} />
          <div
            ref={el => {
              this.el = el;
            }}
          />
        </ScrollableBox>
        {!this.props.archived && (
          <ChatInput
            value={this.state.chatInput}
            onChange={this.handleChatInputChange}
            onSubmit={this.handleSubmit}
          />
        )}
      </RTWrapper>
    );
  }
}

RTChat.propTypes = {
  ticketId: PropTypes.string,
  conferenceHandle: PropTypes.string,
};
