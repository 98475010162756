import firebase from 'firebase/app';

import { buffers, eventChannel, END } from 'redux-saga';
import { fromJS } from 'immutable';

export default context => id =>
  eventChannel(emitter => {
    const reference = firebase
      .database()
      .ref(`${context}/${id}`.replace(/\/+/g, '/'));

    const handler = value =>
      emitter((value.val() || false) && fromJS(value.val()).set('id', id));

    const errorHandler = (...args) => {
      console.error(...args);
      emitter(END);
    };

    reference.on('value', handler, errorHandler);

    return () => reference.off('value', handler);
  }, buffers.sliding(5));
