import { defineMessages } from 'react-intl';

export default defineMessages({
  info: {
    id: 'app.components.ForgotPasswordForm.info',
    defaultMessage:
      'Fill the form below, and we’ll send you an email with instructions to reset your password.',
  },
  email: {
    id: 'app.components.ForgotPasswordForm.email',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'app.components.ForgotPasswordForm.emailPlaceholder',
    defaultMessage: 'e.g., jhn@doe.com',
  },
  requestReset: {
    id: 'app.components.ForgotPasswordForm.requestReset',
    defaultMessage: 'Reset Password',
  },
});
