import React from 'react';
import { Tabs } from 'react-tabs';
import styled from 'styled-components';

const Root = styled.div`
  flex: 1 1 50%;
  margin: 0 0.75em;

  .react-tabs {
  }

  .react-tabs__tab-list {
    margin: 0 0 10px;
    padding: 0;
  }

  .react-tabs__tab {
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 0.25em;
    margin-right: 0.25em;
    background-color: #eff4f7;
  }

  .react-tabs__tab--selected {
    background: ${props => props.theme.colors.brandPrimary};
    color: white;
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;

export default props => (
  <Root>
    <Tabs {...props} />
  </Root>
);
