export async function componentWillMount() {
  const {
    messengerBroadcastId,
    setReached,
    conferenceHandle,
    getBroadcastReached,
    sagaMiddleware,
  } = this.props;
  sagaMiddleware.run(
    getBroadcastReached,
    messengerBroadcastId,
    setReached,
    conferenceHandle
  );
}
