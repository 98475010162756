export default (width, height) => aspectRatio => {
  const frameAspectRatio = width / height;

  const frameIsWider = frameAspectRatio > aspectRatio;

  return {
    width: frameIsWider ? width : height * aspectRatio,
    height: frameIsWider ? width / aspectRatio : height,
  };
};
