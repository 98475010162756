import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Flexbox from 'flexbox-react';
import { minimalTimezoneSet } from 'compact-timezone-list';
import styled from 'styled-components';
import FormError from '../FormError';

const Item = styled(Flexbox)`
  height: 42px;
`;

const ItemView = ({ label }) => (
  <Item flexDirection="column" justifyContent="center">
    {label}
  </Item>
);

const TimezonePickerComponent = ({ input, meta: { touched, error } }) => (
  <div>
    {touched && error && <FormError>{error}</FormError>}
    <Select
      clearable={false}
      searchable={false}
      options={minimalTimezoneSet}
      optionRenderer={ItemView}
      matchProp="value"
      labelKey="label"
      valueKey="tzCode"
      value={input && input.value ? input.value : undefined}
      valueRenderer={ItemView}
      onChange={timezone =>
        input.onChange(timezone ? timezone.tzCode : undefined)
      }
    />
  </div>
);

TimezonePickerComponent.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
};

export default TimezonePickerComponent;
