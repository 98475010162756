import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';

import EntityEmptyState from '../../components/EntityEmptyState';
import Icon from '../../components/Icon';

const ListWrapper = styled(Flexbox)``;

const ItemWrapper = styled(Flexbox)`
  ${props => `
    font-size: ${props.theme.sizes.base}px;
    border-bottom: 1px solid ${props.theme.colors.lightGray};`} padding: 5px;
`;

const DragHandleWrapper = styled.div`
  padding-right: 10px;
  ${props => `color: ${props.theme.colors.gray};`} cursor: move;
`;

export const DefaultItemWrapper = ({ children }) => (
  <ItemWrapper alignItems={'center'}>{children}</ItemWrapper>
);

const DragHandle = SortableHandle(() => (
  <DragHandleWrapper>
    <Icon>drag_handle</Icon>
  </DragHandleWrapper>
));

/**
 * Give items, key where order number is kept and callback function.
 * It returns new array with new order numbers.
 *
 * @param items
 * @param orderKey
 * @param onSortEnd
 * @returns {function({oldIndex?: *, newIndex?: *}): Array}
 */
const onSortEnd = (items, orderKey = 'order', onSortEnd) => ({
  oldIndex,
  newIndex,
}) =>
  onSortEnd(
    arrayMove(items, oldIndex, newIndex).map((item, index) => ({
      ...item,
      [orderKey]: index + 1,
    }))
  );

const List = (ItemWrapper = DefaultItemWrapper) => ({
  sortable = false,
} = {}) => {
  const renderItem = ({ component: Component, keyExtractor, sortable }) => (
    item,
    index
  ) => {
    const Item = ({ item, sortable }) => (
      <ItemWrapper>
        {sortable && <DragHandle />}
        <Component item={item} index={index} />
      </ItemWrapper>
    );
    const ItemComponent = sortable
      ? SortableElement(props => <Item {...props} />)
      : props => <Item {...props} />;

    return (
      <ItemComponent
        item={item}
        key={keyExtractor(item)}
        index={index}
        sortable={sortable}
      />
    );
  };

  const list = ({
    items,
    component,
    keyExtractor,
    noItemsTitle = 'No items added, yet',
    noItemsHint,
    noItemsIcon,
  }) => {
    return (
      <ListWrapper flexDirection="column">
        {items.map(renderItem({ component, keyExtractor, sortable }))}
        {!items.length && (
          <EntityEmptyState
            icon={noItemsIcon}
            title={noItemsTitle}
            hint={noItemsHint}
          />
        )}
      </ListWrapper>
    );
  };

  list.propTypes = {
    items: PropTypes.array.isRequired,
    keyExtractor: PropTypes.func.isRequired,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
      .isRequired,
  };

  const ListComponent = sortable ? SortableContainer(list) : list;
  return props => (
    <ListComponent
      {...props}
      useDragHandle={sortable}
      onSortEnd={onSortEnd(props.items, props.orderKey, props.onSortEnd)}
    />
  );
};

export const DefaultList = List();

export default List;
