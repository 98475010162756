import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, setPropTypes } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import { createSelector } from 'reselect';

import {
  selectCurrentConferenceHandle,
  selectUser,
  selectUserData,
} from '../../selectors/user';

import messages from './messages';
import ActionLink from '../../components/ActionLink';
import Breadcrumb from '../../components/Breadcrumb';
import ProfilePicture from '../../components/ProfilePicture';
import Icon from '../../components/Icon';
import { button } from '../../utils/ui/interactive';
import { logOut } from '../../actions/user';

const Root = styled(Flexbox)`
  padding: 1rem;
`;

const Panel = styled(Flexbox)`
  margin: 1rem;
  padding: 2rem;
  background: white;

  ${props => props.theme.altitude(1)};
`;

const P = styled.p`
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const LogoutButton = styled(ActionLink)`
  padding: 12px;
  margin: 0;

  & > i {
    vertical-align: -25%;
  }

  ${button()};
`;

const Commit = styled.p`
  margin: 0;
  margin-bottom: 1rem;
`;

const NoteContent = styled.p`
  margin: 0;
`;

const NoteMetadata = styled.p`
  font-size: 0.8rem;
  margin: 0;
  margin-bottom: 1rem;
`;

const getBuildInfo = () => {
  try {
    return JSON.parse(process.env.REACT_APP_BUILD_INFO);
  } catch (e) {
    return null;
  }
};

const buildInfo = getBuildInfo();

const AccountPage = ({ conferenceHandle, user, userData }) => {
  const displayName = userData.fullName || user.displayName;

  return (
    <Root
      justifyContent="flex-start"
      alignContent="flex-start"
      alignItems="flex-start"
    >
      <Helmet
        title={`${userData.fullName ||
          user.displayName ||
          'Account'} • Sava Events`}
      />
      <FormattedMessage {...messages.yourAccount}>
        {message => (
          <Breadcrumb
            to={conferenceHandle ? `/@${conferenceHandle}/account` : '/account'}
          >
            {displayName || message}
          </Breadcrumb>
        )}
      </FormattedMessage>

      <Panel flexDirection="column" alignContent="center" alignItems="center">
        <ProfilePicture
          image={user.photoURL}
          color={userData.color || '#f0f0f0'}
          width={128}
          height={128}
          padding={0}
        />

        {displayName ? (
          <FormattedMessage
            {...messages.loggedInAs}
            values={{ displayName, email: user.email }}
          >
            {message => <P>{message}</P>}
          </FormattedMessage>
        ) : (
          <FormattedMessage
            {...messages.loggedInAsEmailOnly}
            values={{ email: user.email }}
          >
            {message => <P>{message}</P>}
          </FormattedMessage>
        )}

        <LogoutButton action={logOut}>
          <Icon>exit_to_app</Icon>{' '}
          <FormattedMessage {...messages.logOut}>
            {message => message}
          </FormattedMessage>
        </LogoutButton>
      </Panel>

      {buildInfo && (
        <Panel flexDirection="column" alignContent="center" alignItems="center">
          <h3>Build Info</h3>
          <dl>
            <dt>Commit</dt>
            <dd>
              <Commit>{buildInfo.commit || '?'}</Commit>
            </dd>
            <dt>Notes</dt>
            <dd>
              {(buildInfo.notes || []).length > 0 ? (
                <ul>
                  {buildInfo.notes.map(note => (
                    <li>
                      <NoteContent>{note.content}</NoteContent>
                      <NoteMetadata>
                        {note.author} ({note.commit.substring(0, 8)})
                      </NoteMetadata>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>
                  Nothing worth mentioning.{' '}
                  <span role="img" aria-label="wondering">
                    🤔
                  </span>
                </p>
              )}
            </dd>
          </dl>
        </Panel>
      )}
    </Root>
  );
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectUser(),
  selectUserData,
  (conferenceHandle, user, userData) => ({
    conferenceHandle,
    user: user.toJS(),
    userData: userData.toJS(),
  })
);

export default compose(
  withRouter,
  connect(mapStateToProps),
  setPropTypes({
    conferenceHandle: PropTypes.string,
    user: PropTypes.any.isRequired,
    userData: PropTypes.any.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        conferenceHandle: PropTypes.string,
      }),
    }).isRequired,
  })
)(injectIntl(AccountPage));
