import React, { Fragment } from 'react';
import { Field } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';

import SelectInputControl from '../SelectInputControl';

const SelectInput = ({
  label,
  name,
  values,
  labelKey = 'label',
  valueKey = 'value',
}) => {
  const getLabel = label => {
    return label && label.id && label.defaultMessage ? (
      <FormattedMessage {...label} />
    ) : (
      label
    );
  };
  const items = values.map(option => ({
    value: option.value,
    label: getLabel(option.label),
  }));

  const Select = SelectInputControl(() => items, { labelKey, valueKey });

  return (
    <Fragment>
      <label htmlFor={name}>
        <FormattedMessage {...label} />
      </label>
      <Field name={name} component={Select} />
    </Fragment>
  );
};

export default SelectInput;
