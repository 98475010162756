import firebase from 'firebase/app';

export default function* saveSubmissionForm(
  conferenceHandle,
  submissionFormBlocks,
  submissionFormConfig
) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/cfp/submissionFormBlocks`)
    .set(submissionFormBlocks);

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/cfp/submissionFormConfig`)
    .set(submissionFormConfig);
}
