import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import PropTypes from 'prop-types';
import React from 'react';
import { NAV_HEIGHT, MENU_WIDTH, topbarBg } from './constants';

const TopBarWrapper = styled.div`
  position: fixed;
  font-size: 18px;
  height: ${NAV_HEIGHT}px;
  top: 0;
  left: ${MENU_WIDTH}px;
  right: 0;
  z-index: 800;
  background: ${topbarBg.string()};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TopBar = ({ children }) => (
  <TopBarWrapper>
    <Flexbox flexDirection="row">{children}</Flexbox>
  </TopBarWrapper>
);

TopBar.propTypes = {
  children: PropTypes.any,
};

export default TopBar;
