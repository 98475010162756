const randomColor = () =>
  `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
    Math.random() * 255
  )}, ${Math.floor(Math.random() * 255)})`;

export const randomColorWithCache = cache => id => {
  if (!cache[id]) {
    cache[id] = randomColor();
  }
  return cache[id];
};

export default randomColor;
