import React from 'react';

export default Component =>
  class WithBodySizes extends React.Component {
    static getBodySizes() {
      return {
        offsetWidth: document.body.offsetWidth,
        offsetHeight: document.body.offsetHeight,
        clientWidth: document.body.clientWidth,
        clientHeight: document.body.clientHeight,
        scrollWidth: document.body.scrollWidth,
        scrollHeight: document.body.scrollHeight,
      };
    }

    constructor(props) {
      super(props);

      this.state = {
        body: WithBodySizes.getBodySizes(),
      };

      this.handleResize = this.handleResize.bind(this);
    }

    componentWillMount() {
      this.setState({
        body: WithBodySizes.getBodySizes(),
      });

      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
      this.setState({
        body: WithBodySizes.getBodySizes(),
      });
    }

    render() {
      return <Component body={this.state.body} {...this.props} />;
    }
  };
