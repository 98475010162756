import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Helmet from 'react-helmet';
import Flexbox from 'flexbox-react';
import styled, { withTheme } from 'styled-components';
import { compose, setPropTypes } from 'recompose';

import { withSagaMiddleware } from '../../providers/SagaMiddlewareProvider';

import logIn from '../../sagas/user/session/logIn';

import Auth from '../Auth';
import ActionLink from '../../components/ActionLink';
import LoginForm from '../../components/LoginForm';
import GoogleIcon from '../../components/icons/GoogleIcon';
import FacebookIcon from '../../components/icons/FacebookIcon';
import LinkedInIcon from '../../components/icons/LinkedInIcon';

import GlobalMessages from '../../components/GlobalMessages';
import GoogleButton from '../../components/buttons/GoogleButton';
import FacebookButton from '../../components/buttons/FacebookButton';
import LinkedInButton from '../../components/buttons/LinkedInButton';

import {
  initiateGoogleAuth,
  initiateFacebookAuth,
  initiateLinkedInAuth,
  logInError,
} from '../../actions/user';

import { selectError, selectSuccess } from '../../selectors/user';

const Title = styled.h1`
  text-align: center;
`;

const SubTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 2.125em;
  text-align: center;
  font-weight: 300;
`;

const FlexWrapper = styled(Flexbox)`
  background-color: #f5f5ff;
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
  background-size: 18%;
  box-shadow: 0 9px 17px 0 rgba(21, 11, 57, 0.15);
  padding: 2.125em 3.75em;
  text-align: center;
`;

const GoogleButtonActionLink = GoogleButton(ActionLink);
const FacebookButtonActionLink = FacebookButton(ActionLink);
const LinkedInButtonActionLink = LinkedInButton(ActionLink);

const LineThrough = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cccccc;
  line-height: 0.1em;
  margin: 10px 0 40px;

  span {
    color: #cccccc;
    font-weight: 300;
    font-size: 0.75em;
    background: #f5f5ff;
    padding: 0 20px;
  }
`;

const InfoMessage = styled.div`
  color: ${({ theme, color }) => theme.colors[color] || theme.colors['text']};
  marginbottom: 0.5em;
  width: 70%;
`;

export const LoginPage = ({
  theme,
  sagaMiddleware,
  location,
  error,
  resetPasswordSuccess,
}) => {
  const handleSubmit = async data => {
    const { email, password } = data.toJS();
    const sagaHandle = sagaMiddleware.run(logIn, {
      email,
      password,
    });
    await sagaHandle.done;
  };

  return (
    <Auth location={location}>
      <Helmet title="Log In • Sava Events" />
      <Title>Welcome! Login to your account.</Title>
      <FlexWrapper flexDirection="column">
        <SubTitle>Glad to see you back!</SubTitle>
        <GlobalMessages />
        <Flexbox flexDirection="column">
          <Flexbox
            flexGrow={2}
            flexShrink={1}
            flexDirection="column"
            alignItems="center"
          >
            {error && (
              <InfoMessage color="attention">{error.message}</InfoMessage>
            )}
            {resetPasswordSuccess && (
              <InfoMessage color="success">
                {resetPasswordSuccess.message}
              </InfoMessage>
            )}
            <LoginForm onSubmit={handleSubmit} />
          </Flexbox>
          <Flexbox
            flexDirection="column"
            flexGrow={1}
            flexShrink={2}
            alignItems="flex-start"
          >
            <LineThrough>
              <span>OR</span>
            </LineThrough>

            <GoogleButtonActionLink
              action={initiateGoogleAuth}
              param={logInError}
            >
              <div style={{ display: 'inline-block', verticalAlign: '-40%' }}>
                <GoogleIcon width={20} height={20} padding={0} color="white" />
              </div>{' '}
              Log In using Google
            </GoogleButtonActionLink>
            <FacebookButtonActionLink
              action={initiateFacebookAuth}
              param={logInError}
            >
              <div style={{ display: 'inline-block', verticalAlign: '-40%' }}>
                <FacebookIcon
                  width={20}
                  height={20}
                  padding={0}
                  color="white"
                  style={{ marginLeft: -4 }}
                />
              </div>{' '}
              Log In using Facebook
            </FacebookButtonActionLink>
            <LinkedInButtonActionLink
              action={initiateLinkedInAuth}
              param={logInError}
            >
              <div style={{ display: 'inline-block', verticalAlign: '-40%' }}>
                <LinkedInIcon
                  width={20}
                  height={20}
                  padding={0}
                  color="white"
                  style={{ marginLeft: -4 }}
                />
              </div>{' '}
              Log In using LinkedIn
            </LinkedInButtonActionLink>
          </Flexbox>
        </Flexbox>
      </FlexWrapper>
    </Auth>
  );
};

const mapStateToProps = createSelector(
  selectError(),
  selectSuccess(),
  (error, success) => ({
    error: error.login,
    resetPasswordSuccess: success.resetPassword,
  })
);

export default compose(
  connect(mapStateToProps),
  setPropTypes({
    location: PropTypes.object,
    error: PropTypes.object,
  }),
  withSagaMiddleware,
  withTheme
)(LoginPage);
