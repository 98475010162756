/*
 *  AddNewTeamMemberPage Container
 */

import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';

import { selectCurrentConferenceHandle } from '../../selectors/user';

import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import NewTeamMemberForm from '../../components/NewTeamMemberForm';

import addEditor from '../../sagas/user/conferences/addEditor';
import messages from '../../components/NewTeamMemberForm/messages';

export const AddNewTeamMemberPage = ({
  history,
  createUserRequestExecutor,
  conferenceHandle,
  intl,
}) => {
  const defaultUserRequestExecutor = createUserRequestExecutor({
    onSuccess: () => history.goBack(),
    errorMessages: error => intl.formatMessage(messages[error.code]),
  });

  const handleSubmit = async data => {
    await defaultUserRequestExecutor(
      addEditor,
      conferenceHandle,
      data.get('email')
    );
  };

  return (
    <Fragment>
      <Helmet title="Add new team member • Sava Events" />
      <NewTeamMemberForm onSubmit={handleSubmit} />
    </Fragment>
  );
};

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  conferenceHandle => ({ conferenceHandle })
);

export default compose(
  withRouter,
  withUserRequestExecutor,
  injectIntl,
  connect(mapStateToProps)
)(AddNewTeamMemberPage);
