import React, { Component } from 'react';
import styled from 'styled-components';
import Form from '../../../components/Form';
import Icon from '../../../components/Icon';
import { button } from '../../../utils/ui/interactive';
const Button = styled.button`
  padding: 12px;
  margin-top: 6px;
  ${button()};
`;

const Label = styled.label`
  color: ${p => p.theme.colors.gray};
  i {
    font-size: 18px;
    vertical-align: sub;
  }
`;

export default class CommentForm extends Component {
  state = { content: '' };

  handleChange = e => this.setState({ content: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.props.submissionId, this.state.content);
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <fieldset>
          <Label htmlFor="comment">
            <Icon>add_comment</Icon> Add Comment
            <textarea
              name="comment"
              style={{ height: '4em' }}
              required
              placeholder="Write a comment…"
              onChange={this.handleChange}
              value={this.state.content}
            />
            <Button disabled={!this.state.content}>Add Comment</Button>
          </Label>
        </fieldset>
      </Form>
    );
  }
}
