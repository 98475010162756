import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  renderNothing,
  lifecycle,
  withState,
} from 'recompose';
import { List, Map } from 'immutable';
import styled, { ThemeProvider } from 'styled-components';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withRouter } from 'react-router-dom';
import { toListOfMapsWithIds } from 'sava-shared/lib/utils/mapOfMapsToListOfMaps';

import responsive from '../../utils/ui/responsive';
import messagingClients from '../../constants/messagingClients';
import Speakers from '../../components/CompactSpeakers/index';
import withNow from '../../hocs/withNow';
import SavaPropTypes from '../../utils/SavaPropTypes';
import Announcements from '../embed/Announcements/index';
import { getAverage } from '../../utils/math/ratings';
import makeHeadline from '../../utils/makeHeadline';
import makeName from '../../utils/makeName';
import withUserRequestExecutor from '../../hocs/withUserRequestExecutor';
import getMessagingClientsData from '../../sagas/user/integrations/getMessagingClientsData';

const phone = `${process.env.PUBLIC_URL}/icons/hand_phone.svg`;
const stars = `${process.env.PUBLIC_URL}/icons/stars.svg`;

const listOfMessagingClients = toListOfMapsWithIds(messagingClients);

const Overlay = styled.div`
  -webkit-font-smoothing: antialiased;
  background-size: contain;
  background-position-y: -110%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: [tracks-start] 65% [tracks-end] 35% [sidebar-end];
  grid-column-gap: 2em;
  position: fixed;
  padding: 2em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: white;
  color: #16073c;
`;

const Tracks = styled.div`
  grid-column-start: tracks-start;
  grid-column-end: tracks-end;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  min-height: 0; /* NEW */
  min-width: 0;
  > .announcements {
    order: -1;
  }
`;

const Track = styled.div`
  overflow: hidden;
  min-width: 0;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.h2`
  border-top: 3px solid #4f4cd3;
  font-size: 2rem;
  margin: 0;
`;

const Talks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
`;

const Talk = styled.div`
  flex: 0 1 45%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const Time = styled.span`
  font-size: 2em;
`;

const TalkTitle = styled.h3`
  font-size: 1.5em;
  font-weight: 900;
  margin-top: 0.25em;
  margin-bottom: 0.5em;
  line-height: normal;

  ${responsive.lg.andSmaller`
    font-size: 1.3em;
  `};

  ${responsive.md.andSmaller`
    font-size: 1em;
  `};
`;

const Sidebar = styled.div`
  display: grid;
  grid-template-rows: [rated-start] 5fr [rated-end] 1fr [promo-end];
  grid-column-start: tracks-end;
  grid-column-end: sidebar-end;
`;

const Best = styled.div`
  grid-row-start: rated-start;
  grid-row-end: rated-end;
`;

const TalkScore = styled.div``;

const CompactSpeakers = ({ speakers }) => (
  <div>
    <p style={{ fontSize: '20px' }}>{makeName(speakers)}</p>
    <p style={{ fontSize: '16px' }}>
      {makeHeadline(speakers.map(speaker => speaker.get('headline')))}
    </p>
  </div>
);

CompactSpeakers.propTypes = {
  speakers: PropTypes.any,
};

const NoRatings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h2 {
    margin-bottom: 1em;
    font-size: 30px;
    width: 80%;
    text-align: center;
  }

  > img {
    margin: 3em 0em;
  }
`;

const Channels = styled.div``;

const Channel = styled.div`
  display: flex;
  align-items: center;
`;

const ChannelIcon = styled.div`
  width: 2em;
  margin-right: 1em;
`;

const ChannelLink = styled.span`
  flex: 1 0 5em;
`;

export const getChatLink = (client, data) =>
  data[client.id] &&
  (data[client.id][client.accessor] ||
    data[client.id][client.fallbackAccessor]);

export const generateChatLink = messagingClientsData => client => {
  const { data = {} } = messagingClientsData || {};
  const username = getChatLink(client, data);

  if (!username) {
    return undefined;
  }

  return { username, icon: client.icon, baseUrl: client.baseUrl };
};

const overlayTheme = {
  card: {
    backgroundColor: 'white',
  },
  page: {
    color: 'inherit',
  },
};

const OverlayPage = ({ now, tracks, best, messagingClientsData }) => (
  <ThemeProvider theme={overlayTheme}>
    <Overlay>
      <Tracks>
        {tracks
          .filter(track => track.get('talks').count() > 0)
          .take(3)
          .map(track => (
            <Track key={track.get('id')}>
              <SectionTitle>{track.get('name')}</SectionTitle>
              {track.get('talks').count() > 0 ? (
                <Talks>
                  {track
                    .get('talks')
                    .take(2)
                    .map(talk => (
                      <Talk key={talk.get('id')}>
                        <Time>
                          {talk.get('time').isBefore(now)
                            ? 'Now'
                            : talk.get('time').format('HH:mm')}
                        </Time>
                        <TalkTitle>{talk.get('title')}</TalkTitle>
                        <Speakers speakers={talk.get('speakers')} />
                      </Talk>
                    ))}
                </Talks>
              ) : (
                <p>None.</p>
              )}
            </Track>
          ))}
        <Announcements />
      </Tracks>
      {best.count() > 0 ? (
        <Sidebar>
          <Best>
            <SectionTitle>Most Rated Talks</SectionTitle>
            {best.count() > 0 ? (
              best.map(talk => (
                <TalkScore key={talk.get('id')}>
                  <Time>{talk.get('score').toFixed(1)}</Time>
                  <TalkTitle>{talk.get('title')}</TalkTitle>
                  <Speakers speakers={talk.get('speakers')} />
                </TalkScore>
              ))
            ) : (
              <NoRatings>
                <h2>Help speakers improve and rate their talks.</h2>
                <img src={stars} alt="rating" />
              </NoRatings>
            )}
          </Best>
          {messagingClientsData.length ? (
            <Channels>
              {messagingClientsData.map((messagingClient, index) => (
                <Channel key={index}>
                  <ChannelIcon>
                    <img
                      width="100%"
                      src={messagingClient.icon}
                      alt={messagingClient.username}
                    />
                  </ChannelIcon>
                  <ChannelLink>
                    {messagingClient.baseUrl}
                    <span>/</span>
                    {messagingClient.username}
                  </ChannelLink>
                </Channel>
              ))}
            </Channels>
          ) : null}
        </Sidebar>
      ) : (
        <Sidebar center>
          <Best center>
            <NoRatings>
              <SectionTitle style={{ marginBottom: '1em' }}>
                Help speakers improve and rate their talks.
              </SectionTitle>
              <img src={stars} alt="rating" />
            </NoRatings>
            {messagingClientsData.length ? (
              <Channels>
                <Channel>
                  {messagingClientsData.map((messagingClient, index) => (
                    <div key={index}>
                      <h3>
                        <img
                          style={{ width: '2em', marginRight: '1em' }}
                          src={messagingClient.icon}
                          alt={messagingClient.username}
                        />
                        {messagingClient.baseUrl}
                        <span>/</span>
                        {messagingClient.username}
                      </h3>
                    </div>
                  ))}
                </Channel>
                <Channel>
                  <img src={phone} alt="Bot phone" />
                </Channel>
              </Channels>
            ) : null}
          </Best>
        </Sidebar>
      )}
      {/* <Announcements /> */}
    </Overlay>
  </ThemeProvider>
);

OverlayPage.propTypes = {
  now: MomentPropTypes.momentObj,
  tracks: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string,
      name: PropTypes.string,
      talks: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          id: PropTypes.string,
          time: MomentPropTypes.momentObj,
          title: PropTypes.string,
          speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
        })
      ),
    })
  ),
  best: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.any,
      score: PropTypes.number,
      title: PropTypes.string,
      speakers: ImmutablePropTypes.listOf(SavaPropTypes.speaker),
    })
  ).isRequired,
};

const using = (...args) => f => f(...args);

const mapStateToProps = (state, props) => {
  if (!state.getIn(['embed', 'sessions'])) {
    return {};
  }

  const tomorrow = props.now
    .clone()
    .add(1, 'day')
    .startOf('day');

  const sessions = state
    .getIn(['embed', 'sessions'], List())
    .valueSeq()
    .toList();

  const filteredSessions = sessions
    .filter(session => session.get('endTime').isAfter(props.now))
    .filter(session => session.get('startTime').isBefore(tomorrow))
    .sortBy(session => session.get('startTime'));

  const tracks = state.getIn(['embed', 'tracks'], List()).map(track => {
    const talks = filteredSessions
      .filter(session => session.getIn(['track', 'id']) === track.get('id'))
      .map(talk =>
        Map({
          id: talk.get('id'),
          time: talk.get('startTime'),
          title: talk.get('title'),
          speakers: talk.get('speakers'),
        })
      );

    return Map({
      id: track.get('id'),
      name: track.get('name'),
      talks,
    });
  });

  const best = sessions
    .map(talk =>
      using(talk.get('ratings', List()))(ratings =>
        talk
          .set('score', getAverage(ratings.toJS()))
          .set('scores', ratings.count())
      )
    )
    .filter(talk => talk.get('scores') > 2)
    .sortBy(talk => talk.get('scores'))
    .reverse()
    .slice(0, 4)
    .sortBy(talk => talk.get('score'))
    .reverse()
    .map(talk =>
      Map({
        id: talk.get('id'),
        score: talk.get('score'),
        title: talk.get('title'),
        speakers: talk.get('speakers'),
      })
    );

  return { tracks, best };
};

const lifecycleMethods = {
  async componentDidMount() {
    const {
      conferenceHandle,
      createUserRequestExecutor,
      setMessagingClientsData,
    } = this.props;
    const defaultUserRequestExecutor = createUserRequestExecutor({
      shouldNotifyToastr: false,
    });

    const messagingClientsData = await defaultUserRequestExecutor(
      getMessagingClientsData,
      conferenceHandle
    );

    const result = listOfMessagingClients
      .map(generateChatLink(messagingClientsData))
      .filter(_ => !!_);

    setMessagingClientsData(result);
  },
};

export default compose(
  withUserRequestExecutor,
  withRouter,
  withNow,
  connect(mapStateToProps),
  withState('messagingClientsData', 'setMessagingClientsData', {}),
  branch(props => !props.tracks, renderNothing),
  lifecycle(lifecycleMethods)
)(OverlayPage);
