import firebase from 'firebase/app';

export default function* reorderSponsors(conferenceHandle, group, sponsors) {
  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsors`)
    .transaction(dbSponsors => {
      for (let i = 0, n = sponsors.length; i < n; i += 1) {
        const dbSponsor = dbSponsors[sponsors[i]];
        if (dbSponsor) {
          dbSponsor.order = i;
        }
      }
      return dbSponsors;
    });
}
