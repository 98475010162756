import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import styled from 'styled-components';
import messages from './messages';
import { link } from '../../utils/ui/interactive';

const Text = styled.p`
  color: #cccccc;
  font-size: 0.75em;
  text-decoration: none;
  font-weight: 400;
`;

const NavLink = styled.a`
  ${link({ color: '#CCCCCC', darken: true })};
`;

const Footer = ({ width, style }) => {
  const styles = Object.assign(style || {}, { width });

  return (
    <Flexbox
      alignItems="center"
      justifyContent="space-around"
      style={styles}
      flexDirection="row"
      flexWrap="wrap"
    >
      <Flexbox>
        <Text>
          <FormattedMessage {...messages.copyrightNotice} />
        </Text>
      </Flexbox>
      <Flexbox>
        <Text>
          Made with{' '}
          <span role="img" aria-label="Hands">
            {' '}
            👐{' '}
          </span>{' '}
          &{' '}
          <span role="img" aria-label="Heart">
            {' '}
            💙{' '}
          </span>{' '}
          in <NavLink href="http://spicefactory.co/"> SpiceFactory</NavLink>
          .
        </Text>
      </Flexbox>
    </Flexbox>
  );
};

Footer.propTypes = {
  width: PropTypes.string.isRequired,
  style: PropTypes.any,
};

export default injectIntl(Footer);
