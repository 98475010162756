import { put } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import firebase from 'firebase/app';
import { textToKey } from 'sava-shared/lib/utils/core';
import uploadFile from '../file/uploadFile';
import isNonEmptyFileList from '../../../utils/isNonEmptyFileList';

export default function* updateSponsor({
  conferenceHandle,
  id,
  name,
  description = '',
  image,
  group,
  profiles,
  form,
}) {
  let imageResponseData = {
    url: undefined,
    thumbUrl: undefined,
  };

  if (isNonEmptyFileList(image)) {
    imageResponseData = yield uploadFile(
      image[0],
      `/@${conferenceHandle}/sponsors/${id}`,
      { thumb: true }
    );
  }

  const sponsor = {
    name,
    profiles,
    group,
  };

  if (description) {
    sponsor.description = description;
  }

  if (imageResponseData.url) {
    sponsor.image = imageResponseData.url;
  }

  if (imageResponseData.thumbUrl) {
    sponsor.thumb = imageResponseData.thumbUrl;
  }

  if (group.id) {
    sponsor.group = group.id;
  } else {
    const key = textToKey(group.name);

    yield firebase
      .database()
      .ref(`conferences/${conferenceHandle}/sponsorGroups`)
      .update({ [key]: group });

    sponsor.group = key;
  }

  yield firebase
    .database()
    .ref(`conferences/${conferenceHandle}/sponsors/${id}`)
    .update(sponsor);

  yield put(goBack());
}
