import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  findEarliestStart,
  findLatestEnd,
  calcRowTemplate,
  getPosition,
} from '../../utils/schedule';
import { slugify } from 'sava-shared/lib/utils/core';
import Session from '../Session';
import Break from '../Break';
import emptyStatePastIllustration from '../../assets/images/coffee_breaks.svg';
import EntityEmptyState from '../EntityEmptyState';

const Schedule = styled.div`
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: ${props =>
      props.hasBreak && props.hasSide ? '[side] 35px' : ''} ${props =>
      props.tracks
        .map(
          (track, i) =>
            `[${slugify(track.get('id'))}${
              i === 0 ? ' first' : ''
            }] minmax(300px, 400px) ${
              i === props.tracks.size - 1 ? '[last] ' : ''
            }`
        )
        .toArray()};
  grid-template-rows: [track-header] 50px ${props => props.rowTemplate};
`;

const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${props => slugify(props.track)};
  grid-row: track-header;
`;

const SessionContainer = styled.div`
  background-color: salmon;
  grid-column: ${props => slugify(props.track)};
  grid-row: ${props => props.position};
  opacity: ${props => (props.isPast ? 0.5 : 1)};
`;

const BreakContainer = styled.div`
  color: royalblue;
  grid-column: ${props => (props.isBlock ? 'first / last' : 'side')};
  grid-row: ${props => props.position};
  opacity: ${props => (props.isPast ? 0.5 : 1)};
`;

const sessionsExist = tracks =>
  tracks.some(track => track.get('sessions').size > 0);

const GridSchedule = props => {
  const { conferenceHandle, linkProvider, tracks, breaks } = props;

  if (sessionsExist(tracks) || breaks.size > 0) {
    const allSessionsAndBreaks = tracks
      .flatMap(track => track.get('sessions'))
      .concat(breaks ? breaks : []); // eslint-disable-line

    const earliestStart = findEarliestStart(allSessionsAndBreaks);
    const latestEnd = findLatestEnd(allSessionsAndBreaks);
    const totalMinutes = latestEnd.diff(earliestStart, 'minutes');

    return (
      <Schedule
        tracks={tracks}
        hasBreak={!breaks.isEmpty()}
        hasSide={breaks.find(_ => !_.get('isBlock'))}
        rowTemplate={calcRowTemplate(earliestStart, breaks, totalMinutes)}
      >
        {tracks.map(track => (
          <TrackInfo key={track.get('id')} track={track.get('id')}>
            <strong>{track.get('name')}</strong>
            <i>{track.get('location')}</i>
          </TrackInfo>
        ))}

        {breaks.map(breakObj => (
          <BreakContainer
            key={breakObj.get('id')}
            isBlock={breakObj.get('isBlock')}
            position={getPosition(
              earliestStart,
              breakObj.get('startTime'),
              breakObj.get('duration')
            )}
            isPast={breakObj.get('isPast', false)}
          >
            <Break breakObj={breakObj} />
          </BreakContainer>
        ))}

        {tracks.map(track =>
          track.get('sessions').map(session => (
            <SessionContainer
              key={session.get('id')}
              track={track.get('id')}
              position={getPosition(
                earliestStart,
                session.get('startTime'),
                session.get('duration')
              )}
              isPast={session.get('isPast', false)}
            >
              <Session
                conferenceHandle={conferenceHandle}
                linkProvider={linkProvider}
                session={session}
              />
            </SessionContainer>
          ))
        )}
      </Schedule>
    );
  }

  return (
    <EntityEmptyState
      illustration={emptyStatePastIllustration}
      title={'No sessions for this day'}
      hint={`Currently there are no session for this day. But keep an eye for new ones.`}
    />
  );
};

GridSchedule.propTypes = {
  conferenceHandle: PropTypes.string,
  linkProvider: PropTypes.func,
  tracks: PropTypes.object,
  breaks: PropTypes.object,
};

export default GridSchedule;
