/**
 *
 * ConferenceWrapper.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Breadcrumb from '../../components/Breadcrumb';
import { Map } from 'immutable';
import NotFound from '../NotFoundPage';
import ConferenceSettingsIntegrationsPage from '../ConferenceSettingsIntegrationsPage';
import ConferenceSettingsEmbedCustomization from '../ConferenceSettingsThemeCustomization';
import ConferenceSettingsEventPage from '../ConferenceSettingsEventPage';
import ConferencePage from '../ConferencePage';
import SpeakersPage from '../SpeakersPage';
import ChatBuilderPage from '../ChatBuilderPage';
import ChatMenuPage from '../ChatMenuPage';
import ChatQuickRepliesPage from '../ChatQuickRepliesPage';
import ConferenceSettingsPage from '../ConferenceSettingsPage';
import AccountPage from '../AccountPage';
import ConferenceSettingsTeamPage from '../ConferenceSettingsTeamPage';
import ConferenceSettingsMobileAppCustomization from '../ConferenceSettingsMobileAppCustomization';
import SponsorsPage from '../SponsorsPage';
import BroadcastPage from '../BroadcastPage';
import BreaksPage from '../BreaksPage';
import ModeratorPage from '../ModeratorPage';
import ChatbotConfiguration from '../ChatbotConfiguration';
import SupportInbox from '../SupportInbox';
import CallForPapersPage from '../CallForPapersPage';
import CallForPapersFormBuilderPage from '../CallForPapersFormBuilderPage';
import {
  selectCurrentConference,
  selectCurrentConferenceHandle,
  selectHasPermission,
} from '../../selectors/user';
import { withIsAdministrator } from '../../providers/IsAdministratorProvider';
import emptyStatePastIllustration from '../../assets/images/access-denied-state.svg';
import EntityEmptyState from '../../components/EntityEmptyState';

class ConferenceWrapper extends PureComponent {
  static propTypes = {
    conferenceHandle: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.shape({
        conferenceHandle: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.redirect();
  }

  componentDidUpdate() {
    this.redirect();
  }

  redirect() {
    const { features, history, conferenceHandle, match } = this.props;

    if (!conferenceHandle) {
      history.push('/');
    }

    if (match.isExact) {
      // Navigate to the first page we have access to.
      return history.push(
        [
          [features.schedule, `/@${conferenceHandle}/days/0`],
          [features.speakers, `/@${conferenceHandle}/speakers`],
          [features.sponsors, `/@${conferenceHandle}/sponsors`],
          [features.broadcasts, `/@${conferenceHandle}/broadcasts`],
          [features.moderator, `/@${conferenceHandle}/moderator`],
          [true, `/@${conferenceHandle}/settings`],
        ].find(([condition]) => condition)[1]
      );
    }
  }

  render() {
    const {
      features,
      conference,
      conferenceHandle,
      hasPermission,
    } = this.props;

    if (!conferenceHandle) {
      return null;
    }

    if (!hasPermission) {
      return (
        <EntityEmptyState
          illustration={emptyStatePastIllustration}
          title={'Access Denied'}
          hint={`You don't have permissions to access requested page.`}
        />
      );
    }

    return (
      <Fragment>
        <Breadcrumb to={`/@${conferenceHandle}`}>{conference.name}</Breadcrumb>
        <Switch>
          <Route
            exact
            path="/@:conferenceHandle/account"
            component={AccountPage}
          />
          {features.broadcasts && (
            <Route
              path="/@:conferenceHandle/broadcasts"
              component={BroadcastPage}
            />
          )}
          <Route
            exact
            path="/@:conferenceHandle/support-inbox"
            component={SupportInbox}
          />
          <Route
            exact
            path="/@:conferenceHandle/settings"
            component={ConferenceSettingsPage}
          />
          <Route
            exact
            path="/@:conferenceHandle/settings/event"
            component={ConferenceSettingsEventPage}
          />
          <Route
            path="/@:conferenceHandle/settings/team"
            component={ConferenceSettingsTeamPage}
          />
          <Route
            path="/@:conferenceHandle/settings/integrations"
            component={ConferenceSettingsIntegrationsPage}
          />
          <Route
            path="/@:conferenceHandle/settings/theme-customization"
            component={ConferenceSettingsEmbedCustomization}
          />
          <Route
            path="/@:conferenceHandle/settings/mobile-app"
            component={ConferenceSettingsMobileAppCustomization}
          />
          {features.schedule && (
            <Route
              path="/@:conferenceHandle/days/:day"
              component={ConferencePage}
            />
          )}
          {features.schedule && (
            <Route
              path="/@:conferenceHandle/breaks/days/:day"
              component={BreaksPage}
            />
          )}
          {features.speakers && (
            <Route
              path="/@:conferenceHandle/speakers"
              component={SpeakersPage}
            />
          )}
          {features.sponsors && (
            <Route
              path="/@:conferenceHandle/sponsors"
              component={SponsorsPage}
            />
          )}
          {features.moderator && (
            <Route
              path="/@:conferenceHandle/moderator"
              component={ModeratorPage}
            />
          )}
          <Route
            exact
            path="/@:conferenceHandle/conversations"
            component={ChatbotConfiguration}
          />
          <Route
            path="/@:conferenceHandle/conversations/chatbuilder"
            component={ChatBuilderPage}
          />
          <Route
            path="/@:conferenceHandle/conversations/chatmenu"
            component={ChatMenuPage}
          />
          <Route
            path="/@:conferenceHandle/conversations/chat-quick-replies"
            component={ChatQuickRepliesPage}
          />
          <Route
            path="/@:conferenceHandle/supportinbox/:ticketId?"
            component={SupportInbox}
          />
          <Route
            exact
            path="/@:conferenceHandle/cfp/form-builder"
            component={CallForPapersFormBuilderPage}
          />
          <Route path="/@:conferenceHandle/cfp" component={CallForPapersPage} />
          <Route component={NotFound} />
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = createSelector(
  selectCurrentConferenceHandle,
  selectCurrentConference,
  selectHasPermission,
  (conferenceHandle, conference, hasPermission) => {
    return {
      conferenceHandle,
      conference: conference.toJS(),
      features: conference.get('features', Map()).toJS(),
      hasPermission,
    };
  }
);

export default compose(
  withRouter,
  withIsAdministrator,
  connect(mapStateToProps)
)(ConferenceWrapper);
