/*
 *  CreateSessionPage Messages
 *
 *  This contains all the text for the UsersPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultLabel: {
    id: 'app.components.QuickReplyItem.defaultLabel',
    defaultMessage: 'Label not provided',
  },
  edit: {
    id: 'app.components.QuickReplyItem.edit',
    defaultMessage: 'Edit',
  },
});
