import React from 'react';
import styled from 'styled-components';

import Spinner from '../Spinner';
import { button } from '../../utils/ui/interactive';

const Button = styled.button`
  padding: 12px;
  min-width: 150px;
  ${button()};
`;

const SubmitButton = ({ submitting, label, children }) => (
  <Button disabled={submitting}>
    {submitting ? <Spinner size={2} barThickness={2} /> : label || children}
  </Button>
);

export default SubmitButton;
