import {
  BROADCAST_TEXT_LIMIT,
  BROADCAST_BUTTON_LIMIT,
  BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT,
  BROADCAST_GENERIC_ITEM_TITLE_LIMIT,
} from '../../constants';

const isImage = file => !!file.type.match('image.*');
const fileMaxSize = 4 * 1000 * 1000;

export default (form, { formKey }) => {
  const itemsModel = { items: undefined, item: undefined };
  const model = formKey ? form[formKey] : form;
  const errorsModel = formKey ? { [formKey]: itemsModel } : itemsModel;
  const errors = formKey ? errorsModel[formKey] : errorsModel;

  if (model && model.type === 'text') {
    if (!model.message) {
      errors.message = 'Message must contain one character at least.';
    } else if (model.message.length > BROADCAST_TEXT_LIMIT) {
      errors.message = `Message needs to be less than ${BROADCAST_TEXT_LIMIT} characters.`;
    }
  }

  if (
    model &&
    (model.type === 'generic' || model.type === 'followUp') &&
    !model.item
  ) {
    errors.item = 'Item is a required field.';
  } else if (
    model &&
    (model.type === 'generic' || model.type === 'followUp') &&
    model.item
  ) {
    if (model.type === 'generic') {
      if (
        !model.item.imageUrl ||
        (model.item.imageUrl instanceof FileList && !model.item.imageUrl.length)
      ) {
        errors.item = 'File is a required field.';
      }

      if (
        model.item.imageUrl instanceof FileList &&
        model.item.imageUrl.length
      ) {
        const file = model.item.imageUrl[0];

        if (!isImage(file)) {
          errors.item = 'File must be a png or jpg.';
        }

        if (file.size > fileMaxSize) {
          errors.item = 'File cant be larger than 4MB.';
        }
      }
    }

    if (
      !model.item.title ||
      (model.item.title &&
        model.item.title.length > BROADCAST_GENERIC_ITEM_TITLE_LIMIT)
    ) {
      errors.item = `Title needs to be less than ${BROADCAST_GENERIC_ITEM_TITLE_LIMIT} characters.`;
    }

    if (
      !model.item.subtitle ||
      (model.item.subtitle &&
        model.item.subtitle.length > BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT)
    ) {
      errors.item = `Description needs to be less than ${BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT} characters.`;
    }

    if (model.item.buttons) {
      // TODO Implement validation.
    } else if (model.item.buttonLabel || model.item.action) {
      if (
        !model.item.buttonLabel ||
        (model.item.buttonLabel &&
          model.item.buttonLabel.length > BROADCAST_BUTTON_LIMIT)
      ) {
        errors.item = `Button label needs to be less than ${BROADCAST_BUTTON_LIMIT} characters.`;
      }
    }
  }

  if (model && model.type === 'list') {
    if (!model.items || model.items.length < 2) {
      errors.items = 'At least two items are required';
    } else {
      errors.items = [...validateListItems(model.items, errors.items)];
    }
  }

  return errorsModel;
};

const validateListItems = (listItems, errorsModel = []) => {
  const errors = [...errorsModel];

  listItems.forEach((listItem, i) => {
    if (
      !listItem.imageUrl ||
      (listItem.imageUrl instanceof FileList && !listItem.imageUrl.length)
    ) {
      errors[i] = { ...errors[i], imageUrl: 'File is a required field.' };
    }

    if (listItem.imageUrl instanceof FileList && listItem.imageUrl.length) {
      const file = listItem.imageUrl[0];

      if (!isImage(file)) {
        errors[i] = { ...errors[i], imageUrl: 'File must be a png or jpg.' };
      }

      if (file.size > fileMaxSize) {
        errors[i] = { ...errors[i], imageUrl: 'File cant be larger than 4MB.' };
      }
    }

    if (
      !listItem.title ||
      (listItem.title &&
        listItem.title.length > BROADCAST_GENERIC_ITEM_TITLE_LIMIT)
    ) {
      errors[i] = {
        ...errors[i],
        title: `Description needs to be less than ${BROADCAST_GENERIC_ITEM_TITLE_LIMIT} characters.`,
      };
    }

    if (
      !listItem.subtitle ||
      (listItem.subtitle &&
        listItem.subtitle.length > BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT)
    ) {
      errors[i] = {
        ...errors[i],
        subtitle: `Description needs to be less than ${BROADCAST_GENERIC_ITEM_SUBTITLE_LIMIT} characters.`,
      };
    }

    if (listItem.buttons) {
      // TODO Implement validation.
    } else if (listItem.buttonLabel || listItem.action) {
      if (
        !listItem.buttonLabel ||
        (listItem.buttonLabel &&
          listItem.buttonLabel.length > BROADCAST_BUTTON_LIMIT)
      ) {
        errors[i] = {
          ...errors[i],
          buttonLabel: `Button label needs to be less than ${BROADCAST_BUTTON_LIMIT} characters.`,
        };
      }
    }
  });

  return errors;
};
