import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import responsive from '../../../utils/ui/responsive';

export const Container = styled(Flexbox)`
  padding: 1.5em;

  ${responsive.md.andSmaller`
    flex-wrap: wrap;
    background-image: none;
  `};
`;

export const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  ${props => props.theme.altitude(1)};
  padding: 1.5em;
  margin: 0 0.75em;
  border-radius: 0.25em;
  border: ${props => props.theme.borders.default};

  > div {
    margin-bottom: 1em;
  }
`;

export const Title = styled.h3`
  margin-top: 0;
`;

export const SubTitle = styled.p``;

export const ScreenTitle = styled.p`
  font-weight: bold;
  margin-top: 0;
`;

export const ScreenRows = styled(Flexbox)`
  flex-direction: column;
`;

export const ScreenRow = styled(Flexbox)`
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0;
  }

  ${responsive.md.andSmaller`
    flex-wrap: wrap;
  `};
`;

export const ScreenImage = styled.img`
  width: 40%;
  height: 100%;

  ${responsive.md.andSmaller`
    width: 100%;
  `};
`;

export const ScreenInfo = styled(Flexbox)`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 1em;

  ${responsive.md.andSmaller`
    margin-left: 0em;
    margin-top: 1em;
  `};
`;

export const Link = styled.a`
  padding: 0.75em 1em;
  background-color: ${props => props.theme.buttons.link.backgroundColor};
  color: ${props => props.theme.buttons.link.color};
  font-weight: 600;
  text-decoration: none;
  border-radius: 0.25em;

  &:hover {
    opacity: 0.85;
  }
`;
