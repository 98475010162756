import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import firebase from 'firebase/app';
import { fromJS } from 'immutable';

import { acceptUser, logInError } from '../../../actions/user';

export default function* logIn({ email, password }) {
  try {
    const ref = yield firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    yield put(acceptUser(fromJS(ref.user.toJSON())));
    yield put(push('/'));
  } catch (error) {
    yield put(logInError(error));
  }
}
