/**
 *
 * TelegramSettings Form
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import Form from '../Form';

import { button } from '../../utils/ui/interactive';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

const Button = styled.button`
  padding: 12px;
  margin: 0px 10px 0px 0px;

  ${button()};
`;

const TelegramSettingsForm = props => {
  const {
    handleSubmit,
    pristine,
    submitting,
    isDisconnectVisible,
    disconnecting,
    onDisconnect,
  } = props;

  const handleDisconnectClick = async () => {
    await onDisconnect();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset>
        <Flexbox alignItems="center">
          <fieldset>
            <FormattedMessage {...messages.apiToken} />
            <Field
              name="token"
              placeholder={messages.apiToken.defaultMessage}
              component="input"
              type="text"
              required
            />
          </fieldset>
        </Flexbox>
      </fieldset>

      <fieldset>
        <Button disabled={pristine || submitting}>
          <FormattedMessage {...messages.save} />
        </Button>
        {isDisconnectVisible ? (
          <Button
            type={'button'}
            disabled={disconnecting}
            onClick={handleDisconnectClick}
          >
            <FormattedMessage {...messages.disconnect} />
          </Button>
        ) : null}
      </fieldset>
    </Form>
  );
};

TelegramSettingsForm.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'telegramSettings',
})(injectIntl(TelegramSettingsForm));
