/*
 * ApiSettingsForm Messages
 *
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  apiWanted: {
    id: 'app.components.ApiSettingsForm.venueLocation',
    defaultMessage: 'Want to use our API?',
  },
  save: {
    id: 'app.components.ApiSettingsForm.save',
    defaultMessage: 'Save',
  },
});
