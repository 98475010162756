/**
 *
 * TimeInputControl
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { injectIntl, intlShape } from 'react-intl';
import messages from './messages';

const addColon = (value, deletion) =>
  deletion ? value.substr(0, value.length - 1) : `${value}:`;
const addM = (value, deletion) =>
  deletion
    ? value.substr(0, value.length - 2)
    : `${
        value.indexOf(' ') < 0
          ? `${value.substring(0, value.length - 1)} ${value.substring(
              value.length - 1,
              value.length
            )}`
          : value
      }M`.toUpperCase();

// TODO Rewrite this madness.
const ACCEPTABLE_STATES = List([
  { pattern: /^$/ },
  { pattern: /^2[0-3]$/, transform: addColon },
  { pattern: /^2[0-3]:$/ },
  { pattern: /^2[0-3]:[0-5]$/ },
  { pattern: /^2[0-3]:[0-5]\d$/ },
  { pattern: /^[01]\d$/, transform: addColon },
  { pattern: /^[01]\d:$/ },
  { pattern: /^[01]\d:[0-5]$/ },
  { pattern: /^[01]\d:[0-5]\d$/ },
  { pattern: /^[01][0-2]:[0-5]\d $/ },
  { pattern: /^[01][0-2]:[0-5]\d\s?[aA]$/, transform: addM },
  { pattern: /^[01][0-2]:[0-5]\d\s?[pP]$/, transform: addM },
  { pattern: /^[3-9]$/, transform: addColon },
  { pattern: /^[1-2]$/ },
  { pattern: /^[1-9]:$/ },
  { pattern: /^[1-9]:[0-5]$/ },
  { pattern: /^[1-9]:[0-5]\d$/ },
  { pattern: /^[1-9]:[0-5]\d $/ },
  { pattern: /^[1-9]:[0-5]\d\s?[aA]$/, transform: addM },
  { pattern: /^[1-9]:[0-5]\d\s?[pP]$/, transform: addM },
  { pattern: /^\d$/ },
]);

const TimeInputControl = ({
  value,
  onChange,
  onFocus,
  onBlur,
  className,
  style,
  intl,
}) => {
  const handleChange = e => {
    const potentialNewValue = e.target.value;
    const acceptableState = ACCEPTABLE_STATES.find(state => {
      const match = potentialNewValue.match(state.pattern);

      return (
        match &&
        match.length === 1 &&
        match[0].length === potentialNewValue.length
      );
    });

    if (acceptableState) {
      onChange(
        acceptableState.transform
          ? acceptableState.transform(
              potentialNewValue,
              e.target.value.length < (value || '').length
            )
          : potentialNewValue
      );
    }
  };

  return (
    <input
      type="text"
      value={value || ''}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      style={style}
      size={14}
      placeholder={intl.formatMessage(messages.placeholder)}
    />
  );
};

TimeInputControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.any,
  style: PropTypes.any,
  intl: intlShape.isRequired,
};

export default injectIntl(TimeInputControl);
